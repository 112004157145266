import { PhaseContract, ProjectStatisticsContract } from '@moonpanda/moonpanda.contracts';

import { ApiPaginateRequestType, ApiPatchOperationType } from 'src/models/API';
import { ApiFunctionType, httpDelete, httpGet, httpPatch, httpPost, httpPut } from 'src/utils/http';

const getPath = (projectId: number, phaseId?: number) => `Projects/${projectId}/Phases${phaseId ? `/${phaseId}` : ''}`;

export const apiGetPhases: ApiFunctionType<ApiPaginateRequestType & { projectId: number }, PhaseContract[]> = (data) =>
  httpGet(getPath(data.projectId), data);

export const apiAddPhasesToProject: ApiFunctionType<PhaseContract & { projectId: number }, PhaseContract> = (data) =>
  httpPost(getPath(data.projectId), data);

export const apiReplacePhaseProject: ApiFunctionType<PhaseContract & { projectId: number }, PhaseContract> = (data) =>
  httpPut(getPath(data.projectId, data.id), data);

export const apiUpdatePhaseProject: ApiFunctionType<ApiPatchOperationType & { projectId: number }, PhaseContract> = (
  data,
) => httpPatch(getPath(data.projectId, data.id), data);

export const apiDeletePhaseProject: ApiFunctionType<{ id: number; projectId: number }, void> = (data) =>
  httpDelete(getPath(data.projectId, data.id), data);

export const apiGetPhaseData: ApiFunctionType<{ projectId: number; phaseId: number }, PhaseContract[]> = (data) =>
  httpGet(getPath(data.projectId, data.phaseId), data);

export const apiGetPhaseStatistics: ApiFunctionType<
  { projectId: number; phaseId: number },
  ProjectStatisticsContract
> = (data) => httpGet(`${getPath(data.projectId, data.phaseId)}/taskstatus`, data);
