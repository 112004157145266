import { ErrorsContract } from '@moonpanda/moonpanda.contracts';
import { AxiosError, AxiosResponse } from 'axios';
import { useCallback, useEffect, useRef } from 'react';

import { CommonApiResponseType } from 'src/models/API';
import { SafeAnyType } from 'src/utils/safeAny';
import { PromiseReturnType } from 'src/utils/types';

const isHttpResponseValid = ({ isSuccess }: CommonApiResponseType) => isSuccess;

export class ApiError {
  readonly name: string;

  readonly message: string;

  readonly errors: ErrorsContract[] | null;

  constructor(message: string, response: CommonApiResponseType) {
    this.name = 'ApiError';
    this.message = message;
    this.errors = response.errors;
  }
}

export type FNPropsType = <F extends (data: never) => SafeAnyType>(
  apiFunction: F,
  data?: Parameters<F>[0],
  options?: {
    showErrors?: boolean; // shows always if not set false
    key?: string | number; // the custom unique key for functions with different parameters
    expectBlob?: boolean;
    outerCall?: boolean;
  },
) => Promise<PromiseReturnType<F>>;

const hashCode = (string: string): string => {
  let hash = 0;

  for (let i = 0, len = string.length; i < len; i += 1) {
    const chr = string.charCodeAt(i);

    // eslint-disable-next-line no-bitwise
    hash = (hash << 5) - hash + chr;
    // eslint-disable-next-line no-bitwise
    hash |= 0; // Convert to 32bit integer
  }

  return hash.toString();
};

type useRequestType = {
  getStatic: () => {
    abort: (specificPromise?: Promise<SafeAnyType>) => void;
    apiCaller: FNPropsType;
  };
  (): {
    abort: (specificPromise?: Promise<SafeAnyType>) => void;
    apiCaller: FNPropsType;
  };
};

// todo types
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const useRequest: useRequestType = () => {
  const map = useRef<Map<string, { controller: AbortController; promise: Promise<SafeAnyType> }>>(new Map());

  /**
   * search in map by the given promise
   * returns object key or null
   */
  const searchByPromise = useCallback(
    (searchValue: Promise<SafeAnyType>) =>
      [...map.current.entries()].find(([, value]) => value.promise === searchValue)?.[0] ?? null,
    [],
  );

  const apiCaller: FNPropsType = useCallback((apiFunction, apiArguments, options) => {
    // the unique name of every given function
    // if it is called the second time, it must be canceled
    const pathMapKey = options?.key?.toString() ?? hashCode(apiFunction.name);

    if (map.current.has(pathMapKey)) {
      map.current.get(pathMapKey)?.controller.abort();
      map.current.delete(pathMapKey);
    }

    const controller = new AbortController();

    const promise = new Promise<SafeAnyType>((resolve, reject) => {
      apiFunction
        // (type never) is a tricky way
        .call(null, { ...(apiArguments || {}), signal: controller.signal } as never)
        .then((response: SafeAnyType) => {
          /**
           * todo
           *
           * httpGet(data.url, data, {
           *     ignoreBaseUrl: true,
           *     expectBlob: true, <-----
           *   });
           *
           *   apiCaller(
           *      apiGetDocument,
           *      {
           *        url: file.url,
           *      },
           *      {
           *        expectBlob: true, <-----
           *      },
           *    )
           */

          if (
            options?.outerCall ||
            (options?.expectBlob && response instanceof Blob) ||
            isHttpResponseValid(response)
          ) {
            resolve(response as AxiosResponse['data']);
          } else {
            const error = new ApiError(response?.error || `Invalid response "${apiFunction.name}"`, response);

            reject(error);
          }

          map.current.delete(pathMapKey);
        })
        .catch((errorResponse: AxiosError) => {
          // is this need to be returned?
          if (errorResponse.name !== 'CanceledError') {
            const error = new ApiError(
              errorResponse.message,
              errorResponse.response as unknown as CommonApiResponseType,
            );

            reject(error);
          }
        });
    });

    map.current.set(pathMapKey, { controller, promise });

    return promise;
  }, []);

  const abort = useCallback(
    (specificPromise?: Promise<SafeAnyType>) => {
      if (specificPromise) {
        const keyMap = searchByPromise(specificPromise);

        if (keyMap) {
          map.current.get(keyMap)?.controller.abort();
          map.current.delete(keyMap);
        }
      } else {
        // clear the map
        map.current.forEach((value) => {
          value.controller.abort();
        });
        map.current.clear();
      }
    },
    [searchByPromise],
  );

  useEffect(
    () => () => {
      abort();
    },
    [abort],
  );

  useRequest.getStatic = () => ({ abort, apiCaller });

  return { abort, apiCaller };
};
