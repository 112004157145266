/* eslint-disable max-len */
import { FC } from 'react';

type Props = {
  className?: string;
  onClick: () => void;
};

export const IconPercent: FC<Props> = ({ className, onClick }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="15"
    viewBox="0 0 10 10"
    fill="none"
    className={className}
    onClick={onClick}
  >
    <path
      d="M4.79983 0.00235748C3.80761 0.0428953 2.86342 0.3703 2.06432 0.950747C1.23864 1.55056 0.61434 2.38895 0.278953 3.34845C0.0594929 3.97692 -0.0311312 4.63431 0.00940438 5.30823C0.076017 6.42342 0.519068 7.48826 1.26368 8.32278C1.47695 8.56162 1.71655 8.78393 1.96621 8.97501C2.54946 9.42119 3.22359 9.73258 3.94394 9.88854C4.46341 10.0009 5.01387 10.0287 5.55245 9.97013C6.5364 9.86272 7.47543 9.45837 8.23064 8.81673C8.94917 8.20607 9.47485 7.42138 9.76169 6.53083C9.88795 6.13836 9.96489 5.72988 9.99071 5.31597C10.0031 5.11328 10.0031 4.88529 9.99071 4.68337C9.95688 4.13959 9.83786 3.6193 9.63105 3.10961C9.54688 2.90227 9.43405 2.67401 9.31735 2.47442C8.85158 1.67811 8.16067 1.01452 7.34841 0.583062C7.23325 0.522125 7.06853 0.443114 6.96474 0.39922C6.38924 0.15599 5.81658 0.0279198 5.18918 0.00209904C5.11637 -0.000741005 4.87496 -0.000741005 4.79983 0.00235748ZM5.18453 0.575315C5.88577 0.606042 6.54338 0.791692 7.15322 1.13123C7.64661 1.40596 8.09689 1.78656 8.45216 2.22964C8.99745 2.90924 9.32794 3.7231 9.40849 4.58525C9.42966 4.81221 9.43276 5.06887 9.41753 5.30048C9.36641 6.06813 9.11364 6.80995 8.68427 7.45263C8.30086 8.02636 7.7912 8.50223 7.19272 8.84513C6.62316 9.1715 5.98853 9.36593 5.33299 9.41473C5.21216 9.42377 5.16491 9.42532 4.99993 9.42532C4.80912 9.42532 4.71979 9.42119 4.56359 9.40595C4.08465 9.35844 3.62895 9.23683 3.18486 9.03801C3.10689 9.00289 2.937 8.91769 2.86213 8.87586C2.54714 8.70002 2.27991 8.50765 2.01269 8.26468C1.95692 8.21407 1.82524 8.08368 1.7687 8.02326C1.51645 7.75344 1.30577 7.46424 1.124 7.13787C1.0814 7.06119 0.996458 6.89206 0.960828 6.81228C0.762798 6.37023 0.641191 5.91398 0.593943 5.43604C0.57871 5.28008 0.574579 5.19049 0.574579 4.99967C0.574579 4.83442 0.576128 4.7882 0.585164 4.66659C0.636802 3.97356 0.849033 3.30997 1.21024 2.71248C1.50173 2.23016 1.89186 1.79844 2.34188 1.4607C3.06171 0.92002 3.90934 0.615596 4.80628 0.575574C4.87574 0.572475 5.11534 0.572475 5.18453 0.575315Z"
      fill="#8D9998"
    />
    <path
      d="M7.17478 2.5522C7.13811 2.55814 7.10042 2.57234 7.07176 2.59119C7.0573 2.60049 6.59437 3.0619 4.83611 4.82003C3.61642 6.03927 2.6131 7.04395 2.60613 7.05273C2.56611 7.10231 2.54649 7.17047 2.5545 7.23218C2.56921 7.34605 2.65467 7.43152 2.76853 7.44624C2.83024 7.45424 2.8984 7.43462 2.94798 7.3946C2.95675 7.38762 3.96136 6.38424 5.18053 5.16447C6.93853 3.40609 7.39992 2.94313 7.40921 2.92867C7.42393 2.9062 7.43529 2.88013 7.44278 2.85198C7.45052 2.82229 7.45052 2.76497 7.44252 2.7363C7.41799 2.64645 7.35267 2.58112 7.26411 2.55814C7.24474 2.55298 7.19207 2.54962 7.17478 2.5522Z"
      fill="#8D9998"
    />
    <path
      d="M3.76338 2.79615C3.43884 2.81423 3.14605 2.98232 2.96454 3.25473C2.87056 3.39597 2.8135 3.56251 2.79879 3.73861C2.79492 3.78405 2.79724 3.89973 2.80292 3.94233C2.82771 4.13056 2.89483 4.29504 3.00637 4.44067C3.10397 4.56796 3.23306 4.67305 3.37532 4.74122C3.63119 4.86412 3.92139 4.87549 4.18939 4.77375C4.38381 4.69965 4.55602 4.56047 4.67453 4.3818C4.76722 4.24133 4.82014 4.09235 4.83925 3.91651C4.84338 3.87804 4.84338 3.75849 4.83925 3.72027C4.81162 3.47136 4.71145 3.26118 4.53923 3.09051C4.35954 2.91261 4.12846 2.81087 3.87053 2.7959C3.82096 2.79306 3.81631 2.79306 3.76338 2.79615ZM3.86949 3.279C4.12768 3.30405 4.32881 3.50131 4.3585 3.75875C4.36263 3.79412 4.36057 3.87597 4.35463 3.90876C4.33862 3.9981 4.30609 4.07272 4.25161 4.14631C4.2307 4.17446 4.185 4.22145 4.15815 4.24262C4.05978 4.31983 3.9423 4.36062 3.81889 4.36062C3.69496 4.36062 3.5749 4.31802 3.47808 4.24004C3.45304 4.2199 3.40605 4.1711 3.38694 4.1458C3.30768 4.03993 3.26869 3.90696 3.27928 3.77837C3.28986 3.64823 3.34176 3.53282 3.43006 3.44167C3.54521 3.32341 3.70761 3.26325 3.86949 3.279Z"
      fill="#8D9998"
    />
    <path
      d="M6.12321 5.15745C6.11753 5.15797 6.09945 5.15978 6.08319 5.16107C5.94996 5.17295 5.80667 5.21684 5.68971 5.28139C5.47851 5.3981 5.30966 5.59072 5.226 5.81045C5.15578 5.99559 5.13899 6.18976 5.17721 6.38109C5.21774 6.58456 5.31689 6.76711 5.46534 6.91196C5.62929 7.07179 5.82629 7.16681 6.05737 7.19702C6.09997 7.2027 6.21564 7.20502 6.26108 7.20115C6.46092 7.18437 6.63984 7.11646 6.79811 6.99743C6.92695 6.90034 7.03616 6.76711 7.10458 6.6238C7.20011 6.42318 7.22825 6.19957 7.18539 5.98087C7.1366 5.73299 6.99847 5.51378 6.79295 5.35937C6.64733 5.24989 6.47977 5.18405 6.29232 5.16236C6.25927 5.15849 6.14541 5.15513 6.12321 5.15745ZM6.24326 5.64133C6.38966 5.65915 6.52056 5.73299 6.61015 5.84867C6.76532 6.04878 6.76016 6.33177 6.59827 6.52698C6.46092 6.69223 6.23397 6.76168 6.02897 6.70101C5.99463 6.69094 5.97346 6.68242 5.93809 6.66486C5.8046 6.59824 5.70184 6.47533 5.66105 6.33332C5.56888 6.01263 5.77878 5.68884 6.10901 5.64262C6.13896 5.63849 6.21357 5.63772 6.24326 5.64133Z"
      fill="#8D9998"
    />
  </svg>
);
