import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { apiGetProjectDashboardWeeklyTaskOverviewData } from 'src/api/projects';
import { UiLoader } from 'src/components/UI/loaders';
import { IconHeader } from 'src/pages/Dashboard/widgets/totalTaskOverview/icons/Header';
import { WidgetTotalTaskOverviewModal } from 'src/pages/Dashboard/widgets/totalTaskOverview/modal';
import useProjectsStore from 'src/pages/Projects/store';
import { useTHelper } from 'src/utils/i18n';

import styles from 'src/pages/Projects/view/widgets/Summary/styles.module.scss';

const Block = ({ value, text }: { value: number | undefined; text: string }) => (
  <div className={styles.infoBlock}>
    <div className={styles.value}>{value || 0}</div>
    <div className={styles.text}>{text}</div>
  </div>
);

type Props = {
  projectId: number;
};

export const ProjectSummaryWidgetTotalTaskOverviewPartial: FC<Props> = ({ projectId }) => {
  const tHelper = useTHelper('pages.projects.projectPage.widgets.summary.totalTaskOverview');

  const { t } = useTranslation();

  const { widgetTotalTaskOverviewData, loading, refreshWidgetTotalTaskOverviewData, refreshWidgetTimeTrackingData } =
    useProjectsStore((state) => ({
      widgetTotalTaskOverviewData: state.widgetTotalTaskOverviewData.response,
      loading: state.widgetTotalTaskOverviewData.loading,
      refreshWidgetTotalTaskOverviewData: state.refreshWidgetTotalTaskOverviewData,
      refreshWidgetTimeTrackingData: state.refreshWidgetTimeTrackingData,
    }));

  const [showModal, setShowModal] = useState(false);

  return (
    <div className={styles.widgetBottom}>
      {loading ? (
        <UiLoader />
      ) : (
        <>
          <div>
            <IconHeader />
          </div>

          <div className={styles.header}>{tHelper('title')}</div>

          <div className={styles.info}>
            <Block value={widgetTotalTaskOverviewData?.inProgress} text={t('common.taskStatuses.InProgress')} />
            <Block value={widgetTotalTaskOverviewData?.inReview} text={t('common.taskStatuses.InReview')} />
            <Block value={widgetTotalTaskOverviewData?.completed} text={t('common.taskStatuses.Completed')} />
            <Block value={widgetTotalTaskOverviewData?.notStarted} text={t('common.taskStatuses.NotStarted')} />
          </div>

          <span
            className={styles.viewModal}
            role="presentation"
            onClick={() => {
              setShowModal(true);
            }}
          >
            {tHelper('viewAll')}
          </span>

          {showModal && (
            <WidgetTotalTaskOverviewModal
              apiFn={apiGetProjectDashboardWeeklyTaskOverviewData}
              projectId={projectId}
              onClose={(somethingChanged) => {
                setShowModal(false);

                if (somethingChanged) {
                  refreshWidgetTotalTaskOverviewData();
                  refreshWidgetTimeTrackingData();
                }
              }}
            />
          )}
        </>
      )}
    </div>
  );
};
