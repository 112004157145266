import classNames from 'classnames';
import isFunction from 'lodash/isFunction';
import React, { memo, ReactNode, useCallback, useEffect, useMemo } from 'react';

import { UiCommonInputLabel, UiCommonInputWrapper } from 'src/components/UI/inputs/common/html';
import { useInputBaseHook } from 'src/hooks/inputs';
import { getDOMElement } from 'src/utils/DOM';
import { useIdOrRandomString } from 'src/utils/formHelper';
import { getStringFromUiLabel } from 'src/utils/reactDom';
import { StateFormInputOptionsType, StateFormReturnType } from 'src/utils/stateForm';

export type UiCheckboxType = {
  formProps: StateFormReturnType;
  name: string;

  label?: string | ReactNode;
  required?: boolean;
  disabled?: boolean; // warning - can't be checked and disabled at the same time. using class 'disabled' instead
  onChange?: (name: string, checked: boolean) => void;
  id?: string;
  wrapperClassName?: string;
  className?: string;
  validate?: StateFormInputOptionsType['validate'];
  requiredMessage?: string;
  classNameLabel?: string;
  errorLabel?: string;
  type?: 'checkbox';
  colorOnChecked?: string;

  customCheckedValueForInnerUsageOnly?: boolean; // only for usage in UiCheckboxGroup
};

export const UiCheckbox: React.FC<UiCheckboxType> = memo(
  ({
    formProps: { onChange: formOnChange, register, unregister, getSubscribeProps },
    name,
    label,
    id: propId,
    disabled,
    required = false,
    className,
    wrapperClassName,
    onChange,
    validate,
    errorLabel,
    type = 'checkbox',
    classNameLabel,
    requiredMessage,
    customCheckedValueForInnerUsageOnly,
    colorOnChecked,
  }) => {
    const inputOptions = useMemo(
      (): StateFormInputOptionsType => ({
        required,
        validate,
        requiredMessage,
        errorLabel: errorLabel || getStringFromUiLabel(label) || undefined,
      }),
      [required, validate, requiredMessage, errorLabel, label],
    );

    const [value, errors] = useInputBaseHook<boolean | undefined | null>({
      getSubscribeProps,
      name,
      inputOptions,
      unregister,
      register,
      type,
    });

    const hasErrors = !!errors?.length;

    const id = useIdOrRandomString(propId);

    useEffect(() => {
      if (colorOnChecked) {
        // todo ref?
        const element = getDOMElement(`label[for="${id}"]`);

        const props = [
          '--checkboxBackgroundColor',
          '--checkboxBorderColor',
          '--checkboxCheckedBorderColor',
          '--checkboxCheckedBackgroundColor',
        ];

        if (value) {
          props.forEach((prop) => {
            element?.style.setProperty(prop, colorOnChecked);
          });
        } else {
          props.forEach((prop) => {
            element?.style.removeProperty(prop);
          });
        }
      }
    }, [colorOnChecked, id, value]);

    const onChangeLocal = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!disabled) {
          formOnChange(name, e.target.checked);

          if (isFunction(onChange)) {
            onChange(name, e.target.checked);
          }
        }
      },
      [disabled, formOnChange, name, onChange],
    );

    const checked =
      customCheckedValueForInnerUsageOnly === undefined ? value || false : customCheckedValueForInnerUsageOnly;

    return (
      <UiCommonInputWrapper className={classNames(wrapperClassName, 'checkboxWrapper')}>
        <input
          type="checkbox"
          className={classNames('UiInput checkbox', className, {
            disabled,
          })}
          onChange={onChangeLocal}
          checked={checked}
          disabled={disabled}
          id={id}
          data-name={name}
        />

        <UiCommonInputLabel
          id={id}
          show
          hasErrors={hasErrors}
          required={required}
          disabled={disabled}
          className={classNameLabel}
        >
          <span>{label}</span>
        </UiCommonInputLabel>
      </UiCommonInputWrapper>
    );
  },
);
