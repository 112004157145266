import { TaskContract, TimeLogContract } from '@moonpanda/moonpanda.contracts';
import classNames from 'classnames';
import cloneDeep from 'lodash/cloneDeep';
import React, { FC, useCallback, useMemo, useState } from 'react';

import { apiGetTask } from 'src/api/tasks';
import { apiCreateTaskTimeLog, apiDeleteTaskTimeLog } from 'src/api/timeLogs';
import { UiDeleteConfirmPopover } from 'src/components/UI/DeleteConfirmPopover';
import { parseDateFromUiDatePicker, UiDatePicker } from 'src/components/UI/inputs/DatePicker';
import { UiMaskedInput } from 'src/components/UI/inputs/MaskedInput';
import { UiModal } from 'src/components/UI/Modals';
import { UiTable } from 'src/components/UI/table';
import { UiButton } from 'src/components/UI/UiButton';
import { SHORT_DATE_FORMAT } from 'src/config';
import { useRequest } from 'src/hooks/useRequest';
import { BaseSeparateModal } from 'src/models/modals';
import { IconTimeTrackingWidgetHeader } from 'src/pages/Dashboard/widgets/timeTracking/icons/Header';
import { IconDelete } from 'src/pages/Dashboard/widgets/totalTaskOverview/icons/Delete';
import { CDate } from 'src/utils/CDate';
import { showErrorToast } from 'src/utils/errors';
import { useTHelper } from 'src/utils/i18n';
import { SafeAnyType } from 'src/utils/safeAny';
import { useStateForm } from 'src/utils/stateForm';

import styles from './styles.module.scss';

type Props = BaseSeparateModal & {
  timeLogs: TimeLogContract[];
  taskId: number;

  onChangeTimeLogs: (clonedTimeLogs: TimeLogContract[]) => void;
  onTotalLoggedChanged: (task: TaskContract) => void;
};

type FormValues = {
  date: string;
  time: string;
};

const emptyTimeLogItem = {
  id: -1,
  startTime: null as SafeAnyType,
  endTime: null,
  taskId: 0,
};

export const WidgetTotalTaskOverviewTimeLogsModal: FC<Props> = ({
  onClose,
  timeLogs,
  taskId,
  onChangeTimeLogs,
  onTotalLoggedChanged,
}) => {
  const tHelper = useTHelper('widgets.weeklyTaskOverview.timeLogged');

  const { apiCaller } = useRequest();

  const [opened, setOpened] = useState(false);

  const formProps = useStateForm<FormValues>();

  const refreshTaskData = useCallback(() => {
    apiCaller(apiGetTask, {
      taskId,
    })
      .then(({ data }) => {
        onTotalLoggedChanged(data);
      })
      .catch(showErrorToast);
  }, [apiCaller, onTotalLoggedChanged, taskId]);

  const columns = useMemo(
    () => [
      {
        title: tHelper('columns.date'),
        renderValue: ({ startTime, id }: TimeLogContract) => {
          if (id === emptyTimeLogItem.id) {
            return <UiDatePicker formProps={formProps} name="date" maxDate="today" />;
          }

          return CDate.format(new Date(startTime), SHORT_DATE_FORMAT);
        },
        className: classNames('text-center', styles.columnDate),
      },
      {
        title: tHelper('columns.timeTotal'),
        renderValue: ({ startTime, endTime, id }: TimeLogContract) => {
          if (id === emptyTimeLogItem.id) {
            return (
              <UiMaskedInput formProps={formProps} name="time" mask="99:99" placeholder={tHelper('timePlaceholder')} />
            );
          }

          const time = CDate.getDiff(endTime ? new Date(endTime) : new Date(), new Date(startTime), {
            roundingMethod: 'minutes',
          });

          return tHelper('timeTotalValue', {
            hours: time.hours,
            minutes: time.hours === '00' && time.minutes === '00' ? '01' : time.minutes,
          });
        },
        className: classNames('text-center', styles.columnTimeLogged),
      },
    ],
    [formProps, tHelper],
  );

  const additionalColumn = useMemo(
    () => ({
      renderValue: ({ id }: TimeLogContract) => {
        if (id === emptyTimeLogItem.id) {
          return (
            <IconDelete
              onClick={() => {
                const clonedTimeLogs = cloneDeep(timeLogs).filter(({ id }) => id !== emptyTimeLogItem.id);

                onChangeTimeLogs(clonedTimeLogs);

                formProps.reset();
              }}
            />
          );
        }

        return (
          <UiDeleteConfirmPopover
            placement="top"
            onConfirm={() => {
              apiCaller(apiDeleteTaskTimeLog, {
                taskId,
                timeLogId: id,
              })
                .then(() => {
                  refreshTaskData();

                  const clonedTimeLogs = cloneDeep(timeLogs).filter(({ id: timeLogId }) => timeLogId !== id);

                  onChangeTimeLogs(clonedTimeLogs);
                })
                .catch(showErrorToast);
            }}
          >
            <IconDelete />
          </UiDeleteConfirmPopover>
        );
      },
      className: styles.columnAdd,
    }),
    [apiCaller, formProps, onChangeTimeLogs, refreshTaskData, taskId, timeLogs],
  );

  const onSubmit = useCallback(() => {
    if (!opened) {
      const clonedTimeLogs = cloneDeep(timeLogs);

      clonedTimeLogs.push({
        ...emptyTimeLogItem,
        taskId,
      });

      onChangeTimeLogs(clonedTimeLogs);

      setOpened(true);
    } else {
      // eslint-disable-next-line no-new
      new Promise<TimeLogContract | null>((resolve, reject) => {
        const startTimeRaw = formProps.getValue('date');

        const time = formProps.getValue('time');

        if (startTimeRaw && time) {
          const startTime = parseDateFromUiDatePicker(startTimeRaw);

          const [hours, minutes] = time.split(':');

          const endTime = CDate.addTime(startTime, {
            hours: +hours,
            minutes: +minutes,
          });

          const model: TimeLogContract = {
            id: 0,
            startTime,
            endTime,
            taskId,
          };

          apiCreateTaskTimeLog(model)
            .then(({ data }) => {
              refreshTaskData();

              resolve(data);
            })
            .catch(reject);
        } else {
          resolve(null);
        }
      })
        .then((res) => {
          const clonedTimeLogs = cloneDeep(timeLogs).filter(({ id }) => id !== emptyTimeLogItem.id);

          if (res) {
            clonedTimeLogs.push(res);
          }

          onChangeTimeLogs(clonedTimeLogs);

          formProps.reset();

          setOpened(false);
        })
        .catch(showErrorToast);
    }
  }, [formProps, onChangeTimeLogs, opened, refreshTaskData, taskId, timeLogs]);

  return (
    <UiModal
      onClose={onClose}
      header={tHelper('headerAddTime')}
      bodyClassName={classNames(styles.wrapperTimeLogModal, styles.addTime)}
    >
      <IconTimeTrackingWidgetHeader />

      <UiTable
        data={{
          data: timeLogs,
          twoFactorConfirmation: false,
          isSuccess: true,
          errors: [],
          totalCount: timeLogs.length,
          loading: false,
        }}
        getData={() => null}
        columns={columns}
        additionalXRightColumn={additionalColumn}
      />

      <div className={styles.button}>
        <UiButton color="green" onClick={onSubmit}>
          {tHelper(opened ? 'saveTime' : 'addTime')}
        </UiButton>
      </div>
    </UiModal>
  );
};
