import axios, { AxiosError, AxiosRequestHeaders } from 'axios';
import axiosRetry from 'axios-retry';
import { Operation } from 'fast-json-patch/module/core';
import isEmpty from 'lodash/isEmpty';
import isNumber from 'lodash/isNumber';
import { stringify } from 'qs';

import { apiAuthenticationRefreshToken, apiAuthenticationRefreshTokenUrl } from 'src/api/authentication';
import { API_URL } from 'src/config';
import { ApiPatchOperationType, CommonApiRequestType, CommonApiResponseType } from 'src/models/API';
import { ROUTE_SIGN_IN_PAGE } from 'src/routes/paths';
import { getJwtToken, removeJwtToken, setJwtToken } from 'src/utils/localStorage';
import { objectToFormData } from 'src/utils/objectToFormData';
import { SafeAnyType } from 'src/utils/safeAny';

axios.defaults.baseURL = API_URL;
axios.defaults.withCredentials = true;

export const setHttpJwtToken = (token: string | null) => {
  if (token) {
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  } else delete axios.defaults.headers.common.Authorization;
};

/** Retry request on concurrency conflict on the server side.  */
axiosRetry(axios, {
  retries: 2,
  retryDelay: (retryCount, error) => {
    // eslint-disable-next-line no-console
    console.info(`Retry request to '${error?.config?.url}'. Try number: ${retryCount}.`);

    return retryCount * 100;
  },
  retryCondition: (error) => error?.response?.status === 409,
});

/** outer input */

let isAppStopped = false;

export const stopApp = () => {
  isAppStopped = true;
};

/** for PATCH verb */
const API_PATCH_DATA: unique symbol = Symbol('patch');

export const prepareDataToPatch = <R = Record<SafeAnyType, SafeAnyType>>(
  data: Operation[],
  otherData: R,
): ApiPatchOperationType<R> => ({
  [API_PATCH_DATA]: data,
  ...otherData,
});

export const isPatchDataEmpty = (data: ApiPatchOperationType): boolean => isEmpty(data[API_PATCH_DATA]);
/** end for PATCH verb */

const onLogout = () => {
  setHttpJwtToken(null);

  removeJwtToken();

  window.location.replace(ROUTE_SIGN_IN_PAGE);
};

axios.interceptors.response.use(
  ({ data }) =>
    // data = data as ResponseContract<SafeAnyType>;

    data,
  (error: AxiosError) => {
    if (error.response?.status === 401) {
      const tokenData = getJwtToken();

      if (tokenData) {
        stopApp();

        apiAuthenticationRefreshToken(tokenData)
          .then(({ data }) => {
            setJwtToken(data);

            // app.restart?
            // eslint-disable-next-line no-restricted-globals
            location.reload();
          })
          .catch(onLogout);

        return 'HIO';
      }
      onLogout();
    }

    // eslint-disable-next-line no-console
    console.info('http Error:', error);

    if (isNumber(error.response?.status)) {
      const statusCode = error.response?.status as number;

      if (statusCode >= 500) {
        onLogout();
      }
    }

    return Promise.reject(error);
  },
);

export type BaseApiFunctionType = (
  method: 'get' | 'post' | 'put' | 'patch' | 'delete',
  url: string,
  data?: CommonApiRequestType,
  options?: {
    asPrimitive?: boolean;
    asFormData?: boolean;
    expectBlob?: boolean;
    ignoreBaseUrl?: boolean;
    ignoreAuthHeader?: boolean;
    outerCall?: boolean;
    headers?: AxiosRequestHeaders;
  },
) => Promise<SafeAnyType>;

export type HttpApiFunctionType = (
  url: Parameters<BaseApiFunctionType>[1],
  data?: Parameters<BaseApiFunctionType>[2],
  options?: Parameters<BaseApiFunctionType>[3],
) => ReturnType<BaseApiFunctionType>;

export type ApiFunctionType<D = SafeAnyType, R = SafeAnyType> = (data: D) => Promise<CommonApiResponseType<R>>;

export type ApiFunctionOptionalParamType<D = SafeAnyType, R = SafeAnyType> = (
  data?: D,
) => Promise<CommonApiResponseType<R>>;

export type ApiFunctionNoParamType<R = SafeAnyType> = ApiFunctionOptionalParamType<never, R>;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const baseApiFunction: BaseApiFunctionType = (method, url, data, options) => {
  let promise: Promise<SafeAnyType>;

  const dataName = method === 'get' ? 'params' : 'data';

  const { signal, ...rest } = (data || {}) as Record<string, SafeAnyType>; // if not, see useRequest

  if (options?.asFormData) {
    promise = axios({
      method,
      url,
      signal,
      [dataName]: objectToFormData(rest),
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  } else if (method === 'get') {
    const stringData = stringify(rest, { encode: false, allowDots: true });

    const preparedUrl: string = (() => {
      if (options?.ignoreBaseUrl) {
        return url;
      }

      return stringData ? `${url}?${stringData}` : url;
    })();

    if (options?.ignoreAuthHeader) {
      promise = axios({
        method,
        withCredentials: false, // 1
        url: preparedUrl,
        signal,
        responseType: 'blob',
        headers: options?.headers,
        transformRequest: (data, headers) => {
          if (options.ignoreAuthHeader) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            delete headers?.common.Authorization; // 2
          }

          return data;
        },
      });
    } else {
      promise = axios({
        method,
        url: preparedUrl,
        signal,
        headers: options?.headers,
        withCredentials: options?.outerCall ? false : undefined, // 1
        responseType: options?.expectBlob ? 'blob' : undefined,
        transformRequest: (data, headers) => {
          if (options?.outerCall) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            delete headers?.common.Authorization; // 2
          }

          return data;
        },
      });
    }
  } else if (method === 'patch') {
    const data = rest[API_PATCH_DATA as SafeAnyType];

    if (isEmpty(data)) {
      signal.abort();
    }

    promise = axios({
      method,
      url,
      signal,
      [dataName]: data,
    });
  } else {
    promise = axios({
      method,
      url,
      signal,
      [dataName]: rest,
    });
  }

  return new Promise((resolve, reject) => {
    promise
      .then((response) => {
        // Ignore this special response
        if (!isAppStopped || url === apiAuthenticationRefreshTokenUrl) {
          resolve(response);
        }
      })
      .catch(reject);
  });
};

export const httpGet: HttpApiFunctionType = (url, data, options) => baseApiFunction('get', url, data, options);

export const httpPost: HttpApiFunctionType = (url, data, options) => baseApiFunction('post', url, data, options);

export const httpPut: HttpApiFunctionType = (url, data, options) => baseApiFunction('put', url, data, options);

export const httpPatch: HttpApiFunctionType = (url, data, options) => baseApiFunction('patch', url, data, options);

export const httpDelete: HttpApiFunctionType = (url, data, options) => baseApiFunction('delete', url, data, options);

/**
 * provide just a url, whereas data will be put indirectly
 * functions below provide a more convenient way to express api calls
 *
 * @example
 * httpGetCancellable('url', options) => (data) => Promise
 *    is equivalent to
 * httpGet('url', data, options) => Promise
 * */
type HttpApiCancellableFunctionType = (
  url: Parameters<BaseApiFunctionType>[1],
  options?: Parameters<BaseApiFunctionType>[3],
) => (data?: Parameters<BaseApiFunctionType>[2]) => ReturnType<BaseApiFunctionType>;

export const httpGetCancellable: HttpApiCancellableFunctionType = (url, options) => {
  // put the url as the name for useRequest
  // eslint-disable-next-line func-names
  const f = function (data?: Parameters<BaseApiFunctionType>[2]) {
    return httpGet(url, data, options);
  };

  Object.defineProperty(f, 'name', { value: url });

  return f;
};

export const httpPostCancellable: HttpApiCancellableFunctionType = (url, options) => {
  // put the url as the name for useRequest
  // eslint-disable-next-line func-names
  const f = function (data?: Parameters<BaseApiFunctionType>[2]) {
    return httpPost(url, data, options);
  };

  Object.defineProperty(f, 'name', { value: url });

  return f;
};

export const httpPutCancellable: HttpApiCancellableFunctionType = (url, options) => {
  // put the url as the name for useRequest
  // eslint-disable-next-line func-names
  const f = function (data?: Parameters<BaseApiFunctionType>[2]) {
    return httpPut(url, data, options);
  };

  Object.defineProperty(f, 'name', { value: url });

  return f;
};

export const httpPatchCancellable: HttpApiCancellableFunctionType = (url, options) => {
  // put the url as the name for useRequest
  // eslint-disable-next-line func-names
  const f = function (data?: Parameters<BaseApiFunctionType>[2]) {
    return httpPatch(url, data, options);
  };

  Object.defineProperty(f, 'name', { value: url });

  return f;
};
