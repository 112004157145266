import {
  CheckoutProcessRequestContract,
  CheckoutProcessResponseContract,
  ManageSubscriptionRequestContract,
  ManageSubscriptionResponseContract,
  ProfileDetailsContract,
  UserContract,
} from '@moonpanda/moonpanda.contracts';

import { ApiPatchOperationType } from 'src/models/API';
import {
  ApiFunctionNoParamType,
  ApiFunctionType,
  httpDelete,
  httpGet,
  httpPatch,
  httpPost,
  httpPut,
} from 'src/utils/http';

const getPath = (userId?: number) => `Account/team${userId ? `/${userId}` : ''}`;

export const apiAccountGetTeams: ApiFunctionNoParamType<UserContract[]> = (data) => httpGet(getPath(), data);

export const apiAccountUpdateTeamUser: ApiFunctionType<ApiPatchOperationType & { userId: number }, UserContract> = (
  data,
) => httpPatch(getPath(data.userId), data);

export const apiAccountDeleteTeamUser: ApiFunctionType<{ userId: number }, undefined> = (data) =>
  httpDelete(getPath(data.userId), data);

export const apiAccountGetUserInfo: ApiFunctionNoParamType<ProfileDetailsContract> = (data) => httpGet('Account', data);

export const apiAccountUpdateUserInfo: ApiFunctionType<ProfileDetailsContract, ProfileDetailsContract> = (data) =>
  httpPut('Account', data);

export const apiAccountDeleteAccount: ApiFunctionNoParamType<void> = (data) => httpDelete('Account', data);

export const apiAccountChangeSubscription: ApiFunctionType<
  ManageSubscriptionRequestContract,
  ManageSubscriptionResponseContract
> = (data) => httpPost('Account/subscription/manage', data);

export const apiPaymentsBuySubscription: ApiFunctionType<
  CheckoutProcessRequestContract,
  CheckoutProcessResponseContract
> = (data) => httpPost('Payments/create-checkout-session', data);

export const apiPaymentsCompleteCheckoutSession: ApiFunctionType<{ success: boolean; sessionId: string }, void> = (
  data,
) => httpGet(`Payments/complete-checkout-session?success=${data.success}&session_id=${data.sessionId}`);
