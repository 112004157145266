import { ProjectContract } from '@moonpanda/moonpanda.contracts';
import { FC } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import bem from 'bem-ts';

import { UiProgress } from 'src/components/common/Progress';
import { UiButton } from 'src/components/UI/UiButton';
import { defaultProjectColor } from 'src/config';
import useModalsStore from 'src/store/modals';
import { useTHelper } from 'src/utils/i18n';
import { IconOk } from 'src/components/icons/Ok';
import { getRouteProjectViewPage } from 'src/routes/paths';
import { IconArrowRight } from 'src/components/icons/ArrowRight';

type Props = {
  item: ProjectContract;
  updateProjectInfo: (projectId: number) => void;
};

export const ProjectCard: FC<Props> = ({ item, updateProjectInfo }) => {
  const tHelper = useTHelper('pages.projects.card');

  const onCreateTask = useModalsStore(
    (state) => () =>
      state.toggleModals('createTask', true, {
        projectId: item.id,
        onCreate: ({ projectId }: { projectId: number }) => {
          updateProjectInfo(projectId);
        },
      }),
  );

  const b = bem('projectsCard');

  const taskProgress =
    100 * ((item.projectStatistics?.completedTasks || 0) / (item.projectStatistics?.totalTasks || 1));

  const itemColor = item.color || defaultProjectColor;

  return (
    <div className={classNames('gridItem', b())}>
      <div className={b({ content: true })}>
        {item.number && (
          <div className={b({ label: true })} style={{ backgroundColor: itemColor }}>
            {item.number}
          </div>
        )}
        <div className={b({ header: true })} title={item.name}>
          {item.name}
        </div>
        {/* <div className={b({ phase: true })} title={item.activePhaseStatistics?.phaseName || undefined}> */}
        {/*   {tHelper('phase')} */}
        {/*   <span>{item.activePhaseStatistics?.phaseName}</span> */}
        {/* </div> */}

        <UiProgress percent={taskProgress} color={itemColor} />
        <div className={b({ text: true })}>
          {tHelper('tasks', {
            from: item.projectStatistics?.completedTasks || 0,
            to: item.projectStatistics?.totalTasks || 0,
          })}
        </div>

        <div className={b({ footer: true })}>
          <UiButton color="light" onClick={onCreateTask}>
            <IconOk />
            {tHelper('createNewTask')}
          </UiButton>

          <Link to={getRouteProjectViewPage(item.id)}>
            <IconArrowRight color={itemColor} />
          </Link>
        </div>
      </div>
    </div>
  );
};
