/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState } from 'react';
import classNames from 'classnames';
import { InvoiceParameterTypeEnumContract } from '@moonpanda/moonpanda.contracts';

import { formatCurrency } from 'src/utils/currency';
import { IconPlus } from 'src/components/icons/Plus';
import { IconDollar } from 'src/components/icons/Dollar';
import { IconPercent } from 'src/components/icons/Percent';
import { IconTrash } from 'src/components/icons/Trash';
import { useTHelper } from 'src/utils/i18n';
import { FormValues, InvoiceItemContractWithLocalId } from 'src/components/common/modals/CreateInvoice';
import { StateFormReturnType } from 'src/utils/stateForm';
import { UiPopover } from 'src/components/UI/Popover';

import styles from './styles.module.scss';

export type BilledItemsGridContract = {
  data: InvoiceItemContractWithLocalId[];
  handleAddLineItem: () => void;
  handleChangeItem: ({ id, value, key, localId }: { [key: string]: string | number }) => void;
  subTotal: number;
  handleDeleteRow: ({ invoiceItemId, localId }: { invoiceItemId: number; localId: number }) => void;
  formProps: StateFormReturnType<FormValues>;
  total: number;
  tax: number;
  discount: number;
  discountType: InvoiceParameterTypeEnumContract;
  taxType: InvoiceParameterTypeEnumContract;
};

export const BilledItemsGrid = ({
  data,
  handleAddLineItem,
  handleChangeItem,
  subTotal,
  handleDeleteRow,
  formProps,
  total,
  tax,
  discount,
  discountType,
  taxType,
}: BilledItemsGridContract) => {
  const tHelper = useTHelper('modals.createInvoice');

  const [isDiscountOpened, setIsDiscountOpened] = useState(false);

  const handleTaxDollarClick = () => {
    formProps.setValue('invoiceParameters.taxType', 'Number');
    formProps.setValue('invoiceParameters.tax', 0);
  };

  const handleTaxPercentClick = () => {
    formProps.setValue('invoiceParameters.taxType', 'Percent');
    formProps.setValue('invoiceParameters.tax', 0);
  };

  const handleAddDiscountClick = () => {
    setIsDiscountOpened(true);
  };

  const handleDiscountDollarClick = () => {
    formProps.setValue('invoiceParameters.discountType', 'Number');
    formProps.setValue('invoiceParameters.discount', 0);
  };

  const handleDiscountPercentClick = () => {
    formProps.setValue('invoiceParameters.discountType', 'Percent');
    formProps.setValue('invoiceParameters.discount', 0);
  };

  const handleChangeDiscount = (e: React.ChangeEvent<HTMLInputElement>) => {
    formProps.setValue('invoiceParameters.discount', e.target.value);
  };

  const handleChangeTax = (e: React.ChangeEvent<HTMLInputElement>) => {
    formProps.setValue('invoiceParameters.tax', e.target.value);
  };

  return (
    <div className={styles.container}>
      <p className={styles.subHeading}>{tHelper('itemsToBeBilled')}</p>
      <div className={styles.wrapper}>
        <div className={styles.tableBlock}>
          <div className={styles.tableWrapper}>
            <table className={styles.invoiceTable}>
              <thead>
                <tr>
                  <th>#</th>
                  <th>{tHelper('description')}</th>
                  <th>{tHelper('qty')}</th>
                  <th>{tHelper('xCost')}</th>
                  <th>= {tHelper('amt')}</th>
                </tr>
              </thead>
              <tbody>
                {data?.map((item: InvoiceItemContractWithLocalId, index: number) => (
                  <tr key={item?.localId}>
                    <td>{index + 1}</td>
                    <td>
                      <input
                        value={item.description}
                        onChange={(e) => {
                          handleChangeItem({
                            id: item.id,
                            value: e.target.value,
                            key: 'description',
                            localId: item.localId,
                          });
                        }}
                      />
                    </td>
                    <td>
                      <input
                        value={item.quantity}
                        type="number"
                        inputMode="numeric"
                        onChange={(e) => {
                          if (Number(e.target.value) <= 1000000000) {
                            handleChangeItem({
                              id: item.id,
                              value: e.target.value,
                              key: 'quantity',
                              localId: item.localId,
                            });
                          }
                        }}
                      />
                    </td>
                    <td className={styles.textRight}>
                      <input
                        value={item.cost}
                        type="number"
                        inputMode="numeric"
                        onChange={(e) => {
                          if (Number(e.target.value) <= 1000000000) {
                            handleChangeItem({
                              id: item.id,
                              value: e.target.value,
                              key: 'cost',
                              localId: item.localId,
                            });
                          }
                        }}
                      />
                    </td>
                    <td className={styles?.textRightAmount}>
                      <UiPopover
                        placement="top"
                        trigger="hover"
                        /* eslint-disable-next-line react/no-unstable-nested-components,react/no-unescaped-entities */
                        content={() => <span>{Number(item.quantity) * Number(item.cost) || ''}</span>}
                      >
                        <span>{Number(item.quantity) * Number(item.cost) || ''}</span>
                      </UiPopover>
                    </td>
                    <td className={styles.trashIcon}>
                      <IconTrash onClick={() => handleDeleteRow({ invoiceItemId: item?.id, localId: item.localId })} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <button type="button" className={styles.addLineBtn} onClick={handleAddLineItem}>
            <IconPlus />
            {tHelper('addLineItem')}
          </button>
        </div>
        <div className={styles.totalInfo}>
          <div className={styles.infoBlock}>
            <div className={styles.infoRaw}>
              <p>{tHelper('subtotal')}</p>
              <div className={styles.taxBlock}>
                {subTotal > 0 ? (
                  <UiPopover
                    placement="top"
                    trigger="hover"
                    /* eslint-disable-next-line react/no-unstable-nested-components,react/no-unescaped-entities */
                    content={() => <span>{formatCurrency(subTotal)}</span>}
                  >
                    <input disabled value={formatCurrency(subTotal)} className={styles.taxInput} />
                  </UiPopover>
                ) : (
                  <input disabled value={formatCurrency(subTotal)} className={styles.taxInput} />
                )}
              </div>
            </div>
            <div className={styles.infoRaw}>
              <p>{tHelper('tax')}</p>
              <div className={styles.taxBlock}>
                <input onChange={handleChangeTax} value={tax} className={styles.taxInput} />
              </div>
            </div>
            <div className={styles.infoRaw}>
              <p>{tHelper('total')}</p>
              <div className={styles.taxBlock}>
                {total > 0 ? (
                  <UiPopover
                    placement="top"
                    trigger="hover"
                    /* eslint-disable-next-line react/no-unstable-nested-components,react/no-unescaped-entities */
                    content={() => <span>{formatCurrency(total)}</span>}
                  >
                    <input disabled value={formatCurrency(total)} className={styles.taxInput} />
                  </UiPopover>
                ) : (
                  <input disabled value={formatCurrency(total)} className={styles.taxInput} />
                )}
              </div>
            </div>
            {!!(isDiscountOpened || discount) && (
              <div className={styles.infoRaw}>
                <p>{tHelper('discount')}</p>
                <div className={styles.taxBlock}>
                  <input onChange={handleChangeDiscount} value={discount} className={styles.taxInput} />
                </div>
              </div>
            )}
            {!isDiscountOpened && !discount && (
              <button type="button" onClick={handleAddDiscountClick} className={styles.addLineBtn}>
                <IconPlus />
                {tHelper('addDiscount')}
              </button>
            )}
          </div>
          <div className={styles.iconsBlock}>
            <div>
              <IconPercent
                className={classNames(styles.taxIcon, taxType === 'Percent' ? styles.taxIconSelected : undefined)}
                onClick={handleTaxPercentClick}
              />
              <IconDollar
                className={classNames(styles.taxIcon, taxType === 'Number' ? styles.taxIconSelected : undefined)}
                onClick={handleTaxDollarClick}
              />
            </div>
            <div>
              {!!(isDiscountOpened || discount) && (
                <div className={styles.discountIcons}>
                  <IconPercent
                    className={classNames(
                      styles.taxIcon,
                      discountType === 'Percent' ? styles.taxIconSelected : undefined,
                    )}
                    onClick={handleDiscountPercentClick}
                  />
                  <IconDollar
                    className={classNames(
                      styles.taxIcon,
                      discountType === 'Number' ? styles.taxIconSelected : undefined,
                    )}
                    onClick={handleDiscountDollarClick}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
