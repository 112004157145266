import { JwtTokenContract } from '@moonpanda/moonpanda.contracts';

const jwtTokenName = 'token';

export const setJwtToken = (data: JwtTokenContract) => {
  localStorage.setItem(jwtTokenName, JSON.stringify(data));
};

export const removeJwtToken = () => {
  localStorage.removeItem(jwtTokenName);
};

export const getJwtToken = (): JwtTokenContract | null => {
  const tokenData = localStorage.getItem('token');

  if (!tokenData) {
    return null;
  }

  return JSON.parse(tokenData);

  // ignore tokenExpirationTime. try refreshing anyway
  // if (new Date().getTime() > new Date(item.tokenExpirationTime).getTime()) {
  //   return null;
  // }
};
