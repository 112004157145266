import { SortingOrderEnumContract, TaskContract, TaskStatusEnumContract } from '@moonpanda/moonpanda.contracts';
import classNames from 'classnames';
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { apiGetTasks } from 'src/api/tasks';
import { UiScrollable } from 'src/components/UI/InfiniteScroll';
import { UiDropdownProps } from 'src/components/UI/inputs/DropDown';
import { UiProjectsDropdown } from 'src/components/UI/ProjectsDropdown';
import { UiTable } from 'src/components/UI/table';
import { UiTableInnerFiltersType } from 'src/components/UI/table/helpers';
import { UiButton } from 'src/components/UI/UiButton';
import { DEFAULT_TABLE_PAGE_SIZE, SHORT_DATE_FORMAT } from 'src/config';
import { useGrid } from 'src/hooks/useGrid';
import { TasksDoneChangeComponent } from 'src/pages/Tasks/components/DoneChange';
import { TasksStatusChangeComponent } from 'src/pages/Tasks/components/StatusChange';
import useModalsStore from 'src/store/modals';
import { CDate } from 'src/utils/CDate';
import { numberToTimeObject } from 'src/utils/dateTime';
import { useTHelper } from 'src/utils/i18n';
import { useStateForm } from 'src/utils/stateForm';

import styles from './styles.module.scss';

type FormValues = {
  status_1: string;
  done_1: boolean;
  projectId: number | null;
};

export const TasksPage: FC = () => {
  const formProps = useStateForm<FormValues>();

  const tHelper = useTHelper('pages.tasks.table');

  const toggleModals = useModalsStore((state) => state.toggleModals);

  const [projectIdSelected, setProjectIdSelected] = useState<number | null>(null);

  const dataRef = useRef<TaskContract[]>();

  const { loading, data, load, loadNext, updateAllData, reload } = useGrid<TaskContract>({
    apiFn: apiGetTasks,
    initialLoad: false,
    pageSize: DEFAULT_TABLE_PAGE_SIZE,
    defaultOrderBy: [{ propertyName: 'id', sortingOrder: 'Desc' }],
  });

  useEffect(() => {
    /** set status */
    const obj: Record<string, TaskStatusEnumContract | boolean> = {};

    data.data.forEach(({ id, status }) => {
      obj[`status_${id}`] = status;
      obj[`done_${id}`] = status === 'Completed';
    });

    dataRef.current = data.data;

    formProps.setValue(obj);
    /** end set status */
  }, [data, formProps]);

  const onTableGetData = useCallback(
    (tableData: UiTableInnerFiltersType) => {
      load({
        page: 0,
        pageSize: DEFAULT_TABLE_PAGE_SIZE,
        orderBy: [{ propertyName: tableData.propertyName, sortingOrder: tableData.sortingOrder }],
        filters:
          projectIdSelected && projectIdSelected > 0
            ? [{ propertyName: 'projectId', operation: 'Equals', value: projectIdSelected.toString() }]
            : [],
      });
    },
    [load, projectIdSelected],
  );

  const updateTaskInList = useCallback(
    (changedTaskData: TaskContract) => {
      updateAllData((storeData) => {
        const indexToChange = storeData.findIndex(({ id }) => id === changedTaskData.id);

        if (indexToChange >= 0) {
          storeData[indexToChange] = changedTaskData;
        }

        return storeData;
      });
    },
    [updateAllData],
  );

  const onShowViewTask = useCallback(
    (id: number) => () => {
      toggleModals('viewTask', true, {
        taskId: id,
        onChange: updateTaskInList,
        onDeleteTask: (task: TaskContract) => {
          updateAllData((storeData) => storeData.filter(({ id }) => id !== task.id));
        },
      });
    },
    [toggleModals, updateAllData, updateTaskInList],
  );

  const onCreateNewTask = useCallback(
    () =>
      toggleModals('createTask', true, {
        onCreate: reload,
      }),
    [reload, toggleModals],
  );

  const columns = useMemo(
    () => [
      {
        title: 'ID',
        renderValue: 'id',
        sortPropertyName: 'id',
        useSortAndNotShow: true,
        defaultSortDirection: 'Desc' as SortingOrderEnumContract,
      },
      {
        title: tHelper('columns.done'),
        renderValue: ({ id, status, project }: TaskContract) => (
          <TasksDoneChangeComponent
            taskId={id}
            status={status}
            onSuccess={updateTaskInList}
            projectColor={project?.projectColor as string}
          />
        ),
        className: classNames('text-center', styles.doneColumn),
      },
      {
        title: tHelper('columns.taskName'),
        renderValue: ({ id, name }: TaskContract) => (
          <span role="presentation" onClick={onShowViewTask(id)} className={styles.taskName} title={name}>
            {name}
          </span>
        ),
        sortPropertyName: 'name',
        className: styles.ellipsable,
        thClassName: styles.ellipsable,
      },
      {
        title: tHelper('columns.projectName'),
        renderValue: ({ project }: TaskContract) => {
          const name = project?.projectName;

          return <span title={name}>{name}</span>;
        },
        sortPropertyName: 'project.Name',
        className: styles.ellipsable,
        thClassName: styles.ellipsable,
      },
      {
        title: tHelper('columns.status'),
        className: styles.statusColumn,
        thClassName: styles.statusColumn,
        sortPropertyName: 'status',
        renderValue: ({ id, status }: TaskContract) => (
          <TasksStatusChangeComponent taskId={id} status={status} onSuccess={updateTaskInList} />
        ),
      },
      {
        title: tHelper('columns.dueDate'),
        renderValue: ({ dueDate }: TaskContract) => CDate.format(new Date(dueDate), SHORT_DATE_FORMAT),
        sortPropertyName: 'dueDate',
        className: 'text-center',
      },
      {
        title: tHelper('columns.timeLogged'),
        renderValue: ({ totalLogged }: TaskContract) => {
          const times = numberToTimeObject(totalLogged);

          return tHelper('timeLoggedTemplate', {
            hours: times.hours,
            minutes: times.minutes,
          });
        },
        className: 'text-center',
      },
      {
        title: tHelper('columns.billable'),
        renderValue: ({ isBillableHours }: TaskContract) => (isBillableHours ? 'Y' : 'N'),
        sortPropertyName: 'isBillableHours',
        className: 'text-center',
      },
      {
        title: tHelper('columns.assignee'),
        renderValue: ({ assignee }: TaskContract) => (
          <span role="presentation" title={assignee?.name}>
            {assignee?.name}
          </span>
        ),
        sortPropertyName: 'assignee.name',
        className: styles.ellipsableAssignee,
        thClassName: styles.ellipsableAssignee,
      },
      {
        title: tHelper('columns.assigner'),
        renderValue: ({ author }: TaskContract) => (
          <span role="presentation" title={author?.name}>
            {author?.name}
          </span>
        ),
        sortPropertyName: 'author.name',
        className: styles.ellipsableAssignee,
        thClassName: styles.ellipsableAssignee,
      },
    ],
    [onShowViewTask, tHelper, updateTaskInList],
  );

  const getData = useCallback(() => {
    loadNext();
  }, [loadNext]);

  const onProjectChange = useCallback((value: UiDropdownProps['options'][0] | null) => {
    setProjectIdSelected(value?.value as number | null);
  }, []);

  return (
    <div className="bodyPadding">
      <div className={styles.top}>
        <UiProjectsDropdown
          className={styles.taskDropdown}
          onChange={onProjectChange}
          customAllValue={tHelper('allTasksFilter')}
        />

        <UiButton className={styles.createNewTask} color="green" onClick={onCreateNewTask}>
          {tHelper('createNewTask')}
        </UiButton>
      </div>

      <h3 className={styles.head}>{tHelper('title')}</h3>

      <UiScrollable data={data} getData={getData} loading={loading}>
        <UiTable data={data} getData={onTableGetData} columns={columns} />
      </UiScrollable>
    </div>
  );
};
