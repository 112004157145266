/* eslint-disable max-len */
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { CommonIconProps } from 'src/utils/iconProps';

type Props = CommonIconProps;

export const IconAppLogo: FC<Props> = ({ className }) => {
  const style = { fill: '#1b2125', strokeWidth: 0 };

  return (
    <Link to="https://www.moonpanda.ai/">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="329.23"
        height="93.97"
        viewBox="0 0 329.23 93.97"
        className={className}
      >
        <g>
          <path
            style={style}
            d="m.85,76.67c.57-1.06,1.34-1.87,2.33-2.46.99-.58,2.09-.87,3.31-.87s2.24.26,3.12.77c.88.52,1.54,1.16,1.97,1.95v-2.49h2.29v13.67h-2.29v-2.54c-.45.8-1.12,1.46-2.01,1.98-.89.52-1.93.79-3.11.79s-2.31-.3-3.29-.9c-.98-.6-1.75-1.44-2.32-2.52s-.85-2.31-.85-3.69.28-2.62.85-3.68Zm10.1,1.01c-.42-.76-.98-1.35-1.68-1.76-.71-.41-1.48-.61-2.33-.61s-1.62.2-2.32.6c-.7.4-1.26.98-1.67,1.75-.42.77-.62,1.66-.62,2.69s.21,1.96.62,2.73c.42.77.97,1.36,1.67,1.77.7.41,1.47.61,2.32.61s1.62-.2,2.33-.61c.71-.41,1.27-1,1.68-1.77.42-.77.62-1.67.62-2.71s-.21-1.93-.62-2.69Z"
          />
          <path
            style={style}
            d="m21.69,73.97c.74-.43,1.64-.65,2.71-.65v2.34h-.6c-2.54,0-3.82,1.38-3.82,4.14v7.43h-2.27v-13.67h2.27v2.22c.4-.78.97-1.39,1.71-1.82Z"
          />
          <path
            style={style}
            d="m27.01,76.67c.57-1.06,1.35-1.87,2.36-2.46,1.01-.58,2.16-.87,3.45-.87,1.68,0,3.06.41,4.15,1.22,1.09.81,1.81,1.95,2.16,3.39h-2.44c-.23-.83-.69-1.49-1.36-1.97-.67-.48-1.51-.72-2.51-.72-1.3,0-2.35.44-3.14,1.33-.8.89-1.2,2.15-1.2,3.78s.4,2.92,1.2,3.82c.8.9,1.85,1.35,3.14,1.35,1,0,1.83-.23,2.49-.7s1.12-1.13,1.37-2h2.44c-.37,1.4-1.1,2.51-2.19,3.35-1.1.84-2.47,1.26-4.12,1.26-1.3,0-2.45-.29-3.45-.87-1.01-.58-1.79-1.4-2.36-2.47-.57-1.06-.85-2.31-.85-3.74s.28-2.65.85-3.7Z"
          />
          <path
            style={style}
            d="m51.73,73.98c.83.44,1.48,1.1,1.96,1.98.47.88.71,1.95.71,3.22v8.06h-2.25v-7.73c0-1.36-.34-2.41-1.02-3.13-.68-.72-1.61-1.08-2.79-1.08s-2.15.37-2.86,1.12-1.06,1.84-1.06,3.27v7.56h-2.27v-18.46h2.27v6.73c.45-.7,1.07-1.24,1.86-1.62.79-.38,1.67-.57,2.66-.57,1.03,0,1.96.22,2.79.66Z"
          />
          <path
            style={style}
            d="m58.19,70.9c-.3-.3-.45-.66-.45-1.1s.15-.8.45-1.1.66-.45,1.1-.45.77.15,1.06.45c.29.3.44.67.44,1.1s-.15.8-.44,1.1c-.29.3-.64.45-1.06.45s-.8-.15-1.1-.45Zm2.2,2.67v13.67h-2.27v-13.67h2.27Z"
          />
          <path
            style={style}
            d="m67.02,75.44v8.06c0,.66.14,1.13.42,1.41.28.27.77.41,1.47.41h1.67v1.92h-2.05c-1.26,0-2.21-.29-2.84-.87-.63-.58-.95-1.54-.95-2.87v-8.06h-1.77v-1.87h1.77v-3.44h2.27v3.44h3.57v1.87h-3.57Z"
          />
          <path
            style={style}
            d="m85.73,81.25h-10.93c.08,1.35.55,2.4,1.38,3.16.84.76,1.86,1.13,3.06,1.13.98,0,1.8-.23,2.46-.69.66-.46,1.12-1.07,1.38-1.83h2.44c-.37,1.31-1.1,2.38-2.2,3.21-1.1.82-2.46,1.23-4.09,1.23-1.3,0-2.46-.29-3.48-.87-1.02-.58-1.83-1.41-2.41-2.48-.58-1.07-.87-2.32-.87-3.73s.28-2.65.85-3.72c.57-1.06,1.36-1.88,2.38-2.46,1.02-.57,2.2-.86,3.53-.86s2.44.28,3.44.85,1.77,1.34,2.31,2.33c.54.99.81,2.11.81,3.35,0,.43-.02.89-.07,1.37Zm-2.84-4.08c-.38-.62-.9-1.1-1.56-1.42-.66-.32-1.38-.49-2.18-.49-1.15,0-2.13.37-2.93,1.1-.81.73-1.27,1.75-1.38,3.04h8.63c0-.86-.19-1.61-.57-2.23Z"
          />
          <path
            style={style}
            d="m88.8,76.67c.57-1.06,1.35-1.87,2.36-2.46,1.01-.58,2.16-.87,3.45-.87,1.68,0,3.06.41,4.15,1.22,1.09.81,1.81,1.95,2.16,3.39h-2.44c-.23-.83-.69-1.49-1.36-1.97-.67-.48-1.51-.72-2.51-.72-1.3,0-2.35.44-3.14,1.33-.8.89-1.2,2.15-1.2,3.78s.4,2.92,1.2,3.82c.8.9,1.85,1.35,3.14,1.35,1,0,1.83-.23,2.49-.7s1.12-1.13,1.37-2h2.44c-.37,1.4-1.1,2.51-2.19,3.35-1.1.84-2.47,1.26-4.12,1.26-1.3,0-2.45-.29-3.45-.87-1.01-.58-1.79-1.4-2.36-2.47-.57-1.06-.85-2.31-.85-3.74s.28-2.65.85-3.7Z"
          />
          <path
            style={style}
            d="m106.7,75.44v8.06c0,.66.14,1.13.42,1.41.28.27.77.41,1.47.41h1.67v1.92h-2.05c-1.26,0-2.21-.29-2.84-.87-.63-.58-.95-1.54-.95-2.87v-8.06h-1.77v-1.87h1.77v-3.44h2.27v3.44h3.57v1.87h-3.57Z"
          />
          <path
            style={style}
            d="m125.14,73.57v13.67h-2.27v-2.02c-.43.7-1.04,1.24-1.81,1.63s-1.63.59-2.56.59c-1.06,0-2.02-.22-2.87-.66-.85-.44-1.52-1.1-2.01-1.98-.49-.88-.74-1.95-.74-3.22v-8.01h2.25v7.71c0,1.35.34,2.38,1.02,3.11s1.61,1.08,2.79,1.08,2.17-.37,2.87-1.12,1.05-1.84,1.05-3.27v-7.51h2.27Z"
          />
          <path
            style={style}
            d="m132.96,73.97c.74-.43,1.64-.65,2.71-.65v2.34h-.6c-2.54,0-3.82,1.38-3.82,4.14v7.43h-2.27v-13.67h2.27v2.22c.4-.78.97-1.39,1.71-1.82Z"
          />
          <path
            style={style}
            d="m138.28,76.67c.57-1.06,1.34-1.87,2.33-2.46.99-.58,2.09-.87,3.31-.87s2.24.26,3.12.77c.88.52,1.54,1.16,1.97,1.95v-2.49h2.29v13.67h-2.29v-2.54c-.45.8-1.12,1.46-2.01,1.98-.89.52-1.93.79-3.11.79s-2.31-.3-3.29-.9c-.98-.6-1.75-1.44-2.32-2.52s-.85-2.31-.85-3.69.28-2.62.85-3.68Zm10.1,1.01c-.42-.76-.98-1.35-1.68-1.76-.71-.41-1.48-.61-2.33-.61s-1.62.2-2.32.6c-.7.4-1.26.98-1.67,1.75-.42.77-.62,1.66-.62,2.69s.21,1.96.62,2.73c.42.77.97,1.36,1.67,1.77.7.41,1.47.61,2.32.61s1.62-.2,2.33-.61c.71-.41,1.27-1,1.68-1.77.42-.77.62-1.67.62-2.71s-.21-1.93-.62-2.69Z"
          />
          <path style={style} d="m157.41,68.78v18.46h-2.27v-18.46h2.27Z" />
          <path
            style={style}
            d="m168.01,70.9c-.3-.3-.45-.66-.45-1.1s.15-.8.45-1.1.66-.45,1.1-.45.77.15,1.06.45c.29.3.44.67.44,1.1s-.15.8-.44,1.1c-.29.3-.64.45-1.06.45s-.8-.15-1.1-.45Zm2.2,2.67v13.67h-2.27v-13.67h2.27Z"
          />
          <path
            style={style}
            d="m184.78,74.83c1.03,1.01,1.55,2.46,1.55,4.35v8.06h-2.25v-7.73c0-1.36-.34-2.41-1.02-3.13-.68-.72-1.61-1.08-2.79-1.08s-2.15.37-2.86,1.12-1.06,1.84-1.06,3.27v7.56h-2.27v-13.67h2.27v1.95c.45-.7,1.06-1.24,1.83-1.62s1.62-.57,2.56-.57c1.66,0,3.01.5,4.04,1.51Z"
          />
          <path
            style={style}
            d="m192.81,75.44v8.06c0,.66.14,1.13.42,1.41.28.27.77.41,1.47.41h1.67v1.92h-2.05c-1.26,0-2.21-.29-2.84-.87-.63-.58-.95-1.54-.95-2.87v-8.06h-1.77v-1.87h1.77v-3.44h2.27v3.44h3.57v1.87h-3.57Z"
          />
          <path
            style={style}
            d="m211.52,81.25h-10.93c.08,1.35.55,2.4,1.38,3.16.84.76,1.86,1.13,3.06,1.13.98,0,1.8-.23,2.46-.69.66-.46,1.12-1.07,1.38-1.83h2.44c-.37,1.31-1.1,2.38-2.2,3.21-1.1.82-2.46,1.23-4.09,1.23-1.3,0-2.46-.29-3.48-.87-1.02-.58-1.83-1.41-2.41-2.48-.58-1.07-.87-2.32-.87-3.73s.28-2.65.85-3.72c.57-1.06,1.36-1.88,2.38-2.46,1.02-.57,2.2-.86,3.53-.86s2.44.28,3.44.85,1.77,1.34,2.31,2.33c.54.99.81,2.11.81,3.35,0,.43-.02.89-.07,1.37Zm-2.84-4.08c-.38-.62-.9-1.1-1.56-1.42-.66-.32-1.38-.49-2.18-.49-1.15,0-2.13.37-2.93,1.1-.81.73-1.27,1.75-1.38,3.04h8.63c0-.86-.19-1.61-.57-2.23Z"
          />
          <path style={style} d="m216.85,68.78v18.46h-2.27v-18.46h2.27Z" />
          <path style={style} d="m222.99,68.78v18.46h-2.27v-18.46h2.27Z" />
          <path
            style={style}
            d="m226.93,70.9c-.3-.3-.45-.66-.45-1.1s.15-.8.45-1.1.66-.45,1.1-.45.77.15,1.06.45c.29.3.44.67.44,1.1s-.15.8-.44,1.1c-.29.3-.64.45-1.06.45s-.8-.15-1.1-.45Zm2.2,2.67v13.67h-2.27v-13.67h2.27Z"
          />
          <path
            style={style}
            d="m241.73,74.11c.89.52,1.55,1.16,1.98,1.95v-2.49h2.29v13.97c0,1.25-.27,2.36-.8,3.33-.53.97-1.29,1.73-2.28,2.28-.99.55-2.14.82-3.45.82-1.8,0-3.29-.42-4.49-1.27-1.2-.85-1.9-2-2.12-3.47h2.25c.25.83.77,1.5,1.55,2.01s1.72.76,2.82.76c1.25,0,2.27-.39,3.06-1.17.79-.78,1.18-1.88,1.18-3.29v-2.87c-.45.8-1.11,1.46-2,2-.88.53-1.91.8-3.09.8s-2.32-.3-3.31-.9c-.99-.6-1.77-1.44-2.33-2.52s-.85-2.31-.85-3.69.28-2.62.85-3.68c.57-1.06,1.34-1.87,2.33-2.46.99-.58,2.09-.87,3.31-.87s2.22.26,3.1.77Zm1.36,3.57c-.42-.76-.98-1.35-1.68-1.76-.71-.41-1.48-.61-2.33-.61s-1.62.2-2.32.6c-.7.4-1.26.98-1.67,1.75-.42.77-.62,1.66-.62,2.69s.21,1.96.62,2.73c.42.77.97,1.36,1.67,1.77.7.41,1.47.61,2.32.61s1.62-.2,2.33-.61c.71-.41,1.27-1,1.68-1.77.42-.77.62-1.67.62-2.71s-.21-1.93-.62-2.69Z"
          />
          <path
            style={style}
            d="m262.25,81.25h-10.93c.08,1.35.55,2.4,1.38,3.16.84.76,1.86,1.13,3.06,1.13.98,0,1.8-.23,2.46-.69.66-.46,1.12-1.07,1.38-1.83h2.44c-.37,1.31-1.1,2.38-2.2,3.21-1.1.82-2.46,1.23-4.09,1.23-1.3,0-2.46-.29-3.48-.87-1.02-.58-1.83-1.41-2.41-2.48-.58-1.07-.87-2.32-.87-3.73s.28-2.65.85-3.72c.57-1.06,1.36-1.88,2.38-2.46,1.02-.57,2.2-.86,3.53-.86s2.44.28,3.44.85,1.77,1.34,2.31,2.33c.54.99.81,2.11.81,3.35,0,.43-.02.89-.07,1.37Zm-2.84-4.08c-.38-.62-.9-1.1-1.56-1.42-.66-.32-1.38-.49-2.18-.49-1.15,0-2.13.37-2.93,1.1-.81.73-1.27,1.75-1.38,3.04h8.63c0-.86-.19-1.61-.57-2.23Z"
          />
          <path
            style={style}
            d="m276.01,74.83c1.03,1.01,1.55,2.46,1.55,4.35v8.06h-2.25v-7.73c0-1.36-.34-2.41-1.02-3.13-.68-.72-1.61-1.08-2.79-1.08s-2.15.37-2.86,1.12-1.06,1.84-1.06,3.27v7.56h-2.27v-13.67h2.27v1.95c.45-.7,1.06-1.24,1.83-1.62s1.62-.57,2.56-.57c1.66,0,3.01.5,4.04,1.51Z"
          />
          <path
            style={style}
            d="m281.28,76.67c.57-1.06,1.35-1.87,2.36-2.46,1.01-.58,2.16-.87,3.45-.87,1.68,0,3.06.41,4.15,1.22,1.09.81,1.81,1.95,2.16,3.39h-2.44c-.23-.83-.69-1.49-1.36-1.97-.67-.48-1.51-.72-2.51-.72-1.3,0-2.35.44-3.14,1.33-.8.89-1.2,2.15-1.2,3.78s.4,2.92,1.2,3.82c.8.9,1.85,1.35,3.14,1.35,1,0,1.83-.23,2.49-.7s1.12-1.13,1.37-2h2.44c-.37,1.4-1.1,2.51-2.19,3.35-1.1.84-2.47,1.26-4.12,1.26-1.3,0-2.45-.29-3.45-.87-1.01-.58-1.79-1.4-2.36-2.47-.57-1.06-.85-2.31-.85-3.74s.28-2.65.85-3.7Z"
          />
          <path
            style={style}
            d="m308.82,81.25h-10.93c.08,1.35.55,2.4,1.38,3.16.84.76,1.86,1.13,3.06,1.13.98,0,1.8-.23,2.46-.69.66-.46,1.12-1.07,1.38-1.83h2.44c-.37,1.31-1.1,2.38-2.2,3.21-1.1.82-2.46,1.23-4.09,1.23-1.3,0-2.46-.29-3.48-.87-1.02-.58-1.83-1.41-2.41-2.48-.58-1.07-.87-2.32-.87-3.73s.28-2.65.85-3.72c.57-1.06,1.36-1.88,2.38-2.46,1.02-.57,2.2-.86,3.53-.86s2.44.28,3.44.85,1.77,1.34,2.31,2.33c.54.99.81,2.11.81,3.35,0,.43-.02.89-.07,1.37Zm-2.84-4.08c-.38-.62-.9-1.1-1.56-1.42-.66-.32-1.38-.49-2.18-.49-1.15,0-2.13.37-2.93,1.1-.81.73-1.27,1.75-1.38,3.04h8.63c0-.86-.19-1.61-.57-2.23Z"
          />
          <path style={style} d="m317.32,68.79v1.08h-2.76v9.06h-1.32v-9.06h-2.78v-1.08h6.86Z" />
          <path
            style={style}
            d="m329.23,68.87v10.06h-1.32v-7.5l-3.34,7.5h-.93l-3.36-7.52v7.52h-1.32v-10.06h1.42l3.72,8.32,3.72-8.32h1.41Z"
          />
        </g>
        <g>
          <path
            style={style}
            d="m51.32,14.1c2.44,2.44,3.66,5.85,3.66,10.23v17.9c0,.64-.52,1.15-1.15,1.15h-5.9c-.64,0-1.15-.52-1.15-1.15v-16.79c0-2.54-.64-4.49-1.93-5.83-1.29-1.35-3.05-2.02-5.28-2.02s-4,.67-5.31,2.02c-1.31,1.35-1.96,3.29-1.96,5.83v16.79c0,.64-.52,1.15-1.15,1.15h-5.9c-.64,0-1.15-.52-1.15-1.15v-16.79c0-2.54-.64-4.49-1.93-5.83-1.29-1.35-3.05-2.02-5.28-2.02s-4.06.67-5.37,2.02c-1.31,1.35-1.96,3.29-1.96,5.83v16.79c0,.64-.52,1.15-1.15,1.15H2.49c-.64,0-1.15-.52-1.15-1.15V12.06c0-.64.52-1.15,1.15-1.15h5.9c.64,0,1.15.52,1.15,1.15h0c0,.96,1.1,1.49,1.86.9.66-.52,1.4-.97,2.21-1.35,1.66-.78,3.49-1.17,5.48-1.17,2.54,0,4.81.54,6.8,1.61,1.5.81,2.75,1.88,3.74,3.21.45.6,1.35.6,1.81.01.97-1.25,2.19-2.29,3.69-3.13,2.01-1.13,4.19-1.7,6.54-1.7,3.99,0,7.2,1.22,9.65,3.66Z"
          />
          <path
            style={style}
            d="m141.35,14.1c2.38,2.44,3.58,5.85,3.58,10.23v17.87c0,.66-.53,1.19-1.19,1.19h-5.83c-.66,0-1.19-.53-1.19-1.19v-16.75c0-2.58-.64-4.56-1.93-5.95-1.29-1.39-3.05-2.08-5.28-2.08s-4.06.69-5.37,2.08-1.96,3.37-1.96,5.95v16.75c0,.66-.53,1.19-1.19,1.19h-5.83c-.66,0-1.19-.53-1.19-1.19V12.1c0-.66.53-1.19,1.19-1.19h5.83c.66,0,1.19.53,1.19,1.19h0c0,.99,1.14,1.54,1.92.93.68-.53,1.44-1,2.27-1.39,1.7-.8,3.57-1.2,5.6-1.2,3.87,0,7,1.22,9.38,3.66Z"
          />
          <path
            style={style}
            d="m161.25,11.87c1.86-1,3.98-1.5,6.36-1.5,2.77,0,5.29.68,7.53,2.05,2.25,1.37,4.03,3.31,5.33,5.83,1.31,2.52,1.96,5.44,1.96,8.77s-.65,6.26-1.96,8.82c-1.31,2.56-3.09,4.54-5.33,5.95-2.25,1.41-4.76,2.11-7.53,2.11-2.39,0-4.49-.49-6.3-1.46-.81-.43-1.55-.92-2.22-1.45-.89-.71-2.21-.11-2.21,1.03v15.49c0,.74-.6,1.35-1.35,1.35h-5.51c-.74,0-1.35-.6-1.35-1.35V12.25c0-.74.6-1.35,1.35-1.35h5.51c.74,0,1.35.6,1.35,1.35h0c0,1.13,1.3,1.75,2.19,1.05.65-.52,1.38-1,2.18-1.43Zm11.61,10.09c-.8-1.43-1.86-2.51-3.17-3.25-1.31-.74-2.73-1.11-4.25-1.11s-2.88.38-4.19,1.14c-1.31.76-2.37,1.87-3.17,3.31s-1.2,3.15-1.2,5.1.4,3.66,1.2,5.1c.8,1.45,1.86,2.55,3.17,3.31,1.31.76,2.71,1.14,4.19,1.14s2.94-.39,4.25-1.17c1.31-.78,2.37-1.9,3.17-3.34.8-1.45,1.2-3.17,1.2-5.16s-.4-3.65-1.2-5.07Z"
          />
          <path
            style={style}
            d="m187.49,18.29c1.31-2.54,3.09-4.5,5.33-5.86s4.76-2.05,7.53-2.05c2.42,0,4.55.49,6.36,1.47.79.43,1.51.9,2.17,1.42.89.71,2.2.12,2.2-1.02h0c0-.74.6-1.34,1.34-1.34h5.59c.74,0,1.34.6,1.34,1.34v29.81c0,.74-.6,1.34-1.34,1.34h-5.59c-.74,0-1.34-.6-1.34-1.34v-.04c0-1.13-1.3-1.74-2.18-1.03-.65.53-1.39,1.01-2.19,1.45-1.86,1-4,1.49-6.42,1.49-2.74,0-5.23-.7-7.48-2.11-2.25-1.41-4.03-3.39-5.33-5.95-1.31-2.56-1.96-5.5-1.96-8.82s.65-6.2,1.96-8.74Zm22.43,3.72c-.78-1.43-1.84-2.52-3.17-3.28s-2.76-1.14-4.28-1.14-2.93.37-4.22,1.11c-1.29.74-2.34,1.83-3.14,3.25s-1.2,3.12-1.2,5.07.4,3.66,1.2,5.13c.8,1.47,1.86,2.59,3.17,3.37,1.31.78,2.71,1.17,4.19,1.17s2.95-.38,4.28-1.14,2.38-1.86,3.17-3.28c.78-1.43,1.17-3.14,1.17-5.13s-.39-3.7-1.17-5.13Z"
          />
          <path
            style={style}
            d="m251.18,14.1c2.38,2.44,3.58,5.85,3.58,10.23v17.87c0,.66-.53,1.19-1.19,1.19h-5.83c-.66,0-1.19-.53-1.19-1.19v-16.75c0-2.58-.64-4.56-1.93-5.95-1.29-1.39-3.05-2.08-5.28-2.08s-4.06.69-5.37,2.08-1.96,3.37-1.96,5.95v16.75c0,.66-.53,1.19-1.19,1.19h-5.83c-.66,0-1.19-.53-1.19-1.19V12.1c0-.66.53-1.19,1.19-1.19h5.83c.66,0,1.19.53,1.19,1.19h0c0,.99,1.14,1.54,1.92.93.68-.53,1.44-1,2.27-1.39,1.7-.8,3.57-1.2,5.6-1.2,3.87,0,7,1.22,9.38,3.66Z"
          />
          <path
            style={style}
            d="m259.79,18.29c1.31-2.54,3.1-4.5,5.36-5.86,2.27-1.37,4.79-2.05,7.56-2.05,2.11,0,4.12.46,6.04,1.38.9.43,1.72.94,2.45,1.51.86.67,2.12.04,2.12-1.06V1.33c0-.73.59-1.33,1.33-1.33h5.67c.73,0,1.33.59,1.33,1.33v40.74c0,.73-.59,1.33-1.33,1.33h-5.67c-.73,0-1.33-.59-1.33-1.33h0c0-1.13-1.31-1.73-2.18-1.01-.63.52-1.33.99-2.1,1.4-1.84.98-3.97,1.46-6.39,1.46-2.74,0-5.24-.7-7.5-2.11-2.27-1.41-4.06-3.39-5.36-5.95-1.31-2.56-1.96-5.5-1.96-8.82s.65-6.2,1.96-8.74Zm22.43,3.72c-.78-1.43-1.84-2.52-3.17-3.28s-2.76-1.14-4.28-1.14-2.93.37-4.22,1.11c-1.29.74-2.34,1.83-3.14,3.25s-1.2,3.12-1.2,5.07.4,3.66,1.2,5.13c.8,1.47,1.86,2.59,3.17,3.37,1.31.78,2.71,1.17,4.19,1.17s2.95-.38,4.28-1.14,2.38-1.86,3.17-3.28c.78-1.43,1.17-3.14,1.17-5.13s-.39-3.7-1.17-5.13Z"
          />
          <path
            style={style}
            d="m297.36,18.29c1.31-2.54,3.09-4.5,5.33-5.86,2.25-1.37,4.76-2.05,7.53-2.05,2.42,0,4.55.49,6.36,1.47.79.43,1.51.9,2.17,1.42.89.71,2.2.12,2.2-1.02h0c0-.74.6-1.34,1.34-1.34h5.59c.74,0,1.34.6,1.34,1.34v29.81c0,.74-.6,1.34-1.34,1.34h-5.59c-.74,0-1.34-.6-1.34-1.34v-.04c0-1.13-1.3-1.74-2.18-1.03-.65.53-1.39,1.01-2.19,1.45-1.86,1-4,1.49-6.42,1.49-2.74,0-5.23-.7-7.48-2.11s-4.03-3.39-5.33-5.95c-1.31-2.56-1.96-5.5-1.96-8.82s.65-6.2,1.96-8.74Zm22.43,3.72c-.78-1.43-1.84-2.52-3.17-3.28s-2.76-1.14-4.28-1.14-2.93.37-4.22,1.11c-1.29.74-2.34,1.83-3.14,3.25s-1.2,3.12-1.2,5.07.4,3.66,1.2,5.13c.8,1.47,1.86,2.59,3.17,3.37,1.31.78,2.71,1.17,4.19,1.17s2.95-.38,4.28-1.14,2.38-1.86,3.17-3.28c.78-1.43,1.17-3.14,1.17-5.13s-.39-3.7-1.17-5.13Z"
          />
          <g>
            <circle style={style} cx="93.2" cy="26.56" r="17.03" />
            <path
              style={style}
              d="m70.83,26.56c0-7.27,4.56-13.48,10.98-15.92-2.48-.94-5.23-1.33-8.12-.99-8.09.96-14.44,7.71-14.93,15.84-.6,9.89,7.24,18.09,17,18.09,2.13,0,4.17-.39,6.05-1.11-6.42-2.44-10.98-8.64-10.98-15.92Z"
            />
          </g>
        </g>
      </svg>
    </Link>
  );
};
