import classNames from 'classnames';
import React, { FC, useMemo } from 'react';

import { UiButton } from 'src/components/UI/UiButton';
import { UiMereLabel } from 'src/components/UI/UiMereLabel';
import { useInputBaseHook } from 'src/hooks/inputs';
import { StateFormInputOptionsType, StateFormReturnType } from 'src/utils/stateForm';

type Props = {
  formProps: StateFormReturnType;
  name: string;
  label: string | React.ReactNode;
  options: { value: string; label: string }[];

  required?: boolean;
};
export const UiButtonCheckbox: FC<Props> = ({ formProps, options, name, label, required }) => {
  const { setValue, getSubscribeProps, unregister, register } = formProps;

  const inputOptions = useMemo(
    (): StateFormInputOptionsType => ({
      required,
    }),
    [required],
  );

  const [value, errors] = useInputBaseHook<string | number | null | undefined>({
    getSubscribeProps,
    name,
    inputOptions,
    unregister,
    register,
    type: 'buttonCheckbox',
  });

  const hasErrors = !!errors?.length;

  return (
    <div className="UiInput-wrapper">
      <UiMereLabel>{label}</UiMereLabel>
      <div
        className={classNames('buttonCheckbox', {
          required: hasErrors && required,
          error: hasErrors,
        })}
      >
        {options.map((option) => (
          <UiButton
            key={option.value}
            color={option.value === value ? 'dark' : 'light'}
            onClick={() => {
              setValue(name, option.value);
            }}
          >
            {option.label}
          </UiButton>
        ))}
      </div>
    </div>
  );
};
