/* eslint-disable max-len */
import { FC } from 'react';

export const IconHeader: FC = () => (
  <svg width="39" height="39" viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.900024" y="0.120117" width="38" height="38" fill="url(#patternUpcomingEvents)" />
    <defs>
      <pattern id="patternUpcomingEvents" patternContentUnits="objectBoundingBox" width="1" height="1">
        <use xlinkHref="#image0_1377_639" transform="scale(0.025641)" />
      </pattern>
      <image
        id="image0_1377_639"
        width="39"
        height="39"
        xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACcAAAAnCAYAAACMo1E1AAAACXBIWXMAAAsSAAALEgHS3X78AAAFL0lEQVRYhe2Xz28VVRTHv+fcOzPvV1/72gJtSQMpBRQsJfwoVKoLjfFHYjAmJu5cuNb/hpXGla5ckLBxQUjEKDEhIghIGwm0pUD7Kn32x3tv5t0fx8W8Vl0xJi8xMe+bTO5N5mbOZ86Z7zkZoKuuuurq/y3KelBE+LMvvnqn3ow39uwZ3IxjN6UFF+uu9e5AUV1c/L353t5Kz/eV4V377i8sFSo6uE353Clp4vJavPHRzIkXv741t/D6Rj2+9enHH97KEpMzvwWRV4XSpM4XXxjZPzacOPPqZlCMoiiaHjt2jIs95RPNMF9yovdXegcP3X38qPysVpsIRkd0lC+88dvycmlzfWNGF4tDWWOqLIdEhN8fHT00v7J03m7UyoPsy08XHx7tqS6sWN96hR48WNto/PEyniwkqrU13qiu7C7VqtRKGpPhg9lW0zRfk/mHq2LjKbVW9Z+89eaNLy9fNs9NSBa4x5cuFR78dP3zb27fOc9K1/tyoczV1v254SH+caUanxwczN/Z2twaYS5arZNn9XpwuNKrfllZpeMDg8EP1aqZ7OvLzTebfrfWuRNDQ9NvX7hw43lxdRa41bk5IuujA8WiIuIgUIywrxxXtC4d7S23BpQqHc7nkz7mXOxF7e7p8b1QxSO9vc2K1oWXesu14TCMSCTeVSiEeaUyVSwTXP++fX6jVosPlHvDYGAgACv4ZqPcePaMjhSKI05Ah6LcCJihdAAQwRNoMJ/PWWvp5K49g56JhoBiEARoOScdg8OjRxDnwGFIulwmYobVClhbg7WWAMCJEEcREGiIcxAROGOIiCCBJvIe1lpiAox1mcJmg5v+AHvW74GchQOgFIOVQt+xYyAvAAQiAtYaIoCIBwHwzoGVAjHDGgtxFmEYIWklwIULnYEbnR5FbbMPrBRaSYJcLgetNYy10Dp9hHMOWis45+FFoJWGdRZMBCZGkiRw3iEXRYiTJFNOsmUOgI1jaK3hWy1YIsA5eGthWi0QM5xzEMUQAbz3gGJ45+GJwMxwSQvWGjgAptHoLJyIwDkH5xy8c3ACWGehlII4lwKJaq8Ate8TETwRrDXwzsMYA2Oe2+L+HRyDSSkFVgytdQoFSVcREBG01nDOAQIoreDFg5lBRCAiCARaawRB0Fk4Dy8sDLTLxswAtfeUTkHxKaTzDuTT/u5c6kzvPYgIuVwORNlGema47QDbl7UWzjoorWCcgYjsnBGfute0zE7GRARRFCGJE6xvrHcWjoiglALzX2UFAK00REnbrRrWWnj4newQEaIoAjMjjmPc/Pkm5hfmOwsnIuS9B/C3srb3oLZhrIP49koAINiqb6G2VMPS0hIWFxexvr4Oa21n4eABYflnWZ0DVPpdiRcIS9pgPRCEAZotg3v37uH69eu4f/8+SqUSZs7NoH+gv7NwpEiYGVrptKysQO0eRkRwcGnpWYE1o5DP49e7d3HlyhWsrq5idHQUExMTODN1BkHYYbcCaekEslPW7d4nSFelFLRO4Te3tvDt1auoVqs4ePAgjh8/jrGxMVT6K9j+PDoGJ5I6zlqLIAxgjU3HU3s6bPc5AKhv1XH1u6tYXl7G1JkpnD51GsPDwwh0kLq4w02YmDlg5rZjFVS7zzEzoijacfLTp09x7do1zM7O4uzZs5icnMTevXsBAMYYhGEIY0ym34OscJ5EVq0xqwQY8R7SbhVhGEIxo95o4MmTJ5idnUW9XsfMzAzGx8dRKpUQBAGS9rBvNpsRM2eb/F111VVXXf13+hPFB6+L3VeEBgAAAABJRU5ErkJggg=="
      />
    </defs>
  </svg>
);
