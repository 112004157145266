import { FileContract, JwtTokenContract } from '@moonpanda/moonpanda.contracts';
import { loadStripe } from '@stripe/stripe-js';
import React, { FC, useCallback, useState } from 'react';

import { useNavigate, useSearchParams } from 'react-router-dom';
import { apiAccountUpdateUserInfo, apiPaymentsBuySubscription } from 'src/api/account';
import { apiAuthenticationSignUp } from 'src/api/authentication';
import { UiAvatarLoader } from 'src/components/common/UiAvatarLoader';
import { UiFormatNumberInput } from 'src/components/UI/inputs/FormatNumber';
import { UiInput } from 'src/components/UI/inputs/Text';
import { UiButton } from 'src/components/UI/UiButton';
import { STRIPE_PUBLISH_KEY } from 'src/config';
import { useRequest } from 'src/hooks/useRequest';
import { useSignInUser } from 'src/pages/SignUp/common';
import { IconBack } from 'src/pages/SignUp/icons/Back';
import { SignUpPageFormValues } from 'src/pages/SignUp/index';
import { ROUTE_INDEX_PAGE } from 'src/routes/paths';
import { showErrorToast, showWarningToast } from 'src/utils/errors';
import { useTHelper } from 'src/utils/i18n';
import { StateFormReturnType } from 'src/utils/stateForm';

import styles from './styles.module.scss';

type Props = {
  formProps: StateFormReturnType<SignUpPageFormValues>;
  onBack: () => void;
  fillProfile: boolean;
};

export const SignUpPageDetailsComponent: FC<Props> = ({ formProps, onBack, fillProfile }) => {
  const tHelper = useTHelper('pages.signUp.stepDetails');

  const { apiCaller } = useRequest();

  const [loading, setLoading] = useState(false);

  const [searchParams] = useSearchParams();

  const signInUser = useSignInUser();

  const navigate = useNavigate();

  const onStartTrialClick = useCallback(
    (referrerId: string | null) => {
      if (referrerId) {
        navigate(ROUTE_INDEX_PAGE);
      } else {
        apiCaller(apiPaymentsBuySubscription, {
          userEmail: formProps.getValue('email') || '',
          subscriptionType: 'Monthly',
        })
          .then((response) => {
            loadStripe(STRIPE_PUBLISH_KEY)
              .then((stripe) => stripe?.redirectToCheckout({ sessionId: response.data.sessionId }))
              .catch(showErrorToast);
          })
          .catch(showErrorToast);
      }
    },
    [apiCaller, formProps, navigate],
  );

  const onSubmit = (formData: SignUpPageFormValues) => {
    setLoading(true);

    const referrerId = searchParams.get('referrerId');

    const nextStep = (jwtContract: JwtTokenContract) => {
      signInUser(jwtContract, referrerId === null).then(() => {
        onStartTrialClick(referrerId);
      });
    };

    if (fillProfile) {
      /** set jwt token to be able to call APIs */
      signInUser(formData.jwt as JwtTokenContract, true);

      apiCaller(apiAccountUpdateUserInfo, {
        email: formData.email as string,
        emailSignature: formData.emailSignature,
        firstName: formData.firstName as string,
        lastName: formData.lastName as string,
        jobTitle: formData.jobTitle as string,
        companyName: formData.companyName as string,
        avatar: formData.avatar ? (formData.avatar as FileContract[])[0] : null,
        baseRate: formData.baseRate as number,
        userId: 0,
        isActive: false,
        isFrozen: false,
      })
        .then(() => {
          nextStep(formData.jwt as JwtTokenContract);
        })
        .catch((e) => {
          setLoading(false);

          showWarningToast(e);
        });
    } else {
      apiCaller(apiAuthenticationSignUp, {
        referrerId: referrerId ? +referrerId : undefined,
        email: formData.email as string,
        password: formData.password as string,
        profileDetails: {
          firstName: formData.firstName as string,
          lastName: formData.lastName as string,
          jobTitle: formData.jobTitle,
          companyName: formData.companyName,
          baseRate: formData.baseRate as number,
          avatar: formData.avatar ? (formData.avatar as FileContract[])[0] : undefined,
        },
      })
        .then((authResponse) => {
          nextStep(authResponse.data.jwtToken);
        })
        .catch((e) => {
          setLoading(false);

          showWarningToast(e);
        });
    }
  };

  return (
    <div className={styles.stepDetails}>
      <div className={styles.head}>
        <IconBack onClick={onBack} className="cursor-pointer" />
        <h1>{tHelper('header')}</h1>
      </div>

      <form onSubmit={formProps.onSubmit(onSubmit)}>
        <UiInput
          formProps={formProps}
          name="firstName"
          label={tHelper('firstNameLabel')}
          placeholder={tHelper('firstNameLabel')}
          required
        />
        <UiInput
          formProps={formProps}
          name="lastName"
          label={tHelper('lastNameLabel')}
          placeholder={tHelper('lastNameLabel')}
          required
        />
        {!fillProfile && (
          <UiInput
            formProps={formProps}
            name="password"
            type="password"
            label={tHelper('passwordLabel')}
            placeholder={tHelper('passwordLabel')}
            required
          />
        )}
        <UiInput
          formProps={formProps}
          name="jobTitle"
          label={tHelper('jobTitleLabel')}
          placeholder={tHelper('jobTitleLabel')}
        />
        <UiInput
          formProps={formProps}
          name="companyName"
          label={tHelper('companyNameLabel')}
          placeholder={tHelper('companyNameLabel')}
        />
        <UiFormatNumberInput formProps={formProps} name="baseRate" label={tHelper('baseRateLabel')} />

        <UiAvatarLoader formProps={formProps} name="avatar" label={tHelper('avatar')} />

        <UiButton type="submit" loading={loading}>
          {tHelper('submitBtn')}
        </UiButton>
      </form>
    </div>
  );
};
