/* eslint-disable max-len */
import { FC } from 'react';

export const IconSearch: FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      d="M14.2597 15.2608L8.77969 9.88078C8.35969 10.2308 7.8797 10.5108 7.3197 10.7108C6.7597 10.9108 6.16968 11.0108 5.54968 11.0108C4.04968 11.0108 2.77969 10.5008 1.73969 9.48078C0.699685 8.46078 0.179688 7.22078 0.179688 5.78078C0.179688 4.34078 0.699685 3.10078 1.73969 2.08078C2.77969 1.06078 4.03968 0.550781 5.51968 0.550781C6.99968 0.550781 8.2597 1.06078 9.2897 2.08078C10.3197 3.10078 10.8397 4.34078 10.8397 5.78078C10.8397 6.36078 10.7397 6.93078 10.5497 7.47078C10.3597 8.02078 10.0597 8.53078 9.67969 9.00078L15.1797 14.3608L14.2597 15.2608ZM5.52969 9.78078C6.65969 9.78078 7.6197 9.39078 8.4097 8.60078C9.1997 7.82078 9.58969 6.87078 9.58969 5.77078C9.58969 4.67078 9.1997 3.72078 8.4097 2.94078C7.6197 2.16078 6.65969 1.76078 5.52969 1.76078C4.39969 1.76078 3.41969 2.15078 2.61969 2.94078C1.81969 3.72078 1.41971 4.67078 1.41971 5.77078C1.41971 6.87078 1.81969 7.82078 2.61969 8.60078C3.41969 9.38078 4.38969 9.78078 5.52969 9.78078Z"
      fill="#3F5B64"
    />
  </svg>
);
