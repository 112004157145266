import { FileContract } from '@moonpanda/moonpanda.contracts';
import classNames from 'classnames';
import { FC, useCallback } from 'react';

import { UiWidget } from 'src/components/UI/widgets';
import useProjectsStore from 'src/pages/Projects/store';
import { ProjectViewWidgetComponent } from 'src/pages/Projects/view/widgets/View/component';

import parentStyles from 'src/pages/Projects/view/styles.module.scss';
import styles from './styles.module.scss';

export const ProjectViewWidget: FC = () => {
  const { projectData, updateProject } = useProjectsStore((state) => ({
    projectData: state.project.response,
    updateProject: state.updateProject,
  }));

  const onImageChange = useCallback(
    (file: FileContract) => {
      if (projectData) {
        updateProject({ ...projectData, cover: file });
      }
    },
    [projectData, updateProject],
  );

  return (
    <UiWidget className={classNames(parentStyles.baseWidget, styles.widget)}>
      {projectData && <ProjectViewWidgetComponent model={projectData} onImageChange={onImageChange} />}
    </UiWidget>
  );
};
