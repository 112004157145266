/* eslint-disable max-len */
import { FC } from 'react';
import { CommonIconProps } from 'src/utils/iconProps';

type Props = CommonIconProps & {
  onClick?: () => void;
};

export const IconDelete: FC<Props> = ({ className, onClick }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="10"
    height="11"
    viewBox="0 0 10 11"
    fill="none"
    onClick={onClick}
    className={className}
  >
    <path
      opacity="0.5"
      d="M1.96973 10.8694C1.72973 10.8694 1.52973 10.7794 1.34973 10.6094C1.17973 10.4394 1.08978 10.2294 1.08978 9.98938V1.72937H0.469727V1.03937H3.06976V0.609375H6.97974V1.03937H9.57977V1.72937H8.95978V9.98938C8.95978 10.2294 8.86977 10.4294 8.69977 10.6094C8.52977 10.7794 8.31977 10.8694 8.07977 10.8694H1.95978H1.96973ZM8.27979 1.72937H1.77979V9.98938C1.77979 10.0394 1.79978 10.0894 1.83978 10.1194C1.87978 10.1594 1.91973 10.1694 1.96973 10.1694H8.08978C8.08978 10.1694 8.17973 10.1494 8.21973 10.1094C8.25973 10.0694 8.27979 10.0294 8.27979 9.97937V1.71938V1.72937ZM3.52979 8.89937H4.21973V2.99937H3.52979V8.89937ZM5.83978 8.89937H6.52979V2.99937H5.83978V8.89937Z"
      fill="#3F5B64"
    />
  </svg>
);
