import { TimeTrackingByTeamMemberContract } from '@moonpanda/moonpanda.contracts';
import classNames from 'classnames';
import { endOfWeek, getWeek, startOfWeek } from 'date-fns';
import React, { FC, useEffect, useMemo, useState } from 'react';

import { apiGetProjectTimeTrackingModalData, apiGetProjectTimeTrackingModalExportLink } from 'src/api/projects';
import { UiModal } from 'src/components/UI/Modals';
import { UiTable } from 'src/components/UI/table';
import { UiButton } from 'src/components/UI/UiButton';
import { useRequest } from 'src/hooks/useRequest';
import { CommonInnerApiResponseType } from 'src/models/API';
import { BaseSeparateModal } from 'src/models/modals';
import { IconArrow } from 'src/pages/Dashboard/widgets/timeTracking/icons/Arrow';
import { IconTimeTrackingWidgetHeader } from 'src/pages/Dashboard/widgets/timeTracking/icons/Header';
import { CDate } from 'src/utils/CDate';
import { numberToTimeObject } from 'src/utils/dateTime';
import { showErrorToast } from 'src/utils/errors';
import { downloadBlobAsFile } from 'src/utils/file';
import { useTHelper } from 'src/utils/i18n';

import styles from 'src/pages/Projects/view/widgets/Summary/styles.module.scss';

type Props = BaseSeparateModal & {
  currentWeek: Date;
  projectId: number;
};

export const ProjectSummaryWidgetTimeTrackingModalPartial: FC<Props> = ({
  onClose,
  currentWeek: initialWeek,
  projectId,
}) => {
  const tHelper = useTHelper('pages.projects.projectPage.widgets.summary.timeTracking.modal');

  const { apiCaller } = useRequest();

  const [data, setData] = useState<CommonInnerApiResponseType<TimeTrackingByTeamMemberContract[]>>({
    data: [],
    twoFactorConfirmation: false,
    isSuccess: true,
    errors: [],
    totalCount: 0,
    loading: true,
  });

  const [currentWeek, setCurrentWeek] = useState<Date>(initialWeek);

  useEffect(() => {
    apiCaller(apiGetProjectTimeTrackingModalData, {
      weekNumber: getWeek(currentWeek),
      projectId,
    }).then((response) => {
      setData({
        ...response,
        loading: false,
      });
    });
  }, [projectId, currentWeek, apiCaller]);

  const getTimeFormatted = (time: number | undefined) => {
    const times = numberToTimeObject(time || 0);

    return `${times.hours}.${times.minutes}`;
  };

  const columns = useMemo(
    () => [
      {
        title: tHelper('columns.teamMemberName'),
        renderValue: ({ teamMemberName }: TimeTrackingByTeamMemberContract) => (
          <span title={teamMemberName}>{teamMemberName}</span>
        ),
        className: classNames('text-left', styles.ellipsable),
      },
      {
        title: tHelper('columns.billableHours'),
        renderValue: ({ billable }: TimeTrackingByTeamMemberContract) => getTimeFormatted(billable),
        className: 'text-center',
      },
      {
        title: tHelper('columns.nonBillable'),
        renderValue: ({ nonBillable }: TimeTrackingByTeamMemberContract) => getTimeFormatted(nonBillable),
        className: 'text-center',
      },
      {
        title: tHelper('columns.rate'),
        renderValue: 'rate',
        className: 'text-center',
      },
      {
        title: tHelper('columns.totalInvoiced'),
        renderValue: ({ totalInvoiced }: TimeTrackingByTeamMemberContract) => getTimeFormatted(totalInvoiced),
        className: 'text-center',
      },
    ],
    [tHelper],
  );

  return (
    <UiModal onClose={onClose} header={tHelper('header')} contentClassName={styles.modalWrapper}>
      <div className={styles.content}>
        <div className={styles.header}>
          <IconTimeTrackingWidgetHeader />

          <div className={styles.week}>
            <IconArrow
              className={classNames(styles.arrow, styles.arrowLeft)}
              onClick={() => {
                setCurrentWeek(
                  CDate.addTime(currentWeek, {
                    days: -7,
                  }),
                );
              }}
            />
            {tHelper('week', {
              start: CDate.format(startOfWeek(currentWeek), 'MMM d'),
              end: CDate.format(endOfWeek(currentWeek), 'MMM d'),
            })}
            <IconArrow
              className={styles.arrow}
              onClick={() => {
                setCurrentWeek(
                  CDate.addTime(currentWeek, {
                    days: 7,
                  }),
                );
              }}
            />
          </div>
        </div>

        <UiTable
          data={data}
          getData={() => null}
          columns={columns}
          itemKeyProp="projectName"
          lastColumn={
            <tr>
              <td>
                <strong>{tHelper('columns.total')}</strong>
              </td>
              <td>
                <strong>{getTimeFormatted(data.data.reduce((acc, { billable }) => acc + billable, 0))}</strong>
              </td>
              <td>
                <strong>{getTimeFormatted(data.data.reduce((acc, { nonBillable }) => acc + nonBillable, 0))}</strong>
              </td>
              <td>&nbsp;</td>
              <td>
                <strong>
                  {getTimeFormatted(data.data.reduce((acc, { totalInvoiced }) => acc + totalInvoiced, 0))}
                </strong>
              </td>
            </tr>
          }
        />

        <UiButton
          className={styles.button}
          onClick={() => {
            apiCaller(
              apiGetProjectTimeTrackingModalExportLink,
              {
                weekNumber: getWeek(currentWeek),
                projectId,
              },
              { expectBlob: true },
            )
              .then((response) => {
                downloadBlobAsFile(
                  response as unknown as Blob,
                  `timesheet_${CDate.format(startOfWeek(currentWeek), 'dd-MM-yyyy')}-${CDate.format(
                    endOfWeek(currentWeek),
                    'dd-MM-yyyy',
                  )}`,
                );
              })
              .catch(showErrorToast);
          }}
        >
          {tHelper('export')}
        </UiButton>
      </div>
    </UiModal>
  );
};
