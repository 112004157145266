import { SortingOrderEnumContract } from '@moonpanda/moonpanda.contracts';
import isFunction from 'lodash/isFunction';
import React from 'react';

import { SafeAnyType } from 'src/utils/safeAny';
import { NullableUndefineable } from 'src/utils/types';

export type BaseTableFilterFiltersType = {
  FilterType: number; // see uiTableGetType
  PropertyName: string;
}[];

export type CommonTableRequestType = {
  pageSize: number; // limit
} & NullableUndefineable<{
  PageIndex: number; // page number, starts with 0 (offset)
  propertyName: string; // sort by named param
  sortingOrder: SortingOrderEnumContract;
  Filters: BaseTableFilterFiltersType;
  CheckboxFilters: SafeAnyType[];
  SortNullsLast: boolean;
}>;

/**
 * @deprecated
 */
export type CommonTableResponseType<T extends Record<SafeAnyType, SafeAnyType>> = {
  Items: T[];
  TotalRecordsCount: number;
};

export type UiTableColumnItemType<T = SafeAnyType> = {
  title: string; // must be required always
  renderValue: ((item: T) => React.ReactNode) | keyof T;

  className?: string;
  thClassName?: string;
  useSortAndNotShow?: boolean; // useful when the column must have sorting but must not be seen
  sortPropertyName?: string;
  sortable?: boolean;
  defaultSortDirection?: SortingOrderEnumContract;
};

// null for columns without data
export type UiTableColumnType<T = SafeAnyType> =
  | UiTableColumnItemType<T>
  | ((item: T | null) => UiTableColumnItemType<T>);

export const uiTableGetColumnDataWithItem = (columnRaw: UiTableColumnType, item: SafeAnyType) =>
  isFunction(columnRaw) ? columnRaw(item) : columnRaw;

export type UiTableInnerFiltersType = {
  propertyName: NonNullable<CommonTableRequestType['propertyName']>;
  sortingOrder: NonNullable<CommonTableRequestType['sortingOrder']>;
};

export type UiTableRefProps = {
  getFilters: () => UiTableInnerFiltersType | undefined;
};
