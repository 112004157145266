import { ProfileDetailsContract } from '@moonpanda/moonpanda.contracts';
import useGlobalStore from 'src/store';
import { SafeAnyType } from 'src/utils/safeAny';

declare global {
  interface Window {
    Intercom: (mode: string, data: Record<SafeAnyType, SafeAnyType>) => void; // chat
    userGuiding: {
      identify: (id: string | number, data: Record<SafeAnyType, SafeAnyType>) => void;
    };
  }
}

export const userIntercom = () => {
  const userData = useGlobalStore.getState().user.response;

  if (userData) {
    try {
      window.Intercom('boot', {
        app_id: 'nno708pw',
        email: userData.email,
        user_id: userData.userId,
        name: `${userData.firstName} ${userData.lastName}`,
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn("Couldn't call Intercom");
    }
  }
};

export const userGuidingIdentify = (userData: ProfileDetailsContract) => {
  try {
    window.userGuiding.identify(userData.userId, {
      email: userData.email,
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn("Couldn't call userGuiding");
  }
};
