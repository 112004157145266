import { FC } from 'react';

import { SettingsPageLayout } from 'src/pages/Settings/layout';
import { SettingsPageDeleteComponent } from 'src/pages/Settings/Personal/components/Delete';
import { SettingsPagePersonalComponent } from 'src/pages/Settings/Personal/components/Personal';
import { SettingsPageIntegrationsComponent } from './components/Integrations';

import styles from './styles.module.scss';

export const SettingsPage: FC = () => (
  <SettingsPageLayout className="fullPageLoader">
    <div className={styles.wrapper}>
      <SettingsPagePersonalComponent />
      <SettingsPageIntegrationsComponent />
      <SettingsPageDeleteComponent />
    </div>
  </SettingsPageLayout>
);
