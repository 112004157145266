import { InvoiceContract } from '@moonpanda/moonpanda.contracts';
import { ApiPaginateRequestType, ApiPatchOperationType } from 'src/models/API';

import { ApiFunctionType, httpDelete, httpGet, httpPost, httpPut } from 'src/utils/http';

const getPath = (url: string) => `Invoices/${url}`;

const getProjectPath = (url: string) => `projects/${url}`;

export const apiCreateInvoice: ApiFunctionType<InvoiceContract & { projectId: number }, InvoiceContract> = (data) =>
  httpPost(getProjectPath(`${data?.projectId}/invoices`), data);

export const apiUpdateInvoice: ApiFunctionType<ApiPatchOperationType & { id: number }, InvoiceContract> = (data) =>
  httpPut(getPath(`${data.id}`), data);

export const apiGetInvoicingList: ApiFunctionType<ApiPaginateRequestType, InvoiceContract[]> = (data) =>
  httpGet(getPath('list'), data);

export const apiDeleteInvoice: ApiFunctionType<{ id: number }, void> = (data) =>
  httpDelete(getPath(`${data.id}`), data);

export const apiGetInvoice: ApiFunctionType<{ id: string }, InvoiceContract> = (data) =>
  httpGet(getPath(data.id), data);

export const apiGetInvoiceExportLink: ApiFunctionType<{ id: number }, Blob> = (data) =>
  httpGet(`invoices/${data.id}/export`, data, {
    expectBlob: true,
  });

export const apiDeleteInvoiceItem: ApiFunctionType<{ invoiceId: number; invoiceItemId: number }, void> = (data) =>
  httpDelete(getPath(`${data.invoiceId}/invoiceItems/${data.invoiceItemId}`), data);
