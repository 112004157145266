export const getQueryParamQuiet = (param: string) => new URLSearchParams(document.location.search).get(param);

export const setQueryParamQuiet = (params: Record<string, string>) => {
  const searchParams = new URLSearchParams(window.location.search);

  Object.entries(params).forEach(([key, value]) => {
    searchParams.set(key, value);
  });

  const newRelativePathQuery =
    searchParams.size === 0 ? window.location.pathname : `${window.location.pathname}?${searchParams.toString()}`;

  // eslint-disable-next-line no-restricted-globals
  history.replaceState(null, '', newRelativePathQuery);
};

export const deleteQueryParamQuiet = (params: string[]) => {
  const searchParams = new URLSearchParams(window.location.search);

  params.forEach((key) => {
    searchParams.delete(key);
  });

  const newRelativePathQuery =
    searchParams.size === 0 ? window.location.pathname : `${window.location.pathname}?${searchParams.toString()}`;

  // eslint-disable-next-line no-restricted-globals
  history.replaceState(null, '', newRelativePathQuery);
};
