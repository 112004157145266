/* eslint-disable max-len */
import { FC } from 'react';

type Props = {
  color: string;
};

export const IconArrowRight: FC<Props> = ({ color }) => (
  <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.1699 30.7197C23.4542 30.7197 30.1699 24.004 30.1699 15.7197C30.1699 7.43546 23.4542 0.719727 15.1699 0.719727C6.88565 0.719727 0.169922 7.43546 0.169922 15.7197C0.169922 24.004 6.88565 30.7197 15.1699 30.7197Z"
      fill={color}
    />
    <path
      d="M14.4099 22.4595L14.3899 22.4395C13.9799 22.0195 13.9799 21.3495 14.3899 20.9395L18.5599 16.7896H8.72992C8.13992 16.7896 7.66992 16.3096 7.66992 15.7296C7.66992 15.1396 8.14992 14.6696 8.72992 14.6696H18.5599L14.3999 10.5096C13.9799 10.0896 13.9799 9.41955 14.3999 8.99955H14.4099C14.8299 8.56955 15.4999 8.56954 15.9199 8.98954L21.9099 14.9796C22.3299 15.3996 22.3299 16.0695 21.9099 16.4895L15.9199 22.4796C15.4999 22.8996 14.8199 22.8996 14.4099 22.4796V22.4595Z"
      fill="white"
    />
  </svg>
);
