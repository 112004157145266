import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { InvoiceContract, InvoiceItemContract } from '@moonpanda/moonpanda.contracts';
import { shallow } from 'zustand/shallow';
import { useSearchParams } from 'react-router-dom';
import isFunction from 'lodash/isFunction';

import { UiModal } from 'src/components/UI/Modals';
import useModalsStore from 'src/store/modals';
import { useTHelper } from 'src/utils/i18n';
import { showErrorToast } from 'src/utils/errors';
import { useRequest } from 'src/hooks/useRequest';
import { apiCreateInvoice, apiGetInvoice, apiGetInvoiceExportLink } from 'src/api/invoicing';
import { UiButton } from 'src/components/UI/UiButton';
import { CDate } from 'src/utils/CDate';
import { SHORT_DATE_FORMAT } from 'src/config';
import { formatCurrency } from 'src/utils/currency';
import { downloadBlobAsFile } from 'src/utils/file';
import { UiPopover } from 'src/components/UI/Popover';

import styles from './styles.module.scss';

export type ViewInvoiceModalAdditionalInfoType = {
  invoiceId?: number;
  onCreateInvoice?: () => void;
  invoiceModel?: InvoiceContract;
};

export const ViewInvoiceModal: React.FC = () => {
  const tHelper = useTHelper('modals.viewInvoice');

  const { apiCaller } = useRequest();

  const [invoiceData, setInvoiceData] = useState<InvoiceContract>();

  const { toggleModals, additionalInfo } = useModalsStore(
    (state) => ({
      toggleModals: state.toggleModals,
      additionalInfo: state.viewInvoice.additionalInfo as ViewInvoiceModalAdditionalInfoType,
    }),
    shallow,
  );

  const [, setSearchParams] = useSearchParams();

  const closeModal = useCallback(
    (): Promise<void> =>
      new Promise((resolve) => {
        toggleModals('viewInvoice', false);

        setSearchParams((state) => {
          state.delete('viewInvoiceModalId');

          return state;
        });

        resolve(undefined);
      }),
    [setSearchParams, toggleModals],
  );

  useEffect(() => {
    setSearchParams((state) => {
      if (additionalInfo.invoiceId) {
        state.set('viewInvoiceModalId', additionalInfo.invoiceId.toString());
      }

      return state;
    });
  }, [setSearchParams, additionalInfo.invoiceId]);

  useEffect(() => {
    if (additionalInfo.invoiceModel) {
      setInvoiceData(additionalInfo.invoiceModel);
    }
  }, [additionalInfo.invoiceModel]);

  useEffect(() => {
    if (additionalInfo.invoiceId) {
      apiCaller(apiGetInvoice, {
        id: String(additionalInfo.invoiceId),
      }).then(
        ({ data }) => {
          setInvoiceData(data);
        },
        (error) => {
          showErrorToast(error);

          toggleModals('viewInvoice', false);
        },
      );
    }
  }, [apiCaller, toggleModals, additionalInfo.invoiceId]);

  const subTotal = useMemo(
    () => invoiceData?.items.reduce((acc, el) => acc + Number(el.amount), 0) || 0,
    [invoiceData],
  );

  const handleDownloadInvoice = useCallback(() => {
    if (invoiceData && additionalInfo.invoiceId) {
      apiCaller(
        apiGetInvoiceExportLink,
        {
          id: invoiceData?.id,
        },
        { expectBlob: true },
      )
        .then((response) => {
          toggleModals('viewInvoice', false);
          downloadBlobAsFile(response as unknown as Blob, `${invoiceData.invoiceNameId}`);
        })
        .catch(showErrorToast);
    } else {
      apiCaller(apiCreateInvoice, additionalInfo.invoiceModel)
        .then((createResponse) => {
          if (isFunction(additionalInfo?.onCreateInvoice)) {
            additionalInfo?.onCreateInvoice();
          }
          const invoiceId = createResponse.data.id;

          return apiCaller(apiGetInvoiceExportLink, { id: invoiceId }, { expectBlob: true });
        })
        .then((downloadResponse) => {
          toggleModals('viewInvoice', false);
          if (isFunction(additionalInfo?.onCreateInvoice)) {
            additionalInfo?.onCreateInvoice();
          }
          downloadBlobAsFile(downloadResponse as unknown as Blob, `Invoice - ${invoiceData?.invoiceNameId}`);
        })
        .catch(showErrorToast);
    }
  }, [additionalInfo, apiCaller, invoiceData, toggleModals]);

  const handleEditInvoice = useCallback(() => {
    if (invoiceData) {
      toggleModals('viewInvoice', false);
      toggleModals('createInvoice', true, { invoiceData, onCreateInvoice: additionalInfo.onCreateInvoice });
    }
  }, [additionalInfo.onCreateInvoice, invoiceData, toggleModals]);

  return (
    <UiModal
      onClose={closeModal}
      className={styles.modal}
      bodyClassName={styles.body}
      header={tHelper('header')}
      headerClassName={styles.header}
    >
      <div className={styles.wrapper}>
        <div className={styles.mainInfo}>
          <div className={styles.billTo}>
            <p className={styles.mainInfoHeader}>{tHelper('billTo')}</p>
            <p className={styles.mainInfoText}>{invoiceData?.customerData}</p>
          </div>
          <div className={styles.invoice}>
            <p className={styles.mainInfoHeader}>
              {tHelper('invoice')} {invoiceData?.invoiceNameId}
            </p>
            <p className={styles.mainInfoText}>
              {tHelper('invoiceDate')}
              {invoiceData?.invoiceDate ? CDate.format(new Date(invoiceData?.invoiceDate), SHORT_DATE_FORMAT) : null}
            </p>
            <p className={styles.mainInfoText}>
              {tHelper('dueOn')}{' '}
              {invoiceData?.dueDate ? CDate.format(new Date(invoiceData?.dueDate), SHORT_DATE_FORMAT) : null}
            </p>
          </div>
        </div>
        {!!invoiceData?.items.length && (
          <table>
            <thead>
              <tr>
                <th>#</th>
                <th>{tHelper('description')}</th>
                <th>{tHelper('qty')}</th>
                <th>{tHelper('cost')}</th>
                <th>{tHelper('amt')}</th>
              </tr>
            </thead>
            <tbody>
              {invoiceData &&
                invoiceData.items?.map((item: InvoiceItemContract, index) => (
                  <tr key={item?.id}>
                    <td>{index + 1}</td>
                    <td>
                      <UiPopover
                        placement="top"
                        trigger="hover"
                        /* eslint-disable-next-line react/no-unstable-nested-components,react/no-unescaped-entities */
                        content={() => <span>{item.description}</span>}
                      >
                        <span>{item.description}</span>
                      </UiPopover>
                    </td>
                    <td>
                      <UiPopover
                        placement="top"
                        trigger="hover"
                        /* eslint-disable-next-line react/no-unstable-nested-components,react/no-unescaped-entities */
                        content={() => <span>{item.quantity}</span>}
                      >
                        <span>{item.quantity}</span>
                      </UiPopover>
                    </td>
                    <td>
                      <UiPopover
                        placement="top"
                        trigger="hover"
                        /* eslint-disable-next-line react/no-unstable-nested-components,react/no-unescaped-entities */
                        content={() => <span> {Number(item.cost).toFixed(2)}</span>}
                      >
                        <span> {Number(item.cost).toFixed(2)}</span>
                      </UiPopover>
                    </td>
                    <td>
                      <UiPopover
                        placement="top"
                        trigger="hover"
                        /* eslint-disable-next-line react/no-unstable-nested-components,react/no-unescaped-entities */
                        content={() => <span>{item.amount.toFixed(2)}</span>}
                      >
                        <span> {item.amount.toFixed(2)}</span>
                      </UiPopover>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        )}
        {!!invoiceData?.invoiceParameters && (
          <div className={styles.totalInfo}>
            <p>
              {tHelper('subtotal')} <span>{formatCurrency(subTotal)}</span>
            </p>
            <p>
              {tHelper('tax')}
              <span>
                {`${invoiceData?.invoiceParameters.taxType === 'Number' ? '$' : ''}
                ${invoiceData?.invoiceParameters.tax}
                ${invoiceData?.invoiceParameters.taxType === 'Percent' ? '%' : ''}
                `}
              </span>
            </p>
            <p>
              {tHelper('discount')}{' '}
              <span>
                {`${invoiceData?.invoiceParameters.discountType === 'Number' ? '$' : ''}
                ${invoiceData?.invoiceParameters.discount || 0}
                ${invoiceData?.invoiceParameters.discountType === 'Percent' ? '%' : ''}
                `}
              </span>
            </p>
            <p>
              {tHelper('totalDue')} <span>{invoiceData?.total}</span>
            </p>
          </div>
        )}
        <div className={styles.note}>
          <p>{tHelper('note')}</p>
          <p>{invoiceData?.note}</p>
        </div>
        <div className={styles.buttonBlock}>
          <UiButton color="dark" onClick={handleEditInvoice}>
            {tHelper('edit')}
          </UiButton>
          <UiButton color="green" onClick={handleDownloadInvoice}>
            {tHelper('download')}
          </UiButton>
        </div>
      </div>
    </UiModal>
  );
};
