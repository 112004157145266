import classNames from 'classnames';
import React, { FC } from 'react';

import { UiMereLabel } from 'src/components/UI/UiMereLabel';
import { ChildrenPropType } from 'src/utils/types';

type UiCommonInputWrapperProps = {
  children: ChildrenPropType;

  className?: string;
};

export const UiCommonInputWrapper: FC<UiCommonInputWrapperProps> = ({ children, className }) => (
  <div className={classNames('UiInput-wrapper', className)}>{children}</div>
);

type UiCommonInputLabelProps = {
  id: string;
  children: ChildrenPropType;
  show: boolean;
  hasErrors: boolean;

  className?: string;
  disabled?: boolean;
  required?: boolean;
};

export const UiCommonInputLabel: FC<UiCommonInputLabelProps> = ({
  show,
  id,
  hasErrors,
  children,
  className,
  disabled,
  required,
}) =>
  show ? (
    <UiMereLabel
      htmlFor={id}
      className={classNames(className, {
        disabled,
        required: hasErrors && required,
        error: hasErrors,
      })}
    >
      {children}
    </UiMereLabel>
  ) : null;
