/**
 * a formatter for currencies
 */
export const formatCurrency = (
  value: number,
  digits = 2,
  decimalSeparator: string | undefined = undefined,
  groupSeparator: string | undefined = undefined,
): string => {
  if (value == null || Number.isNaN(value)) {
    return '';
  }

  const n = value > 0 ? value : -value;

  const c = Number.isNaN(Math.abs(digits)) ? 2 : digits;

  const d = decimalSeparator === undefined ? '.' : decimalSeparator;

  const t = groupSeparator === undefined ? ',' : groupSeparator;

  const s = value < 0 ? '-' : '';

  const i = `${parseInt(Math.abs(+n || 0).toFixed(c), 10)}`;

  let j = i.length;

  j = j > 3 ? j % 3 : 0;

  return (
    s +
    (j ? i.substr(0, j) + t : '') +
    i.substr(j).replace(/(\d{3})(?=\d)/g, `$1${t}`) +
    (c
      ? d +
        Math.abs(n - Number(i))
          .toFixed(c)
          .slice(2)
      : '')
  );
};
