import { CommonApiResponseType } from 'src/models/API';

export type BaseStoreEntityType<R = string | null, E = CommonApiResponseType> = {
  loading: boolean;
  error: E | null;
  response: R | null;
};

export const baseStoreEntityValue = <R = string, E = string>(
  response: R | null = null,
  loading = false,
  error: E | null = null,
): BaseStoreEntityType<R, E> => ({
  loading,
  error,
  response,
});
