import {
  EventContract,
  PhaseContract,
  ProjectContract,
  TaskWeekStatusContract,
  TimeTrackingContract,
} from '@moonpanda/moonpanda.contracts';
import { endOfDay, getWeek, startOfDay } from 'date-fns';
import { compare } from 'fast-json-patch';
import { create } from 'zustand';

import { FNPropsType, useRequest } from 'src/hooks/useRequest';
import { BaseStoreEntityType, baseStoreEntityValue } from 'src/store/types';
import { showErrorToast } from 'src/utils/errors';
import {
  apiGetProjectData,
  apiGetProjectTimeTrackingData,
  apiGetProjectTotalTaskOverviewData,
  apiUpdateProjectData,
} from 'src/api/projects';
import { prepareDataToPatch } from 'src/utils/http';
import { apiGetEvents } from 'src/api/events';
import { apiGetTasks } from 'src/api/tasks';

export type ProjectStoreCRUDProjectDataType = Omit<ProjectContract, 'phase'> & {
  phase: (PhaseContract & {
    hasTasks: boolean;
  })[];
};

type Actions = {
  getProject: (cleanLoad?: boolean) => void;
  getProjectSilent: (projectId: number) => void;
  refreshProject: () => void;
  updateProject: (data: ProjectContract) => void;

  getWidgetTotalTaskOverviewData: (softReload?: boolean) => void;
  refreshWidgetTotalTaskOverviewData: () => void;

  getWidgetTimeTrackingData: (softReload?: boolean) => void;
  refreshWidgetTimeTrackingData: () => void;
  setWidgetTimeTrackingDataWeek: (date: Date) => void;

  start: (data: { projectId: number }) => Promise<boolean>;
  stop: () => void;

  getUpcomingEvents: () => void;

  getProjectCRUDStep: () => Promise<ProjectStoreCRUDProjectDataType>;
};

type State = {
  request: {
    apiCaller: FNPropsType;
    abort: () => void;
  } | null;

  commonData: { projectId: number };

  project: BaseStoreEntityType<ProjectContract | null>;

  widgetTotalTaskOverviewData: BaseStoreEntityType<TaskWeekStatusContract | null>;

  widgetTimeTrackingData: BaseStoreEntityType<TimeTrackingContract | null>;
  widgetTimeTrackingWeek: Date;

  upcomingEvents: BaseStoreEntityType<{ today: EventContract[]; later: EventContract[] }>;
};

const initialState: State = {
  request: null,

  commonData: { projectId: 0 },

  project: baseStoreEntityValue(null, true),

  widgetTotalTaskOverviewData: baseStoreEntityValue(null, true),

  widgetTimeTrackingData: baseStoreEntityValue(null, true),
  widgetTimeTrackingWeek: new Date(),

  upcomingEvents: baseStoreEntityValue({ today: [], later: [] }, true),
};

const useProjectsStore = create<State & Actions>()((set, get) => ({
  ...initialState,

  getProject: (cleanLoad = true) => {
    const apiCaller = get().request?.apiCaller;

    if (apiCaller) {
      if (cleanLoad) {
        set({
          project: baseStoreEntityValue(null, true),
        });
      }

      apiCaller(apiGetProjectData, get().commonData)
        .then(({ data }) => {
          set({
            project: baseStoreEntityValue(data, false),
          });
        })
        .catch((e) => {
          set({
            project: baseStoreEntityValue(null, false, e),
          });

          showErrorToast(e);
        });

      get().getWidgetTotalTaskOverviewData();
      get().getUpcomingEvents();
    }
  },
  getProjectSilent: (projectId) => {
    if (get().project.response?.id === projectId) {
      get().getProject(false);
    }
  },
  refreshProject: () => {
    get().getProject();
  },
  updateProject: (data) => {
    const apiCaller = get().request?.apiCaller;

    if (apiCaller) {
      const projectData = get().project.response;

      if (projectData) {
        apiCaller(
          apiUpdateProjectData,
          prepareDataToPatch(compare(projectData, data), {
            projectId: projectData.id,
          }),
        )
          .then(({ data }) => {
            get().project.response = data;
          })
          .catch(showErrorToast);
      }
    }
  },

  getWidgetTotalTaskOverviewData: (softReload = false) => {
    const apiCaller = get().request?.apiCaller;

    if (apiCaller) {
      if (!softReload) {
        set({
          widgetTotalTaskOverviewData: baseStoreEntityValue(null, true),
        });
      }

      apiCaller(apiGetProjectTotalTaskOverviewData, get().commonData)
        .then(({ data }) => {
          set({
            widgetTotalTaskOverviewData: baseStoreEntityValue(data, false),
          });
        })
        .catch((e) => {
          set({
            widgetTotalTaskOverviewData: baseStoreEntityValue(null, false, e),
          });

          showErrorToast(e);
        });
    }
  },
  refreshWidgetTotalTaskOverviewData: () => {
    get().getWidgetTotalTaskOverviewData(true);
  },

  getWidgetTimeTrackingData: (softReload = false) => {
    const apiCaller = get().request?.apiCaller;

    if (apiCaller) {
      if (!softReload) {
        set({
          widgetTimeTrackingData: baseStoreEntityValue(null, false),
        });
      }

      apiCaller(apiGetProjectTimeTrackingData, {
        ...get().commonData,
        weekNumber: getWeek(get().widgetTimeTrackingWeek),
      })
        .then(({ data }) => {
          set({
            widgetTimeTrackingData: baseStoreEntityValue(data, false),
          });
        })
        .catch((e) => {
          set({
            widgetTimeTrackingData: baseStoreEntityValue(null, false, e),
          });

          showErrorToast(e);
        });
    }
  },
  refreshWidgetTimeTrackingData: () => {
    get().getWidgetTimeTrackingData(true);
  },
  setWidgetTimeTrackingDataWeek: (date) => {
    set({
      widgetTimeTrackingWeek: date,
    });

    get().getWidgetTimeTrackingData(true);
  },

  start: (commonData) =>
    new Promise((resolve) => {
      const apiCaller = get().request?.apiCaller;

      if (apiCaller) {
        set({
          commonData,
        });
      } else {
        const { apiCaller, abort } = useRequest.getStatic();

        set({
          commonData,
          request: {
            apiCaller,
            abort,
          },
        });
      }

      resolve(true);
    }),

  stop: () => {
    if (get().request !== null) {
      get().request?.abort();

      set(initialState);
    }
  },

  getUpcomingEvents: () => {
    const apiCaller = get().request?.apiCaller;

    if (apiCaller) {
      Promise.all([
        apiCaller(
          apiGetEvents,
          {
            page: 0,
            pageSize: 2,
            filters: [
              {
                propertyName: 'startDate',
                operation: 'GreaterThanOrEqual',
                value: startOfDay(new Date()) as unknown as string,
              },
              {
                propertyName: 'startDate',
                operation: 'LessThanOrEqual',
                value: endOfDay(new Date()) as unknown as string,
              },
              {
                propertyName: 'projectId',
                operation: 'Equals',
                value: get().commonData.projectId.toString(),
              },
            ],
            orderBy: [{ propertyName: 'startDate', sortingOrder: 'Asc' }],
          },
          { key: 'today' },
        ),
        apiCaller(
          apiGetEvents,
          {
            page: 0,
            pageSize: 2,
            filters: [
              {
                propertyName: 'startDate',
                operation: 'GreaterThanOrEqual',
                value: endOfDay(new Date()) as unknown as string,
              },
              {
                propertyName: 'projectId',
                operation: 'Equals',
                value: get().commonData.projectId.toString(),
              },
            ],
            orderBy: [{ propertyName: 'startDate', sortingOrder: 'Asc' }],
          },
          { key: 'later' },
        ),
      ])
        .then(([todayResponse, laterResponse]) => {
          set({
            upcomingEvents: baseStoreEntityValue({ today: todayResponse.data, later: laterResponse.data }, false),
          });
        })
        .catch((e) => {
          set({
            upcomingEvents: baseStoreEntityValue({ today: [], later: [] }, false),
          });

          showErrorToast(e);
        });
    }
  },

  getProjectCRUDStep: () => {
    const apiCaller = get().request?.apiCaller;

    if (apiCaller) {
      const { projectId } = get().commonData;

      return new Promise<ProjectStoreCRUDProjectDataType>((resolve, reject) => {
        apiCaller(apiGetProjectData, { projectId })
          .then(({ data }) => {
            Promise.all(
              data.phase.map(({ id }) =>
                apiGetTasks({
                  page: 0,
                  pageSize: 1,
                  filters: [
                    { propertyName: 'projectId', operation: 'Equals', value: `${projectId}` },
                    { propertyName: 'phaseId', operation: 'Equals', value: `${id}` },
                  ],
                }),
              ),
            )
              .then((response) => {
                resolve({
                  ...data,
                  phase: data.phase.map((phase, index) => ({
                    ...phase,
                    hasTasks: response[index].data.length > 0,
                  })),
                });
              })
              .catch(reject);
          })
          .catch(reject);
      });
    }

    return Promise.reject(Error('Unavailable'));
  },
}));

export default useProjectsStore;
