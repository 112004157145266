import { FileContract, JwtTokenContract, ProfileDetailsContract } from '@moonpanda/moonpanda.contracts';
import { FC, useCallback, useState } from 'react';

import { SignUpPageCreateComponent } from 'src/pages/SignUp/Create';
import { SignUpPageDetailsComponent } from 'src/pages/SignUp/Details';
import { useStateForm } from 'src/utils/stateForm';
import { RecursiveNullable } from 'src/utils/types';

import styles from './styles.module.scss';

type SignUpPageCreateFormValues = RecursiveNullable<{ email: string }>;
export type SignUpPageDetailsFormValues = RecursiveNullable<{
  firstName: string;
  lastName: string;
  password: string;
  jobTitle: string;
  companyName: string;
  avatar: FileContract[];
  baseRate: number;

  emailSignature: string | undefined;
  jwt: JwtTokenContract | null;
}>;

export type SignUpPageFormValues = SignUpPageCreateFormValues & SignUpPageDetailsFormValues;

export const SignUpPage: FC = () => {
  const formProps = useStateForm<SignUpPageFormValues>();

  const [step, setStep] = useState<'create' | 'details' | 'fillProfile'>('create');

  const onNext = useCallback(() => {
    setStep('details');

    formProps.setValue({
      firstName: null,
      lastName: null,
      password: null,
      jobTitle: null,
      companyName: null,
      avatar: null,
      emailSignature: null,
      jwt: null,
    });
  }, [formProps]);

  const onBack = useCallback(() => {
    setStep('create');
  }, []);

  return (
    <div className={styles.content}>
      {step === 'create' && (
        <SignUpPageCreateComponent
          formProps={formProps}
          onNext={onNext}
          onFillProfileData={(data: ProfileDetailsContract, jwt: JwtTokenContract) => {
            setStep('fillProfile');

            formProps.setValue({
              firstName: data.firstName,
              lastName: data.lastName,
              email: data.email,
              password: null,
              jobTitle: data.jobTitle,
              companyName: data.companyName,
              avatar: null,

              emailSignature: data.emailSignature,
              jwt,
            });
          }}
        />
      )}
      {(step === 'details' || step === 'fillProfile') && (
        <SignUpPageDetailsComponent formProps={formProps} onBack={onBack} fillProfile={step === 'fillProfile'} />
      )}
    </div>
  );
};
