import { PhaseContract, ProjectContract, TaskContract } from '@moonpanda/moonpanda.contracts';
import { GanttStatic } from '@moonpanda/dhtmlx-gantt';
import { endOfDay, startOfDay } from 'date-fns';

import { CDate } from 'src/utils/CDate';
import { defaultProjectColor, SHORT_DATE_FORMAT } from 'src/config';
import { getDOMElement, getDOMElements } from 'src/utils/DOM';
import styles from './styles.module.scss';

export type GanttItemType<T> = {
  start_date: Date;
  end_date: Date;
  name: string;
  parent: number | string; // phase parent - id, task parent - string
  itemData: T;
  isProject: boolean;
  innerType: 'phase' | 'project' | 'task';

  dueDateText?: string;
  color: string;
};

export type GanttProjectType = GanttItemType<ProjectContract> & {
  id: number;
};

export type GanttTaskType = GanttItemType<TaskContract> & {
  id: string;
};

export type GanttPhaseType = GanttItemType<PhaseContract> & {
  id: string;
};

export const globalParentId = 0;

const getProjectDates = (item: ProjectContract): Pick<GanttProjectType, 'start_date' | 'end_date'> => {
  if (item.phase.length) {
    return item.phase.reduce<{ start_date: Date; end_date: Date }>(
      (acc, phase) => {
        const startDate = startOfDay(new Date(phase.startDate));

        const endDate = endOfDay(new Date(phase.endDate));

        if (!acc.start_date || startDate < acc.start_date) {
          acc.start_date = startDate;
        }

        if (!acc.end_date || endDate > acc.end_date) {
          acc.end_date = endDate;
        }

        return acc;
      },
      {
        start_date: startOfDay(new Date(item.phase[0].startDate)),
        end_date: endOfDay(new Date(item.phase[0].endDate)),
      },
    );
  }

  return {
    start_date: new Date(),
    end_date: new Date(),
  };
};

export const prepareProjectsForGantt = (items: ProjectContract[]): GanttProjectType[] =>
  items.map((item) => ({
    ...getProjectDates(item), // viewable period depends on these dates. this can be avoided to show only a small range of dates by start
    id: item.id,
    name: item.name,
    parent: globalParentId,
    itemData: { ...item },
    isProject: true,
    innerType: 'project',
    color: item.color || '',
  }));

const getBaseLineElement = (values: { left: number; width: number; top: number; color: string; name: string }) => {
  const element = document.createElement('div');

  element.className = styles.baseline;
  element.style.left = `${values.left}px`;
  element.style.width = `${values.width}px`;
  element.style.top = `${values.top + 9}px`;
  element.style.backgroundColor = values.color || defaultProjectColor;
  element.innerText = values.name;

  return element;
};

export const serverListPropertyName = 'resource';

export const drawSingleProjectBaselines = (gantt: GanttStatic) => {
  const project = gantt.serverList(serverListPropertyName)[0] as GanttProjectType | undefined;

  const container = getDOMElement(`.${styles.projectLine} .gantt_data_area`);

  if (project && container) {
    container.textContent = '';

    project.itemData?.phase.forEach((phase) => {
      const left = gantt.posFromDate(startOfDay(new Date(phase.startDate)));

      const right = gantt.posFromDate(endOfDay(new Date(phase.endDate)));

      const width = right - left;

      const element = getBaseLineElement({
        left,
        top: 8,
        width,
        color: project.itemData.color || defaultProjectColor,
        name: phase.name,
      });

      container.appendChild(element);
    });
  }
};

// today line
export const createVerticalLines = (gantt: GanttStatic) => {
  const tasksContainers = getDOMElements('.gantt_task');

  if (tasksContainers) {
    tasksContainers.forEach((tasksContainer) => {
      let markerElement = tasksContainer.querySelector(`.${styles.marker}`) as HTMLElement | null;

      if (!markerElement) {
        markerElement = document.createElement('div');
        markerElement.className = styles.marker;
      }

      markerElement.style.left = `${gantt.posFromDate(startOfDay(new Date()))}px`;
      tasksContainer.appendChild(markerElement);
    });
  }
};

const taskIdPrefix = 'task_';

export const getTaskIdForGantt = (id: number): string => `${taskIdPrefix}${id}`;

export const getRealTaskIdFromGantt = (id: string): number => +id.split(taskIdPrefix)[1];

export const phaseIdPrefix = 'phase_';

export const getPhaseIdForGantt = (id: number): string => `${phaseIdPrefix}${id}`;

export const getRealPhaseIdFromGantt = (id: string): number => +id.split(phaseIdPrefix)[1];

const getTaskColor = (status: TaskContract['status']) => {
  switch (status) {
    case 'InProgress':
      return 'var(--taskStatusInProgress)';
    case 'Completed':
      return 'var(--taskStatusCompleted)';
    case 'InReview':
      return 'var(--taskStatusInReview)';
    case 'NotStarted':
      return 'var(--taskStatusNotStarted)';
    default:
      return 'red';
  }
};

export const prepareTaskItemForGantt = (item: TaskContract): GanttTaskType => ({
  id: getTaskIdForGantt(item.id),
  start_date: startOfDay(new Date(item.startDate)),
  end_date: endOfDay(new Date(item.dueDate)),
  name: item.name,
  parent: getPhaseIdForGantt(item.phaseId),
  itemData: { ...item },
  color: getTaskColor(item.status),
  dueDateText: CDate.format(new Date(item.dueDate), SHORT_DATE_FORMAT),
  isProject: false,
  innerType: 'task',
});

export const preparePhaseItemForGantt = ({
  item,
  projectColor,
  parentId,
}: {
  item: PhaseContract;
  projectColor?: string | null;
  parentId: number;
}): GanttPhaseType => ({
  id: getPhaseIdForGantt(item.id),
  start_date: startOfDay(new Date(item.startDate)),
  end_date: endOfDay(new Date(item.endDate)),
  name: item.name,
  parent: parentId,
  itemData: item,
  color: projectColor || defaultProjectColor,
  dueDateText: CDate.format(new Date(item.endDate), SHORT_DATE_FORMAT),
  isProject: false,
  innerType: 'phase',
});

export const prepareTasksForGantt = (items: TaskContract[]): GanttTaskType[] =>
  items.map((item) => prepareTaskItemForGantt(item));
