import React, { Suspense, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import {
  createRoutesFromElements,
  createBrowserRouter,
  useLocation,
  useNavigate,
  Route,
  RouterProvider,
} from 'react-router-dom';
import { ToastContainer, Zoom } from 'react-toastify';

import { ErrorBoundary } from 'src/components/common/ErrorBoundary';
import { AllAppModals, InAppModals } from 'src/components/common/modals';
import { AppSubscribes } from 'src/components/Subscribes';
import { UiLoader } from 'src/components/UI/loaders';
import { AppSidebar } from 'src/components/Sidebar';
import { useRequest } from 'src/hooks/useRequest';
import { ROUTE_SIGN_IN_PAGE } from 'src/routes/paths';
import { AppRoutes, UnAuthorizedRoutes, publicRoutesMap } from 'src/routes';
import useGlobalStore from 'src/store';
import { showErrorToast } from 'src/utils/errors';
import { setHttpJwtToken } from 'src/utils/http';
import { getJwtToken } from 'src/utils/localStorage';

import 'src/styles/index.scss';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const CheckUser = () => {
  const { apiCaller } = useRequest();

  const setUser = useGlobalStore((state) => state.setUser);

  const authUser = useGlobalStore((state) => state.authUser);

  const navigate = useNavigate();

  const { pathname } = useLocation();

  useEffect(() => {
    const token = getJwtToken();

    if (token) {
      setHttpJwtToken(token.jwt);

      authUser(apiCaller).catch(showErrorToast);
    } else {
      setUser({
        loading: false,
        response: null,
        error: null,
      });

      /** redirect any route to sign in except public routes */
      if (publicRoutesMap.findIndex(({ path }) => path === pathname) === -1) {
        navigate(ROUTE_SIGN_IN_PAGE);
      }
    }
  }, [apiCaller, authUser, navigate, pathname, setUser]);

  return <UiLoader fullScreen portal />;
};

const IndexComponent = () => {
  const user = useGlobalStore((state) => state.user);

  return (
    <ErrorBoundary>
      <main>
        <AppSubscribes />

        {user.loading && <CheckUser />}

        {user.response === null ? (
          <UnAuthorizedRoutes />
        ) : (
          <>
            <AppSidebar />

            <div className="content-wrapper">
              <AppRoutes />
            </div>

            <InAppModals />
          </>
        )}

        <AllAppModals />
      </main>
    </ErrorBoundary>
  );
};

const router = createBrowserRouter(
  createRoutesFromElements(<Route path="*" element={<IndexComponent />} errorElement={<>Unexpected error</>} />),
);

root.render(
  <Suspense fallback={<div />}>
    <ToastContainer
      position="bottom-left"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="colored"
      transition={Zoom}
      icon={false}
    />

    <RouterProvider router={router} />
  </Suspense>,
);
