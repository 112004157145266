import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';

type UiTableLoadingPropsType = {
  colSpan: number;
};

export const UiTableLoadingPartial: FC<UiTableLoadingPropsType> = memo(({ colSpan }: UiTableLoadingPropsType) => {
  const { t } = useTranslation('');

  return (
    <tr>
      <td colSpan={colSpan}>{t('components.table.loading')}</td>
    </tr>
  );
});
