import classNames from 'classnames';
import { FC } from 'react';

import 'src/components/UI/loaders/styles.scss';
import { createPortal } from 'react-dom';
import { DOM_ELEMENT_FOR_PORTALS } from 'src/config';

type Props = {
  fullScreen?: boolean;
  btn?: boolean;
  portal?: boolean;
  className?: string;
  centered?: boolean;
};

export const UiLoader: FC<Props> = ({ fullScreen, btn, portal, className, centered }) => {
  const element = (
    <div
      className={classNames('UiLoader', className, {
        fullScreen,
        btn,
        centered,
      })}
    >
      <div className="custom-loader" />
    </div>
  );

  return portal ? createPortal(element, DOM_ELEMENT_FOR_PORTALS) : element;
};
