/* eslint-disable max-len */
import { FC } from 'react';

type Props = {
  onClick?: () => void;
};
export const IconTrash: FC<Props> = ({ onClick }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="10"
    height="10"
    viewBox="0 0 10 10"
    fill="none"
    aria-label="Delete"
    role="button"
    onClick={onClick}
  >
    <path
      d="M1.64654 10C1.3831 10 1.16356 9.91227 0.965973 9.74658C0.779364 9.58089 0.680574 9.37621 0.680574 9.1423V1.09161H0V0.419096H2.85401V0H7.146V0.419096H10V1.09161H9.31943V9.1423C9.31943 9.37621 9.22064 9.57114 9.03403 9.74658C8.84742 9.91227 8.6169 10 8.35346 10H1.63557H1.64654ZM8.57299 1.09161H1.43798V9.1423C1.43798 9.19103 1.45994 9.23977 1.50384 9.26901C1.54775 9.30799 1.59166 9.31773 1.64654 9.31773H8.36444C8.36444 9.31773 8.46323 9.29824 8.50713 9.25925C8.55104 9.22026 8.57299 9.18127 8.57299 9.13254V1.08186V1.09161ZM3.35895 8.07992H4.11636V2.32942H3.35895V8.07992ZM5.89462 8.07992H6.65203V2.32942H5.89462V8.07992Z"
      fill="#C6C6C6"
    />
  </svg>
);
