import { NewCommentItemContract } from '@moonpanda/moonpanda.contracts';
import { FC } from 'react';

import { UiButton } from 'src/components/UI/UiButton';
import styles from 'src/pages/Dashboard/Comments/styles.module.scss';
import { useTHelper } from 'src/utils/i18n';

type Props = {
  model: NewCommentItemContract & { id: string };
  showViewTaskModal: (id: string, taskIds: number[]) => void;
};

export const DashboardPageCommentsRowComponent: FC<Props> = ({ model, showViewTaskModal }) => {
  const tHelper = useTHelper('pages.dashboard.comments');

  return (
    <div className={styles.item}>
      <div className={styles.counter} style={{ backgroundColor: model.projectColor }}>
        {model.newCommentsCount}
      </div>
      <div className={styles.projectName}>{model.projectName}</div>
      <UiButton
        onClick={() => {
          showViewTaskModal(model.id, model.taskIdsWithNewComments);
        }}
      >
        {tHelper('view')}
      </UiButton>
    </div>
  );
};
