/* eslint-disable max-len */
import { FC } from 'react';

export const IconEdit: FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="23" height="22" viewBox="0 0 23 22" fill="none">
    <path
      d="M1.89979 22C1.45979 22 1.07978 21.84 0.759785 21.52C0.439785 21.2 0.279785 20.82 0.279785 20.38V4.48999C0.279785 4.04999 0.439785 3.67001 0.759785 3.35001C1.07978 3.03001 1.45979 2.87 1.89979 2.87H12.4998L11.2198 4.14999H1.89979C1.80979 4.14999 1.72979 4.19001 1.65979 4.26001C1.58979 4.33001 1.54979 4.41 1.54979 4.5V20.39C1.54979 20.48 1.58979 20.56 1.65979 20.63C1.72979 20.7 1.80979 20.74 1.89979 20.74H17.7898C17.8798 20.74 17.9598 20.7 18.0298 20.63C18.0998 20.56 18.1398 20.48 18.1398 20.39V11.01L19.4198 9.73001V20.39C19.4198 20.83 19.2598 21.21 18.9398 21.53C18.6198 21.85 18.2398 22.01 17.7998 22.01H1.90979L1.89979 22ZM15.2598 3.03L16.1798 3.92999L8.30979 11.77V13.97H10.4898L18.3998 6.07999L19.2898 6.95999L11.0298 15.24H7.03979V11.25L15.2698 3.01999L15.2598 3.03ZM19.2898 6.97L15.2598 3.03L17.8398 0.450012C18.1398 0.150012 18.5198 0 18.9798 0C19.4298 0 19.8198 0.16999 20.1398 0.48999L21.8098 2.17001C22.1198 2.50001 22.2798 2.88999 22.2798 3.32999C22.2798 3.76999 22.1098 4.14999 21.7798 4.45999L19.2898 6.97Z"
      fill="#3F5B64"
    />
  </svg>
);
