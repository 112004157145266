import { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { apiResetPassword } from 'src/api/authentication';
import { IconAppLogo } from 'src/components/icons/AppLogo';
import { UiInput } from 'src/components/UI/inputs/Text';
import { UiToast } from 'src/components/UI/toasts';
import { UiButton } from 'src/components/UI/UiButton';
import { EMAIL_PATTERN } from 'src/config';
import { useRequest } from 'src/hooks/useRequest';
import { IconBack } from 'src/pages/SignUp/icons/Back';
import { ROUTE_SIGN_IN_PAGE } from 'src/routes/paths';
import { showErrorToast } from 'src/utils/errors';
import { useTHelper } from 'src/utils/i18n';
import { useStateForm } from 'src/utils/stateForm';

import styles from './styles.module.scss';

type FormValues = {
  email: string | null;
};

export const RecoverPasswordPage: FC = () => {
  const tHelper = useTHelper('pages.recoverPassword');

  const { t } = useTranslation();

  const formProps = useStateForm<FormValues>();

  const replaceValue = useCallback((value: string) => value.replace(/\s/g, ''), []);

  const { apiCaller } = useRequest();

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  return (
    <form
      className={styles.wrapper}
      onSubmit={formProps.onSubmit((formData) => {
        if (EMAIL_PATTERN.test(formData.email as string)) {
          setLoading(true);

          apiCaller(apiResetPassword, {
            email: formData.email as string,
          })
            .then(() => {
              UiToast.success(tHelper('linkSent'));
            })
            .catch(showErrorToast)
            .finally(() => {
              setLoading(false);
            });
        } else {
          UiToast.warning(t('common.validation.emailInvalid'));
        }
      })}
    >
      <div className={styles.head}>
        <IconBack
          onClick={() => {
            navigate(ROUTE_SIGN_IN_PAGE);
          }}
          className="cursor-pointer"
        />
        <h1>{tHelper('header')}</h1>
      </div>

      <IconAppLogo className={styles.iconLogo} />

      <div>
        <UiInput
          formProps={formProps}
          name="email"
          type="email"
          placeholder={tHelper('emailPlaceholder')}
          replaceValue={replaceValue}
          required
        />

        <UiButton type="submit" loading={loading}>
          {tHelper('submitBtn')}
        </UiButton>
      </div>
    </form>
  );
};
