import { TaskStatusEnumContract } from '@moonpanda/moonpanda.contracts';
import classNames from 'classnames';
import React, { FC, memo, useMemo } from 'react';

import { UiDropdown, UiDropdownOptionsType, UiDropdownProps } from 'src/components/UI/inputs/DropDown';
import { useTHelper } from 'src/utils/i18n';
import { useStateFormValueWatch } from 'src/utils/stateForm/useFormWatch/useStateFormValueWatch';

import styles from './styles.module.scss';

type Props = Omit<UiDropdownProps, 'options'>;

export const UiTaskStatusDropdown: FC<Props> = memo(({ ...rest }) => {
  const tHelper = useTHelper('common.taskStatuses');

  const localStatus: TaskStatusEnumContract = useStateFormValueWatch(rest.formProps.getSubscribeProps, rest.name);

  const taskStatusOptions: UiDropdownOptionsType = useMemo(
    () =>
      ['NotStarted', 'InProgress', 'Completed', 'InReview'].map((value) => ({
        value,
        label: tHelper(`${value}`),
      })),
    [tHelper],
  );

  return (
    <UiDropdown
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...rest}
      mode="small"
      options={taskStatusOptions}
      className={classNames(
        rest.className,
        localStatus === 'InProgress' && styles.InProgress,
        localStatus === 'NotStarted' && styles.NotStarted,
        localStatus === 'Completed' && styles.Completed,
        localStatus === 'InReview' && styles.InReview,
      )}
    />
  );
});
