/* eslint-disable max-len */
import { FC } from 'react';

export const IconEmptyImage: FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="45" height="41" viewBox="0 0 45 41" fill="none">
    <path
      d="M3.16992 40.9604C2.36992 40.9604 1.66992 40.6603 1.06992 40.0603C0.469916 39.4604 0.169922 38.7604 0.169922 37.9604V12.3103C0.169922 11.5403 0.469916 10.8503 1.06992 10.2303C1.66992 9.61035 2.36992 9.30035 3.16992 9.30035H10.5199L14.1699 4.95035H27.8699V7.95035H15.5699L11.9199 12.3004H3.16992V37.9503H37.1699V17.2504H40.1699V37.9503C40.1699 38.7503 39.8599 39.4504 39.2399 40.0504C38.6199 40.6504 37.9299 40.9503 37.1599 40.9503H3.15991L3.16992 40.9604ZM37.1699 12.2603V7.96036H32.8699V4.96036H37.1699V0.610352H40.1699V4.96036H44.5199V7.96036H40.1699V12.2603H37.1699ZM20.1399 33.6104C22.5599 33.6104 24.5799 32.7904 26.2199 31.1604C27.8499 29.5304 28.6699 27.5004 28.6699 25.0804C28.6699 22.6604 27.8499 20.6504 26.2199 19.0303C24.5899 17.4103 22.5599 16.6004 20.1399 16.6004C17.7199 16.6004 15.7099 17.4103 14.0899 19.0303C12.4699 20.6504 11.6599 22.6604 11.6599 25.0804C11.6599 27.5004 12.4699 29.5204 14.0899 31.1604C15.7099 32.7904 17.7199 33.6104 20.1399 33.6104ZM20.1399 30.6104C18.5599 30.6104 17.2499 30.0803 16.2099 29.0303C15.1799 27.9804 14.6599 26.6604 14.6599 25.0804C14.6599 23.5004 15.1799 22.1903 16.2099 21.1503C17.2399 20.1203 18.5499 19.6004 20.1399 19.6004C21.7299 19.6004 23.0399 20.1203 24.0899 21.1503C25.1399 22.1803 25.6699 23.4904 25.6699 25.0804C25.6699 26.6704 25.1399 27.9804 24.0899 29.0303C23.0399 30.0803 21.7199 30.6104 20.1399 30.6104Z"
      fill="#3F5B64"
    />
  </svg>
);
