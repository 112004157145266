import { CommentContract } from '@moonpanda/moonpanda.contracts';
import { FC, memo } from 'react';

import { UiAvatar } from 'src/components/common/Avatar';
import { SHORT_DATE_FORMAT } from 'src/config';
import { IconDelete } from 'src/pages/Projects/create/icons/Delete';
import { CDate } from 'src/utils/CDate';

import styles from 'src/components/common/modals/ViewTask/components/chat/styles.module.scss';
import { IconEdit } from 'src/components/common/modals/ViewTask/components/chat/icons/Edit';
import { IconDelimeter } from 'src/components/common/modals/ViewTask/components/chat/icons/Delimeter';

export type ChatMessageProps = CommentContract;

type Props = {
  comment: CommentContract;
  canEditAndRemove: boolean;
  onDelete: (data: CommentContract) => void;
  onEdit: (data: CommentContract) => void;
};

export const TaskMessage: FC<Props> = memo(({ comment, canEditAndRemove, onDelete, onEdit }) => (
  <div className={styles.message}>
    <div className={styles.logoWrapper}>
      <UiAvatar
        data={{ ...comment.author, avatar: comment.author?.avatar }}
        width={37}
        unread={comment.isNewForCurrentUser}
      />
    </div>
    <div className={styles.top}>
      <div className={styles.name} title={comment.author?.name}>
        {comment.author?.name}
      </div>
      <div className={styles.date}>{CDate.format(new Date(comment.createdAt), `${SHORT_DATE_FORMAT} hh:mm aaa`)}</div>
    </div>
    <div className={styles.comment}>
      {/* eslint-disable-next-line react/no-danger */}
      <div className={styles.text} dangerouslySetInnerHTML={{ __html: comment.content }} />
      {canEditAndRemove && (
        <div className={styles.actions}>
          <IconEdit className={styles.action} onClick={() => onEdit(comment)} />
          <IconDelimeter />
          <IconDelete className={styles.action} onClick={() => onDelete(comment)} fill="currentColor" />
        </div>
      )}
    </div>
  </div>
));
