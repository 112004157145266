import classNames from 'classnames';
import flatpickr from 'flatpickr';
import isArray from 'lodash/isArray';
import React, { memo, useCallback, useEffect, useMemo, useRef } from 'react';
import Flatpickr from 'react-flatpickr';
import { useTranslation } from 'react-i18next';
import MaskedInput from 'react-text-mask';
import isFunction from 'lodash/isFunction';

import { UiCommonInputWrapper } from 'src/components/UI/inputs/common/html';
import { SHORT_DATE_FORMAT } from 'src/config';
import { CDate } from 'src/utils/CDate';
import { useIdOrRandomString } from 'src/utils/formHelper';
import { getStringFromReactElement } from 'src/utils/reactDom';
import { SafeAnyType } from 'src/utils/safeAny';
import { StateFormInputOptionsType, StateFormReturnType } from 'src/utils/stateForm';
import { useInputBaseHook } from 'src/hooks/inputs';
import { maskedInputConvertMask } from 'src/components/UI/inputs/MaskedInput';

export const prepareSetDateToUiDatePicker = (date: Date) => CDate.format(new Date(date), SHORT_DATE_FORMAT);

export const parseDateFromUiDatePicker = (date: string) => CDate.parse(date, SHORT_DATE_FORMAT, false);

export type UiDatePickerProps = {
  formProps: StateFormReturnType;
  name: string;

  label?: string;
  required?: boolean;
  minDate?: string; // @see dateFormat
  placeholder?: string;
  autoFocus?: boolean;
  disabled?: boolean;
  className?: string;
  readonly?: boolean;
  onChange?: (value: string | null) => void;
  maxDate?: UiDatePickerProps['minDate'];
  errorLabel?: string;
  onClose?: (strDate: string | null) => void;
  classNameLabel?: string;
  wrapperClassName?: string;
  disabledDates?: {
    from: string; // @see dateFormat
    to: string; // @see dateFormat
  }[];
};

export const UiDatePicker = memo<UiDatePickerProps>(
  ({
    formProps: { register, unregister, getSubscribeProps, onChange: formOnChange, getValue },
    required,
    name,
    minDate,
    disabled,
    className,
    placeholder = 'mm/dd/yyyy',
    autoFocus,
    readonly,
    onChange: onChangeDate,
    maxDate,
    errorLabel,
    onClose,
    label,
    classNameLabel,
    wrapperClassName,
    disabledDates,
  }) => {
    const { t } = useTranslation();

    const id = useIdOrRandomString();

    const mountRef = useRef(false);

    useEffect(() => {
      mountRef.current = true;

      return () => {
        mountRef.current = false;
      };
    });

    const inputOptions: StateFormInputOptionsType = useMemo(
      () => ({ required, errorLabel: errorLabel || getStringFromReactElement(label) || placeholder || undefined }),
      [errorLabel, label, placeholder, required],
    );

    const [value, errors] = useInputBaseHook({
      getSubscribeProps,
      name,
      inputOptions,
      register,
      unregister,
      type: 'datepicker',
    });

    const hasErrors = !!errors?.length;

    /** prepare the value */
    const getPreparedValue = useCallback((val: Date | string | null): string | undefined => {
      if (val) {
        if (isArray(val)) {
          // can be set as [] in setCustomFields()
          return '';
        }

        return val instanceof Date ? CDate.format(val, SHORT_DATE_FORMAT) : val;
      }

      // must return an empty string so that the value is changed correctly via setValue
      return '';
    }, []);

    const inputRef = useRef<HTMLInputElement>();

    /** memoize the options */
    const options = useMemo((): flatpickr.Options.Options => {
      const getMinDate = () => minDate || undefined;

      const getMaxDate = () => maxDate || undefined;

      return {
        monthSelectorType: 'dropdown',
        dateFormat: 'm/d/Y', // 'm/d/Y h:i K' with time
        minDate: getMinDate(),
        maxDate: getMaxDate(),
        shorthandCurrentMonth: true,
        showMonths: 1,
        disable: disabledDates || [],
        onReady: () => {
          // `!mountRef.current` for ignoring opening later. it happens just in the beginning
          if (!mountRef.current && autoFocus && !disabled) {
            // open it just a little bit later
            setTimeout(() => {
              inputRef.current?.click();
            }, 50);
          }
        },
      };
      // don't depend on value. take it once at start
      // eslint-disable-next-line
    }, [minDate, maxDate, getPreparedValue, autoFocus, disabled, t, formOnChange, name]);

    const onChange = useCallback(
      (_: unknown, strDate: string, instance: SafeAnyType) => {
        const selectedDate = strDate.length ? strDate : null;

        const isChangedDate = selectedDate !== getValue(name);

        if (isChangedDate && !disabled && !readonly) {
          formOnChange(name, selectedDate);

          if (isFunction(onChangeDate)) {
            onChangeDate(selectedDate);
          }
        }

        /** close on change especially when pass a date via input */
        instance.close();
      },
      [getValue, name, disabled, readonly, formOnChange, onChangeDate],
    );

    const handleClose = useCallback(
      (_: unknown, strDate: string) => {
        if (isFunction(onClose)) {
          onClose(strDate.length ? strDate : null);
        }
      },
      [onClose],
    );

    /** m/d/y */
    const arrMask = useMemo(() => maskedInputConvertMask('19/39/9999'), []);

    const ref = useRef<Flatpickr>(null);

    return (
      <UiCommonInputWrapper className={wrapperClassName}>
        {label && (
          <label htmlFor={id} className={classNameLabel}>
            {label}
          </label>
        )}
        <Flatpickr
          key={value}
          ref={ref}
          options={options as SafeAnyType} // types?
          onChange={onChange}
          onClose={handleClose}
          render={({ defaultValue }, refPicker) => (
            <MaskedInput
              defaultValue={defaultValue}
              mask={arrMask}
              disabled={disabled}
              placeholder={placeholder}
              value={getPreparedValue(value)}
              readOnly={readonly}
              render={(refInput, props) => (
                <input
                  ref={(element) => {
                    if (element) {
                      refPicker(element);
                      refInput(element);
                      inputRef.current = element;
                    }
                  }}
                  /* eslint-disable-next-line react/jsx-props-no-spreading */
                  {...props}
                  id={id}
                  className={classNames('UiInput datepicker', className, {
                    required: hasErrors && required,
                    error: hasErrors,
                    disabled,
                  })}
                />
              )}
            />
          )}
        />
      </UiCommonInputWrapper>
    );
  },
);
