import { AxiosError } from 'axios';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
import { FC } from 'react';

import { UiToast } from 'src/components/UI/toasts';
import { ApiError } from 'src/hooks/useRequest';
import { SafeAnyType } from 'src/utils/safeAny';
import { StateFormErrors } from 'src/utils/stateForm';

type PreparedFormErrorsType = { key: string; message: string }[];

export type ErrorsType =
  | PreparedFormErrorsType
  | Record<SafeAnyType, StateFormErrors>
  | ApiError
  | StateFormErrors
  | Error
  | string;

export const getErrorsFromObject = (errors: ErrorsType): { key: string; message: string }[] => {
  if (errors instanceof AxiosError) {
    return [
      {
        key: errors.code || errors.message,
        message: errors.message,
      },
    ];
  }

  if (errors instanceof ApiError) {
    // errors from backend
    if (errors.errors) {
      return errors.errors.map((error) => ({
        key: error.propertyName,
        message: error.errorMessage,
      }));
    }

    // simple http errors
    return [
      {
        key: errors.name,
        message: errors.message,
      },
    ];
  }

  if (typeof errors === 'string') {
    return [
      {
        key: errors,
        message: errors,
      },
    ];
  }

  const arr = Object.entries(errors).reduce<{ key: string; message: string }[]>((acc, [key, value]) => {
    // StateFormErrors
    if (Array.isArray(value)) {
      return [...acc, ...value.map(({ message }) => ({ key, message }))];
    }

    // response errors
    if (value.message) {
      acc.push({ message: value.message, key });
    } else {
      acc.push(...getErrorsFromObject(value));
    }

    return acc;
  }, []);

  if (arr.length) {
    return arr;
  }

  return [
    {
      key: (errors as unknown as Error).name,
      message: (errors as unknown as Error).message,
    },
  ];
};

type Props = {
  errorMessages: string[];
};

const ListErrors: FC<Props> = ({ errorMessages }) => (
  <ul>
    {uniq(errorMessages).map((message) => (
      <li key={message}>{message}</li>
    ))}
  </ul>
);

export const showWarningToast = (errors: ErrorsType) => {
  const preparedErrors = Array.isArray(errors) ? errors : getErrorsFromObject(errors);

  if (!preparedErrors.length) {
    return;
  }

  UiToast.warning(<ListErrors errorMessages={uniqBy(preparedErrors, 'message').map(({ message }) => message)} />);
};

export const showErrorToast = (errors: ErrorsType) => {
  const preparedErrors = Array.isArray(errors) ? errors : getErrorsFromObject(errors);

  if (!preparedErrors.length) {
    return;
  }

  UiToast.error(<ListErrors errorMessages={uniqBy(preparedErrors, 'message').map(({ message }) => message)} />);
};
