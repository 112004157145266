/* eslint-disable camelcase */
import { Gantt, GanttStatic, Task } from '@moonpanda/dhtmlx-gantt';
import {
  ProjectContract,
  QueryRequestContract,
  SortingOrderEnumContract,
  TaskContract,
} from '@moonpanda/moonpanda.contracts';
import '@moonpanda/dhtmlx-gantt/codebase/dhtmlxgantt.css';
import classNames from 'classnames';
import { add, differenceInCalendarDays } from 'date-fns';
import debounce from 'lodash/debounce';
import throttle from 'lodash/throttle';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import useGanntStore, { ganntStoreDefaultProjectsFilter } from 'src/components/UI/gantt/store';
import { UiLoader } from 'src/components/UI/loaders';
import {
  getRouteProjectsCreatePhasesPage,
  getRouteProjectViewPage,
  ROUTE_PROJECTS_TIMELINE_PAGE,
} from 'src/routes/paths';
import useModalsStore from 'src/store/modals';
import { getDOMElement } from 'src/utils/DOM';
import { SafeAnyType } from 'src/utils/safeAny';
import {
  GANNT_DAYS_TO_ADD_ON_SCROLL,
  GANTT_CREATE_TASK_ID,
  GANTT_DUE_DATE_ID,
  GANTT_VERTICAL_SCROLL_OFFSET,
} from './config';
import { getGanttColumns } from './config/columns';
import { getGanttLayout } from './config/layout';
import {
  createVerticalLines,
  drawSingleProjectBaselines,
  GanttItemType,
  GanttPhaseType,
  GanttProjectType,
  GanttTaskType,
  getPhaseIdForGantt,
  getRealPhaseIdFromGantt,
  getRealTaskIdFromGantt,
  getTaskIdForGantt,
  globalParentId,
  phaseIdPrefix,
  preparePhaseItemForGantt,
  prepareTaskItemForGantt,
  prepareTasksForGantt,
} from './helpers';
import styles from './styles.module.scss';

type Props = {
  projectId?: number;
};

export const UiGantt: FC<Props> = ({ projectId }) => {
  const isSingleProject = !!projectId;

  const navigate = useNavigate();

  const containerRef = useRef<HTMLDivElement>(null);

  const ganttRef = useRef<GanttStatic>(Gantt.getGanttInstance());

  // cache data before dragging
  const currentDragTaskRef = useRef<{ startDate?: number; duration?: number }>({});

  const hasInitialSingleProjectLoadRef = useRef(false);

  const showToggleModal = useModalsStore((state) => state.toggleModals);

  const [searchParams] = useSearchParams();

  const phaseIdRef = useRef<null | number>(null);

  const prevPhaseIdRef = useRef<null | number>(null);

  const {
    projects,
    getProjects,
    projectsFilter,
    getProjectPhases,
    updatePhase,
    updateTask,
    getProject,
    getTasksByPhase,
  } = useGanntStore((state) => state);

  /* load data */
  const tasksSortOrder = useRef<SortingOrderEnumContract>('Asc');

  const projectsRef = useRef<GanttProjectType[]>([]);

  useEffect(() => {
    // sync projectsRef
    projectsRef.current = projects.data;
  }, [projects.data]);

  const tasksRef = useRef<(GanttTaskType | GanttPhaseType)[]>([]);

  const openedProjectIdsRef = useRef<number[]>([]);

  const openedPhaseIdsRef = useRef<string[]>([]);

  const renderGannt = useCallback((fn: () => void) => {
    ganttRef.current.batchUpdate(fn);
  }, []);

  /* for tasks bottom padding */
  const EMPTY_TASK_ID = 'emptyTask';

  const addBottomPadding = useCallback(() => {
    if (ganttRef.current.isTaskExists(EMPTY_TASK_ID)) {
      ganttRef.current.deleteTask(EMPTY_TASK_ID);
    }

    ganttRef.current.addTask({
      id: EMPTY_TASK_ID,
      isEmptyProject: true,
      unscheduled: true,
      start_date: new Date(),
      end_date: new Date(),
      duration: 0,
    });
  }, []);

  const onUnloadPhase = useCallback(
    (phaseId: string, withItself = false) => {
      renderGannt(() => {
        ganttRef.current.getChildren(phaseId).forEach((itemId) => {
          ganttRef.current.deleteTask(itemId);
        });

        if (withItself) {
          ganttRef.current.deleteTask(phaseId);
        }
      });

      tasksRef.current = tasksRef.current.filter((item) => item.parent !== phaseId);
    },
    [renderGannt],
  );

  const renderAll = useCallback(() => {
    /**
     * prepare structure
     *
     * phases stay at the same position but tasks in them are both filtered and sorted
     * */
    const phases: Record<
      number,
      GanttPhaseType & {
        children: GanttTaskType[];
      }
    > = {};

    const phaseId = phaseIdRef.current;

    const fromPhaseToAll = phaseId === null && prevPhaseIdRef.current !== null;

    if (fromPhaseToAll) {
      prevPhaseIdRef.current = null;
    }

    tasksRef.current.forEach((item) => {
      if (item.innerType === 'phase') {
        const phaseItemId = (item as GanttPhaseType).itemData.id;

        if (phaseId === null || +phaseId === phaseItemId) {
          phases[phaseItemId] = { ...(item as GanttPhaseType), children: [] };
        }
      } else if (item.innerType !== 'project') {
        phases[(item as GanttTaskType).itemData.phaseId]?.children.push(item as GanttTaskType);
      }
    });

    /** sort by end date */
    Object.values(phases).forEach((phase) => {
      phase.children.sort((a, b) => {
        const left = a.end_date.getTime();

        const right = b.end_date.getTime();

        return tasksSortOrder.current === 'Asc' ? left - right : right - left;
      });
    });
    /** end prepare structure */

    let needToWaitScroll = false;

    /** !important! */
    if (isSingleProject) {
      needToWaitScroll = (getDOMElement('.gantt_ver_scroll')?.scrollHeight || 0) > 0;

      if (needToWaitScroll) {
        getDOMElement('.gantt_ver_scroll')?.scroll({
          top: 0,
        });
      }
    }

    /** render sorted phases */
    const renderer = () => {
      renderGannt(() => {
        /** delete unnecessary */
        if (isSingleProject) {
          if (fromPhaseToAll) {
            // when changing from the phase to show all, close and delete all phases
            ganttRef.current.clearAll();

            ganttRef.current.getChildren(globalParentId).forEach((itemId) => {
              if (itemId.startsWith(phaseIdPrefix)) {
                onUnloadPhase(itemId, true);
              }
            });
          } else {
            ganttRef.current.getChildren(globalParentId).forEach((itemId) => {
              if (itemId.startsWith(phaseIdPrefix)) {
                if (phaseId && itemId !== getPhaseIdForGantt(phaseId)) {
                  onUnloadPhase(itemId, true);
                } else if (!openedPhaseIdsRef.current.includes(itemId)) {
                  onUnloadPhase(itemId);
                }
              }
            });
          }
        } else {
          openedProjectIdsRef.current.forEach((id) => {
            ganttRef.current.getChildren(id).forEach((itemId) => {
              if (!itemId.startsWith(phaseIdPrefix)) {
                ganttRef.current.deleteTask(itemId);
              }
            });
          });
        }
        /** end delete unnecessary */

        /** insert new data */
        Object.values(phases).forEach((phase) => {
          ganttRef.current.addTask(phase, phase.parent);

          phase.children.forEach((task) => {
            ganttRef.current.addTask(task, phase.id);
          });
        });

        if (isSingleProject) {
          addBottomPadding();
        }
      });
    };

    /**
     * (Firefox issue)
     * timer is to wait until gannt is scrolled to top (if scroll is available)
     */
    if (needToWaitScroll) {
      setTimeout(renderer, 1);
    } else {
      renderer();
    }
  }, [addBottomPadding, isSingleProject, onUnloadPhase, renderGannt]);

  /** API */

  const onUnloadProject = useCallback(
    (projectId: number) => {
      renderGannt(() => {
        ganttRef.current.getChildren(projectId).forEach((itemId) => {
          ganttRef.current.deleteTask(itemId);
        });
      });

      tasksRef.current = tasksRef.current.filter((item) => item.parent !== projectId);

      openedProjectIdsRef.current = openedProjectIdsRef.current.filter((id) => id !== projectId);
    },
    [renderGannt],
  );

  const onDeleteTask = useCallback(
    (task: TaskContract, render = true) => {
      const taskIdInGannt = getTaskIdForGantt(task.id);

      if (render) {
        renderGannt(() => {
          ganttRef.current.deleteTask(taskIdInGannt);
        });
      } else {
        ganttRef.current.deleteTask(taskIdInGannt);
      }

      tasksRef.current = tasksRef.current.filter(({ id }) => id !== taskIdInGannt);
    },
    [renderGannt],
  );

  /** expand gannt view to fit all items properly */
  const calculateGanttDates = (items: { start_date: Date; end_date: Date }[]) => {
    const project = projectsRef.current[0];

    const [startDate, endDate] = items.reduce(
      (acc, item) => {
        if (item.start_date < acc[0]) {
          acc[0] = item.start_date;
        }
        if (item.end_date > acc[1]) {
          acc[1] = item.end_date;
        }

        return acc;
      },
      [ganttRef.current.config.start_date || project.start_date, ganttRef.current.config.end_date || project.end_date],
    );

    const diffStartDate = differenceInCalendarDays(new Date(), startDate);

    const diffEndDate = differenceInCalendarDays(new Date(), endDate);

    // ganttRef.current.config.start_date = add(startDate, { days: diffStartDate < 0 ? diffStartDate : -1 });
    ganttRef.current.config.start_date = add(startDate, {
      days: diffStartDate < GANNT_DAYS_TO_ADD_ON_SCROLL ? -GANNT_DAYS_TO_ADD_ON_SCROLL : -diffStartDate,
    });
    ganttRef.current.config.end_date = add(endDate, {
      days:
        diffEndDate > GANNT_DAYS_TO_ADD_ON_SCROLL
          ? diffEndDate + GANNT_DAYS_TO_ADD_ON_SCROLL
          : GANNT_DAYS_TO_ADD_ON_SCROLL,
    });
  };

  /**
   * important: tasks have to be of the same phase
   */
  const onBulkCreateTask = useCallback(
    (tasks: TaskContract[], render = true) => {
      const preparedTasks = prepareTasksForGantt(tasks);

      calculateGanttDates(preparedTasks);

      for (let i = 0; i < tasksRef.current.length; i += 1) {
        const item = tasksRef.current[i];

        if (item.innerType === 'phase' && getRealPhaseIdFromGantt(item.id) === tasks[0].phaseId) {
          tasksRef.current.splice(i + 1, 0, ...preparedTasks);
          break;
        }
      }

      if (render) {
        renderAll();
      }
    },
    [renderAll],
  );

  const onCreateTask = useCallback(
    (task: TaskContract, render = true) => {
      onBulkCreateTask([task], render);
    },
    [onBulkCreateTask],
  );

  const onMoveTaskToAnotherPhase = useCallback(
    (task: TaskContract, render = true) => {
      onDeleteTask(task, false);
      onCreateTask(task, false);

      if (render) {
        renderAll();
      }
    },
    [onCreateTask, onDeleteTask, renderAll],
  );
  /** end API */

  const phaseIdFromSearch = searchParams.get('phaseId');

  useEffect(() => {
    prevPhaseIdRef.current = phaseIdRef.current;
    phaseIdRef.current = phaseIdFromSearch ? +phaseIdFromSearch : null;

    if (phaseIdRef.current === null) {
      openedPhaseIdsRef.current = [];
    } else {
      openedPhaseIdsRef.current = [getPhaseIdForGantt(phaseIdRef.current)];
    }

    renderAll();
  }, [renderAll, phaseIdFromSearch]);

  const projectsQueryRef = useRef<QueryRequestContract & { loading: boolean; hasMore: boolean }>(
    ganntStoreDefaultProjectsFilter,
  );

  useEffect(() => {
    projectsQueryRef.current = projectsFilter;
  }, [projectsFilter]);

  const toggleTasksLoader = (entityId: number | string, show: boolean) => {
    try {
      const id = `${entityId}_loader`;

      if (show) {
        ganttRef.current.addTask(
          {
            id,
            isLoader: true,
            unscheduled: true,
            start_date: new Date(),
            duration: 0,
          },
          entityId,
        );
      } else {
        ganttRef.current.deleteTask(id);
      }
    } catch (e) {
      // this can be caused by unexpected reasons
    }
  };

  const [loading, setLoading] = useState(true);

  const loadingRef = useRef(true);

  const scrollToToday = useCallback(
    (anyway?: true) => {
      if (loadingRef.current || anyway) {
        renderGannt(() => {
          // this is an attempt to show today in a very center of the gantt's view
          ganttRef.current.scrollTo(
            ganttRef.current.posFromDate(new Date()) -
              (getDOMElement('.gantt_layout_outer_scroll_horizontal')?.getBoundingClientRect().width || 1) / 2,
          );

          // ganttRef.current.showDate(new Date()); // this works perfectly but not centered
        });

        setTimeout(() => {
          setLoading(false);
          loadingRef.current = false;
        }, 100);

        // finish here
      }
    },
    [renderGannt],
  );

  const loadProjectPhases = useCallback(
    (projectId: number) => {
      if (!isSingleProject) {
        toggleTasksLoader(projectId, true);
      }

      getProjectPhases(projectId)
        .then((phases) => {
          const openedProjectInfo = projectsRef.current.find(({ id }) => id === projectId);

          const parentId = isSingleProject ? globalParentId : projectId;

          const preparedPhases = phases.map((phase) => {
            const preparedPhase = preparePhaseItemForGantt({
              item: phase,
              parentId,
              projectColor: openedProjectInfo?.itemData.color,
            });

            tasksRef.current.push(preparedPhase);

            return preparedPhase;
          });

          calculateGanttDates(preparedPhases);

          renderAll();
        })
        .finally(() => {
          if (!isSingleProject) {
            toggleTasksLoader(projectId, false);
          }
          scrollToToday();
        });
    },
    [getProjectPhases, isSingleProject, renderAll, scrollToToday],
  );

  const toggleProjectsLoader = (show: boolean) => {
    const id = 'projects_loader';

    try {
      if (show) {
        ganttRef.current.addTask(
          {
            id,
            isLoader: true,
            unscheduled: true,
            start_date: '',
            end_date: '',
            duration: 0,
          },
          globalParentId,
        );
      } else {
        ganttRef.current.deleteTask(id);
      }
    } catch (e) {
      // this can be caused by unexpected reasons
    }
  };

  const togglePhaseTree = (opened: boolean, phaseId: string, projectId: number) => {
    if (opened) {
      ganttRef.current.close(phaseId);

      onUnloadPhase(phaseId);

      openedPhaseIdsRef.current = openedPhaseIdsRef.current.filter((v) => v !== phaseId);
    } else {
      openedPhaseIdsRef.current.push(phaseId);

      ganttRef.current.open(phaseId);

      toggleTasksLoader(phaseId, true);

      /** load tasks by phase and insert them in the phase (phase as a parent) */
      getTasksByPhase(projectId, getRealPhaseIdFromGantt(phaseId))
        .then((tasks) => {
          if (tasks.length) {
            onBulkCreateTask(tasks, true);
          }
        })
        .finally(() => {
          toggleTasksLoader(phaseId, false);
        });
    }
  };

  const toggleProjectTree = (opened: boolean, projectId: number) => {
    if (opened) {
      ganttRef.current.close(projectId);

      onUnloadProject(projectId);
    } else {
      ganttRef.current.open(projectId);

      openedProjectIdsRef.current.push(projectId);

      loadProjectPhases(projectId);
    }
  };

  // gets project on start and when scrolling to bottom
  const loadProjects = (page: number) => {
    let displayedLoader = false;

    if (projectsRef.current.length) {
      displayedLoader = true;
      toggleProjectsLoader(true);
    }

    getProjects(page).then((preparedProjects) => {
      renderGannt(() => {
        // add projects as phases
        preparedProjects.forEach((item) => {
          ganttRef.current.addTask(item, globalParentId);
        });

        calculateGanttDates(preparedProjects);

        addBottomPadding();
      });

      if (displayedLoader) {
        toggleProjectsLoader(false);
      }

      scrollToToday();
    });
  };

  const loadSingleProject = () => {
    if (projectId) {
      getProject(projectId).then(() => {
        openedProjectIdsRef.current.push(projectId);

        loadProjectPhases(projectId);

        hasInitialSingleProjectLoadRef.current = true;
      });
    }
  };

  const handleClickDueDate = () => {
    if (!(openedProjectIdsRef.current.length && projectsRef.current.length)) {
      return;
    }

    tasksSortOrder.current = tasksSortOrder.current === 'Asc' ? 'Desc' : 'Asc';

    renderAll();

    scrollToToday(true);
  };
  /* end load data */

  /* modals */
  const showCreateTaskModal = (projectId: number, phaseId?: number) => {
    showToggleModal('createTask', true, {
      projectId,
      phaseId,
      onCreate: onCreateTask,
    });
  };

  const showEditTaskModal = (id: string) => {
    const task = ganttRef.current.getTask(id);

    showToggleModal('viewTask', true, {
      onChange: (task, taskBefore) => {
        const phaseId = phaseIdRef.current;

        if (task.phaseId !== taskBefore.phaseId) {
          // phase changed
          onMoveTaskToAnotherPhase(task);
        } else if (phaseId !== null && task.phaseId !== +phaseId) {
          // change phase when a specific phase is selected (remove from view)
          onDeleteTask(task);
        } else if (projectId === undefined || task.projectId === projectId) {
          ganttRef.current.updateTask(getTaskIdForGantt(task.id), prepareTaskItemForGantt(task));
          renderAll();
        }
        // move task to another project is not available
      },
      onDeleteTask,
      taskId: getRealTaskIdFromGantt(task.id as string),
    });
  };

  const showCreateProjectModal = () => {
    showToggleModal('createProject', true, {
      onCreateProject: (response: ProjectContract) => {
        navigate(getRouteProjectsCreatePhasesPage(response.id), {
          state: {
            returnTo: ROUTE_PROJECTS_TIMELINE_PAGE,
          },
        });
      },
    });
  };
  /* end modals */

  // const debounceScroll = useMemo(
  //   () =>
  //     debounce((dateToScroll: Date) => {
  //       try {
  //         ganttRef.current.render();
  //
  //         if (!loadingRef.current) {
  //           ganttRef.current.scrollTo(
  //             ganttRef.current.posFromDate(dateToScroll) -
  //               (getDOMElement('.gantt_layout_outer_scroll_horizontal')?.getBoundingClientRect().width || 1) / 2,
  //           );
  //         }
  //       } catch (e) {
  //         /* empty */
  //       }
  //     }, 300),
  //   [],
  // );

  const lastScrollPositions = useRef({
    left: 0,
    top: 0,
  });

  type ZoomAvailable = 'day' | 'week' | 'month';
  const zoomAPIRef = useRef<(level: ZoomAvailable) => void>();

  const [currentZoom, setCurrentZoom] = useState<ZoomAvailable>('day');

  const isScrollBlockedRef = useRef(false);

  useEffect(() => {
    // start
    setLoading(true);
    loadingRef.current = true;

    if (isSingleProject) {
      loadSingleProject();
    } else {
      loadProjects(0);
    }

    const gantt = ganttRef.current;

    let horizontalScroll: HTMLDivElement | undefined | null;

    let verticalScroll: HTMLDivElement | undefined | null;

    const handleScroll = throttle(() => {
      const projectsQuery = projectsQueryRef.current;

      const scrollState = gantt.getScrollState();

      const verticalScrollOverlap =
        scrollState.y + scrollState.inner_height + GANTT_VERTICAL_SCROLL_OFFSET >= scrollState.height;

      if (!isSingleProject && projectsQuery.hasMore && !projectsQuery.loading && verticalScrollOverlap) {
        // load more project on scroll
        loadProjects(projectsQuery.page + 1);
      }
    }, 500);

    const debounceClick = debounce((id: number, clickType: 'double' | 'single') => {
      const task = gantt.getTask(id);

      if (clickType === 'single' && !task.isProject) {
        if ((task as GanttItemType<SafeAnyType> & Task).innerType === 'task') {
          showEditTaskModal(task.id as string);
        }
      } else if (clickType === 'double' && task.start_date && task.end_date) {
        const sizes = gantt.getTaskPosition(task, add(task.start_date, { days: -1 }), add(task.end_date, { days: 1 }));

        gantt.scrollTo(sizes.left);
      }
    }, 200);

    if (containerRef.current) {
      gantt.config.date_format = '%m/%d/%Y';
      gantt.config.show_progress = false;
      gantt.config.bar_height = 24;
      gantt.config.scale_height = 50;
      gantt.config.row_height = 42;
      gantt.config.show_links = false;
      gantt.config.drag_resize = true;
      gantt.config.resize_rows = false;
      gantt.config.select_task = false;
      gantt.config.open_tree_initially = isSingleProject;
      gantt.config.layout = getGanttLayout(gantt, isSingleProject);
      gantt.config.columns = getGanttColumns(isSingleProject);

      gantt.templates.grid_header_class = () => styles.gridHeaderCell;
      gantt.templates.grid_row_class = (_start, _end, task) =>
        classNames(styles.gridRow, {
          [styles.parent]: task.isProject && !isSingleProject,
          [styles.isEmptyProject]: task.isEmptyProject,
        });
      gantt.templates.scale_row_class = () => styles.scaleRow;
      gantt.templates.task_row_class = () => styles.taskRow;
      gantt.templates.task_class = (_start, _end, task) => (task.isProject ? styles.projectTask : styles.task);
      gantt.templates.task_text = () => '';

      /* custom show create/edit task modal and create a project modal */
      gantt.attachEvent('onBeforeLightbox', () => false);

      gantt.attachEvent('onGanttScroll', (left, top) => {
        if (!isScrollBlockedRef.current) {
          const leftDate = ganttRef.current.dateFromPos(left);

          const rightDate = ganttRef.current.dateFromPos(left + ganttRef.current.$task.offsetWidth);

          ganttRef.current.config.start_date =
            ganttRef.current.config.start_date || ganttRef.current.getState().min_date;
          ganttRef.current.config.end_date = ganttRef.current.config.end_date || ganttRef.current.getState().max_date;

          const minAllowedDate = ganttRef.current.date.add(ganttRef.current.config.start_date as Date, 2, 'day');

          const maxAllowedDate = ganttRef.current.date.add(ganttRef.current.config.end_date as Date, -2, 'day');

          let repaint = false;

          // let pos: 'left' | 'right' = 'left';

          if (leftDate && +leftDate <= +minAllowedDate) {
            ganttRef.current.config.start_date = ganttRef.current.date.add(
              ganttRef.current.config.start_date as Date,
              -GANNT_DAYS_TO_ADD_ON_SCROLL,
              'day',
            );
            repaint = true;
            // pos = 'left';
          } else if (!rightDate || +rightDate >= +maxAllowedDate) {
            ganttRef.current.config.end_date = ganttRef.current.date.add(
              ganttRef.current.config.end_date as Date,
              GANNT_DAYS_TO_ADD_ON_SCROLL,
              'day',
            );
            repaint = true;
            // pos = 'right';
          }

          if (repaint) {
            isScrollBlockedRef.current = true;

            setTimeout(() => {
              isScrollBlockedRef.current = false;
            }, 100);

            ganttRef.current.render();

            // ganttRef.current.showDate(pos === 'left' ? minAllowedDate : maxAllowedDate);
          }
        }

        lastScrollPositions.current = {
          left,
          top,
        };
      });

      gantt.attachEvent('onBeforeTaskDrag', (id, mode) => {
        if (['move', 'resize'].includes(mode)) {
          const task = gantt.getTask(id);

          currentDragTaskRef.current = {
            startDate: task.start_date?.getTime(),
            duration: task.duration,
          };
        }

        return true;
      });

      gantt.attachEvent('onAfterTaskDrag', (id, mode) => {
        if (['move', 'resize'].includes(mode)) {
          const task = gantt.getTask(id);

          const { startDate, duration } = currentDragTaskRef.current;

          currentDragTaskRef.current = {};

          if (
            task &&
            task.start_date &&
            task.duration &&
            (startDate !== task.start_date?.getTime() || duration !== task.duration)
          ) {
            if (task.innerType === 'task') {
              updateTask({
                dueDate: add(task.start_date, { days: task.duration - 1 }),
                startDate: task.start_date,
                taskId: getRealTaskIdFromGantt(task.id as string),
              }).then(({ data }) => {
                gantt.updateTask(getTaskIdForGantt(data.id), prepareTaskItemForGantt(data));
              });
            } else if (task.innerType === 'phase') {
              updatePhase({
                endDate: add(task.start_date, { days: task.duration - 1 }),
                startDate: task.start_date,
                projectId: projectId || (task.parent as number),
                phaseId: getRealPhaseIdFromGantt(task.id as string),
              }).then(({ data }) => {
                gantt.updateTask(
                  getPhaseIdForGantt(data.id),
                  preparePhaseItemForGantt({
                    item: data,
                    parentId: task.parent as number,
                    projectColor: task.color,
                  }),
                );
              });
            }
          }
        }
      });

      gantt.attachEvent('onGanttRender', () => {
        if (isSingleProject) {
          drawSingleProjectBaselines(gantt);
        }

        createVerticalLines(gantt);

        const dueDateElement = getDOMElement(`#${GANTT_DUE_DATE_ID}`);

        if (dueDateElement) {
          const isDesc = tasksSortOrder.current === 'Desc';

          dueDateElement.classList.remove(isDesc ? styles.asc : styles.desc);
          dueDateElement.classList.add(isDesc ? styles.desc : styles.asc);
        }

        /* infinite scroll */
        if (horizontalScroll) {
          horizontalScroll.removeEventListener('scroll', handleScroll);
        }

        horizontalScroll = getDOMElement('.gantt_hor_scroll');
        if (horizontalScroll) {
          horizontalScroll.addEventListener('scroll', handleScroll);
        }

        if (verticalScroll) {
          verticalScroll.removeEventListener('scroll', handleScroll);
        }

        verticalScroll = getDOMElement('.gantt_ver_scroll');
        if (verticalScroll) {
          verticalScroll.addEventListener('scroll', handleScroll);
        }
        /* end infinite scroll */
      });

      gantt.attachEvent('onEmptyClick', (event) => {
        if (event?.target?.id === GANTT_CREATE_TASK_ID && isSingleProject && projectsRef.current[0]) {
          showCreateTaskModal(+projectsRef.current[0].id);
        }
      });

      gantt.attachEvent('onTaskDblClick', (id) => {
        if (id !== EMPTY_TASK_ID) {
          debounceClick(id, 'double');
        }

        return false;
      });

      gantt.attachEvent('onTaskClick', (id, event) => {
        const getProjectIdByPhase = (phaseId: string): number | null => {
          const foundPhase = tasksRef.current.find((phase) => phase.id === phaseId);

          return (foundPhase?.parent as number) || projectId || null;
        };

        if (event.target.classList.contains(styles.tree)) {
          const isOpened = event.target.classList.contains(styles.opened);

          if (id.startsWith(phaseIdPrefix)) {
            const phaseProjectId = getProjectIdByPhase(id);

            if (phaseProjectId) {
              togglePhaseTree(isOpened, id, phaseProjectId);
            }
          } else {
            toggleProjectTree(isOpened, +id);
          }

          return false;
        }

        if (event.target.classList.contains(styles.createPlusButton)) {
          if (id.startsWith(phaseIdPrefix)) {
            const phaseProjectId = getProjectIdByPhase(id);

            if (phaseProjectId) {
              showCreateTaskModal(phaseProjectId, getRealPhaseIdFromGantt(id));
            }
          } else {
            showCreateTaskModal(+id);
          }

          return false;
        }

        if (event.target.classList.contains(styles.gridRowProjectName)) {
          navigate(getRouteProjectViewPage(id));

          return false;
        }

        if (id !== EMPTY_TASK_ID) {
          debounceClick(id, 'single');
        }

        return false;
      });

      gantt.attachEvent('onGridHeaderClick', (_name, event) => {
        if (event.target.id === GANTT_DUE_DATE_ID) {
          handleClickDueDate();

          return false;
        }

        if (event.target.classList.contains(styles.createPlusButton)) {
          showCreateProjectModal();

          return false;
        }

        return true;
      });

      gantt.config.min_column_width = 120;

      /** zoom */
      const month = [{ unit: 'month', format: '%M %Y', step: 1 }];

      const week = [
        {
          unit: 'week',
          step: 1,
          format(date: Date) {
            const dateToStr = gantt.date.date_to_str('%d %M');

            const endDate = gantt.date.add(date, -6, 'day');

            const weekNum = gantt.date.date_to_str('%W')(date);

            return `<div class="${styles.scaleCellWeek}"><span>Week #${weekNum},</span><span>${dateToStr(
              date,
            )} - ${dateToStr(endDate)}</span></div>`;
          },
        },
      ];

      const day = [{ unit: 'day', format: '%j %M', step: 1 }];

      const zoomConfig: SafeAnyType = {
        levels: [month, week, day],
        // useKey: 'ctrlKey',
        // trigger: 'wheel',
        activeLevelIndex: 2,
        element() {
          return gantt.$root.querySelector('.gantt_layout_root');
        },
      };

      zoomAPIRef.current = (level: ZoomAvailable) => {
        // eslint-disable-next-line default-case
        switch (level) {
          case 'day':
            gantt.config.scales = day;
            break;
          case 'week':
            gantt.config.scales = week;
            break;
          case 'month':
            gantt.config.scales = month;
            break;
        }

        ganttRef.current.render();

        scrollToToday(true);
      };

      gantt.ext.zoom.init(zoomConfig);
      /** end zoom */

      gantt.init(containerRef.current);
    }

    return () => {
      if (gantt) {
        gantt.destructor();
      }

      if (horizontalScroll) {
        horizontalScroll.removeEventListener('scroll', handleScroll);
      }

      if (verticalScroll) {
        verticalScroll.removeEventListener('scroll', handleScroll);
      }
    };

    /* eslint-disable-next-line */
  }, []);

  const getParentDimensions = (): SafeAnyType => {
    const elem = getDOMElement('#ganntWrapper');

    return elem
      ? {
          width: elem.getBoundingClientRect().width,
          height: elem.getBoundingClientRect().height,
        }
      : {};
  };

  return (
    <>
      {loading && (
        <div style={getParentDimensions()} className={styles.loader}>
          <UiLoader centered />
        </div>
      )}
      <div ref={containerRef} className={styles.container} style={{ visibility: loading ? 'hidden' : 'initial' }} />

      <div
        role="presentation"
        className={styles.zoom}
        onClick={(event) => {
          const zoomTo = (event.target as HTMLDivElement).dataset.zoom;

          if (zoomTo) {
            const zoom = zoomTo as ZoomAvailable;

            zoomAPIRef.current?.(zoom);
            setCurrentZoom(zoom);
          }
        }}
      >
        {['Day', 'Week', 'Month'].map((item) => (
          <div
            key={item}
            data-zoom={item.toLowerCase()}
            className={currentZoom === item.toLowerCase() ? styles.zoomActive : undefined}
          >
            {item}
          </div>
        ))}
      </div>
    </>
  );
};
