/* eslint-disable max-len */
import { FC } from 'react';

export const IconHeader: FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="21" viewBox="0 0 22 21" fill="none">
    <path
      d="M11.2202 20.9696C16.9142 20.9696 21.5302 16.3537 21.5302 10.6596C21.5302 4.96555 16.9142 0.349609 11.2202 0.349609C5.5261 0.349609 0.910156 4.96555 0.910156 10.6596C0.910156 16.3537 5.5261 20.9696 11.2202 20.9696Z"
      fill="#4BD37B"
    />
    <path
      d="M16.03 4.46973L8.81 11.8997L6.42 9.43973L4 11.8997L8.81 16.8497V16.8397L8.82001 16.8497L18.44 6.94973L16.03 4.46973Z"
      fill="white"
    />
  </svg>
);
