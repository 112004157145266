/* eslint-disable react/jsx-props-no-spreading */
import classNames from 'classnames';
import React, { HtmlHTMLAttributes, useCallback, useEffect, useState } from 'react';

import { isValidEmail } from 'src/utils/email';

type State = { value: string; isCorrect: boolean }[];

export type InputTagsProps = {
  onChange: (value: State, action: 'remove' | 'add' | 'init', addedOrRemovedValue: string) => void;

  placeholder?: string;
  values?: string[];
  name?: string;
  disabled?: boolean;
  error?: boolean;
  emailMode?: boolean;
  label?: string;
};

type ElementProps = {
  item: State[0];
  index: number;
  onRemove: (index: number) => void;

  disabled?: boolean;
};

const Element = ({ index, onRemove, item, disabled }: ElementProps): JSX.Element => {
  const onDelete = useCallback(() => {
    if (!disabled) {
      onRemove(index);
    }
  }, [disabled, index, onRemove]);

  return (
    <div role="presentation" className={classNames('element', { invalid: !item.isCorrect })}>
      {item.value}
      <button type="button" onClick={onDelete}>
        <svg viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            /* eslint-disable-next-line max-len */
            d="M6.41413 5.00001L9.70713 1.70701C10.0981 1.31601 10.0981 0.684006 9.70713 0.293006C9.31613 -0.0979941 8.68413 -0.0979941 8.29313 0.293006L5.00013 3.58601L1.70713 0.293006C1.31613 -0.0979941 0.684128 -0.0979941 0.293128 0.293006C-0.0978721 0.684006 -0.0978721 1.31601 0.293128 1.70701L3.58613 5.00001L0.293128 8.29301C-0.0978721 8.68401 -0.0978721 9.31601 0.293128 9.70701C0.488128 9.90201 0.744128 10 1.00013 10C1.25613 10 1.51213 9.90201 1.70713 9.70701L5.00013 6.41401L8.29313 9.70701C8.48813 9.90201 8.74413 10 9.00013 10C9.25613 10 9.51213 9.90201 9.70713 9.70701C10.0981 9.31601 10.0981 8.68401 9.70713 8.29301L6.41413 5.00001Z"
          />
        </svg>
      </button>
    </div>
  );
};

/**
 * taken from the internet
 */
export const InputTags = ({
  placeholder,
  values,
  onChange,
  name,
  className,
  disabled,
  error,
  emailMode,
  ...rest
}: InputTagsProps & HtmlHTMLAttributes<HTMLInputElement>): JSX.Element => {
  const getValues = useCallback(
    (values: string[] | undefined) =>
      (values || []).map((value) => ({
        value,
        isCorrect: emailMode ? isValidEmail(value) : true,
      })),
    [emailMode],
  );

  const [terms, setTerms] = useState<State>(() => {
    const formattedValues = getValues(values);

    onChange(formattedValues, 'init', '');

    return formattedValues;
  });

  const [value, setValue] = useState('');

  const addValue = useCallback(
    (value: State[0]['value'], type: Parameters<InputTagsProps['onChange']>[1]) => {
      const preparedValue = value.replace(',', '');

      new Promise<State>((resolve) => {
        setTerms((terms) => {
          const newValues: State = [
            ...terms,
            { value: preparedValue, isCorrect: emailMode ? isValidEmail(preparedValue) : true },
          ];

          resolve(newValues);

          return newValues;
        });
      }).then((newValues) => {
        onChange(newValues, type, preparedValue);

        setValue('');
      });
    },
    [emailMode, onChange],
  );

  useEffect(() => {
    setTerms(getValues(values));
  }, [getValues, values]);

  const onLocalChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.currentTarget.value);
  }, []);

  const onBlur = () => {
    const currentValue = value.trim();

    if (currentValue.length) {
      addValue(currentValue, 'add');
    }
  };

  const onKeyup = useCallback(
    ({ preventDefault, currentTarget: { selectionEnd }, key }: React.KeyboardEvent<HTMLInputElement>) => {
      const currentValue = value.trim();

      const valueLength = currentValue.length;

      const currentTarget = selectionEnd || 0;

      const isEndOfText = currentTarget > valueLength;

      const isStartOfText = currentTarget === 0;

      const isPossibleAddKeys = key === 'Enter' || key === ' ' || key === 'Tab' || key === ',';

      if (isPossibleAddKeys && currentValue !== '') {
        addValue(currentValue, 'add');
      } else if (
        (isStartOfText && (key === 'Backspace' || key === 'ArrowLeft')) ||
        (isEndOfText && key === 'ArrowRight')
      ) {
        preventDefault();
      }
    },
    [addValue, value],
  );

  const handleRemove = (index: number) => {
    const removingValue = terms[index];

    const values = terms.filter((_, i) => i !== index);

    onChange(values, 'remove', removingValue.value);
  };

  return (
    <div
      className={classNames('r-tags-input', {
        noElements: terms.length === 0,
        error,
      })}
    >
      {terms.map((item, index) => (
        <Element
          /* eslint-disable-next-line react/no-array-index-key */
          key={index}
          item={item}
          index={index}
          onRemove={handleRemove}
          disabled={disabled}
        />
      ))}
      <input
        type="text"
        className={className}
        placeholder={placeholder}
        aria-label={placeholder}
        value={value}
        onChange={onLocalChange}
        onBlur={onBlur}
        onKeyUp={onKeyup}
        name={name}
        disabled={disabled}
        {...rest}
      />
    </div>
  );
};
