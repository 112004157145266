/* eslint-disable max-len */
import { FC } from 'react';

export const IconCalendar: FC = () => (
  <svg width="24" height="26" viewBox="0 0 24 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.96973 18.4501C7.08973 18.4501 6.35973 18.1501 5.74973 17.5401C5.14973 16.9301 4.84973 16.1901 4.84973 15.3201C4.84973 14.4501 5.14973 13.7101 5.75973 13.1001C6.36973 12.5001 7.10973 12.2001 7.97973 12.2001C8.84973 12.2001 9.58973 12.5001 10.1997 13.1101C10.7997 13.7201 11.0997 14.4601 11.0997 15.3301C11.0997 16.2001 10.7997 16.9401 10.1897 17.5501C9.57973 18.1501 8.83973 18.4501 7.96973 18.4501ZM2.39973 25.8601C1.87973 25.8601 1.42973 25.6701 1.04973 25.2801C0.669726 24.8901 0.469727 24.4401 0.469727 23.9301V3.96008C0.469727 3.44008 0.659727 2.99008 1.04973 2.61008C1.43973 2.23008 1.88973 2.03009 2.39973 2.03009H4.48973V0.100098H6.57973V2.03009H17.5297V0.100098H19.6197V2.03009H21.7097C22.2297 2.03009 22.6797 2.22008 23.0597 2.61008C23.4397 3.00008 23.6397 3.45008 23.6397 3.96008V23.9301C23.6397 24.4501 23.4497 24.9001 23.0597 25.2801C22.6697 25.6601 22.2197 25.8601 21.7097 25.8601H2.38973H2.39973ZM2.39973 23.9301H21.7197V10.0801H2.39973V23.9301ZM2.39973 8.15009H21.7197V3.96008H2.39973V8.15009Z"
      fill="#3F5B64"
    />
  </svg>
);
