import { useId } from 'react';

export const useIdOrRandomString = (id?: string | number): string => {
  const generatedId = useId();

  if (typeof id === 'number') {
    return id.toString();
  }

  return id || generatedId;
};
