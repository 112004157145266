import cloneDeep from 'lodash/cloneDeep';
import sanitizeHtml from 'sanitize-html';
import { create } from 'zustand';

import { apiGlobalSearch } from 'src/api/search';
import { getRouteProjectViewPage, ROUTE_INDEX_PAGE, viewTaskModalQueryParam } from 'src/routes/paths';
import { showErrorToast } from 'src/utils/errors';
import { FNPropsType, useRequest } from 'src/hooks/useRequest';
import { SafeAnyType } from 'src/utils/safeAny';

export type SearchStoreResultItem = {
  id: number;
  text: string;
  link: string;
  email?: string;
  raw: Record<SafeAnyType, SafeAnyType>;
};

export type SearchStoreEntities = 'comments' | 'tasks' | 'projects' | 'users';

const emptyItems: Record<SearchStoreEntities, SearchStoreResultItem[]> = {
  comments: [],
  tasks: [],
  projects: [],
  users: [],
};

type Actions = {
  start: () => void;
  stop: () => void;

  search: (data: { query: string; userSearch?: boolean }) => Promise<string | void>;
  clear: () => void;
};

type State = {
  request: {
    apiCaller: FNPropsType;
    abort: () => void;
  } | null;

  searchItems: Record<SearchStoreEntities, SearchStoreResultItem[]>;
  query: string;
  isEmptySearch: boolean;
  loading: boolean;
};

const initialState: State = {
  request: null,

  searchItems: emptyItems,
  query: '',
  isEmptySearch: true,
  loading: false,
};

const useSearchStore = create<State & Actions>()((set, get) => ({
  ...initialState,

  start: () => {
    const { apiCaller, abort } = useRequest.getStatic();

    set({
      request: {
        apiCaller,
        abort,
      },
    });
  },
  stop: () => {
    get().request?.abort();

    set({
      ...initialState,
    });
  },

  search: ({ query, userSearch = false }) => {
    const apiCaller = get().request?.apiCaller;

    if (apiCaller) {
      // const pageSize = 4 * maxShowEachCategory + 1; // 4 sections by maxShow max + 1 to see if there are more data

      const itemsToSet = cloneDeep(emptyItems);

      set((state) => ({
        ...initialState,
        request: state.request,
        loading: true,
      }));

      return apiCaller(apiGlobalSearch, {
        queryString: query,
        page: 0,
        pageSize: 1e4, // as there is no limit for each category. categories go one by one
        userSearch,
      })
        .then(({ data }) => {
          data.forEach((item) => {
            if (item.searchItemType === 'Comments') {
              itemsToSet.comments.push({
                id: item.data.Id,
                text: sanitizeHtml(item.data.Content, {
                  allowedTags: [],
                }),
                link: `${ROUTE_INDEX_PAGE}?${viewTaskModalQueryParam}=${item.data.TaskId}`,
                raw: item.data,
              });
            } else if (item.searchItemType === 'Tasks') {
              itemsToSet.tasks.push({
                id: item.data.Id,
                text: item.data.Name,
                link: `${ROUTE_INDEX_PAGE}?${viewTaskModalQueryParam}=${item.data.Id}`,
                raw: item.data,
              });
            } else if (item.searchItemType === 'Projects') {
              itemsToSet.projects.push({
                id: item.data.Id,
                text: item.data.Name,
                link: getRouteProjectViewPage(item.data.Id),
                raw: item.data,
              });
            } else if (item.searchItemType === 'Users') {
              itemsToSet.users.push({
                id: item.data.Id,
                email: item.data.Email,
                text: `${item.data.FirstName} ${item.data.LastName} ${item.data.Email}`,
                link: '',
                raw: item.data,
              });
            }
          });

          set({
            searchItems: { ...itemsToSet },
            query,
            isEmptySearch:
              itemsToSet.comments.length === 0 &&
              itemsToSet.tasks.length === 0 &&
              itemsToSet.projects.length === 0 &&
              itemsToSet.users.length === 0,
            loading: false,
          });

          return 'OK';
        })
        .catch((e) => {
          showErrorToast(e);

          set((state) => ({
            ...initialState,
            request: state.request,
            loading: false,
          }));
        });
    }

    return Promise.reject();
  },
  clear: () => {
    set((state) => ({
      ...initialState,
      request: state.request,
    }));
  },
}));

export default useSearchStore;
