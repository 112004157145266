import { FileContract, ProfileDetailsContract } from '@moonpanda/moonpanda.contracts';
import React, { FC, useState } from 'react';

import { apiAccountUpdateUserInfo } from 'src/api/account';
import { apiCheckEmailExists } from 'src/api/authentication';
import { UiAvatarLoader } from 'src/components/common/UiAvatarLoader';
import { UiFormatNumberInput } from 'src/components/UI/inputs/FormatNumber';
import { UiInput } from 'src/components/UI/inputs/Text';
import { UiLoader } from 'src/components/UI/loaders';
import { UiToast } from 'src/components/UI/toasts';
import { UiButton } from 'src/components/UI/UiButton';
import { EMAIL_PATTERN } from 'src/config';
import { ApiError, useRequest } from 'src/hooks/useRequest';
import useGlobalStore from 'src/store';
import { showErrorToast } from 'src/utils/errors';
import { useTHelper } from 'src/utils/i18n';
import { StateFormGetStatusValue, useStateForm } from 'src/utils/stateForm';

import styles from 'src/pages/Settings/Personal/styles.module.scss';

type FormValues = ProfileDetailsContract & {
  avatar: FileContract[] | null;
};

export const SettingsPagePersonalComponent: FC = () => {
  const tHelper = useTHelper('pages.settings.personal.info');

  const { apiCaller } = useRequest();

  const { setUser, user } = useGlobalStore((state) => ({ setUser: state.setUser, user: state.user }));

  const formProps = useStateForm<FormValues>({
    defaultValues: user.response
      ? { ...user.response, avatar: user.response.avatar ? [user.response.avatar] : null }
      : {},
  });

  const [loading, setLoading] = useState(false);

  const [isEdit, setIsEdit] = useState(false);

  const disabled = !isEdit || loading;

  const onSubmit = async (formData: FormValues, { isDirty }: StateFormGetStatusValue) => {
    if (isDirty) {
      if (EMAIL_PATTERN.test(formData.email)) {
        setIsEdit(false);
        setLoading(true);

        let canGo = true;

        // check if the new email is available
        if (formProps.getInitialValue().email !== formData.email) {
          await apiCaller(apiCheckEmailExists, {
            email: formData.email,
          }).catch((e: ApiError) => {
            canGo = false;

            setIsEdit(true);
            setLoading(false);

            if (e.errors?.find(({ propertyName }) => propertyName === 'Email')) {
              UiToast.warning(tHelper('emailExists'));
            } else {
              showErrorToast(e);
            }
          });
        }

        if (canGo) {
          apiCaller(apiAccountUpdateUserInfo, {
            ...formData,
            avatar: formData.avatar ? formData.avatar[0] : null,
          })
            .then(({ data }) => {
              formProps.reset(
                { ...data, avatar: data.avatar ? [data.avatar] : null },
                {
                  resetInitialForm: true,
                },
              );

              setUser({
                loading: false,
                response: data,
                error: null,
              });

              UiToast.commonSuccessSaved();
            })
            .catch(showErrorToast)
            .finally(() => {
              setLoading(false);
            });
        }
      } else {
        formProps.setError('email', tHelper('emailInvalid'));

        UiToast.warning(tHelper('emailInvalid'));
      }
    } else {
      setIsEdit(false);
    }
  };

  return (
    <>
      {loading && <UiLoader centered />}

      <form className={styles.personalWrapper} autoComplete="off">
        <div className={styles.avatarWrapper}>
          <UiAvatarLoader formProps={formProps} name="avatar" disabled={disabled} />
        </div>

        <UiInput
          formProps={formProps}
          name="firstName"
          label={tHelper('firstNameLabel')}
          placeholder={tHelper('firstNameLabel')}
          disabled={disabled}
          required
        />
        <UiInput
          formProps={formProps}
          name="lastName"
          label={tHelper('lastNameLabel')}
          placeholder={tHelper('lastNameLabel')}
          disabled={disabled}
          required
        />
        <UiInput
          formProps={formProps}
          name="email"
          label={tHelper('emailLabel')}
          placeholder={tHelper('emailLabel')}
          disabled={disabled}
          required
        />
        <UiInput
          formProps={formProps}
          name="jobTitle"
          label={tHelper('jobTitleLabel')}
          placeholder={tHelper('jobTitleLabel')}
          disabled={disabled}
        />
        <UiInput
          formProps={formProps}
          name="companyName"
          label={tHelper('companyNameLabel')}
          placeholder={tHelper('companyNameLabel')}
          disabled={disabled}
        />
        <UiFormatNumberInput
          formProps={formProps}
          name="baseRate"
          label={tHelper('baseRateLabel')}
          disabled={disabled}
        />
        {/** not yet ready */}
        {/* <UiInput */}
        {/*   formProps={formProps} */}
        {/*   name="emailSignature" */}
        {/*   type="textarea" */}
        {/*   className={styles.textarea} */}
        {/*   label={tHelper('emailSignatureLabel')} */}
        {/*   placeholder={tHelper('emailSignatureLabel')} */}
        {/*   disabled={disabled} */}
        {/* /> */}

        {isEdit ? (
          <UiButton color="dark" className={styles.saveBtn} onClick={formProps.onSubmit(onSubmit)}>
            {tHelper('saveBtn')}
          </UiButton>
        ) : (
          <UiButton
            color="light"
            className={styles.editBtn}
            onClick={() => {
              setIsEdit(true);
            }}
          >
            {tHelper('editBtn')}
          </UiButton>
        )}
      </form>
    </>
  );
};
