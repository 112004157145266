import React, { FC, useCallback, useMemo, useRef, useState } from 'react';
import { FilterContract, InvoiceContract } from '@moonpanda/moonpanda.contracts';
import classNames from 'classnames';

import { UiDeleteConfirmPopover } from 'src/components/UI/DeleteConfirmPopover';
import { UiScrollable } from 'src/components/UI/InfiniteScroll';
import { UiCheckbox } from 'src/components/UI/inputs/Checkbox';
import { UiDropdownProps } from 'src/components/UI/inputs/DropDown';
import { UiProjectsDropdown } from 'src/components/UI/ProjectsDropdown';
import { UiSearchInput } from 'src/components/UI/Search';
import { UiTable } from 'src/components/UI/table';
import { UiTableInnerFiltersType, UiTableRefProps } from 'src/components/UI/table/helpers';
import { UiToast } from 'src/components/UI/toasts';
import { UiButton } from 'src/components/UI/UiButton';
import { DEFAULT_TABLE_PAGE_SIZE, SHORT_DATE_FORMAT } from 'src/config';
import { useGrid } from 'src/hooks/useGrid';
import { useRequest } from 'src/hooks/useRequest';
import { IconDelete } from 'src/components/icons/Delete';
import useModalsStore from 'src/store/modals';
import { CDate } from 'src/utils/CDate';
import { showErrorToast } from 'src/utils/errors';
import { useTHelper } from 'src/utils/i18n';
import { useStateForm } from 'src/utils/stateForm';
import { useStateFormValueWatch } from 'src/utils/stateForm/useFormWatch/useStateFormValueWatch';
import { apiDeleteInvoice, apiGetInvoiceExportLink, apiGetInvoicingList } from 'src/api/invoicing';
import { IconPreview } from 'src/components/icons/Preview';
import { IconDownload } from 'src/components/icons/Download';
import { downloadBlobAsFile } from 'src/utils/file';

import styles from './styles.module.scss';

type FormValues = {
  check_: string | undefined;
};

export const InvoicingPage: FC = () => {
  const tHelper = useTHelper('pages.invoicing');

  const formProps = useStateForm<FormValues>();

  const checked = useStateFormValueWatch(formProps.getSubscribeProps, '');

  const checkedIds = useMemo(
    () =>
      Object.entries(checked)
        .filter(([, v]) => Boolean(v))
        .map(([key]) => +key.split('_')[1]),
    [checked],
  );

  const [projectIdSelected, setProjectIdSelected] = useState<number | null>(null);

  const [search, setSearch] = useState<string | null>(null);

  const { loading, data, load, reload, loadNext, updateAllData } = useGrid<InvoiceContract>({
    apiFn: apiGetInvoicingList,
    initialLoad: false,
    pageSize: DEFAULT_TABLE_PAGE_SIZE,
  });

  const { apiCaller } = useRequest();

  const tableRef = useRef<UiTableRefProps>(null);

  const projectIdRef = useRef<number | null>(null);

  const toggleModals = useModalsStore((state) => state.toggleModals);

  const onCreateInvoice = useCallback(
    () =>
      toggleModals('createInvoice', true, {
        onCreateInvoice: reload,
      }),
    [reload, toggleModals],
  );

  const onShowViewTask = useCallback(
    (id: number) => () => {
      toggleModals('viewInvoice', true, {
        invoiceId: id,
        onCreateInvoice: reload,
      });
    },
    [reload, toggleModals],
  );

  const handleDownloadInvoice = useCallback(
    (id: number, invoiceNameId: string) => {
      apiCaller(
        apiGetInvoiceExportLink,
        {
          id,
        },
        { expectBlob: true },
      )
        .then((response) => {
          downloadBlobAsFile(response as unknown as Blob, `${invoiceNameId}`);
        })
        .catch(showErrorToast);
    },
    [apiCaller],
  );

  const onTableGetData = useCallback(
    (tableData: UiTableInnerFiltersType) => {
      const filters: FilterContract[] = [];

      if (projectIdSelected && projectIdSelected !== -1) {
        filters.push({ propertyName: 'projectId', operation: 'Equals', value: projectIdSelected.toString() });
      }

      if (search) {
        filters.push({ propertyName: 'InvoiceNameId', operation: 'IndexOf', value: search });
      }

      load({
        page: 0,
        pageSize: DEFAULT_TABLE_PAGE_SIZE,
        orderBy: tableData.propertyName
          ? [{ propertyName: tableData.propertyName, sortingOrder: tableData.sortingOrder }]
          : undefined,
        filters,
      });
    },
    [load, projectIdSelected, search],
  );

  const columns = useMemo(
    () => [
      {
        title: tHelper('columns.projectName'),
        renderValue: ({ projectName }: InvoiceContract) => <span title={projectName}>{projectName}</span>,
        sortPropertyName: 'projectName',
        className: classNames(styles.columnName),
      },
      {
        title: tHelper('columns.invoiceID'),
        renderValue: ({ invoiceNameId }: InvoiceContract) => <span title={invoiceNameId}>{invoiceNameId}</span>,
        sortPropertyName: 'invoiceNameId',
        className: classNames(styles.columnOwner),
      },
      {
        title: tHelper('columns.dateCreated'),
        renderValue: ({ invoiceDate }: InvoiceContract) => CDate.format(new Date(invoiceDate), SHORT_DATE_FORMAT),
        sortPropertyName: 'invoiceDate',
      },
      {
        title: tHelper('columns.total'),
        renderValue: ({ total }: InvoiceContract) => <span title={String(total)}>{total}</span>,
        sortPropertyName: 'total',
      },
      {
        title: tHelper('columns.dueOn'),
        renderValue: ({ dueDate }: InvoiceContract) => CDate.format(new Date(dueDate), SHORT_DATE_FORMAT),
        sortPropertyName: 'dueDate',
        className: classNames(styles.columnProject),
      },
    ],
    [tHelper],
  );

  const additionalLeftColumn = useMemo(
    () => ({
      renderValue: ({ id }: InvoiceContract) => <UiCheckbox formProps={formProps} name={`check_${id}`} />,
    }),
    [formProps],
  );

  const additionalRightColumn = useMemo(
    () => ({
      renderValue: ({ id, invoiceNameId }: InvoiceContract) => {
        const isDelete = checkedIds.includes(id);

        return (
          <div className={isDelete ? styles.actionDelete : styles.actions}>
            {isDelete ? (
              <UiDeleteConfirmPopover
                placement="bottom"
                text={
                  checkedIds.length === 1
                    ? undefined
                    : tHelper('deleteMultipleSure', {
                        number: checkedIds.length,
                      })
                }
                onConfirm={() => {
                  Promise.all(
                    checkedIds.map((id) =>
                      apiCaller(
                        apiDeleteInvoice,
                        {
                          id,
                        },
                        { key: id },
                      ),
                    ),
                  )
                    .then(() => {
                      UiToast.commonSuccessDeleted();

                      checkedIds.forEach((id) => {
                        formProps.setValue(`check_${id}` as 'check_', null);
                      });

                      updateAllData((storeData) => storeData.filter((doc) => !checkedIds.includes(doc.id)));
                    })
                    .catch(showErrorToast);
                }}
              >
                <IconDelete />
              </UiDeleteConfirmPopover>
            ) : (
              <>
                <IconPreview onClick={onShowViewTask(id)} />
                <IconDownload onClick={() => handleDownloadInvoice(id, invoiceNameId)} />
              </>
            )}
          </div>
        );
      },
    }),
    [checkedIds, tHelper, onShowViewTask, apiCaller, updateAllData, formProps, handleDownloadInvoice],
  );

  const onProjectChange = useCallback((value: UiDropdownProps['options'][0] | null) => {
    setProjectIdSelected(value?.value as number | null);
    projectIdRef.current = value?.value as number | null;
  }, []);

  return (
    <div className="bodyPadding">
      <div className={styles.top}>
        <UiSearchInput
          placeholder={tHelper('search')}
          onSearch={setSearch}
          onClear={() => {
            setSearch(null);
          }}
        />
        <UiProjectsDropdown
          onChange={onProjectChange}
          placeholder={tHelper('allProjectsDropDownPlaceHolder')}
          customAllValue={tHelper('allProjectsDropDownPlaceHolder')}
        />
        <UiButton color="green" onClick={onCreateInvoice}>
          {tHelper('createInvoice')}
        </UiButton>
      </div>

      <UiScrollable data={data} getData={loadNext} loading={loading}>
        <UiTable
          data={data}
          getData={onTableGetData}
          columns={columns}
          additionalXRightColumn={additionalRightColumn}
          additionalXLeftColumn={additionalLeftColumn}
          appearance="table-striped table-hover"
          className={styles.table}
          ref={tableRef}
        />
      </UiScrollable>
    </div>
  );
};
