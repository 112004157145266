/* eslint-disable max-len */
import { FC } from 'react';
import { CommonIconProps } from 'src/utils/iconProps';

type Props = CommonIconProps & {
  onClick?: () => void;
};

export const IconClose: FC<Props> = ({ className, onClick }) => (
  <svg
    width="10"
    height="11"
    viewBox="0 0 8 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    onClick={onClick}
  >
    <path
      d="M0.690653 1.16017C0.370653 1.51017 0.370653 2.08017 0.690653 2.43017L2.75065 4.68017L0.670664 6.95017C0.350664 7.30017 0.350664 7.87017 0.670664 8.22017C0.980664 8.56017 1.49064 8.56017 1.80064 8.22017L3.89067 5.94017L6.09065 8.35017C6.40065 8.69017 6.91065 8.69017 7.22065 8.35017C7.54065 8.00017 7.54065 7.43017 7.22065 7.08017L5.03065 4.69017L7.20066 2.32017C7.52066 1.97017 7.52066 1.40017 7.20066 1.05017C6.89066 0.710166 6.38066 0.710166 6.07066 1.05017L3.88066 3.44017L1.80064 1.17016C1.49064 0.830165 0.980664 0.830165 0.670664 1.17016L0.690653 1.16017Z"
      fill="#12171A"
    />
  </svg>
);
