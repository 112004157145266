import { EventContentArg } from '@fullcalendar/core';
import { EventContract, TaskContract } from '@moonpanda/moonpanda.contracts';
import classNames from 'classnames';
import { FC } from 'react';

import { IconCalendar } from 'src/pages/Calendar/icons/Icon';
import { CDate } from 'src/utils/CDate';

type Props = {
  model: EventContentArg;
  getProjectColorById: (projectId: number) => string;
};

export const CalendarEvent: FC<Props> = ({ model, getProjectColorById }) => {
  const info = model.event.extendedProps;

  const getDate = () =>
    model.event.extendedProps.type === 'event'
      ? (model.event.extendedProps as EventContract).startDate
      : (model.event.extendedProps as TaskContract).dueDate;

  const isTask = model.event.extendedProps.type === 'task';

  if (model.view.type === 'timeGridWeek') {
    return (
      <div className="week-item">
        <div className="color" style={{ backgroundColor: getProjectColorById(info.projectId) }} />
        {!isTask && <div className="date">{CDate.format(new Date(getDate()), 'HH:mmaaa')}</div>}
        <IconCalendar />
        <div className="name" title={info.name}>
          {info.name}
        </div>
      </div>
    );
  }

  return (
    <div
      className={classNames('month-item', {
        'rows-4': !isTask,
      })}
    >
      <div className="color" style={{ backgroundColor: getProjectColorById(info.projectId) }} />
      <div className="name" title={info.name}>
        {info.name}
      </div>
      {!isTask && <div className="date">{CDate.format(new Date(getDate()), 'HH:mmaaa')}</div>}
      <IconCalendar />
    </div>
  );
};
