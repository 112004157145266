/* eslint-disable max-len */
import { FC } from 'react';

import { CommonIconProps } from 'src/utils/iconProps';

type Props = CommonIconProps & {
  onClick: () => void;
};

export const IconEdit: FC<Props> = ({ className, onClick }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    viewBox="0 0 12 12"
    fill="none"
    className={className}
    onClick={onClick}
  >
    <path
      d="M1.58984 11.0498C1.37984 11.0498 1.20981 10.9798 1.05981 10.8298C0.909814 10.6798 0.839844 10.4998 0.839844 10.2998V2.8998C0.839844 2.6898 0.909814 2.5198 1.05981 2.3698C1.20981 2.2198 1.38984 2.1498 1.58984 2.1498H6.52985L5.92981 2.7498H1.58984C1.58984 2.7498 1.5098 2.7698 1.4798 2.7998C1.4498 2.8298 1.42981 2.86981 1.42981 2.90981V10.3098C1.42981 10.3098 1.4498 10.3898 1.4798 10.4198C1.5098 10.4498 1.54984 10.4698 1.58984 10.4698H8.98981C8.98981 10.4698 9.06979 10.4498 9.09979 10.4198C9.12979 10.3898 9.14984 10.3498 9.14984 10.3098V5.9398L9.74982 5.3398V10.3098C9.74982 10.5198 9.67985 10.6898 9.52985 10.8398C9.37985 10.9898 9.19982 11.0598 8.99982 11.0598H1.59979L1.58984 11.0498ZM7.80981 2.2098L8.23981 2.6298L4.56982 6.2798V7.3098H5.57983L9.25983 3.6398L9.6698 4.0498L5.81982 7.90981H3.95984V6.0498L7.78979 2.2198L7.80981 2.2098ZM9.68982 4.0398L7.80981 2.2098L9.00983 1.0098C9.14983 0.869804 9.32979 0.799805 9.53979 0.799805C9.74979 0.799805 9.92983 0.8798 10.0798 1.0298L10.8598 1.8098C11.0098 1.9598 11.0798 2.1398 11.0798 2.3498C11.0798 2.5598 10.9998 2.7298 10.8498 2.8798L9.68982 4.0498V4.0398Z"
      fill="#C6C6C6"
    />
  </svg>
);
