import isNil from 'lodash/isNil';

export const numberToTimeObject = (
  value: string | number | null | undefined,
): {
  hours: number;
  minutes: string;
} => {
  if (isNil(value)) {
    return {
      hours: 0,
      minutes: '00',
    };
  }

  const localValue = value.toString();

  const hours = Math.trunc(+localValue);

  if (localValue.toString().includes('.')) {
    let minutesRaw = localValue.split('.')[1];

    if (minutesRaw.length > 2) {
      [, minutesRaw] = (+`0.${minutesRaw}`).toFixed(2).split('.');
    }

    if (!minutesRaw.startsWith('0') && minutesRaw.length === 1) {
      minutesRaw = `${minutesRaw}0`;
    }
    const minutes = Math.round(0.6 * +minutesRaw);

    return {
      hours,
      minutes: minutes >= 10 ? minutes.toString() : `0${minutes}`,
    };
  }

  return {
    hours,
    minutes: '00',
  };
};
