import { Task } from '@moonpanda/dhtmlx-gantt';
import classNames from 'classnames';

import styles from 'src/components/UI/gantt/styles.module.scss';
import { GANTT_DUE_DATE_ID } from 'src/components/UI/gantt/config/index';
import { SafeAnyType } from 'src/utils/safeAny';

export const getGanttColumns = (singleProject: boolean) => {
  const createButton = `<span class="${styles.createPlusButton}"></span>`;

  const dueDateLabel = `
    <div id=${GANTT_DUE_DATE_ID} class="${styles.sortingHeader}">
      Due date
      <div class="${styles.icons}">
        <span class="${styles.icon} ${styles.top}"></span>
        <span class="${styles.icon}"></span>
      </div>
    </div>`
    .replace(/\s+/g, ' ')
    .trim();

  return [
    {
      name: 'name',
      label: singleProject ? 'Tasks' : `Project ${createButton}`,
      align: 'left',
      tree: !singleProject,
      width: 320,
      template(task: Task) {
        if (task.isEmptyProject) {
          return '';
        }

        if (task.isLoader) {
          return `<div class="${styles.loaderWrapper}"><div class="custom-loader"></div></div>`;
        }

        /** phases tree */
        if (task.innerType === 'phase') {
          return `<span class="${styles.tree} ${task.$open ? styles.opened : ''}"></span>
              <span class="${classNames(
                styles.gridRowTaskName,
                styles.gridRowPhaseName,
              )}" title="${task.name}">${task.name}</span>${createButton}`;
        }

        /** common tasks */
        if (task.innerType === 'task') {
          return `<span class="TASKA ${classNames(styles.gridRowTaskName, {
            [styles.gridRowTimelineInnerTaskName]: !singleProject,
            [styles.gridRowSingleProjectInnerTaskName]: singleProject,
          })}" title="${task.name}">${task.name}</span>`;
        }

        /** projects tree */
        return `<span class="${styles.tree} ${task.$open ? styles.opened : ''}"></span><span class="${
          styles.gridRowProjectName
        }" title="${task.name}">${task.name}</span>${createButton}`;
      },
    },
    {
      width: 125,
      max_width: 125,
      min_width: 125,
      name: 'dueDate',
      label: dueDateLabel,
      align: 'left',
      /* display dueDate only for tasks */
      template: (task: Task): SafeAnyType => (task.isProject || task.isLoader ? '' : task.dueDateText),
    },
  ];
};
