/* eslint-disable max-len */
import { FC } from 'react';

export const IconMessages: FC = () => (
  <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.500235 25.2798L2.64023 17.6898C2.24023 16.8398 1.96024 15.9598 1.78024 15.0498C1.60024 14.1398 1.51023 13.2198 1.51023 12.2698C1.51023 10.5998 1.82024 9.04979 2.45024 7.59979C3.08024 6.14979 3.93023 4.8798 5.02024 3.78979C6.11024 2.69979 7.38024 1.83979 8.83024 1.21979C10.2802 0.599788 11.8402 0.279785 13.5002 0.279785C15.1602 0.279785 16.7202 0.589788 18.1702 1.21979C19.6202 1.84979 20.8902 2.69979 21.9802 3.78979C23.0702 4.8798 23.9302 6.14979 24.5502 7.59979C25.1702 9.04979 25.4902 10.6098 25.4902 12.2698C25.4902 13.9298 25.1802 15.4898 24.5502 16.9398C23.9302 18.3898 23.0702 19.6598 21.9802 20.7498C20.8902 21.8398 19.6202 22.6998 18.1702 23.3198C16.7202 23.9398 15.1602 24.2598 13.5002 24.2598C12.5502 24.2598 11.6302 24.1698 10.7202 23.9898C9.81024 23.8098 8.94024 23.5198 8.08024 23.1298L0.490234 25.2698L0.500235 25.2798ZM3.21023 22.5698L7.32023 21.4398C7.64023 21.3398 7.94023 21.3098 8.21023 21.3398C8.48023 21.3698 8.78023 21.4398 9.07023 21.5598C9.78023 21.8398 10.5102 22.0598 11.2502 22.2298C11.9902 22.3998 12.7402 22.4798 13.5002 22.4798C16.3402 22.4798 18.7502 21.4898 20.7302 19.4998C22.7102 17.5198 23.7102 15.1098 23.7102 12.2698C23.7102 9.42978 22.7202 7.01979 20.7302 5.03979C18.7502 3.05979 16.3402 2.05978 13.5002 2.05978C10.6602 2.05978 8.25024 3.04979 6.27024 5.02979C4.29024 7.00979 3.29024 9.42978 3.29024 12.2798C3.29024 13.0498 3.34024 13.8098 3.45024 14.5598C3.56024 15.3098 3.81023 16.0298 4.21023 16.6998C4.35023 16.9698 4.42023 17.2598 4.43023 17.5598C4.43023 17.8598 4.41023 18.1598 4.33023 18.4498L3.20024 22.5598L3.21023 22.5698ZM12.6102 17.0598H14.4002V13.2798H18.2102V11.4898H14.4002V7.67978H12.6102V11.4898H8.80023V13.2798H12.6102V17.0598Z"
      fill="#3F5B64"
    />
  </svg>
);
