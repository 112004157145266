import { ProjectContract } from '@moonpanda/moonpanda.contracts';
import bem from 'bem-ts';
import React, { FC, useCallback, useEffect } from 'react';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';

import { UiScrollable } from 'src/components/UI/InfiniteScroll';
import { UiLoader } from 'src/components/UI/loaders';
import { useGrid } from 'src/hooks/useGrid';
import { apiGetProjectData, apiGetProjectList } from 'src/api/projects';
import { useOneRequest } from 'src/hooks/useOneRequest';
import {
  getRouteProjectsCreatePhasesPage,
  ROUTE_PROJECTS_CREATE_PROJECT_MODAL,
  ROUTE_PROJECTS_PAGE,
} from 'src/routes/paths';
import useModalsStore from 'src/store/modals';
import { useTHelper } from 'src/utils/i18n';
import classNames from 'classnames';
import { ProjectPageLayout } from 'src/pages/Projects/layout';
import { IconNew } from 'src/pages/Projects/icons/New';
import { ProjectCard } from 'src/pages/Projects/overview/Card';

export const ProjectsOverviewPage: FC = () => {
  const tHelper = useTHelper('pages.projects');

  const b = bem('projectsOverview');

  const navigate = useNavigate();

  const { pathname } = useLocation();

  const toggleModals = useModalsStore((state) => state.toggleModals);

  const showCreateProjectModal = useCallback(() => {
    toggleModals('createProject', true, {
      onClose: () => {
        navigate(ROUTE_PROJECTS_PAGE, { replace: true });
      },
      onCreateProject: (response: ProjectContract) => {
        navigate(getRouteProjectsCreatePhasesPage(response.id), {
          state: {
            isNew: true,
          },
        });
      },
    });
  }, [navigate, toggleModals]);

  // when route changes
  useEffect(() => {
    if (matchPath(ROUTE_PROJECTS_CREATE_PROJECT_MODAL, pathname)) {
      showCreateProjectModal();
    }
  }, [pathname, showCreateProjectModal]);

  const { loading, data, loadNext, updateAllData } = useGrid<ProjectContract>({
    apiFn: apiGetProjectList,
    pageSize: 20,
    defaultOrderBy: [{ propertyName: 'id', sortingOrder: 'Desc' }],
    initialLoad: false, // it will do UiScrollable
  });

  const { apiCaller } = useOneRequest(apiGetProjectData);

  const updateProjectInfo = useCallback(
    (projectId: number) => {
      apiCaller({ projectId }).then(({ data }) => {
        updateAllData((storeData) => {
          const indexToChange = storeData.findIndex(({ id }) => id === data.id);

          if (indexToChange >= 0) {
            storeData[indexToChange] = data;
          }

          return storeData;
        });
      });
    },
    [apiCaller, updateAllData],
  );

  return (
    <ProjectPageLayout>
      <div className={classNames(b(), 'fullPageLoader')}>
        {loading && <UiLoader />}

        <UiScrollable
          className={classNames('gridBlock', b('container'))}
          data={data}
          getData={loadNext}
          loading={loading}
          fromGrid
        >
          <div
            role="presentation"
            className={classNames('gridItem', b('item', { new: true }))}
            onClick={() => {
              navigate(ROUTE_PROJECTS_CREATE_PROJECT_MODAL, { replace: true });
            }}
          >
            <IconNew />
            {tHelper('createNew')}
          </div>

          {data.data.map((item) => (
            <ProjectCard key={item.id} item={item} updateProjectInfo={updateProjectInfo} />
          ))}
        </UiScrollable>
      </div>
    </ProjectPageLayout>
  );
};
