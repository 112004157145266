import { JwtTokenContract, ProfileDetailsContract } from '@moonpanda/moonpanda.contracts';
import { FC, lazy, Suspense, useCallback } from 'react';
import { Link } from 'react-router-dom';

import { apiCheckEmailExists } from 'src/api/authentication';
import { GoogleAuthButton } from 'src/components/common/GoogleAuthButton';
import { MicrosoftAuthButton } from 'src/components/common/MicrosoftAuthButton';
import { IconAppLogo } from 'src/components/icons/AppLogo';
import { UiInput } from 'src/components/UI/inputs/Text';
import { UiToast } from 'src/components/UI/toasts';
import { UiButton } from 'src/components/UI/UiButton';
import { EMAIL_PATTERN } from 'src/config';
import { useOneRequest } from 'src/hooks/useOneRequest';
import { ApiError } from 'src/hooks/useRequest';
import { SignUpPageFormValues } from 'src/pages/SignUp/index';
import { ROUTE_SIGN_IN_PAGE } from 'src/routes/paths';
import { useTHelper } from 'src/utils/i18n';
import { StateFormReturnType } from 'src/utils/stateForm';

import styles from './styles.module.scss';

const SignInfoBlock = lazy(() => import('src/pages/SignIn/Terms'));

type Props = {
  formProps: StateFormReturnType<SignUpPageFormValues>;
  onNext: () => void;
  onFillProfileData: (data: ProfileDetailsContract, jwt: JwtTokenContract) => void;
};

export const SignUpPageCreateComponent: FC<Props> = ({ formProps, onNext, onFillProfileData }) => {
  const tHelper = useTHelper('pages.signUp.stepCreate');

  const replaceValue = useCallback((value: string) => value.replace(/\s/g, ''), []);

  const { apiCaller, loading } = useOneRequest(apiCheckEmailExists);

  return (
    <div className={styles.stepCreate}>
      <h1>{tHelper('header')}</h1>

      <IconAppLogo className={styles.iconLogo} />

      <form
        onSubmit={formProps.onSubmit((formData) => {
          if (EMAIL_PATTERN.test(formData.email as string)) {
            apiCaller({
              email: formData.email as string,
            })
              .then(onNext)
              .catch(({ errors }: ApiError) => {
                if (errors?.find(({ propertyName }) => propertyName === 'Email')) {
                  UiToast.warning(tHelper('emailExists'));
                }
              });
          } else {
            UiToast.warning(tHelper('emailInvalid'));
          }
        })}
      >
        <div>
          <GoogleAuthButton onFillProfileData={onFillProfileData} />
          <MicrosoftAuthButton onFillProfileData={onFillProfileData} />

          <div className={styles.delimiter}>
            <span>OR</span>
          </div>

          <UiInput
            formProps={formProps}
            name="email"
            type="email"
            placeholder={tHelper('emailPlaceholder')}
            replaceValue={replaceValue}
            required
          />
          <UiButton type="submit" loading={loading}>
            {tHelper('submitBtn')}
          </UiButton>
          <Link to={ROUTE_SIGN_IN_PAGE} className="linkButtonLike dark">
            {tHelper('linkToSignIn')}
          </Link>

          <Suspense fallback={<></>}>
            <SignInfoBlock />
          </Suspense>
        </div>
      </form>
    </div>
  );
};
