/* eslint-disable max-len */
import { FC } from 'react';
import { CommonIconProps } from 'src/utils/iconProps';

type Props = CommonIconProps & {
  onClick?: () => void;
};

export const IconEdit: FC<Props> = ({ className, onClick }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="11"
    height="11"
    viewBox="0 0 11 11"
    fill="none"
    onClick={onClick}
    className={className}
  >
    <path
      opacity="0.5"
      d="M1.21973 10.8691C1.00973 10.8691 0.839758 10.7991 0.689758 10.6491C0.539758 10.4991 0.469727 10.3191 0.469727 10.1191V2.71915C0.469727 2.50915 0.539758 2.33915 0.689758 2.18915C0.839758 2.03915 1.01973 1.96915 1.21973 1.96915H6.15973L5.55975 2.56915H1.21973C1.21973 2.56915 1.13974 2.58914 1.10974 2.61914C1.07974 2.64914 1.05975 2.68914 1.05975 2.72914V10.1292C1.05975 10.1292 1.07974 10.2092 1.10974 10.2392C1.13974 10.2692 1.17973 10.2891 1.21973 10.2891H8.61975C8.61975 10.2891 8.69974 10.2692 8.72974 10.2392C8.75974 10.2092 8.77979 10.1692 8.77979 10.1292V5.75914L9.37976 5.15915V10.1292C9.37976 10.3392 9.30973 10.5091 9.15973 10.6591C9.00973 10.8091 8.82976 10.8792 8.62976 10.8792H1.22974L1.21973 10.8691ZM7.43976 2.02914L7.86975 2.44914L4.19977 6.09915V7.12915H5.20978L8.88977 3.45915L9.29974 3.86914L5.44977 7.72914H3.58978V5.86914L7.41974 2.03914L7.43976 2.02914ZM9.31976 3.85915L7.43976 2.02914L8.63977 0.829147C8.77977 0.689147 8.95974 0.619141 9.16974 0.619141C9.37974 0.619141 9.55978 0.699152 9.70978 0.849152L10.4897 1.62915C10.6397 1.77915 10.7098 1.95914 10.7098 2.16914C10.7098 2.37914 10.6297 2.54914 10.4797 2.69914L9.31976 3.86914V3.85915Z"
      fill="#3F5B64"
    />
  </svg>
);
