import { TaskContract } from '@moonpanda/moonpanda.contracts';

import { ApiPaginateRequestType, ApiPatchOperationType } from 'src/models/API';
import { ApiFunctionType, httpDelete, httpGet, httpPatch, httpPostCancellable } from 'src/utils/http';

const getPath = (taskId?: number) => `Tasks${taskId ? `/${taskId}` : ''}`;

export const apiCreateTask: ApiFunctionType<TaskContract, TaskContract> = httpPostCancellable(getPath());

export const apiGetTask: ApiFunctionType<{ taskId: number }, TaskContract> = (data) =>
  httpGet(getPath(data.taskId), data);

export const apiDeleteTask: ApiFunctionType<{ taskId: number }, unknown> = (data) =>
  httpDelete(getPath(data.taskId), data);

export const apiGetTasks: ApiFunctionType<ApiPaginateRequestType, TaskContract[]> = (data) => httpGet(getPath(), data);

export const apiUpdateTask: ApiFunctionType<ApiPatchOperationType & { taskId: number }, TaskContract> = (data) =>
  httpPatch(getPath(data.taskId), data);
