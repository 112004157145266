/* eslint-disable max-len */
import React, { FC } from 'react';

export const IconComments: FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18">
    <path
      fill="#3F5B64"
      strokeWidth={0}
      d="m18,4h-2v5c0,1.06-.42,2.08-1.17,2.83-.75.75-1.77,1.17-2.83,1.17h-3l-2.15,1.61c.33.25.74.38,1.15.39h3.67l3.73,2.8c.17.13.38.2.6.2.27,0,.52-.11.71-.29s.29-.44.29-.71v-2h1c.53,0,1.04-.21,1.41-.59.38-.38.59-.88.59-1.41v-7c0-.53-.21-1.04-.59-1.41-.38-.38-.88-.59-1.41-.59Z"
    />
    <path
      fill="#3F5B64"
      strokeWidth={0}
      d="m12,0H2C1.47,0,.96.21.59.59c-.38.38-.59.88-.59,1.41v7c0,.53.21,1.04.59,1.41.38.38.88.59,1.41.59h1v2c0,.19.05.37.15.53.1.16.24.29.4.37.17.08.35.12.54.1.18-.02.36-.08.51-.2l3.73-2.8h3.67c.53,0,1.04-.21,1.41-.59s.59-.88.59-1.41V2c0-.53-.21-1.04-.59-1.41-.38-.38-.88-.59-1.41-.59Z"
    />
  </svg>
);
