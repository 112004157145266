import { FC } from 'react';

import { UiGantt } from 'src/components/UI/gantt';
import { ProjectPageLayout } from './layout';

export const ProjectsTimelinePage: FC = () => (
  <ProjectPageLayout>
    <div className="timelineGant" id="ganntWrapper">
      <UiGantt />
    </div>
  </ProjectPageLayout>
);
