/* eslint-disable max-len */
import { FC } from 'react';
import { CommonIconProps } from 'src/utils/iconProps';

type Props = CommonIconProps & {
  onClick?: () => void;
};

export const IconModalArrow: FC<Props> = ({ className, onClick }) => (
  <svg
    width="21"
    height="43"
    viewBox="0 0 21 43"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    onClick={onClick}
  >
    <path
      d="M17.7413 40L3 21.5664L17.7413 3.10498"
      stroke="white"
      strokeWidth="6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
