import React, { FC } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { AuthProviders } from 'src/components/AuthProviders';
import { CalendarPage } from 'src/pages/Calendar';
import { ErrorPage } from 'src/pages/ErrorPage';
import { ChangePasswordPage } from 'src/pages/ChangePassword';
import { RecoverPasswordPage } from 'src/pages/RecoverPassword';
import { SearchPage } from 'src/pages/Search';
import { SettingsPage } from 'src/pages/Settings/Personal';
import { SettingsTeamPage } from 'src/pages/Settings/Team';
import { SignInPage } from 'src/pages/SignIn';
import { SignUpPage } from 'src/pages/SignUp';
import { TasksPage } from 'src/pages/Tasks';
import { DashboardPage } from 'src/pages/Dashboard';
import { ProjectsOverviewPage } from 'src/pages/Projects/overview';
import { ProjectsProjectPage } from 'src/pages/Projects/view';
import { ProjectsTimelinePage } from 'src/pages/Projects/timeline';
import { Test } from 'src/pages/Test';
import { ProjectsCreateProjectStep2 } from 'src/pages/Projects/create';
import {
  ROUTE_CALENDAR_PAGE,
  ROUTE_CHANGE_PASSWORD_PAGE,
  ROUTE_DOCUMENTS_PAGE,
  ROUTE_INDEX_PAGE,
  ROUTE_INVOICING_PAGE,
  ROUTE_PRIVACY_POLICY,
  ROUTE_PROJECTS_CREATE_PROJECT_MODAL,
  ROUTE_PROJECTS_CREATE_PROJECT_PHASES_PAGE,
  ROUTE_PROJECTS_PAGE,
  ROUTE_PROJECTS_PROJECT_PAGE,
  ROUTE_PROJECTS_TIMELINE_PAGE,
  ROUTE_RECOVER_PASSWORD_PAGE,
  ROUTE_SEARCH_PAGE,
  ROUTE_SETTINGS_PAGE,
  ROUTE_SETTINGS_TEAM_PAGE,
  ROUTE_SIGN_IN_PAGE,
  ROUTE_SIGN_UP_PAGE,
  ROUTE_TASKS_PAGE,
  ROUTE_TERMS_CONDITIONS,
} from 'src/routes/paths';
import { DocumentsPage } from 'src/pages/Documents';
import { InvoicingPage } from 'src/pages/Invoicing';

export const publicRoutesMap = [
  {
    path: ROUTE_SIGN_UP_PAGE,
    element: (
      <AuthProviders>
        <SignUpPage />
      </AuthProviders>
    ),
  },
  {
    path: ROUTE_RECOVER_PASSWORD_PAGE,
    element: <RecoverPasswordPage />,
  },
  {
    path: ROUTE_CHANGE_PASSWORD_PAGE,
    element: <ChangePasswordPage />,
  },
  {
    path: ROUTE_SIGN_IN_PAGE,
    element: (
      <AuthProviders>
        <SignInPage />
      </AuthProviders>
    ),
  },
  {
    path: ROUTE_PRIVACY_POLICY,
    element: (
      <AuthProviders>
        <SignInPage />
      </AuthProviders>
    ),
  },
  {
    path: ROUTE_TERMS_CONDITIONS,
    element: (
      <AuthProviders>
        <SignInPage />
      </AuthProviders>
    ),
  },
];

export const UnAuthorizedRoutes = () => (
  <Routes>
    {publicRoutesMap.map(({ path, element }) => (
      <Route key={path} path={path} element={element} />
    ))}
  </Routes>
);

export const AppRoutes: FC = () => (
  <Routes>
    <Route path="/" element={<Navigate to={ROUTE_INDEX_PAGE} replace />} />
    <Route path={ROUTE_INDEX_PAGE} element={<DashboardPage />} />
    <Route path={ROUTE_PROJECTS_PAGE} element={<ProjectsOverviewPage />} />
    <Route path={ROUTE_PROJECTS_TIMELINE_PAGE} element={<ProjectsTimelinePage />} />
    <Route path={ROUTE_PROJECTS_PROJECT_PAGE} element={<ProjectsProjectPage />} />
    <Route path={ROUTE_TASKS_PAGE} element={<TasksPage />} />
    <Route path={ROUTE_DOCUMENTS_PAGE} element={<DocumentsPage />} />
    <Route path={ROUTE_INVOICING_PAGE} element={<InvoicingPage />} />
    <Route path={ROUTE_CALENDAR_PAGE} element={<CalendarPage />} />
    <Route path={ROUTE_SETTINGS_PAGE} element={<SettingsPage />} />
    <Route path={ROUTE_SETTINGS_TEAM_PAGE} element={<SettingsTeamPage />} />
    <Route path={ROUTE_SEARCH_PAGE} element={<SearchPage />} />
    <Route path={ROUTE_PROJECTS_CREATE_PROJECT_PHASES_PAGE} element={<ProjectsCreateProjectStep2 />} />
    <Route path={ROUTE_PROJECTS_CREATE_PROJECT_MODAL} element={<ProjectsOverviewPage />} />
    <Route path="/test" element={<Test />} />
    <Route path="/error" element={<ErrorPage />} />

    {publicRoutesMap.map(({ path, element }) => (
      <Route key={path} path={path} element={element} />
    ))}

    {/* eslint-disable-next-line react/no-unescaped-entities */}
    <Route path="*" element={<p>There's nothing here: 404!</p>} />
  </Routes>
);
