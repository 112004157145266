import {
  DocumentContract,
  FileContract,
  FilterContract,
  SortingOrderEnumContract,
} from '@moonpanda/moonpanda.contracts';
import classNames from 'classnames';
import { AddOperation } from 'fast-json-patch/module/core';
import React, { FC, useCallback, useMemo, useRef, useState } from 'react';
import { writeText } from 'clipboard-polyfill';

import { apiDeleteDocument, apiGetDocumentsList } from 'src/api/documents';
import { apiUpdateProjectData } from 'src/api/projects';
import { UiDeleteConfirmPopover } from 'src/components/UI/DeleteConfirmPopover';
import { UiScrollable } from 'src/components/UI/InfiniteScroll';
import { UiCheckbox } from 'src/components/UI/inputs/Checkbox';
import { UiDropdownProps } from 'src/components/UI/inputs/DropDown';
import { getIconByName, UiFileUploader } from 'src/components/UI/inputs/FileUploader';
import { UiProjectsDropdown } from 'src/components/UI/ProjectsDropdown';
import { UiSearchInput } from 'src/components/UI/Search';
import { UiTable } from 'src/components/UI/table';
import { UiTableInnerFiltersType, UiTableRefProps } from 'src/components/UI/table/helpers';
import { UiToast } from 'src/components/UI/toasts';
import { UiButton } from 'src/components/UI/UiButton';
import { APP_HIDDEN_COMPONENTS, DEFAULT_TABLE_PAGE_SIZE, SHORT_DATE_FORMAT } from 'src/config';
import { useGrid } from 'src/hooks/useGrid';
import { useRequest } from 'src/hooks/useRequest';
import { DocumentsPageDownloadFileButton } from 'src/components/DownloadButton';
import { DocumentsPagePreviewFileButton } from 'src/components/PreviewButton';
import { IconCopyLink } from 'src/components/icons/CopyLink';
import { IconDelete } from 'src/components/icons/Delete';
import { IconForward } from 'src/components/icons/Forward';
import { getRouteProjectViewPage } from 'src/routes/paths';
import useModalsStore from 'src/store/modals';
import { CDate } from 'src/utils/CDate';
import { showErrorToast } from 'src/utils/errors';
import { prepareDataToPatch } from 'src/utils/http';
import { useTHelper } from 'src/utils/i18n';
import { useStateForm } from 'src/utils/stateForm';
import { useStateFormValueWatch } from 'src/utils/stateForm/useFormWatch/useStateFormValueWatch';

import styles from './styles.module.scss';

type FormValues = {
  check_: string | undefined;
};

export const DocumentsPage: FC = () => {
  const tHelper = useTHelper('pages.documents');

  const formProps = useStateForm<FormValues>();

  const checked = useStateFormValueWatch(formProps.getSubscribeProps, '');

  const checkedIds = useMemo(
    () =>
      Object.entries(checked)
        .filter(([, v]) => Boolean(v))
        .map(([key]) => +key.split('_')[1]),
    [checked],
  );

  const [projectIdSelected, setProjectIdSelected] = useState<number | null>(null);

  const [search, setSearch] = useState<string | null>(null);

  const [uploading, setUploading] = useState(false);

  const { loading, data, load, loadNext, updateAllData } = useGrid<DocumentContract>({
    apiFn: apiGetDocumentsList,
    initialLoad: false,
    pageSize: DEFAULT_TABLE_PAGE_SIZE,
  });

  const { apiCaller } = useRequest();

  const toggleModals = useModalsStore((state) => state.toggleModals);

  const fileInputRef = useRef<HTMLInputElement>(null);

  const tableRef = useRef<UiTableRefProps>(null);

  const projectIdRef = useRef<number | null>(null);

  const onTableGetData = useCallback(
    (tableData: UiTableInnerFiltersType) => {
      const filters: FilterContract[] = [];

      if (projectIdSelected) {
        filters.push({ propertyName: 'projectId', operation: 'Equals', value: projectIdSelected.toString() });
      }

      if (search) {
        filters.push({ propertyName: 'downloadInfo.fileName', operation: 'IndexOf', value: search });
      }

      load({
        page: 0,
        pageSize: DEFAULT_TABLE_PAGE_SIZE,
        orderBy: tableData.propertyName
          ? [{ propertyName: tableData.propertyName, sortingOrder: tableData.sortingOrder }]
          : undefined,
        filters,
      });
    },
    [load, projectIdSelected, search],
  );

  const getData = useCallback(() => {
    loadNext();
  }, [loadNext]);

  const columns = useMemo(
    () => [
      {
        title: tHelper('columns.name'),
        renderValue: ({ downloadInfo }: DocumentContract) => (
          <span title={downloadInfo.fileName}>
            {getIconByName(downloadInfo.fileName)}
            {downloadInfo.fileName}
          </span>
        ),
        sortPropertyName: 'downloadInfo.fileName',
        className: classNames(styles.columnName),
      },
      {
        title: tHelper('columns.owner'),
        renderValue: ({ ownerName }: DocumentContract) => <span title={ownerName}>{ownerName}</span>,
        sortPropertyName: 'ownerName',
        className: classNames(styles.columnOwner),
      },
      {
        title: tHelper('columns.lastModified'),
        renderValue: ({ lastModified }: DocumentContract) => CDate.format(new Date(lastModified), SHORT_DATE_FORMAT),
        sortPropertyName: 'lastModified',
      },
      {
        title: tHelper('columns.fileSize'),
        renderValue: ({ fileSizeMb }: DocumentContract) =>
          tHelper('columns.fileSizeMb', {
            size: fileSizeMb,
          }),
        sortPropertyName: 'fileSizeMb',
      },
      {
        title: tHelper('columns.projectName'),
        renderValue: ({ projectName, projectId }: DocumentContract) =>
          projectId ? (
            <a title={projectName} href={`${getRouteProjectViewPage(projectId)}`} target="_blank" rel="noreferrer">
              {projectName}
            </a>
          ) : (
            ''
          ),
        sortPropertyName: 'projectName',
        defaultSortDirection: 'Desc' as SortingOrderEnumContract,
        className: classNames(styles.columnProject),
      },
      {
        title: tHelper('columns.taskName'),
        renderValue: ({ taskName, taskId }: DocumentContract) =>
          taskId ? (
            <span
              role="presentation"
              title={taskName}
              onClick={() => {
                toggleModals('viewTask', true, {
                  taskId,
                });
              }}
            >
              {taskName}
            </span>
          ) : (
            ''
          ),
        sortPropertyName: 'taskName',
        className: classNames(styles.columnTask),
      },
      {
        title: tHelper('columns.phaseName'),
        renderValue: ({ phaseName }: DocumentContract) => phaseName,
        sortPropertyName: 'phaseName',
        className: styles.columnPhase,
        thClassName: styles.columnPhase,
      },
    ],
    [tHelper, toggleModals],
  );

  const additionalLeftColumn = useMemo(
    () => ({
      renderValue: ({ downloadInfo }: DocumentContract) => (
        <UiCheckbox formProps={formProps} name={`check_${downloadInfo.id}`} />
      ),
    }),
    [formProps],
  );

  const additionalRightColumn = useMemo(
    () => ({
      renderValue: ({ downloadInfo }: DocumentContract) => {
        const isDelete = checkedIds.includes(downloadInfo.id);

        return (
          <div className={isDelete ? styles.actionDelete : styles.actions}>
            {isDelete ? (
              <UiDeleteConfirmPopover
                placement="bottom"
                text={
                  checkedIds.length === 1
                    ? undefined
                    : tHelper('deleteMultipleSure', {
                        number: checkedIds.length,
                      })
                }
                onConfirm={() => {
                  Promise.all(
                    checkedIds.map((id) =>
                      apiCaller(
                        apiDeleteDocument,
                        {
                          documentId: id,
                        },
                        { key: id },
                      ),
                    ),
                  )
                    .then(() => {
                      UiToast.commonSuccessDeleted();

                      checkedIds.forEach((id) => {
                        formProps.setValue(`check_${id}` as 'check_', null);
                      });

                      updateAllData((storeData) =>
                        storeData.filter((doc) => !checkedIds.includes(doc.downloadInfo.id)),
                      );
                    })
                    .catch(showErrorToast);
                }}
              >
                <IconDelete />
              </UiDeleteConfirmPopover>
            ) : (
              <>
                <DocumentsPageDownloadFileButton file={downloadInfo} />

                <DocumentsPagePreviewFileButton file={downloadInfo} />

                {!APP_HIDDEN_COMPONENTS.DOCUMENTS_SEND_BUTTON_HIDDEN && (
                  <IconForward
                    onClick={() => {
                      // eslint-disable-next-line no-console
                      console.log('TODO forward', downloadInfo);
                    }}
                  />
                )}
                <IconCopyLink
                  onClick={() => {
                    writeText(downloadInfo.url).then(() => {
                      UiToast.success(tHelper('linkCopied'));
                    });
                  }}
                />
              </>
            )}
          </div>
        );
      },
    }),
    [checkedIds, tHelper, apiCaller, updateAllData, formProps],
  );

  const onProjectChange = useCallback((value: UiDropdownProps['options'][0] | null) => {
    setProjectIdSelected(value?.value as number | null);
    projectIdRef.current = value?.value as number | null;
  }, []);

  return (
    <div className="bodyPadding">
      <div className={styles.top}>
        <UiSearchInput
          placeholder={tHelper('search')}
          onSearch={setSearch}
          onClear={() => {
            setSearch(null);
          }}
        />
        <UiProjectsDropdown onChange={onProjectChange} placeholder={tHelper('projectsDropdownPlaceholder')} />
        <UiButton
          color="green"
          onClick={() => {
            fileInputRef.current?.click();
          }}
          loading={uploading}
        >
          {tHelper('upload')}
        </UiButton>
      </div>

      <UiScrollable data={data} getData={getData} loading={loading}>
        <UiTable
          data={data}
          getData={onTableGetData}
          columns={columns}
          additionalXRightColumn={additionalRightColumn}
          additionalXLeftColumn={additionalLeftColumn}
          appearance="table-striped table-hover"
          className={styles.table}
          itemKeyProp="downloadInfo.id"
          ref={tableRef}
        />
      </UiScrollable>

      <UiFileUploader
        formProps={formProps}
        name="files"
        ref={fileInputRef}
        className="d-none"
        onStartUpload={() => {
          setUploading(true);
        }}
        onFinishUpload={() => {
          setUploading(false);
        }}
        onUpload={async (_, loadedFiles) => {
          if (projectIdRef.current) {
            const op: AddOperation<FileContract[]>[] = [
              {
                op: 'add',
                path: '/attachments',
                value: loadedFiles,
              },
            ];

            await apiCaller(
              apiUpdateProjectData,
              prepareDataToPatch(op, {
                projectId: projectIdRef.current,
              }),
            ).catch(showErrorToast);
          }

          // reload all data
          if (tableRef.current?.getFilters) {
            onTableGetData(tableRef.current.getFilters() as UiTableInnerFiltersType);
          }
        }}
      />
    </div>
  );
};
