import { TeamMemberContract, UserContract } from '@moonpanda/moonpanda.contracts';

import { ProjectsAddTeamMembersLocalUserType } from 'src/components/common/modals/AddTeamMembersToProject/component';

export const mergerUsers = (
  accountUsers: UserContract[],
  projectTeamUsers: TeamMemberContract[],
): ProjectsAddTeamMembersLocalUserType[] => {
  const projectMap = new Map<number, number>();

  let index = 0;

  let accId = -1;

  const result: ProjectsAddTeamMembersLocalUserType[] = [];

  // every user from project team has to be checked
  projectTeamUsers.forEach((user, i) => {
    index = i;

    projectMap.set(user.userId, user.hourlyRate);

    result.push({
      id: user.id,
      userId: user.userId,
      firstName: '',
      lastName: '',
      title: '',
      email: user.userId === 0 ? user.name : user.email,
      name: user.name,
      index,
      selected: true,
      baseRate: user.hourlyRate,
      ignoreInvite: true,
    });
  });

  accountUsers.forEach((user) => {
    if (projectMap.has(user.userId)) {
      // check existing data

      const userIndex = result.findIndex(({ userId }) => userId === user.userId);

      if (~userIndex) {
        result[userIndex].baseRate = result[userIndex].baseRate || user.baseRate;
        result[userIndex].firstName = result[userIndex].firstName || user.firstName.trim();
        result[userIndex].lastName = result[userIndex].lastName || user.lastName;
      }
    } else {
      index += 1;

      result.push({
        id: accId,
        userId: user.userId,
        firstName: user.firstName.trim(),
        lastName: user.lastName.trim(),
        title: '',
        email: user.email,
        ignoreInvite: true,
        name: `${user.firstName.trim()} ${user.lastName.trim()}`,
        index,
        selected: false,
        baseRate: projectMap.get(user.userId) || user.baseRate,
      });

      accId -= 1;
    }
  });

  return result;
};
