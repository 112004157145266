/* eslint-disable max-len */
import React, { FC } from 'react';

export const IconProjects: FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="23" viewBox="0 0 22 23" fill="none">
    <path
      d="M11.1508 22.5906C17.1431 22.5906 22.0008 17.7329 22.0008 11.7406C22.0008 5.74831 17.1431 0.890625 11.1508 0.890625C5.1585 0.890625 0.300781 5.74831 0.300781 11.7406C0.300781 17.7329 5.1585 22.5906 11.1508 22.5906Z"
      fill="#3F5B64"
    />
    <path
      d="M5.41992 4.88086H12.2099V7.85086H5.41992V4.88086ZM5.41992 10.1909H18.9999V13.1609H5.41992V10.1909ZM5.41992 15.5009H14.7599V18.4709H5.41992V15.5009Z"
      fill="white"
    />
  </svg>
);
