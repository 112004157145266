import classNames from 'classnames';
import { endOfWeek, startOfWeek } from 'date-fns';
import React, { FC, useState } from 'react';

import { UiButton } from 'src/components/UI/UiButton';
import { UiWidget } from 'src/components/UI/widgets';
import useDashboardStore from 'src/pages/Dashboard/store';
import { IconArrow } from 'src/pages/Dashboard/widgets/timeTracking/icons/Arrow';
import { IconExport } from 'src/pages/Dashboard/widgets/timeTracking/icons/Export';
import { WidgetTimeTrackingModal } from 'src/pages/Dashboard/widgets/timeTracking/modal';
import { CDate } from 'src/utils/CDate';
import { numberToTimeObject } from 'src/utils/dateTime';
import { useTHelper } from 'src/utils/i18n';

import styles from './styles.module.scss';

export const DashboardWidgetTimeTracking: FC = () => {
  const tHelper = useTHelper('widgets.timeTracking');

  const [showModal, setShowModal] = useState(false);

  const { widgetTimeTrackingData, loading, currentWeek, setWidgetTimeTrackingDataWeek } = useDashboardStore(
    (state) => ({
      widgetTimeTrackingData: state.widgetTimeTrackingData.response,
      loading: state.widgetTimeTrackingData.loading,
      currentWeek: state.widgetTimeTrackingWeek,
      setWidgetTimeTrackingDataWeek: state.setWidgetTimeTrackingDataWeek,
    }),
  );

  const getTimeFormatted = (time: number | undefined) => {
    const times = numberToTimeObject(time || 0);

    return `${times.hours}.${times.minutes}`;
  };

  return (
    <div className={styles.widgetWrapper}>
      <div className={styles.top}>
        <div className={styles.header}>{tHelper('header')}</div>
      </div>

      <UiWidget loading={loading} className={styles.wrapper}>
        <div className={styles.week}>
          <IconArrow
            className={classNames(styles.arrow, styles.arrowLeft)}
            onClick={() => {
              setWidgetTimeTrackingDataWeek(
                CDate.addTime(currentWeek, {
                  days: -7,
                }),
              );
            }}
          />
          {tHelper('week', {
            start: CDate.format(startOfWeek(currentWeek), 'MMM d'),
            end: CDate.format(endOfWeek(currentWeek), 'MMM d'),
          })}
          <IconArrow
            className={styles.arrow}
            onClick={() => {
              setWidgetTimeTrackingDataWeek(
                CDate.addTime(currentWeek, {
                  days: 7,
                }),
              );
            }}
          />
        </div>

        <div className={styles.info}>
          <div className={styles.infoBlock}>
            <div className={styles.value}>
              {tHelper('time', { time: getTimeFormatted(widgetTimeTrackingData?.billable) })}
            </div>
            <div className={styles.text}>{tHelper('billable')}</div>
          </div>
          <div className={styles.infoBlock}>
            <div className={styles.value}>
              {tHelper('time', { time: getTimeFormatted(widgetTimeTrackingData?.nonBillable) })}
            </div>
            <div className={styles.text}>{tHelper('nonBillable')}</div>
          </div>
        </div>

        <div />

        <UiButton
          className={styles.button}
          onClick={() => {
            setShowModal(true);
          }}
        >
          <IconExport />
        </UiButton>

        {showModal && (
          <WidgetTimeTrackingModal
            currentWeek={currentWeek}
            onClose={() => {
              setShowModal(false);
            }}
          />
        )}
      </UiWidget>
    </div>
  );
};
