/* eslint-disable max-len */
import { FC } from 'react';

export const IconFileUploader: FC = () => (
  <svg width="15" height="20" viewBox="0 0 15 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      id="Vector"
      d="M5.84995 19.0502C4.49995 19.0502 3.33994 18.5803 2.37994 17.6403C1.41994 16.7003 0.939941 15.5603 0.939941 14.2003V3.95026C0.939941 3.00026 1.27993 2.19025 1.95993 1.51025C2.63993 0.830254 3.45995 0.490234 4.41995 0.490234C5.37995 0.490234 6.20994 0.830254 6.87994 1.51025C7.55994 2.19025 7.89993 3.01021 7.89993 3.97021V11.9102H7.12994V3.96027C7.12994 3.21027 6.86994 2.57023 6.33994 2.05023C5.81994 1.53023 5.17995 1.27026 4.41995 1.27026C3.65995 1.27026 3.01993 1.53023 2.48993 2.05023C1.95993 2.57023 1.69995 3.21027 1.69995 3.96027V14.2502C1.69995 15.3802 2.10995 16.3302 2.91995 17.1202C3.72995 17.9002 4.70994 18.2902 5.83994 18.2902C6.39994 18.2902 6.92993 18.1902 7.42993 17.9802C7.92993 17.7702 8.35993 17.4902 8.73993 17.1302V18.1403C8.31993 18.4303 7.86995 18.6603 7.38995 18.8203C6.90995 18.9803 6.38995 19.0602 5.84995 19.0602V19.0502ZM10.45 17.8203V15.0203H7.64993V13.9603H10.45V11.1602H11.5099V13.9603H14.3099V15.0203H11.5099V17.8203H10.45ZM5.84995 14.6002V15.3702C5.26995 15.3702 4.77995 15.1602 4.38995 14.7302C3.98995 14.3002 3.79993 13.8003 3.79993 13.2103V3.92023H4.56995V13.2603C4.56995 13.6303 4.68994 13.9503 4.93994 14.2103C5.18994 14.4703 5.48994 14.6002 5.84995 14.6002ZM9.98993 9.06024V3.92023H10.7599V9.06024H9.98993Z"
      fill="#3F5B64"
    />
  </svg>
);
