import { TaskContract } from '@moonpanda/moonpanda.contracts';
import cloneDeep from 'lodash/cloneDeep';
import React, { FC } from 'react';

import {
  parseDateFromUiDatePicker,
  prepareSetDateToUiDatePicker,
  UiDatePicker,
} from 'src/components/UI/inputs/DatePicker';
import { UiPopover } from 'src/components/UI/Popover';
import { CDate } from 'src/utils/CDate';
import { useStateForm } from 'src/utils/stateForm';

type Props = {
  model: TaskContract;
  updateTaskAPI: (subject: TaskContract, newSubject: TaskContract) => void;
};

type FormValues = {
  dueDate: string;
};

export const TasksDueDateChangeComponent: FC<Props> = ({ model, updateTaskAPI }) => {
  const formProps = useStateForm<FormValues>({
    defaultValues: {
      dueDate: prepareSetDateToUiDatePicker(model.dueDate),
    },
  });

  return (
    <UiPopover
      placement="top"
      customClose
      /* eslint-disable-next-line react/no-unstable-nested-components */
      content={(close) => (
        <UiDatePicker
          formProps={formProps}
          name="dueDate"
          autoFocus
          wrapperClassName="mb-0"
          onChange={(value) => {
            close();

            if (value) {
              const newSubject = cloneDeep(model);

              newSubject.dueDate = parseDateFromUiDatePicker(value);

              updateTaskAPI(model, newSubject);
            }
          }}
        />
      )}
    >
      {CDate.format(new Date(model.dueDate), 'MM/dd/yy')}
    </UiPopover>
  );
};
