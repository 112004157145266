import classNames from 'classnames';
import flatpickr from 'flatpickr';
import { DateOption } from 'flatpickr/dist/types/options';
import isArray from 'lodash/isArray';
import React, { memo, useCallback, useEffect, useMemo, useRef } from 'react';
import Flatpickr from 'react-flatpickr';
import { useTranslation } from 'react-i18next';
import MaskedInput from 'react-text-mask';
import isFunction from 'lodash/isFunction';

import { UiCommonInputLabel, UiCommonInputWrapper } from 'src/components/UI/inputs/common/html';
import { useIdOrRandomString } from 'src/utils/formHelper';
import { getStringFromUiLabel } from 'src/utils/reactDom';
import { SafeAnyType } from 'src/utils/safeAny';
import { StateFormInputOptionsType, StateFormReturnType } from 'src/utils/stateForm';
import { useInputBaseHook } from 'src/hooks/inputs';
import { maskedInputConvertMask } from 'src/components/UI/inputs/MaskedInput';

export type UiDateRangePickerProps = {
  formProps: StateFormReturnType;
  name: string;

  label?: string;
  required?: boolean;
  placeholder?: string;
  autoFocus?: boolean;
  disabled?: boolean;
  className?: string;
  readonly?: boolean;
  onChange?: (value: [string, string] | null) => void;
  maxDate?: string | number;
  errorLabel?: string;
  classNameLabel?: string;
  wrapperClassName?: string;
};

export const UiDateRangePicker = memo<UiDateRangePickerProps>(
  ({
    formProps: { register, unregister, getSubscribeProps, onChange: formOnChange, getValue },
    required,
    name,
    disabled,
    className,
    placeholder = 'mm/dd/yyyy - mm/dd/yyyy',
    autoFocus,
    readonly,
    onChange: onChangeDate,
    maxDate,
    errorLabel,
    label,
    classNameLabel,
    wrapperClassName,
  }) => {
    const { t } = useTranslation();

    const id = useIdOrRandomString();

    const mountRef = useRef(false);

    useEffect(() => {
      mountRef.current = true;

      return () => {
        mountRef.current = false;
      };
    });

    const inputOptions: StateFormInputOptionsType = useMemo(
      () => ({ required, errorLabel: errorLabel || getStringFromUiLabel(label) || placeholder || undefined }),
      [errorLabel, label, placeholder, required],
    );

    const [value, errors] = useInputBaseHook({
      getSubscribeProps,
      name,
      inputOptions,
      register,
      unregister,
      type: 'rangeDatepicker',
    });

    const hasErrors = !!errors?.length;

    const replaceValue = useCallback((el: SafeAnyType) => {
      el.value = el.value.replace('to', '-');
    }, []);

    /** prepare the value */
    const getPreparedValue = useCallback((val: Date | string | null): string | undefined => {
      if (val && isArray(val)) {
        return `${val[0]} - ${val[1]}`;
      }

      // must return an empty string so that the value is changed correctly via setValue
      return '';
    }, []);

    const ref = useRef<Flatpickr>(null);

    const selectDates = useCallback(
      (strDate: string) => {
        let selectedDates = strDate ? (strDate.split('to').map((date) => date.trim()) as [string, string]) : null;

        // the same date picked twice
        selectedDates = selectedDates
          ? [selectedDates[0], selectedDates[1] ? selectedDates[1] : selectedDates[0]]
          : null;

        const isChangedDate = selectedDates !== getValue(name);

        if (
          isChangedDate &&
          !disabled &&
          !readonly &&
          (selectedDates === null || !(selectedDates[0].includes('-') && selectedDates[1].includes('-')))
        ) {
          formOnChange(name, selectedDates);

          if (isFunction(onChangeDate)) {
            onChangeDate(selectedDates);
          }

          if (selectedDates) {
            setTimeout(() => {
              ref.current?.flatpickr.setDate(selectedDates as DateOption[]);

              replaceValue(ref.current?.flatpickr.element as SafeAnyType);
            }, 10);
          }
        }
      },
      [disabled, formOnChange, getValue, name, onChangeDate, readonly, replaceValue],
    );

    const inputRef = useRef<HTMLInputElement>();

    /** memoize the options */
    const options = useMemo(
      (): flatpickr.Options.Options => ({
        monthSelectorType: 'dropdown',
        dateFormat: 'm/d/Y', // 'm/d/Y h:i K' with time
        maxDate,
        shorthandCurrentMonth: true,
        showMonths: 1,
        mode: 'range',
        defaultDate: getValue(name),
        onReady: (_, b, { element }) => {
          if (getValue(name)) {
            setTimeout(() => {
              replaceValue(element);
            }, 10);
          }

          // `!mountRef.current` for ignoring opening later. it happens just in the beginning
          if (!mountRef.current && autoFocus && !disabled) {
            // open it just a little bit later
            setTimeout(() => {
              inputRef.current?.click();
            }, 50);
          }
        },
        onChange: (dates: Date[], strDate: string) => {
          if (dates.length === 2) {
            selectDates(strDate);
          }
        },
        onClose: (_, strDate) => {
          selectDates(strDate);
        },
      }),
      // don't depend on value. take it once at start
      // eslint-disable-next-line
      [maxDate, getPreparedValue, autoFocus, disabled, t, formOnChange, name, getValue, selectDates],
    );

    /** m/d/y */
    const arrMask = useMemo(() => maskedInputConvertMask('19/39/9999 - 19/39/9999'), []);

    return (
      <UiCommonInputWrapper className={wrapperClassName}>
        <UiCommonInputLabel id={id} show={!!label} hasErrors={hasErrors} className={classNameLabel}>
          {label}
        </UiCommonInputLabel>

        <Flatpickr
          key={value}
          ref={ref}
          options={options as SafeAnyType} // types?
          render={(_, refPicker) => (
            <MaskedInput
              mask={arrMask}
              disabled={disabled}
              placeholder={placeholder}
              value={getPreparedValue(value)}
              readOnly={readonly}
              render={(refInput, props) => (
                <input
                  ref={(element) => {
                    if (element) {
                      refPicker(element);
                      refInput(element);
                      inputRef.current = element;
                    }
                  }}
                  /* eslint-disable-next-line react/jsx-props-no-spreading */
                  {...props}
                  id={id}
                  className={classNames('UiInput datepicker', className, {
                    required: hasErrors && required,
                    error: hasErrors,
                    disabled,
                  })}
                />
              )}
            />
          )}
        />
      </UiCommonInputWrapper>
    );
  },
);
