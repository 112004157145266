import { FileContract } from '@moonpanda/moonpanda.contracts';
import classNames from 'classnames';
import React, { FC, useMemo, useRef, useState } from 'react';

import { apiUploadDocuments } from 'src/api/documents';
import { UiCommonInputLabel } from 'src/components/UI/inputs/common/html';
import { UiFileUploaderOnChangeValueType, UiFileUploaderProps } from 'src/components/UI/inputs/FileUploader';
import { useInputBaseHook } from 'src/hooks/inputs';
import { useRequest } from 'src/hooks/useRequest';
import { IconAvatar } from 'src/pages/SignUp/icons/Avatar';
import { showErrorToast } from 'src/utils/errors';
import { getStringFromUiLabel } from 'src/utils/reactDom';
import { StateFormInputOptionsType } from 'src/utils/stateForm';
import { useStateFormValueWatch } from 'src/utils/stateForm/useFormWatch/useStateFormValueWatch';

type Props = Omit<UiFileUploaderProps, 'pickIcon' | 'accept' | 'maxNumber' | 'privateStorage'>;

export const UiAvatarLoader: FC<Props> = ({ formProps, name, label, className, disabled, required, errorLabel }) => {
  const image: FileContract[] = useStateFormValueWatch(formProps.getSubscribeProps, name);

  const [loading, setLoading] = useState(false);

  const { apiCaller } = useRequest();

  const fileInputRef = useRef<HTMLInputElement>(null);

  const inputOptions = useMemo(
    (): StateFormInputOptionsType => ({
      required,
      errorLabel: errorLabel || getStringFromUiLabel(label) || undefined,
    }),
    [errorLabel, label, required],
  );

  const [, errors] = useInputBaseHook<UiFileUploaderOnChangeValueType | undefined>({
    getSubscribeProps: formProps.getSubscribeProps,
    name,
    inputOptions,
    unregister: formProps.unregister,
    register: formProps.register,
    type: 'file',
  });

  const hasErrors = !!errors?.length;

  const isButtonDisabled = !!(loading || disabled);

  return (
    <div className={classNames('UiInput-wrapper', 'fileUploader', 'avatarUploader', className)}>
      <UiCommonInputLabel id="" show={!!label} hasErrors={hasErrors}>
        {label}
      </UiCommonInputLabel>

      {image ? (
        <img
          role="presentation"
          className={classNames({
            'cursor-pointer': !disabled,
          })}
          alt=""
          src={image[0].url}
          onClick={() => {
            if (!disabled) {
              fileInputRef.current?.click();
            }
          }}
        />
      ) : (
        <button
          type="button"
          className={classNames('UiInput', {
            disabled: isButtonDisabled,
            required: hasErrors && required,
            error: hasErrors,
          })}
          onClick={() => fileInputRef.current?.click()}
          disabled={isButtonDisabled}
        >
          <IconAvatar />
        </button>
      )}

      <input
        onChange={({ target }) => {
          const files = target.files ? [...target.files] : [];

          if (files.length) {
            setLoading(true);

            apiCaller(apiUploadDocuments, {
              files,
              access: 'Public',
            })
              .then(({ data }) => {
                formProps.onChange(name, data);
              })
              .catch(showErrorToast)
              .finally(() => {
                setLoading(false);
              });
          }
        }}
        ref={fileInputRef}
        type="file"
        accept="image/jpeg,image/png"
        hidden
      />
    </div>
  );
};
