/* eslint-disable max-len */
import { FC } from 'react';
import { CommonIconProps } from 'src/utils/iconProps';

type Props = CommonIconProps & {
  onClick: () => void;
};

export const IconArrow: FC<Props> = ({ className, onClick }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="10"
    height="17"
    viewBox="0 0 10 17"
    fill="none"
    className={className}
    onClick={onClick}
  >
    <path
      d="M2.32031 2.02051L7.63031 8.66051L2.32031 15.3105"
      stroke="#455A63"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
