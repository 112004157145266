import { FileContract } from '@moonpanda/moonpanda.contracts';
import classNames from 'classnames';
import isFunction from 'lodash/isFunction';
import React, { forwardRef, MouseEvent, useImperativeHandle, useMemo, useRef, useState } from 'react';

import { apiDeleteDocument, apiUploadDocuments } from 'src/api/documents';
import { UiCommonInputLabel } from 'src/components/UI/inputs/common/html';
import { IconDOC } from 'src/components/UI/inputs/FileUploader/icons/DOC';
import { IconFileUploader } from 'src/components/UI/inputs/FileUploader/icons/Icon';
import { IconJPG } from 'src/components/UI/inputs/FileUploader/icons/JPG';
import { IconPDF } from 'src/components/UI/inputs/FileUploader/icons/PDF';
import { IconPNG } from 'src/components/UI/inputs/FileUploader/icons/PNG';
import { IconXLS } from 'src/components/UI/inputs/FileUploader/icons/XLS';
import { useInputBaseHook } from 'src/hooks/inputs';
import { useRequest } from 'src/hooks/useRequest';
import { showErrorToast } from 'src/utils/errors';
import { getMimeTypeByExtension, openFileInNewTab, useDownloadFile } from 'src/utils/file';
import { getStringFromUiLabel } from 'src/utils/reactDom';
import { StateFormInputOptionsType, StateFormReturnType } from 'src/utils/stateForm';
import { ChildrenPropType } from 'src/utils/types';
import { IconDelete } from 'src/components/UI/inputs/FileUploader/icons/Delete';

export type UiFileUploaderProps = {
  formProps: StateFormReturnType;
  name: string;

  privateStorage?: boolean;
  label?: string | React.ReactNode;
  required?: boolean;
  errorLabel?: string;
  className?: string;
  onStartUpload?: () => void;
  onFinishUpload?: () => void;
  onUpload?: (allFiles: UiFileUploaderOnChangeValueType, loadedFiles: UiFileUploaderOnChangeValueType) => void;
  pickIcon?: JSX.Element;
  accept?: string;
  maxNumber?: number;
  showFiles?: boolean;
  disabled?: boolean;
  renderProps?: (props: { onOpen: () => void; files: FileContract[] }) => ChildrenPropType;
  canRemove?: boolean;
};

export type UiFileUploaderOnChangeValueType = FileContract[];

export type UiFileUploaderRefType = { click: () => void };

const defaultAvailableFormats = ['pdf', 'csv', 'doc', 'docx', 'jpg', 'png', 'xls', 'xlsx'].map(getMimeTypeByExtension);

export const getIconByName = (name: string) => {
  const ext = name.split('.').pop();

  switch (ext) {
    case 'doc':
    case 'docx':
    case 'csv':
      return <IconDOC />;
    case 'xls':
    case 'xlsx':
      return <IconXLS />;
    case 'jpeg':
    case 'jpg':
      return <IconJPG />;
    case 'png':
      return <IconPNG />;
    default:
      return <IconPDF />;
  }
};

export const UiFileUploader = forwardRef<UiFileUploaderRefType, UiFileUploaderProps>(
  (
    {
      formProps,
      name,
      label,
      className,
      required,
      errorLabel,
      privateStorage = true,
      onStartUpload,
      onFinishUpload,
      onUpload,
      pickIcon,
      accept,
      maxNumber,
      showFiles = true,
      disabled = false,
      renderProps,
      canRemove = false,
    },
    ref,
  ) => {
    const fileInputRef = useRef<HTMLInputElement>(null);

    const [loading, setLoading] = useState(false);

    const inputOptions = useMemo(
      (): StateFormInputOptionsType => ({
        required,
        errorLabel: errorLabel || getStringFromUiLabel(label) || undefined,
      }),
      [errorLabel, label, required],
    );

    const [value, errors] = useInputBaseHook<UiFileUploaderOnChangeValueType | undefined>({
      getSubscribeProps: formProps.getSubscribeProps,
      name,
      inputOptions,
      unregister: formProps.unregister,
      register: formProps.register,
      type: 'file',
    });

    const hasErrors = !!errors?.length;

    const { apiCaller } = useRequest();

    useImperativeHandle(ref, () => ({ click: () => fileInputRef.current?.click() }));

    const isButtonDisabled = (() => {
      if (loading || disabled) {
        return true;
      }
      if (maxNumber) {
        return value?.length === maxNumber;
      }

      return false;
    })();

    const deleteFile = (documentId: FileContract['id']) => (event: MouseEvent) => {
      event.stopPropagation();
      setLoading(true);

      apiCaller(apiDeleteDocument, { documentId })
        .then(() => {
          const files = (formProps.getValue(name) as FileContract[]).filter((item) => item.id !== documentId);

          formProps.onChange(name, files);
        })
        .catch(showErrorToast)
        .finally(() => setLoading(false));
    };

    const { downLoad } = useDownloadFile();

    return (
      <>
        <div className={classNames('UiInput-wrapper', 'fileUploader', className)}>
          <UiCommonInputLabel id="" show={!!label} hasErrors={hasErrors}>
            {label}
          </UiCommonInputLabel>

          {renderProps ? (
            renderProps({ onOpen: () => fileInputRef.current?.click(), files: value || [] })
          ) : (
            <div className="fileGrid">
              <button
                type="button"
                className={classNames('UiInput', {
                  disabled: isButtonDisabled,
                  required: hasErrors && required,
                  error: hasErrors,
                })}
                onClick={() => fileInputRef.current?.click()}
                disabled={isButtonDisabled}
              >
                {pickIcon || <IconFileUploader />}
              </button>

              {showFiles &&
                value?.map((file) => (
                  <button
                    className={classNames('fileGridItem', { disabled: canRemove && loading })}
                    key={file.id}
                    title={file.fileName}
                    type="button"
                    onClick={() => {
                      downLoad(file).then((response) => {
                        if (response) {
                          openFileInNewTab(response, file.fileName);
                        }
                      });
                    }}
                  >
                    <div>{getIconByName(file.fileName)}</div>
                    <span className="fileGridItem_text">{file.fileName}</span>
                    {canRemove && (
                      <div>
                        <IconDelete className="fileGridItem_delete-icon" onClick={deleteFile(file.id)} />
                      </div>
                    )}
                  </button>
                ))}
            </div>
          )}
        </div>

        <input
          onChange={({ target }) => {
            const files = target.files ? [...target.files] : [];

            if (files.length) {
              setLoading(true);

              if (isFunction(onStartUpload)) {
                onStartUpload();
              }

              apiCaller(apiUploadDocuments, {
                files,
                access: privateStorage ? 'Private' : 'Public',
              })
                .then(({ data }) => {
                  const prevFiles: FileContract[] | undefined = formProps.getValue(name);

                  let files = prevFiles ? [...prevFiles, ...data] : data;

                  if (maxNumber && files.length > maxNumber) {
                    files = files.slice(files.length - 1);
                  }

                  formProps.onChange(name, files);

                  if (isFunction(onUpload)) {
                    onUpload(files, data);
                  }
                })
                .catch(showErrorToast)
                .finally(() => {
                  setLoading(false);

                  if (isFunction(onFinishUpload)) {
                    onFinishUpload();
                  }

                  target.value = '';
                });
            }
          }}
          multiple={maxNumber !== 1}
          ref={fileInputRef}
          type="file"
          accept={accept || defaultAvailableFormats.join(',')}
          hidden
        />
      </>
    );
  },
);
