import { ProfileDetailsContract } from '@moonpanda/moonpanda.contracts';
import { loadStripe } from '@stripe/stripe-js';
import { FC, useCallback } from 'react';

import { apiPaymentsBuySubscription } from 'src/api/account';
import { IconRocket } from 'src/components/common/modals/TrialEnded/icons/Rocket';
import { UiModal } from 'src/components/UI/Modals';
import { UiButton } from 'src/components/UI/UiButton';
import { STRIPE_PUBLISH_KEY } from 'src/config';
import { useOneRequest } from 'src/hooks/useOneRequest';
import useGlobalStore from 'src/store';
import { showErrorToast } from 'src/utils/errors';
import { useTHelper } from 'src/utils/i18n';

import styles from './styles.module.scss';

export const TrialEndedModal: FC = () => {
  const tHelper = useTHelper('modals.trialEnded');

  const user = useGlobalStore((state) => state.user.response as ProfileDetailsContract);

  const { apiCaller, loading } = useOneRequest(apiPaymentsBuySubscription);

  // useEffect(() => {
  //   stopApp(); // stop or whitelist the request below
  // }, []);

  const onStartTrialClick = useCallback(() => {
    apiCaller({
      userEmail: user.email,
      subscriptionType: 'Monthly',
    })
      .then((response) => {
        loadStripe(STRIPE_PUBLISH_KEY)
          .then((stripe) => stripe?.redirectToCheckout({ sessionId: response.data.sessionId }))
          .catch(showErrorToast);
      })
      .catch(showErrorToast);
  }, [apiCaller, user.email]);

  return (
    <UiModal onClose={() => null} contentClassName={styles.content} noClose>
      <div className={styles.bodyContent}>
        <h2>{user.subscriptionStatus === 'JustRegistered' ? tHelper('headerStartTrial') : tHelper('header')}</h2>
        <p>{user.subscriptionStatus === 'JustRegistered' ? tHelper('textStartTrial') : tHelper('text')}</p>
        <UiButton loading={loading} onClick={onStartTrialClick}>
          {user.subscriptionStatus === 'JustRegistered' ? tHelper('buttonStartTrial') : tHelper('button')}
          <IconRocket />
        </UiButton>
      </div>
    </UiModal>
  );
};
