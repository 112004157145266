/* eslint-disable max-len */
import { FC } from 'react';

export const IconTimeTrackingWidgetHeader: FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
    <path
      d="M12.2505 24.52C17.9004 24.52 22.4805 19.9174 22.4805 14.24C22.4805 8.56247 17.9004 3.95996 12.2505 3.95996C6.60064 3.95996 2.02051 8.56247 2.02051 14.24C2.02051 19.9174 6.60064 24.52 12.2505 24.52Z"
      fill="#428BC1"
    />
    <path
      d="M12.25 6.25C16.65 6.25 20.21 9.83 20.21 14.24C20.21 18.65 16.65 22.23 12.25 22.23C7.85004 22.23 4.29004 18.65 4.29004 14.24C4.29004 9.83 7.85004 6.25 12.25 6.25Z"
      fill="white"
    />
    <path
      d="M3.70051 19.3291L2.77051 24.8991H3.53051C4.00051 24.8991 4.18051 24.3391 4.49051 23.6491C4.88051 22.7691 5.89051 20.4291 5.89051 20.4291L3.71051 19.3291H3.70051Z"
      fill="#428BC1"
    />
    <path
      d="M20.7896 19.3291L21.7196 24.8991H20.9596C20.4896 24.8991 20.3096 24.3391 19.9996 23.6491C19.6096 22.7691 18.5996 20.4291 18.5996 20.4291L20.7796 19.3291H20.7896Z"
      fill="#428BC1"
    />
    <path d="M4.62035 3.85938L7.00035 6.26938L5.74035 7.53938L3.36035 5.12937L4.62035 3.85938Z" fill="#428BC1" />
    <path d="M19.88 3.85938L17.5 6.26938L18.76 7.53938L21.14 5.12937L19.88 3.85938Z" fill="#428BC1" />
    <path
      d="M1.53004 9.28953L8.71004 2.02953C7.79004 1.09953 6.52004 0.519531 5.11004 0.519531C2.31004 0.519531 0.0400391 2.81953 0.0400391 5.64953C0.0400391 7.06953 0.610039 8.35953 1.54004 9.28953H1.53004Z"
      fill="#B0B8B8"
    />
    <path
      d="M15.6799 2.05957L22.8999 9.26957C23.8299 8.34957 24.3999 7.06957 24.3999 5.65957C24.3999 2.83957 22.1099 0.55957 19.2899 0.55957C17.8799 0.55957 16.5899 1.12957 15.6699 2.05957H15.6799Z"
      fill="#B0B8B8"
    />
    <path d="M7.58008 18.6296L11.7801 14.3896L12.2501 14.8596L8.06007 19.0996L7.58008 18.6296Z" fill="#ED4C5C" />
    <path
      d="M12.2505 12.7197C13.0905 12.7197 13.7705 13.3997 13.7705 14.2397C13.7705 15.0797 13.0905 15.7597 12.2505 15.7597C11.4105 15.7597 10.7305 15.0797 10.7305 14.2397C10.7305 13.3997 11.4105 12.7197 12.2505 12.7197Z"
      fill="#428BC1"
    />
    <path d="M13.0102 8.5293H11.4902V14.6193H13.0102V8.5293Z" fill="#428BC1" />
    <path d="M16.0398 13.4795H13.0098V14.9995H16.0398V13.4795Z" fill="#428BC1" />
  </svg>
);
