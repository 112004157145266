/* eslint-disable max-len */
import React, { FC } from 'react';

export const IconContacts: FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="23" viewBox="0 0 22 23" fill="none">
    <path
      d="M4.23999 17.32C5.16999 16.6 6.22 16.04 7.37 15.63C8.51999 15.22 9.74 15.01 11 15.01C12.26 15.01 13.48 15.22 14.63 15.63C15.78 16.04 16.83 16.61 17.76 17.32C18.4 16.57 18.9 15.72 19.26 14.76C19.62 13.8 19.8 12.79 19.8 11.71C19.8 9.26996 18.94 7.19998 17.23 5.47998C15.52 3.75998 13.44 2.90997 11 2.90997C8.56 2.90997 6.48999 3.76998 4.76999 5.47998C3.05999 7.18998 2.20001 9.26996 2.20001 11.71C2.20001 12.79 2.37999 13.81 2.73999 14.76C3.09999 15.71 3.59999 16.57 4.23999 17.32ZM11 12.81C9.92 12.81 9.01001 12.44 8.26001 11.7C7.51001 10.96 7.14999 10.05 7.14999 8.95996C7.14999 7.86996 7.52001 6.96997 8.26001 6.21997C9.00001 5.46997 9.91 5.10995 11 5.10995C12.09 5.10995 12.99 5.47997 13.74 6.21997C14.49 6.95997 14.85 7.86996 14.85 8.95996C14.85 10.05 14.48 10.95 13.74 11.7C13 12.45 12.09 12.81 11 12.81ZM11 22.71C9.48 22.71 8.04999 22.42 6.70999 21.84C5.36999 21.26 4.21 20.48 3.22 19.49C2.23 18.5 1.45 17.34 0.869995 16C0.289995 14.66 0 13.23 0 11.71C0 10.19 0.289995 8.75998 0.869995 7.41998C1.45 6.07998 2.23 4.91996 3.22 3.92996C4.21 2.93996 5.36999 2.15996 6.70999 1.57996C8.04999 0.999956 9.48 0.709961 11 0.709961C12.52 0.709961 13.95 0.999956 15.29 1.57996C16.63 2.15996 17.79 2.93996 18.78 3.92996C19.77 4.91996 20.55 6.07998 21.13 7.41998C21.71 8.75998 22 10.19 22 11.71C22 13.23 21.71 14.66 21.13 16C20.55 17.34 19.77 18.5 18.78 19.49C17.79 20.48 16.63 21.26 15.29 21.84C13.95 22.42 12.52 22.71 11 22.71Z"
      fill="#3F5B64"
    />
  </svg>
);
