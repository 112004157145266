import isFunction from 'lodash/isFunction';
import React, { FC, useCallback, useMemo } from 'react';
import { UiCommonInputLabel, UiCommonInputWrapper } from 'src/components/UI/inputs/common/html';

import { InputTags, InputTagsProps } from 'src/components/UI/inputs/TagInput/base';
import { useInputBaseHook } from 'src/hooks/inputs';
import { useIdOrRandomString } from 'src/utils/formHelper';
import { SafeAnyType } from 'src/utils/safeAny';
import { StateFormInputOptionsType, StateFormReturnType } from 'src/utils/stateForm';

type Props = {
  formProps: StateFormReturnType;
  name: string;

  label?: string;
  disabled?: boolean;
  required?: boolean;
  errorLabel?: string;
  emailMode?: boolean;
  onAdd?: (value: string) => void;
  onRemove?: (value: string) => void;
};

export const UiTaginput: FC<Props> = ({
  label,
  formProps: { register, setValue, unregister, getSubscribeProps },
  name,
  required,
  errorLabel,
  disabled,
  emailMode,
  onAdd,
  onRemove,
}) => {
  const inputOptions = useMemo(
    (): StateFormInputOptionsType => ({
      required,
      errorLabel: errorLabel || undefined,
    }),
    [errorLabel, required],
  );

  const [value, errors] = useInputBaseHook<string | number | null | undefined>({
    getSubscribeProps,
    name,
    inputOptions,
    unregister,
    register,
    type: 'tags',
  });

  const hasErrors = !!errors?.length;

  const onChange: InputTagsProps['onChange'] = useCallback(
    (values, action, addedOrRemovedValue) => {
      let changeState = true;

      if (action === 'remove' && isFunction(onRemove)) {
        onRemove(addedOrRemovedValue);
      } else if (action === 'add' && isFunction(onAdd)) {
        onAdd(addedOrRemovedValue);
      } else if (action === 'init') {
        changeState = false;
      }

      if (changeState) {
        setValue(
          name,
          values.map(({ value }) => value),
        );
      }

      // if (~values.findIndex(({ isCorrect }) => !isCorrect)) {
      //   setError(name, {
      //     type: 'manual',
      //     message: t('common.validation.emailInvalidTagInput'),
      //   });
      // } else if (errorRef.current) {
      //   clearErrors(name);
      // }
    },
    [name, onAdd, onRemove, setValue],
  );

  const id = useIdOrRandomString();

  return (
    <UiCommonInputWrapper className="r-tags-input-wrapper">
      <UiCommonInputLabel id={id} show={!!label} hasErrors={hasErrors} required={required} disabled={disabled}>
        {label}
      </UiCommonInputLabel>
      <InputTags
        values={value as string[] | undefined}
        onChange={onChange as SafeAnyType}
        disabled={disabled}
        error={hasErrors}
        emailMode={emailMode}
        label={label}
      />
    </UiCommonInputWrapper>
  );
};
