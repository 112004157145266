/* eslint-disable max-len */
import { FC } from 'react';

export const IconDocuments: FC = () => (
  <svg width="32" height="24" viewBox="0 0 32 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.89977 23.3198C5.87977 23.3198 4.13977 22.5898 2.68977 21.1398C1.22977 19.6798 0.509766 17.9498 0.509766 15.9298C0.509766 14.0998 1.08977 12.4898 2.24977 11.0898C3.40977 9.68981 4.90977 8.84982 6.73977 8.56982C7.20976 6.28982 8.30976 4.42981 10.0498 2.98981C11.7898 1.54981 13.7898 0.819824 16.0698 0.819824C18.7198 0.819824 20.9498 1.77982 22.7398 3.68982C24.5398 5.59982 25.4298 7.89982 25.4298 10.5698V11.4198C27.1198 11.3698 28.5498 11.9198 29.7298 13.0598C30.8998 14.1998 31.4898 15.6398 31.4898 17.3698C31.4898 18.9898 30.8998 20.3898 29.7298 21.5598C28.5598 22.7298 27.1598 23.3198 25.5398 23.3198H17.0498C16.4898 23.3198 15.9898 23.1098 15.5698 22.6898C15.1498 22.2698 14.9398 21.7698 14.9398 21.2098V12.1298L12.0198 15.0498L10.5098 13.5398L15.9998 8.0498L21.4898 13.5398L19.9798 15.0498L17.0598 12.1298V21.2098H25.5498C26.6098 21.2098 27.5098 20.8298 28.2598 20.0798C29.0098 19.3298 29.3898 18.4298 29.3898 17.3698C29.3898 16.3098 29.0098 15.4098 28.2598 14.6598C27.5098 13.9098 26.6098 13.5298 25.5498 13.5298H23.3298V10.5698C23.3298 8.47982 22.6198 6.68981 21.1998 5.17981C19.7798 3.67981 18.0298 2.92981 15.9398 2.92981C13.8498 2.92981 12.0898 3.67981 10.6598 5.17981C9.22976 6.67981 8.50977 8.47982 8.50977 10.5698H7.83977C6.37977 10.5698 5.14977 11.0798 4.13976 12.0998C3.12976 13.1198 2.62976 14.3798 2.62976 15.8898C2.62976 17.3998 3.14977 18.5998 4.17977 19.6398C5.20977 20.6798 6.45977 21.2098 7.90977 21.2098H12.8398V23.3198H7.90977H7.89977Z"
      fill="#3F5B64"
    />
  </svg>
);
