import { FC, memo } from 'react';
import bem from 'bem-ts';
import { Link, useLocation } from 'react-router-dom';

export type UiTopMenuProps = {
  options: {
    label: string;
    url: string;
    checkActive?: (v: string) => boolean;
    isVisible?: boolean;
    isActive?: boolean;
  }[];
};

export const UiTopMenu: FC<UiTopMenuProps> = memo(({ options }) => {
  const { pathname } = useLocation();

  const b = bem('topMenu');

  const localOptions = options.filter(({ isVisible }) => isVisible !== false);

  return (
    <div className={b([`items${localOptions.length}`])}>
      {localOptions.map(({ label, url, checkActive, isActive }) => (
        <Link
          to={url}
          key={url}
          className={b('item', {
            active: (() => {
              if (isActive) {
                return isActive;
              }
              if (checkActive) {
                return checkActive(pathname);
              }

              return pathname === url;
            })(),
          })}
        >
          {label}
        </Link>
      ))}
    </div>
  );
});
