import { ProfileDetailsContract } from '@moonpanda/moonpanda.contracts';
import { FC, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { apiResetPassword } from 'src/api/authentication';
import { UiInput } from 'src/components/UI/inputs/Text';
import { UiToast } from 'src/components/UI/toasts';
import { useRequest } from 'src/hooks/useRequest';
import { useTHelper } from 'src/utils/i18n';
import { useStateForm } from 'src/utils/stateForm';
import { UiButton } from 'src/components/UI/UiButton';
import { showErrorToast } from 'src/utils/errors';
import useGlobalStore from 'src/store';

import styles from 'src/pages/Settings/Personal/styles.module.scss';

type FormValues = Pick<ProfileDetailsContract, 'subscriptionName'> & {
  password: string;
};

export const SettingsPageIntegrationsComponent: FC = () => {
  const tHelper = useTHelper('pages.settings.personal.integrations');

  const { subscriptionName, email } = useGlobalStore((state) => state.user.response as ProfileDetailsContract);

  const formProps = useStateForm<FormValues>({
    defaultValues: {
      subscriptionName,
      password: '********', // imitation of the password
    },
  });

  const [loadingSubscriptionChange, setLoadingSubscriptionChange] = useState(false);

  const [loadingPassword, setLoadingPassword] = useState(false);

  const { apiCaller } = useRequest();

  const logOut = useGlobalStore((state) => state.logOut);

  const navigate = useNavigate();

  const changeSubscription = useGlobalStore((state) => state.changeSubscription);

  const onSubscriptionChange = useCallback(() => {
    setLoadingSubscriptionChange(true);

    changeSubscription()
      .catch(showErrorToast)
      .finally(() => {
        setLoadingSubscriptionChange(false);
      });
  }, [changeSubscription]);

  const onPasswordChange = useCallback(() => {
    setLoadingPassword(true);

    apiCaller(apiResetPassword, {
      email,
    })
      .then(() => {
        UiToast.success(tHelper('restorePasswordToast'));

        logOut(navigate);
      })
      .catch(showErrorToast)
      .finally(() => {
        setLoadingPassword(false);
      });
  }, [apiCaller, email, logOut, navigate, tHelper]);

  const isSubscriptionMember = formProps.getValue('subscriptionName') === 'Subscription member';

  return (
    <div className={styles.integrationsWrapper}>
      <div className={styles.row}>
        <UiInput
          formProps={formProps}
          name="subscriptionName"
          label={tHelper('subscriptionAndBilling')}
          placeholder={tHelper('subscriptionAndBilling')}
          disabled
        />
        <UiButton
          color={isSubscriptionMember ? 'light' : 'dark'}
          loading={loadingSubscriptionChange}
          onClick={onSubscriptionChange}
          disabled={isSubscriptionMember}
        >
          {tHelper('changeBtn')}
        </UiButton>
      </div>

      <div className={styles.row}>
        <UiInput formProps={formProps} name="password" label={tHelper('passwordLabel')} disabled />
        <UiButton color="dark" loading={loadingPassword} onClick={onPasswordChange}>
          {tHelper('changeBtn')}
        </UiButton>
      </div>
    </div>
  );
};
