/* eslint-disable max-len */
import { FC } from 'react';
import { CommonIconProps } from 'src/utils/iconProps';

type Props = CommonIconProps & {
  onClick: () => void;
};

export const IconAdd: FC<Props> = ({ className, onClick }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="27"
    height="27"
    viewBox="0 0 27 27"
    fill="none"
    className={className}
    onClick={onClick}
  >
    <path
      d="M12.5602 20.12H14.8203V14.9H20.0703V12.64H14.8203V7.17H12.5602V12.64H7.12024V14.9H12.5602V20.12ZM13.6102 27C11.7502 27 10.0103 26.65 8.39026 25.96C6.77026 25.27 5.35026 24.31 4.14026 23.1C2.93026 21.89 1.97027 20.47 1.28027 18.85C0.590273 17.23 0.240234 15.49 0.240234 13.63C0.240234 11.77 0.590273 10.02 1.28027 8.39999C1.97027 6.77999 2.93026 5.37 4.14026 4.16C5.35026 2.96 6.77026 2 8.39026 1.3C10.0103 0.600003 11.7502 0.25 13.6202 0.25C15.4902 0.25 17.2302 0.600003 18.8502 1.3C20.4702 2 21.8903 2.94999 23.0903 4.14999C24.2903 5.34999 25.2503 6.77 25.9503 8.39C26.6503 10.01 27.0002 11.76 27.0002 13.63C27.0002 15.5 26.6503 17.24 25.9503 18.86C25.2503 20.48 24.2903 21.89 23.0903 23.1C21.8903 24.31 20.4702 25.26 18.8502 25.96C17.2302 26.66 15.4902 27.01 13.6202 27.01L13.6102 27ZM13.6102 24.04C16.4902 24.04 18.9402 23.03 20.9702 21.01C22.9902 18.99 24.0002 16.53 24.0002 13.62C24.0002 10.71 22.9902 8.28999 20.9702 6.25999C18.9502 4.23999 16.4903 3.23 13.5803 3.23C10.6703 3.23 8.25021 4.23999 6.22021 6.25999C4.20021 8.27999 3.19025 10.74 3.19025 13.65C3.19025 16.56 4.20021 18.98 6.22021 21.01C8.24021 23.03 10.7002 24.04 13.6102 24.04Z"
      fill="#3F5B64"
    />
  </svg>
);
