import classNames from 'classnames';
import React, { FC } from 'react';
import { useParams } from 'react-router-dom';

import { UiLoader } from 'src/components/UI/loaders';
import { ProjectPageLayout } from 'src/pages/Projects/layout';
import useProjectsStore from 'src/pages/Projects/store';
import { ProjectSummaryWidget } from 'src/pages/Projects/view/widgets/Summary';
import { ProjectViewWidget } from 'src/pages/Projects/view/widgets/View';
import { ProjectsProjectGantSection } from 'src/pages/Projects/view/GanttSection';
import { ProjectTeamWidget } from 'src/pages/Projects/view/widgets/Team';

import styles from './styles.module.scss';

export const ProjectsProjectPage: FC = () => {
  const { id } = useParams<{ id?: string }>();

  const projectId = +(id as string) || 0;

  const { loading, projectData } = useProjectsStore((state) => ({
    loading: state.project.loading,
    projectData: state.project.response,
  }));

  return (
    <ProjectPageLayout className={classNames(styles.layout, 'fullPageLoader')} projectId={projectId}>
      {loading && <UiLoader />}

      {projectData && (
        <>
          <ProjectsProjectGantSection projectId={projectId} />

          <div className={styles.widgets}>
            <ProjectViewWidget />
            <ProjectTeamWidget projectId={projectId} />
            <ProjectSummaryWidget projectId={projectId} />
          </div>
        </>
      )}
    </ProjectPageLayout>
  );
};
