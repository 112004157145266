import { TaskStatusEnumContract, TimeLogContract } from '@moonpanda/moonpanda.contracts';
import isFunction from 'lodash/isFunction';
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';

import { apiGetTask, apiUpdateTask } from 'src/api/tasks';
import { apiCreateTaskTimeLog, apiGetTimeLogsByTask, apiStopTaskTimerById } from 'src/api/timeLogs';
import { ViewTaskModalAdditionalInfoType } from 'src/components/common/modals/ViewTask';
import { UiInput } from 'src/components/UI/inputs/Text';
import { UiToast } from 'src/components/UI/toasts';
import { useRequest } from 'src/hooks/useRequest';
import useGlobalStore from 'src/store';
import { CDate } from 'src/utils/CDate';
import { numberToTimeObject } from 'src/utils/dateTime';
import { showErrorToast } from 'src/utils/errors';
import { prepareDataToPatch } from 'src/utils/http';
import { useTHelper } from 'src/utils/i18n';
import { useStateForm } from 'src/utils/stateForm';

import { ChildrenPropType } from 'src/utils/types';

type Props = {
  taskId: number;
  taskStatus: TaskStatusEnumContract;
  totalLogged: number;
  children: (props: {
    input: JSX.Element;
    text: string;
    onTimerClick: () => void;
    isStarted: boolean;
  }) => ChildrenPropType;

  onTaskChange?: ViewTaskModalAdditionalInfoType['onChange'];
  onTimerClick?: (value: 'start' | 'stop') => void;
  childrenAfter?: ChildrenPropType;
};

type FormValues = {
  totalLogged: number;
};

export type ViewTaskModalTimerRefProps = {
  refresh: () => void;
};

export const ViewTaskModalTimerPartial = forwardRef<ViewTaskModalTimerRefProps, Props>(
  ({ taskId, onTaskChange, totalLogged, children, onTimerClick: onTimerClickProp, taskStatus, childrenAfter }, ref) => {
    const tHelper = useTHelper('modals.viewTask');

    const { apiCaller } = useRequest();

    const formProps = useStateForm<FormValues>({
      defaultValues: {
        totalLogged,
      },
    });

    const timer = useRef<number>();

    const [, updateTime] = useState<number>(0);

    const [timerTime, setTimerTime] = useState<Date>(() => new Date());

    const [timerInfo, setTimerInfo] = useState<null | TimeLogContract>(null);

    const apiFn = useCallback(() => {
      apiCaller(apiGetTimeLogsByTask, {
        orderBy: [{ propertyName: 'id', sortingOrder: 'Desc' }],
        pageSize: 1,
        page: 0,
        taskId,
      }).then((timeLogs) => {
        const openedTimer = timeLogs.data.find(({ endTime }) => endTime === null);

        setTimerInfo(openedTimer || null);
      });
    }, [apiCaller, taskId]);

    useEffect(() => {
      apiFn();
    }, [apiFn]);

    useEffect(() => {
      if (timerInfo) {
        setTimerTime(new Date(timerInfo.startTime));

        timer.current = window.setInterval(() => {
          updateTime((v) => (v === 1 ? 0 : 1));
        }, 1000);
      } else {
        clearInterval(timer.current);
      }

      return () => {
        clearInterval(timer.current);
      };
    }, [timerInfo]);

    const timePrepareValue = useCallback(
      (value: string | number | null | undefined) => {
        const times = numberToTimeObject(value);

        return tHelper('timeFormatted', {
          hours: times.hours,
          minutes: times.minutes,
        });
      },
      [tHelper],
    );

    const time = CDate.getDiff(new Date(), timerTime);

    /** sync with global task timers */
    const checkIfCanCreate = useCallback(() => useGlobalStore.getState().tasksWithTimers.length === 0, []);

    const addTaskTimer = useCallback((taskId: number) => {
      useGlobalStore.getState().addTaskWithTimer(taskId);
    }, []);

    const removeTaskTimer = useCallback((taskId: number) => {
      useGlobalStore.getState().removeTaskWithTimer(taskId);
    }, []);
    /** end sync with global task timers */

    const onTimerClick = useCallback(() => {
      if (isFunction(onTimerClickProp)) {
        onTimerClickProp(timerInfo === null ? 'start' : 'stop');
      }

      if (timerInfo === null) {
        if (checkIfCanCreate()) {
          apiCaller(apiCreateTaskTimeLog, {
            id: 0,
            startTime: new Date(),
            endTime: null,
            taskId,
          })
            .then(({ data }) => {
              setTimerInfo(data);

              addTaskTimer(taskId);
            })
            .catch(showErrorToast);

          if (taskStatus !== 'InProgress') {
            apiCaller(
              apiUpdateTask,
              prepareDataToPatch(
                [
                  {
                    op: 'replace',
                    path: '/status',
                    value: 'InProgress',
                  },
                ],
                {
                  taskId,
                },
              ),
            )
              .then(({ data }) => {
                if (isFunction(onTaskChange)) {
                  onTaskChange(data, data);
                }
              })
              .catch(showErrorToast);
          }
        } else {
          UiToast.warning(tHelper('anotherTimerIsActive'));
        }
      } else {
        apiCaller(apiStopTaskTimerById, { taskId, timeLogId: timerInfo?.id })
          .then(() => {
            removeTaskTimer(taskId);

            // update time logged value given from the server (it's difficult to calculate)
            apiCaller(apiGetTask, {
              taskId,
            }).then(({ data }) => {
              formProps.setValue('totalLogged', data.totalLogged);

              if (isFunction(onTaskChange)) {
                onTaskChange(data, data);
              }
            });

            setTimerInfo(null);
          })
          .catch(showErrorToast);
      }
    }, [
      onTimerClickProp,
      timerInfo,
      checkIfCanCreate,
      apiCaller,
      taskId,
      taskStatus,
      addTaskTimer,
      onTaskChange,
      tHelper,
      removeTaskTimer,
      formProps,
    ]);

    useImperativeHandle(ref, () => ({
      refresh: apiFn,
    }));

    const input = (
      <UiInput
        formProps={formProps}
        name="totalLogged"
        label={tHelper('timeLabel')}
        placeholder={tHelper('timePlaceholder')}
        prepareValue={timePrepareValue}
        readonly
        className="disabled-input"
        wrapperClassName="cursor-pointer"
        childrenAfter={childrenAfter}
      />
    );

    const text = timerInfo
      ? tHelper('endTask', {
          hours: time.hours,
          minutes: time.minutes,
          seconds: time.seconds,
        })
      : tHelper('startTask');

    return children({ input, text, onTimerClick, isStarted: !!timerInfo });
  },
);
