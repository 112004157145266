import classNames from 'classnames';
import { FC, useEffect } from 'react';
import { createPortal } from 'react-dom';

import { UiLoader } from 'src/components/UI/loaders';
import { IconModalArrow } from 'src/components/UI/Modals/icons/Arrow';
import { IconClose } from 'src/components/UI/Modals/icons/Close';
import { DOM_ELEMENT_FOR_PORTALS } from 'src/config';
import { useRefClickOutside } from 'src/hooks/useRefClickOutside';
import { getDOMElement, getDOMElements } from 'src/utils/DOM';
import { useIdOrRandomString } from 'src/utils/formHelper';
import { ChildrenPropType } from 'src/utils/types';

import styles from './styles.module.scss';
import { UiScrollbar } from '../Scrollbar';

type Props = {
  onClose: () => void;
  children: ChildrenPropType;

  header?: ChildrenPropType | false;
  footer?: ChildrenPropType;

  className?: string;
  contentClassName?: string;
  bodyContentClassName?: string;
  bodyClassName?: string;
  wrapperClassName?: string;
  headerClassName?: string;
  loading?: boolean;
  noCustomScrollBar?: boolean; // use when having problems when overflowing the sizes of the modal
  noClose?: boolean;
  id?: string;

  numberIds?: number;
  currentIdsPosition?: number;
  onPositionGo?: (position: number | 'left' | 'right') => void;
};

let ids: string[] = [];

export const UiModal: FC<Props> = ({
  onClose,
  children,
  header,
  footer,
  headerClassName,
  bodyClassName,
  bodyContentClassName,
  className,
  wrapperClassName,
  contentClassName,
  loading,
  noCustomScrollBar = false,
  noClose,
  numberIds,
  currentIdsPosition,
  onPositionGo,
  id: propId,
}) => {
  const id = useIdOrRandomString(propId);

  const ref = useRefClickOutside<HTMLDivElement>(
    true,
    (event: Event) => {
      // ignore click on elements below
      const calendars = getDOMElements('.flatpickr-calendar');

      const modalArrows = getDOMElements('.modalArrow');

      const deleteConfirms = getDOMElements('.deleteConfirmPopover');

      const skipIf =
        (calendars && Array.from(calendars).some((el) => el.contains(event.target as Node))) ||
        getDOMElement('.Toastify')?.contains(event.target as Node) ||
        (modalArrows && Array.from(modalArrows).some((el) => el.contains(event.target as Node))) ||
        (deleteConfirms && Array.from(deleteConfirms).some((el) => el.contains(event.target as Node)));

      // do not react to other outer libraries
      // close only top modal
      if (!skipIf && ids.at(-1) === id) {
        onClose();
      }
    },
    10,
  );

  useEffect(() => {
    ids.push(id);

    return () => {
      ids = ids.filter((v) => v !== id);
    };
  }, [id]);

  return createPortal(
    <>
      <div className={styles.backdrop} />
      <div
        className={classNames('UiModal', header ? header.toString() : 'task', styles.modal, wrapperClassName)}
        id={id}
      >
        <div className={classNames(styles.dialog, className)}>
          {onPositionGo && currentIdsPosition !== 0 && (
            <div className={classNames('modalArrow', styles.arrow, styles.arrowLeft)}>
              <IconModalArrow onClick={loading ? undefined : () => onPositionGo('left')} />
            </div>
          )}

          <div className={classNames(styles.content, contentClassName, 'modalContent')} ref={ref}>
            {loading ? (
              <UiLoader centered />
            ) : (
              <>
                <div className={classNames(header === false ? undefined : styles.modalHeader, 'modalHeader')}>
                  {noClose !== true && (
                    <button type="button" className={styles.closeButton} onClick={onClose}>
                      <IconClose />
                    </button>
                  )}

                  {header && <h3 className={classNames(styles.header, headerClassName)}>{header}</h3>}
                </div>

                <div className={classNames(styles.modalBody, bodyClassName, 'modalBody')}>
                  {noCustomScrollBar ? (
                    <div className={classNames(styles.bodyContent, bodyContentClassName, 'bodyContent')}>
                      {children}
                    </div>
                  ) : (
                    <UiScrollbar autoHeight autoHeightMin="100%" autoHeightMax="730px">
                      <div className={classNames(styles.bodyContent, bodyContentClassName, 'bodyContent')}>
                        {children}
                      </div>
                    </UiScrollbar>
                  )}
                </div>

                {footer && <div className={styles.modalFooter}>{footer}</div>}
              </>
            )}

            {numberIds && numberIds > 0 ? (
              <div className={styles.points}>
                {new Array(numberIds).fill(1).map((_, index) => (
                  <div
                    role="presentation"
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    className={classNames(styles.point, index === currentIdsPosition ? styles.pointActive : undefined)}
                    onClick={() => {
                      if (!loading) {
                        onPositionGo?.(index);
                      }
                    }}
                  />
                ))}
              </div>
            ) : null}
          </div>

          {onPositionGo && currentIdsPosition !== (numberIds || 0) - 1 && (
            <div className={classNames('modalArrow', styles.arrow, styles.arrowRight)}>
              <IconModalArrow onClick={loading ? undefined : () => onPositionGo('right')} />
              {currentIdsPosition !== undefined && numberIds !== undefined
                ? `${currentIdsPosition + 1}/${numberIds}`
                : null}
            </div>
          )}
        </div>
      </div>
    </>,
    DOM_ELEMENT_FOR_PORTALS,
  );
};
