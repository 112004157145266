import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import { FC, useCallback, useEffect, useLayoutEffect, useMemo, useRef } from 'react';
import debounce from 'lodash/debounce';

import useProjectsListStore from 'src/components/UI/ProjectsDropdown/store';
import { MAX_PAGE_SIZE_FOR_ONE_REQUEST } from 'src/config';
import { CalendarEvent } from 'src/pages/Calendar/Event';
import useCalendarStore from 'src/pages/Calendar/store';
import useModalsStore from 'src/store/modals';
import { getDOMElement } from 'src/utils/DOM';
import { useTHelper } from 'src/utils/i18n';

export const CalendarPage: FC = () => {
  const toggleModals = useModalsStore((state) => state.toggleModals);

  const tHelper = useTHelper('pages.calendar');

  const calendarRef = useRef<FullCalendar>(null);

  const { getEvents, getTasks, start, events, tasks, addEvent, updateEvent, deleteEvent } = useCalendarStore(
    (state) => ({
      getEvents: state.getEvents,
      getTasks: state.getTasks,
      start: state.start,
      events: state.events,
      tasks: state.tasks,
      addEvent: state.addEvent,
      updateEvent: state.updateEvent,
      deleteEvent: state.deleteEvent,
    }),
  );

  const { getColorById } = useProjectsListStore((state) => state);

  const preparedEvents = useMemo(
    () => [
      ...(events.response || []).map((item) => ({
        title: item.name,
        start: item.startDate,
        end: item.endDate,
        extendedProps: { type: 'event', ...item },
      })),
      ...(tasks.response || []).map((item) => ({
        title: item.name,
        start: item.dueDate,
        // end: item.dueDate,
        extendedProps: { type: 'task', ...item },
      })),
    ],
    [events, tasks],
  );

  useEffect(
    () => () => {
      useCalendarStore.getState().stop();
    },
    [],
  );

  useLayoutEffect(() => {
    const elem = getDOMElement('.calendar') as HTMLElement;

    let res: ResizeObserver;

    const fn = debounce(() => {
      calendarRef.current?.requestResize();
      res.unobserve(elem);
    }, 100);

    res = new ResizeObserver(() => {
      fn();
    });
    res.observe(elem);

    return () => {
      res.unobserve(elem);
    };
  }, []);

  const getData = useCallback(
    (start: string, end: string) => {
      getEvents({
        page: 0,
        pageSize: MAX_PAGE_SIZE_FOR_ONE_REQUEST,
        filters: [
          {
            propertyName: 'startDate',
            operation: 'GreaterThanOrEqual',
            value: start,
          },
          {
            propertyName: 'endDate',
            operation: 'LessThanOrEqual',
            value: end,
          },
        ],
      });

      getTasks({
        page: 0,
        pageSize: MAX_PAGE_SIZE_FOR_ONE_REQUEST,
        filters: [
          {
            propertyName: 'dueDate',
            operation: 'GreaterThanOrEqual',
            value: start,
          },
          {
            propertyName: 'dueDate',
            operation: 'LessThanOrEqual',
            value: end,
          },
        ],
      });
    },
    [getEvents, getTasks],
  );

  return (
    <div className="bodyPadding calendar">
      <FullCalendar
        ref={calendarRef}
        viewDidMount={(mountArg) => {
          start();

          setTimeout(() => {
            getData(mountArg.view.activeStart as unknown as string, mountArg.view.activeEnd as unknown as string);
          }, 1);
        }}
        plugins={[dayGridPlugin, timeGridPlugin]}
        initialView="dayGridMonth"
        events={preparedEvents}
        /* eslint-disable-next-line react/no-unstable-nested-components */
        eventContent={(renderProps) => <CalendarEvent model={renderProps} getProjectColorById={getColorById} />}
        /* eslint-disable-next-line react/no-unstable-nested-components */
        dayHeaderContent={(props) => {
          if (props.view.type === 'timeGridWeek') {
            const [day, name] = props.text.split(' ');

            return (
              <>
                {name} - {day}
              </>
            );
          }

          return props.text;
        }}
        slotMinTime="00:00:00"
        slotDuration="01:00:00"
        dayMaxEventRows={5}
        allDaySlot={false}
        expandRows
        dayMaxEvents={3}
        eventMaxStack={1}
        contentHeight={window.innerHeight - 150}
        customButtons={{
          createNewButton: {
            text: tHelper('addNewEvent'),
            click() {
              toggleModals('eventModal', true, {
                onSave: addEvent,
              });
            },
          },
          backButton: {
            click() {
              const api = calendarRef.current?.getApi();

              if (api) {
                api.prev();

                getData(api.view.activeStart as unknown as string, api.view.activeEnd as unknown as string);
              }
            },
          },
          nextButton: {
            click() {
              const api = calendarRef.current?.getApi();

              if (api) {
                api.next();

                getData(api.view.activeStart as unknown as string, api.view.activeEnd as unknown as string);
              }
            },
          },
        }}
        headerToolbar={{
          start: 'dayGridMonth,timeGridWeek', // createNewButton
          center: 'title',
          end: 'backButton nextButton',
        }}
        views={{
          dayGridMonth: {
            titleFormat: { year: 'numeric', month: 'long', day: 'numeric' },
            dayHeaderFormat: { weekday: 'long' },
          },
          week: {
            titleFormat: { year: 'numeric', month: 'long', day: 'numeric' },
            dayHeaderFormat: { weekday: 'long', day: '2-digit' },
          },
        }}
        buttonText={{
          month: tHelper('month'),
          week: tHelper('week'),
        }}
        slotEventOverlap={false}
        eventClassNames="calendar-event"
        moreLinkClassNames="calendar-more-link"
        eventClick={(model) => {
          getDOMElement('.fc-popover-close')?.click();

          if (model.event.extendedProps.type === 'event') {
            toggleModals('eventModal', true, {
              eventId: model.event.extendedProps.id,
              onSave: updateEvent,
              onDelete: deleteEvent,
            });
          } else if (model.event.extendedProps.type === 'task') {
            toggleModals('viewTask', true, {
              taskId: model.event.extendedProps.id,
            });
          }
        }}
      />
    </div>
  );
};
