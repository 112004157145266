import { ProjectContract } from '@moonpanda/moonpanda.contracts';
import isNumber from 'lodash/isNumber';
import { SafeAnyType } from 'src/utils/safeAny';
import { create } from 'zustand';

import { apiGetProjectList } from 'src/api/projects';
import { MAX_PAGE_SIZE_FOR_ONE_REQUEST } from 'src/config';
import { ApiPaginateRequestType } from 'src/models/API';
import { BaseStoreEntityType, baseStoreEntityValue } from 'src/store/types';

type Actions = {
  load: (data?: ApiPaginateRequestType) => void;
  delete: (projectId: number) => void;
  add: (project: ProjectContract) => void;
  update: (project: ProjectContract) => void;
  clear: () => void;

  getColorById: (id: number) => string;
};

type State = {
  projects: BaseStoreEntityType<ProjectContract[]>;

  // subscribe like this to get full info when the data loads
  // const { getColorById } = useProjectsListStore((state) => state);
  done: boolean;
};

const checkIfItIsProjectContract = (project: SafeAnyType) => isNumber(project?.id) && project?.budget;

const initialState: State = {
  projects: baseStoreEntityValue([], true),

  done: false,
};

const cache = new Map<number, ProjectContract>();

const useProjectsListStore = create<State & Actions>()((set, get) => ({
  ...initialState,
  load: (
    data = {
      pageSize: MAX_PAGE_SIZE_FOR_ONE_REQUEST,
      page: 0,
    },
  ) => {
    if (get().projects.response?.length === 0) {
      apiGetProjectList(data).then((response) => {
        const projects = (get().projects.response || []).concat(response.data);

        if (response.totalCount > projects.length) {
          set({
            projects: baseStoreEntityValue(projects, true),
          });

          get().load({
            pageSize: data.pageSize,
            page: data.page + 1,
          });
        } else {
          set({
            projects: baseStoreEntityValue(projects, false),
            done: true,
          });
        }
      });
    }
  },
  delete: (projectId) => {
    set((state) => ({
      projects: {
        ...state.projects,
        response: (state.projects.response || []).filter(({ id }) => id !== projectId),
      },
    }));
  },
  add: (project) => {
    if (checkIfItIsProjectContract(project)) {
      set((state) => ({
        projects: {
          ...state.projects,
          response: (state.projects.response || []).concat(project),
        },
      }));
    }
  },
  update: (project) => {
    if (checkIfItIsProjectContract(project)) {
      cache.delete(project.id);

      set((state) => {
        const projects = state.projects.response;

        if (projects) {
          const foundId = projects.findIndex(({ id }) => id === project.id);

          if (~foundId) {
            projects[foundId] = project;

            return {
              ...state,
              projects: {
                ...state.projects,
                response: projects,
              },
            };
          }
        }

        return state;
      });
    }
  },
  clear: () => {
    set(initialState);
  },

  getColorById: (id) => {
    if (cache.has(id)) {
      return cache.get(id)?.color || '';
    }

    const projects = get().projects.response;

    if (projects?.length) {
      const found = projects.find((project) => project.id === id);

      if (found) {
        cache.set(id, found);

        return found.color || '';
      }
    }

    return '';
  },
}));

export default useProjectsListStore;
