import classNames from 'classnames';
import { FC, useMemo } from 'react';

import { UiTopMenu } from 'src/components/common/TopMenu';
import { ROUTE_SETTINGS_PAGE, ROUTE_SETTINGS_TEAM_PAGE } from 'src/routes/paths';
import { useTHelper } from 'src/utils/i18n';
import { ChildrenPropType } from 'src/utils/types';

type Props = {
  children: ChildrenPropType;

  className?: string;
};

export const SettingsPageLayout: FC<Props> = ({ children, className }) => {
  const tHelper = useTHelper('pages.settings.menuOptions');

  const menuOptions = useMemo(
    () => [
      { label: tHelper('personal'), url: ROUTE_SETTINGS_PAGE },
      { label: tHelper('team'), url: ROUTE_SETTINGS_TEAM_PAGE },
    ],
    [tHelper],
  );

  return (
    <div className={classNames('bodyPadding', className)}>
      <UiTopMenu options={menuOptions} />

      {children}
    </div>
  );
};
