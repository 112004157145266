import { PublicClientApplication } from '@azure/msal-browser';
import { FC } from 'react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { MsalProvider } from '@azure/msal-react';

import { GOOGLE_CLIENT_ID, msalConfig } from 'src/config';
import { ChildrenPropType } from 'src/utils/types';

type Props = {
  children: ChildrenPropType;
};

const msalInstance = new PublicClientApplication(msalConfig);

export const AuthProviders: FC<Props> = ({ children }) => (
  <MsalProvider instance={msalInstance}>
    <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>{children}</GoogleOAuthProvider>
  </MsalProvider>
);
