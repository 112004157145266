/* eslint-disable max-len */
import { FC } from 'react';

export const IconStart: FC = () => (
  <svg width="15" height="10" viewBox="0 0 15 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.169922 9.24927V0.739258H1.00995V9.24927H0.169922ZM10.33 9.17926L9.72992 8.59927L12.9099 5.41928H3.10992V4.57925H12.9L9.73993 1.38928L10.3199 0.809265L14.5099 4.99927L10.3199 9.18927L10.33 9.17926Z"
      fill="white"
    />
  </svg>
);
