import { PhaseContract, QueryRequestContract, TaskContract } from '@moonpanda/moonpanda.contracts';
import cloneDeep from 'lodash/cloneDeep';
import { MAX_PAGE_SIZE_FOR_ONE_REQUEST } from 'src/config';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { apiGetTasks, apiUpdateTask } from 'src/api/tasks';
import { apiGetPhases, apiUpdatePhaseProject } from 'src/api/phases';
import { CommonApiResponseType } from 'src/models/API';
import { prepareDataToPatch } from 'src/utils/http';
import { apiGetProjectData, apiGetProjectList } from 'src/api/projects';
import { GanttProjectType, prepareProjectsForGantt } from 'src/components/UI/gantt/helpers';
import { GANTT_PROJECTS_PAGE_SIZE, GANTT_TASKS_PAGE_SIZE } from 'src/components/UI/gantt/config';

type Actions = {
  /** timeline view API */
  getProjects: (page: number) => Promise<GanttProjectType[]>;
  updatePhase: (model: {
    endDate: Date;
    startDate: Date;
    projectId: number;
    phaseId: number;
  }) => Promise<CommonApiResponseType<PhaseContract>>;
  updateTask: (model: {
    dueDate: Date;
    startDate: Date;
    taskId: number;
  }) => Promise<CommonApiResponseType<TaskContract>>;

  /** project view API */
  getProject: (projectId: number) => Promise<GanttProjectType>;

  /** both view API */
  getProjectPhases: (projectId: number) => Promise<PhaseContract[]>;
  getTasksByPhase: (projectId: number, phaseId: number) => Promise<TaskContract[]>;

  clear: () => void;
};

type State = {
  projectsFilter: QueryRequestContract & { loading: boolean; hasMore: boolean };

  tempTasks: Record<number, TaskContract[]>;

  projects: {
    loading: boolean;
    data: GanttProjectType[];
  };
};

export const ganntStoreDefaultProjectsFilter: State['projectsFilter'] = {
  page: 0,
  pageSize: GANTT_PROJECTS_PAGE_SIZE,
  filters: [],
  orderBy: [{ propertyName: 'id', sortingOrder: 'Desc' }],
  hasMore: false,
  loading: true,
};

const initialState: State = {
  projectsFilter: ganntStoreDefaultProjectsFilter,

  tempTasks: [],

  projects: {
    loading: false,
    data: [],
  },
};

const useGanntStore = create<State & Actions>()(
  devtools(
    (set, get) => ({
      ...initialState,

      getProjects: (page) => {
        set((state) => ({
          ...state,
          projectsFilter: {
            ...state.projectsFilter,
            loading: true,
          },
          projects: {
            loading: true,
            data: page === 0 ? [] : state.projects.data,
          },
        }));

        return apiGetProjectList({
          ...get().projectsFilter,
          page,
        })
          .then(({ data, totalCount }) => {
            const projects = prepareProjectsForGantt(data);

            const projectsToSet = page === 0 ? projects : [...get().projects.data, ...projects];

            set((state) => ({
              projectsFilter: {
                ...state.projectsFilter,
                loading: false,
                page,
                hasMore: projectsToSet.length < totalCount,
              },
              projects: {
                loading: false,
                data: projectsToSet,
              },
            }));

            return projects;
          })
          .catch((e) => {
            set((state) => ({
              projectsFilter: {
                ...state.projectsFilter,
                loading: false,
              },
              projects: {
                loading: false,
                data: [],
              },
            }));

            return e;
          });
      },

      updatePhase: (model) =>
        apiUpdatePhaseProject(
          prepareDataToPatch(
            [
              {
                op: 'replace',
                path: '/endDate',
                value: model.endDate,
              },
              {
                op: 'replace',
                path: '/startDate',
                value: model.startDate,
              },
            ],
            {
              projectId: model.projectId,
              id: model.phaseId,
            },
          ),
        ),

      updateTask: (model) =>
        apiUpdateTask(
          prepareDataToPatch(
            [
              {
                op: 'replace',
                path: '/dueDate',
                value: model.dueDate,
              },
              {
                op: 'replace',
                path: '/startDate',
                value: model.startDate,
              },
            ],
            {
              taskId: model.taskId,
            },
          ),
        ),

      getProject: (projectId) => {
        set((state) => ({
          ...state,
          projects: {
            loading: true,
            data: [],
          },
        }));

        return apiGetProjectData({ projectId })
          .then(({ data }) => {
            const projects = prepareProjectsForGantt([data]);

            set((state) => ({
              ...state,
              projects: {
                loading: false,
                data: projects,
              },
            }));

            return projects[0];
          })
          .catch((e) => {
            set((state) => ({
              projectsFilter: {
                ...state.projectsFilter,
                loading: false,
              },
              projects: {
                loading: false,
                data: [],
              },
            }));

            return e;
          });
      },

      getProjectPhases: (projectId) =>
        apiGetPhases({ projectId, pageSize: MAX_PAGE_SIZE_FOR_ONE_REQUEST, page: 0 }).then(({ data }) => data),

      getTasksByPhase: (projectId, phaseId) => {
        set((state) => ({
          ...state,
          tempTasks: { ...state.tempTasks, [phaseId]: [] },
        }));

        return new Promise((resolve) => {
          const fn = (page: number) =>
            apiGetTasks({
              page,
              pageSize: GANTT_TASKS_PAGE_SIZE,
              filters: [
                { propertyName: 'projectId', operation: 'Equals', value: `${projectId}` },
                { propertyName: 'phaseId', operation: 'Equals', value: `${phaseId}` },
              ],
            }).then(({ data, totalCount }) => {
              set((state) => ({
                ...state,
                tempTasks: { ...state.tempTasks, [phaseId]: [...state.tempTasks[phaseId], ...data] },
              }));

              if (totalCount > 0 && data.length === totalCount) {
                fn(page + 1);
              } else {
                resolve(get().tempTasks[phaseId]);

                const temp = cloneDeep(get().tempTasks);

                delete temp[phaseId];

                set((state) => ({
                  ...state,
                  tempTasks: temp,
                }));
              }
            });

          fn(0);
        });
      },

      clear: () => {
        set(initialState);
      },
    }),
    {
      enabled: process.env.NODE_ENV === 'development',
    },
  ),
);

export default useGanntStore;
