import * as signalR from '@microsoft/signalr';
import { CommentContract } from '@moonpanda/moonpanda.contracts';

import useTaskCommentsStore from 'src/components/common/modals/ViewTask/store';
import config from 'src/config/applicationconfig.json';
import { ApiPaginateRequestType, CommonApiResponseType } from 'src/models/API';
import useDashboardStore from 'src/pages/Dashboard/store';
import { showErrorToast } from 'src/utils/errors';
import { getJwtToken } from 'src/utils/localStorage';
import { SafeAnyType } from 'src/utils/safeAny';

const connection = new signalR.HubConnectionBuilder()
  .configureLogging(process.env.NODE_ENV === 'development' ? signalR.LogLevel.Information : signalR.LogLevel.None)
  .withUrl(`${config.API_BASE_URL}hub`, {
    accessTokenFactory: () => getJwtToken()?.jwt || '',
  })
  .withAutomaticReconnect([1000, 2000, 3000, 5000])
  .build();

export const apiSignalRStart = () =>
  new Promise((resolve) => {
    connection.start().then(resolve).catch(resolve);
  });

export const apiSignalRStop = () => connection.stop();

connection.onclose(apiSignalRStart);

connection.on('addOrUpdateComment', (comment: CommentContract) => {
  useTaskCommentsStore.getState().addOrUpdate(comment);

  useDashboardStore.getState().addOrUpdateComment(comment);
});

// connection.on('deleteComment', (commentToDeleteId: number) => {
//   // TODO: remove comment from task in frontend store
//   // eslint-disable-next-line no-console
//   console.log(commentToDeleteId);
// });

const baseInvoke = async <R = CommonApiResponseType>(method: string, ...args: SafeAnyType[]): Promise<R> => {
  if (connection.state === 'Disconnected') {
    await apiSignalRStart();
  }

  return connection.invoke(method, ...args).catch((e) => {
    showErrorToast([
      { key: '1', message: `Couldn't connect to the server. Try again later` },
      { key: '2', message: `More info: "${e}"` },
    ]);

    throw new Error(e); // to prevent .then
  });
};

export const apiTaskCommentsGet = (request: ApiPaginateRequestType, taskId: number) =>
  // Task api, return only 10 comments by default (page: 0, pageSize: 10, OrderByDescending(c => c.CreatedAt))
  // so call this method with (page: 1, pageSize: 10)

  baseInvoke<CommonApiResponseType<CommentContract[]>>('getComments', request, taskId);

export const apiTaskCommentsCreate = (comment: CommentContract) =>
  baseInvoke<CommonApiResponseType<CommentContract>>('createComment', comment);

export const apiTaskCommentsUpdate = (comment: CommentContract) =>
  baseInvoke<CommonApiResponseType<CommentContract>>('updateComment', comment);

export const apiTaskCommentsDelete = (comment: CommentContract) =>
  baseInvoke<CommonApiResponseType>('deleteComment', comment);

export const apiTaskCommentsRead = (taskId: number) => baseInvoke<CommonApiResponseType>('readComments', taskId);
