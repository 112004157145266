import { JwtTokenContract, ProfileDetailsContract } from '@moonpanda/moonpanda.contracts';
import { FC, lazy, Suspense, useCallback, useState } from 'react';
import { Link } from 'react-router-dom';

import { apiAuthenticationSignIn } from 'src/api/authentication';
import { GoogleAuthButton } from 'src/components/common/GoogleAuthButton';
import { MicrosoftAuthButton } from 'src/components/common/MicrosoftAuthButton';
import { IconAppLogo } from 'src/components/icons/AppLogo';
import { UiInput } from 'src/components/UI/inputs/Text';
import { UiToast } from 'src/components/UI/toasts';
import { UiButton } from 'src/components/UI/UiButton';
import { EMAIL_PATTERN } from 'src/config';
import { useRequest } from 'src/hooks/useRequest';
import { SignUpPageDetailsFormValues, SignUpPageFormValues } from 'src/pages/SignUp';
import { useSignInUser } from 'src/pages/SignUp/common';
import { SignUpPageDetailsComponent } from 'src/pages/SignUp/Details';
import { ROUTE_RECOVER_PASSWORD_PAGE, ROUTE_SIGN_UP_PAGE } from 'src/routes/paths';
import { showErrorToast } from 'src/utils/errors';
import { useTHelper } from 'src/utils/i18n';
import { StateFormReturnType, useStateForm } from 'src/utils/stateForm';
import { RecursiveNullable } from 'src/utils/types';

import styles from './styles.module.scss';

const SignInfoBlock = lazy(() => import('src/pages/SignIn/Terms'));

type FormValues = RecursiveNullable<{ email: string; password: string }> | SignUpPageDetailsFormValues;

export const SignInPage: FC = () => {
  const tHelper = useTHelper('pages.signIn');

  const formProps = useStateForm<FormValues>();

  const [step, setStep] = useState<'create' | 'fillProfile'>('create');

  const onBack = useCallback(() => {
    setStep('create');
  }, []);

  const { apiCaller } = useRequest();

  const [loading, setLoading] = useState(false);

  const signInUser = useSignInUser();

  const replaceValue = useCallback((value: string) => value.replace(/\s/g, ''), []);

  return (
    <div className={styles.content}>
      {step === 'create' && (
        <div className={styles.stepCreate}>
          <h1>{tHelper('header')}</h1>

          <IconAppLogo className={styles.iconLogo} />

          <form
            onSubmit={formProps.onSubmit((formData) => {
              formData = formData as { email: string; password: string };

              const dataToSend = {
                email: formData.email as string,
                password: formData.password as string,
              };

              if (EMAIL_PATTERN.test(dataToSend.email)) {
                setLoading(true);

                apiCaller(apiAuthenticationSignIn, dataToSend)
                  .then((response) => {
                    signInUser(response.data);
                  })
                  .catch((e) => {
                    showErrorToast(e);

                    setLoading(false);
                  });
              } else {
                formProps.setError('email', tHelper('emailInvalid'));

                UiToast.warning(tHelper('emailInvalid'));
              }
            })}
          >
            <div>
              <GoogleAuthButton
                onFillProfileData={(data: ProfileDetailsContract, jwt: JwtTokenContract) => {
                  setStep('fillProfile');

                  formProps.setValue({
                    firstName: data.firstName,
                    lastName: data.lastName,
                    password: null,
                    jobTitle: data.jobTitle,
                    companyName: data.companyName,
                    email: data.email,
                    avatar: null,

                    emailSignature: data.emailSignature,
                    jwt,
                  });
                }}
              />
              <MicrosoftAuthButton
                onFillProfileData={(data: ProfileDetailsContract, jwt: JwtTokenContract) => {
                  setStep('fillProfile');

                  formProps.setValue({
                    firstName: data.firstName,
                    lastName: data.lastName,
                    password: null,
                    jobTitle: data.jobTitle,
                    companyName: data.companyName,
                    email: data.email,
                    avatar: null,

                    emailSignature: data.emailSignature,
                    jwt,
                  });
                }}
              />

              <div className={styles.delimiter}>
                <span>OR</span>
              </div>

              <UiInput
                formProps={formProps}
                name="email"
                type="email"
                placeholder={tHelper('emailPlaceholder')}
                replaceValue={replaceValue}
                required
              />
              <UiInput
                formProps={formProps}
                name="password"
                type="password"
                placeholder={tHelper('passwordPlaceholder')}
                required
              />
              <UiButton type="submit" loading={loading}>
                {tHelper('submitBtn')}
              </UiButton>
              <Link to={ROUTE_SIGN_UP_PAGE} className="linkButtonLike dark">
                {tHelper('linkToSignUp')}
              </Link>
              <Link to={ROUTE_RECOVER_PASSWORD_PAGE} className="linkButtonLike dark">
                {tHelper('forgotPassword')}
              </Link>

              <Suspense fallback={<></>}>
                <SignInfoBlock />
              </Suspense>
            </div>
          </form>
        </div>
      )}
      {step === 'fillProfile' && (
        <SignUpPageDetailsComponent
          formProps={formProps as unknown as StateFormReturnType<SignUpPageFormValues>}
          onBack={onBack}
          fillProfile={step === 'fillProfile'}
        />
      )}
    </div>
  );
};
