/* eslint-disable max-len */
import { FC } from 'react';

export const IconOk: FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="27" height="26" viewBox="0 0 27 26" fill="none">
    <path
      d="M11.52 18.5893L20.52 9.58933L19.0599 8.15934L11.52 15.6993L7.69995 11.8793L6.26996 13.3093L11.52 18.5893ZM13.4 25.5193C11.66 25.5193 10.0199 25.1893 8.46991 24.5193C6.91991 23.8493 5.56991 22.9393 4.40991 21.7793C3.24991 20.6193 2.33992 19.2693 1.66992 17.7193C0.999922 16.1693 0.669922 14.5293 0.669922 12.7893C0.669922 11.0493 0.999922 9.37934 1.66992 7.82934C2.33992 6.27934 3.24991 4.93934 4.40991 3.78934C5.56991 2.64934 6.91991 1.73934 8.46991 1.06934C10.0199 0.399336 11.66 0.0693359 13.4 0.0693359C15.14 0.0693359 16.8099 0.399336 18.3599 1.06934C19.9099 1.73934 21.25 2.63934 22.4 3.78934C23.54 4.92934 24.4499 6.27934 25.1199 7.82934C25.7899 9.37934 26.1199 11.0293 26.1199 12.7893C26.1199 14.5493 25.7899 16.1693 25.1199 17.7193C24.4499 19.2693 23.55 20.6193 22.4 21.7793C21.26 22.9393 19.9099 23.8493 18.3599 24.5193C16.8099 25.1893 15.16 25.5193 13.4 25.5193Z"
      fill="#00C712"
    />
  </svg>
);
