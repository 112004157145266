import { FileContract } from '@moonpanda/moonpanda.contracts';
import classNames from 'classnames';
import React, { FC, memo } from 'react';

import styles from './styles.module.scss';

type Props = {
  data: { avatar?: FileContract | null; firstName?: string; lastName?: string; name?: string } | null | undefined;
  width: number;

  unread?: boolean;
  className?: string;
  wrapperClassName?: string;
};

export const UiAvatar: FC<Props> = memo(({ data, className, wrapperClassName, width, unread }) => {
  const title = data?.name || `${data?.firstName} ${data?.lastName}`;

  return (
    <div className={classNames(styles.wrapper, wrapperClassName)} style={{ minWidth: width, minHeight: width }}>
      {data?.avatar ? (
        <img
          src={data.avatar?.url}
          alt=""
          className={classNames(styles.img, className)}
          width={width}
          height={width}
          title={title}
          loading="lazy"
        />
      ) : (
        <div className={classNames(styles.imgEmpty, className)} style={{ width, height: width }} title={title}>
          {data?.name ? (
            <>
              {(() => {
                const names = data.name.split(' ');

                return names ? `${names[0]?.[0] ?? ''}${names[1]?.[0] ?? ''}` : '';
              })()}
            </>
          ) : (
            <>
              {(data?.firstName?.[0] || '').toUpperCase()}
              {(data?.lastName?.[0] || '').toUpperCase()}
            </>
          )}
        </div>
      )}
      {unread && <div className={styles.unread} />}
    </div>
  );
});
