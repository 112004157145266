import { MAX_PAGE_SIZE_FOR_ONE_REQUEST } from 'src/config';

export const GANTT_DUE_DATE_ID = 'dueDateWithSort';

export const GANTT_DUE_DATE_PROPERTY = 'dueDate';

export const GANTT_CREATE_TASK_ID = 'createTaskButtonId';

export const GANTT_TASKS_PAGE_SIZE = MAX_PAGE_SIZE_FOR_ONE_REQUEST;

export const GANTT_PROJECTS_PAGE_SIZE = 20;

export const GANTT_VERTICAL_SCROLL_OFFSET = 100;

export const GANNT_DAYS_TO_ADD_ON_SCROLL = 10;
