import { FC } from 'react';

import styles from './styles.module.scss';

type Props = {
  percent: number;

  color?: string;
};

export const UiProgress: FC<Props> = ({ percent, color }) => (
  <div className={styles.wrapper}>
    <div style={{ width: `${percent}%`, backgroundColor: color }} />
  </div>
);
