export const setSessionData = (key: string, value: string) => {
  sessionStorage.setItem(key, value);
};

export const clearSessionData = (key: string) => {
  sessionStorage.removeItem(key);
};

export const getSessionData = (key: string): string | null => {
  const data = sessionStorage.getItem(key);

  if (!data) {
    return null;
  }

  return data;
};

/** timers info */
const timerInfoKey = 'timersInfo';

const COLLECT_TIMERS_INFO = false;

type TimerInfoType = { taskId: number; timeLogId: number };
type TimersInfoType = TimerInfoType[];

export const addTimerInfo = (timerInfo: TimerInfoType) => {
  if (COLLECT_TIMERS_INFO) {
    const currentTimersString = getSessionData(timerInfoKey);

    const currentTimers: TimersInfoType = currentTimersString ? JSON.parse(currentTimersString) : [];

    currentTimers.push({ taskId: timerInfo.taskId, timeLogId: timerInfo.timeLogId });

    setSessionData(timerInfoKey, JSON.stringify(currentTimers));
  }
};

export const clearTimersInfo = () => {
  clearSessionData(timerInfoKey);
};

export const removeTimerInfo = (timerInfo: TimerInfoType) => {
  const currentTimersString = getSessionData(timerInfoKey);

  let currentTimers: TimersInfoType = currentTimersString ? JSON.parse(currentTimersString) : [];

  currentTimers = currentTimers.filter(
    (timerInfoItem) => timerInfoItem.taskId !== timerInfo.taskId && timerInfoItem.timeLogId !== timerInfo.timeLogId,
  );

  if (currentTimers.length) {
    setSessionData(timerInfoKey, JSON.stringify(currentTimers));
  } else {
    clearTimersInfo();
  }
};

export const getTimersInfo = (): TimersInfoType => {
  const currentTimersString = getSessionData(timerInfoKey);

  if (currentTimersString === null) {
    return [];
  }

  return JSON.parse(currentTimersString);
};
/** end timers info */
