/* eslint-disable max-len */
import { FC } from 'react';
import { CommonIconProps } from 'src/utils/iconProps';

type Props = CommonIconProps & {
  onClick?: () => void;
};

export const IconOk: FC<Props> = ({ className, onClick }) => (
  <svg
    width="15"
    height="16"
    viewBox="0 0 15 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    onClick={onClick}
  >
    <path
      d="M7.59995 15.0395C11.4825 15.0395 14.6299 11.8921 14.6299 8.00949C14.6299 4.12693 11.4825 0.979492 7.59995 0.979492C3.71739 0.979492 0.569946 4.12693 0.569946 8.00949C0.569946 11.8921 3.71739 15.0395 7.59995 15.0395Z"
      fill="#4BD37B"
    />
    <path
      d="M10.8799 3.7998L5.95993 8.8598L4.31993 7.17981L2.67993 8.8598L5.95993 12.2298L12.5199 5.47983L10.8799 3.7998Z"
      fill="white"
    />
  </svg>
);
