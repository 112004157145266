import classNames from 'classnames';
import flatpickr from 'flatpickr';
import React, { FC, memo, useCallback, useMemo } from 'react';
import Flatpickr from 'react-flatpickr';

import { useInputBaseHook } from 'src/hooks/inputs';
import { CDate } from 'src/utils/CDate';
import { useIdOrRandomString } from 'src/utils/formHelper';
import { getStringFromUiLabel } from 'src/utils/reactDom';
import { SafeAnyType } from 'src/utils/safeAny';
import { StateFormInputOptionsType, StateFormReturnType } from 'src/utils/stateForm';

export type UiTimePickerProps = {
  formProps: StateFormReturnType;
  name: string;

  label?: string;
  classNameLabel?: string;
  required?: boolean;
  disabled?: boolean;
  errorLabel?: string;
  placeholder?: string;
};

export const UiTimePicker: FC<UiTimePickerProps> = memo(
  ({
    formProps: { register, unregister, getSubscribeProps, onChange: formOnChange, getValue },
    required,
    name,
    errorLabel,
    disabled = false,
    label,
    classNameLabel,
    placeholder,
  }) => {
    const id = useIdOrRandomString();

    const inputOptions: StateFormInputOptionsType = useMemo(
      () => ({ required, errorLabel: errorLabel || getStringFromUiLabel(label) || undefined }),
      [errorLabel, label, required],
    );

    const [value, errors] = useInputBaseHook({
      getSubscribeProps,
      name,
      register,
      unregister,
      inputOptions,
      type: 'timepicker',
    });

    const hasErrors = !!errors?.length;

    /** memoize the options */
    const options = useMemo(
      (): flatpickr.Options.Options => ({
        allowInput: !disabled,
        clickOpens: !disabled,
        enableTime: true,
        noCalendar: true,
        dateFormat: 'h:i K',
        defaultHour: 23,
        defaultMinute: 59,
      }),
      [disabled],
    );

    const onChange = useCallback(
      (dates: Date[]) => {
        if (!disabled) {
          const value = dates.length ? CDate.format(dates[0], 'HH:mm:ss') : null;

          if (value !== getValue(name)) {
            formOnChange(name, value);
          }
        }
      },
      [disabled, formOnChange, getValue, name],
    );

    /** set the end of the day when opened */
    const onOpen = useCallback((_: unknown, str: string, ins: SafeAnyType) => {
      if (str.length === 0) {
        ins.setDate(
          CDate.setTime(new Date(), {
            hours: 23,
            minutes: 59,
          }),
        );
      }
    }, []);

    return (
      <div className="UiInput-wrapper">
        {label && (
          <label htmlFor={id} className={classNameLabel}>
            {label}
          </label>
        )}
        <Flatpickr
          key={value}
          data-name={name}
          options={options as SafeAnyType} // types?
          value={value}
          onOpen={onOpen}
          onClose={onChange}
          id={id}
          placeholder={placeholder || 'hh:mm am'}
          className={classNames('UiInput timepicker', {
            required: hasErrors && required,
            error: hasErrors,
            disabled,
          })}
        />
      </div>
    );
  },
);
