import classNames from 'classnames';
import debounce from 'lodash/debounce';
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef } from 'react';

import { UiInput, UiInputProps } from 'src/components/UI/inputs/Text';
import { IconSearch } from 'src/components/UI/Search/icons/Search';
import { useStateForm } from 'src/utils/stateForm';

import styles from './styles.module.scss';

type Props = Omit<UiInputProps, 'formProps' | 'name'> & {
  onSearch?: (value: string) => void;
  onClear?: () => void;
  defaultValue?: string | number;

  onClickOutSide?: () => void;
  active?: boolean;
};

type FormValues = {
  search: string;
};

export type UiSearchInputRefProps = {
  clear: () => void;
};

export const UiSearchInput = forwardRef<UiSearchInputRefProps, Props>((props, ref) => {
  const { onSearch, onClear, wrapperClassName, active, onClickOutSide, defaultValue } = props;

  const formProps = useStateForm<FormValues>({
    defaultValues: {
      search: defaultValue,
    },
  });

  const search = useRef(
    debounce((value) => {
      onSearch?.(value);
    }, 500),
  );

  useImperativeHandle(ref, () => ({ clear: () => formProps.setValue('search', null) }));

  const clickOutSide = useCallback(
    ({ target }: Event) => {
      const elem: Element = target as Element;

      if (elem?.closest('.app-search') === null && elem?.closest('.searchResults') === null) {
        onClickOutSide?.();
      }
    },
    [onClickOutSide],
  );

  useEffect(() => {
    if (onClickOutSide) {
      window.removeEventListener('click', clickOutSide);

      if (active) {
        setTimeout(() => {
          window.addEventListener('click', clickOutSide);
        }, 100);
      }
    }

    return () => {
      window.removeEventListener('click', clickOutSide);
    };
  }, [clickOutSide, active, onClickOutSide]);

  return (
    <UiInput
      formProps={formProps}
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...props}
      name="search"
      onChange={(value) => {
        if (value === null) {
          onClear?.();
        } else if (value.length >= 3) {
          search.current(value);
        }
      }}
      wrapperClassName={classNames(styles.wrapper, wrapperClassName)}
    >
      <IconSearch />
    </UiInput>
  );
});
