import isArray from 'lodash/isArray';
import { useCallback, useEffect } from 'react';
import { apiGetDocument } from 'src/api/documents';
import { useRequest } from 'src/hooks/useRequest';
import { showErrorToast } from 'src/utils/errors';

import { SafeAnyType } from 'src/utils/safeAny';

const mimeTypes = {
  'application/vnd.ms-excel': 'xls',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx',
  'text/csv': 'csv',
  'application/pdf': 'pdf',
  'application/msword': 'doc',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'docx',
  'image/jpeg': ['jpe', 'jpeg', 'jpg'],
  'image/png': 'png',
} as const;

export const getFileExtensionFromName = (fileName: string) => fileName.split('.').pop() || '';

export const getExtensionByMimeType = (mimeType: string): string => {
  const extension = (mimeTypes as SafeAnyType)[mimeType] || 'unknown';

  if (typeof extension === 'string') {
    return extension;
  }

  return extension.join(',');
};

export const getMimeTypeByExtension = (extension: string): string => {
  const entries = Object.entries(mimeTypes);

  for (let i = 0; i < entries.length; i += 1) {
    if (isArray(entries[i][1])) {
      const arr = entries[i][1];

      for (let j = 0; j < arr.length; j += 1) {
        if (arr[j] === extension) {
          return entries[i][0];
        }
      }
    } else if (entries[i][1] === extension) {
      return entries[i][0];
    }
  }

  return 'unknown';
};

export const previewableFileTypes: string[] = [
  mimeTypes['image/jpeg'],
  mimeTypes['image/png'],
  mimeTypes['application/pdf'],
].flat();

export const downloadBlobAsFile = (blob: Blob, fileName: string, options?: { nameAsIs?: boolean }) => {
  const url = window.URL.createObjectURL(blob);

  const link = document.createElement('a');

  link.href = url;
  link.setAttribute('download', options?.nameAsIs ? fileName : `${fileName}.${getExtensionByMimeType(blob.type)}`);
  document.body.appendChild(link);
  link.click();
};

export const openFileInNewTab = (blob: Blob, fileName: string) => {
  const url = window.URL.createObjectURL(blob);

  const newWin = window.open(url, '_blank');

  newWin?.addEventListener('load', () => {
    newWin.document.title = fileName;
  });
};

// export const useDownloadFile = () => {
//   const [state, setState] = useState<AbortController>();
//
//   const downLoad = useCallback(({ url, fileName }: { url: string; fileName: string }) => {
//     const controller = new AbortController();
//
//     // private urls always contain "fileKey"
//     const isPrivateUrl = url.includes('fileKey');
//
//     const { signal } = controller;
//
//     setState((prevState) => {
//       prevState?.abort();
//
//       return controller;
//     });
//
//     return fetch(url, {
//       method: 'get',
//       signal,
//       headers: isPrivateUrl
//         ? { Authorization: `Bearer ${(axios.defaults.headers.common.Authorization as string).split(' ')[1]}` }
//         : {},
//     })
//       .then((response) => response.blob())
//       .then((blob) => new Blob([blob], { type: getMimeTypeByExtension(getFileExtensionFromName(fileName)) }))
//       .catch(() => null);
//   }, []);
//
//   const abort = useCallback(() => {
//     state?.abort();
//   }, [state]);
//
//   useEffect(
//     () => () => {
//       abort();
//     },
//     [abort],
//   );
//
//   return { downLoad, abort };
// };

export const useDownloadFile = () => {
  const { apiCaller, abort } = useRequest();

  const downLoad = useCallback(
    ({ url, fileName }: { url: string; fileName: string }) => {
      // private urls always contain "fileKey"
      const isPrivateUrl = url.includes('fileKey');

      return apiCaller(
        apiGetDocument,
        {
          url,
          ignoreAuthHeader: !isPrivateUrl,
        },
        {
          expectBlob: true,
        },
      )
        .then(
          (response) =>
            new Blob([response as unknown as Blob], {
              type: getMimeTypeByExtension(getFileExtensionFromName(fileName)),
            }),
        )
        .catch(showErrorToast);
    },
    [apiCaller],
  );

  useEffect(
    () => () => {
      abort();
    },
    [abort],
  );

  return { downLoad, abort };
};
