import {
  FileContract,
  ProfileDetailsContract,
  ProjectContract,
  TaskContract,
  TeamMemberContract,
} from '@moonpanda/moonpanda.contracts';
import isFunction from 'lodash/isFunction';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { apiGetPhases } from 'src/api/phases';
import { apiCreateTask } from 'src/api/tasks';
import { apiGetProjectTeams } from 'src/api/team';
import { UiCheckbox } from 'src/components/UI/inputs/Checkbox';
import { parseDateFromUiDatePicker } from 'src/components/UI/inputs/DatePicker';
import { UiDateRangePicker } from 'src/components/UI/inputs/DateRangePicker';
import { UiDropdown, UiDropdownOnChangeValueType, UiDropdownOptionsType } from 'src/components/UI/inputs/DropDown';
import { UiFileUploader, UiFileUploaderOnChangeValueType } from 'src/components/UI/inputs/FileUploader';
import { UiMaskedInput, UiMaskedInputOnChangeValueType } from 'src/components/UI/inputs/MaskedInput';
import { UiInput } from 'src/components/UI/inputs/Text';
import { UiWYSIWYG } from 'src/components/UI/inputs/WYSIWYG';
import { UiModal } from 'src/components/UI/Modals';
import { UiToast } from 'src/components/UI/toasts';
import { UiButton } from 'src/components/UI/UiButton';
import { MAX_PAGE_SIZE_FOR_ONE_REQUEST, SHORT_DATE_FORMAT } from 'src/config';
import { useRequest } from 'src/hooks/useRequest';
import { apiGetProjectList } from 'src/api/projects';
import useGlobalStore from 'src/store';
import useModalsStore from 'src/store/modals';
import { CDate } from 'src/utils/CDate';
import { showErrorToast, showWarningToast } from 'src/utils/errors';
import { useTHelper } from 'src/utils/i18n';
import { useStateForm } from 'src/utils/stateForm';
import { RecursiveNullable } from 'src/utils/types';
import { shallow } from 'zustand/shallow';

import styles from './styles.module.scss';

export type CreateTaskModalAdditionalInfoType =
  | { projectId?: number; phaseId?: number; onCreate?: (task: TaskContract) => void }
  | undefined;

type FormValues = RecursiveNullable<{
  isBillable: boolean;
  description: string;
  name: string;
  project: number;
  phase: number;
  assignee: number;
  author: number;
  startDueDate: [string, string];
  time: string;
  files: UiFileUploaderOnChangeValueType;
}>;

export const CreateTaskModal: FC = () => {
  const { closeModal, additionalInfo } = useModalsStore(
    (state) => ({
      closeModal: () => state.toggleModals('createTask', false),
      additionalInfo: state.createTask.additionalInfo as CreateTaskModalAdditionalInfoType,
    }),
    shallow,
  );

  const user = useGlobalStore((state) => state.user.response as ProfileDetailsContract);

  const formProps = useStateForm<FormValues>({
    defaultValues: {
      isBillable: true,
    },
  });

  const tHelper = useTHelper('modals.createTask');

  const [projectList, setProjectList] = useState<ProjectContract[]>([]);

  const [projectOptions, setProjectOptions] = useState<UiDropdownOptionsType>([]);

  const [phaseOptions, setPhaseOptions] = useState<UiDropdownOptionsType>([]);

  const [assignees, setAssignees] = useState<TeamMemberContract[]>([]);

  const [saving, setSaving] = useState(false);

  const { apiCaller } = useRequest();

  /** find phases by project id */
  const findPhases = useCallback(
    (projectId: number) => {
      apiCaller(apiGetPhases, {
        projectId,
        pageSize: MAX_PAGE_SIZE_FOR_ONE_REQUEST,
        page: 0,
      }).then((phasesResponse) => {
        setPhaseOptions(
          phasesResponse.data.map(({ id, name, startDate, endDate }) => {
            const date = `${CDate.format(new Date(startDate), SHORT_DATE_FORMAT)} - ${CDate.format(
              new Date(endDate),
              SHORT_DATE_FORMAT,
            )}`;

            return {
              value: id,
              label: `${name} (${date})`,
              title: date,
            };
          }),
        );
      });
    },
    [apiCaller],
  );

  const findAssignees = useCallback(
    (projectId: number, projectOwnerId: number | null) => {
      apiCaller(apiGetProjectTeams, {
        projectId,
        pageSize: MAX_PAGE_SIZE_FOR_ONE_REQUEST,
        page: 0,
      }).then((teamsResponse) => {
        setAssignees(teamsResponse.data);

        const found = teamsResponse.data.find(({ userId }) => userId === projectOwnerId);

        const foundAuthor = teamsResponse.data.find(({ userId }) => userId === user.userId);

        formProps.setValue('assignee', found ? found.id : null);
        formProps.setValue('author', foundAuthor ? foundAuthor.id : null);
      });
    },
    [apiCaller, formProps, user.userId],
  );

  const assigneeOptions = useMemo(
    () =>
      assignees.map(({ id, name }) => ({
        value: id,
        label: name,
      })),
    [assignees],
  );

  /** get the initial project's info */
  useEffect(() => {
    apiCaller(apiGetProjectList, {
      pageSize: MAX_PAGE_SIZE_FOR_ONE_REQUEST,
      page: 0,
    }).then((response) => {
      setProjectList(response.data);

      setProjectOptions(
        response.data.map(({ id, name }) => ({
          value: id,
          label: name,
        })),
      );

      if (additionalInfo?.projectId) {
        formProps.setValue('project', additionalInfo.projectId);

        const found = response.data.find(({ id }) => id === additionalInfo.projectId);

        findPhases(additionalInfo.projectId);
        findAssignees(additionalInfo.projectId, found?.projectOwnerId ?? null);
      }

      if (additionalInfo?.phaseId) {
        formProps.setValue('phase', additionalInfo.phaseId);
      }
    });
  }, [additionalInfo?.phaseId, additionalInfo?.projectId, apiCaller, findAssignees, findPhases, formProps]);

  const onProjectChange = useCallback(
    (value: UiDropdownOnChangeValueType) => {
      formProps.setValue('phase', null);

      if (value) {
        const found = projectList.find(({ id }) => id === value.value);

        if (found) {
          findPhases(found.id);
          findAssignees(found.id, found.projectOwnerId ?? null);
        }
      } else {
        setPhaseOptions([]);
      }
    },
    [findAssignees, findPhases, formProps, projectList],
  );

  const onTimeBlur = useCallback(
    (value: UiMaskedInputOnChangeValueType) => {
      if (value) {
        const [hours, minutes] = value.split(':');

        if (+minutes > 60) {
          formProps.setValue('time', `${hours}:60`);
        }
      }
    },
    [formProps],
  );

  const onSubmit = useCallback(
    (formData: FormValues) => {
      const assigneeContract = assignees.find(({ id }) => id === formData.assignee);

      const authorContract = assignees.find(({ id }) => id === formData.author);

      if (assigneeContract && authorContract) {
        setSaving(true);

        const [startDate, dueDate] = formData.startDueDate as [string, string];

        const startDateDate = parseDateFromUiDatePicker(startDate);

        const dueDateDate = parseDateFromUiDatePicker(dueDate);

        const timeLogs = (() => {
          if (formData.time) {
            const [hours, minutes] = formData.time.split(':');

            // set worked time to the beginning of the task
            return [
              {
                id: 0,
                startTime: startDateDate,
                endTime: CDate.addTime(startDateDate, { hours: +hours, minutes: +minutes }),
                taskId: 0,
              },
            ];
          }

          return [];
        })();

        const model: TaskContract = {
          id: 0,
          name: formData.name as string,
          description: formData.description || '',
          startDate: startDateDate,
          dueDate: dueDateDate,
          status: 'NotStarted',
          isBillableHours: formData.isBillable as boolean,
          timeLogs,
          attachments: formData.files as FileContract[],
          comments: [],
          assignee: assigneeContract,
          author: authorContract,
          projectId: formData.project as number,
          phaseId: formData.phase as number,
        };

        apiCaller(apiCreateTask, model)
          .then(({ data }) => {
            UiToast.commonSuccessSaved();

            closeModal();

            if (isFunction(additionalInfo?.onCreate)) {
              additionalInfo?.onCreate(data);
            }
          })
          .catch(showErrorToast)
          .finally(() => {
            setSaving(false);
          });
      } else {
        UiToast.warning('Something went wrong. Create task modal');
      }
    },
    [additionalInfo, apiCaller, assignees, closeModal],
  );

  const toolbarButtons = useMemo(() => ['insertLink', 'bold', 'italic', 'underline'], []);

  return (
    <UiModal
      onClose={closeModal}
      className={styles.modal}
      bodyClassName={styles.body}
      header={tHelper('header')}
      headerClassName={styles.header}
    >
      <div className={styles.wrapper}>
        <div>
          <UiCheckbox formProps={formProps} name="isBillable" label={tHelper('billableLabel')} />

          <UiInput
            formProps={formProps}
            name="name"
            label={tHelper('nameLabel')}
            placeholder={tHelper('namePlaceholder')}
            required
          />

          <UiDropdown
            formProps={formProps}
            name="project"
            options={projectOptions}
            label={tHelper('projectLabel')}
            placeholder={tHelper('projectPlaceholder')}
            onChange={onProjectChange}
            maxHeight={100}
            isSearchable
            required
          />

          <UiDropdown
            formProps={formProps}
            name="phase"
            options={phaseOptions}
            label={tHelper('phaseLabel')}
            placeholder={tHelper('phasePlaceholder')}
            maxHeight={100}
            isSearchable
            required
          />

          <UiDropdown
            formProps={formProps}
            name="assignee"
            options={assigneeOptions}
            label={tHelper('assigneeLabel')}
            placeholder={tHelper('assigneePlaceholder')}
            maxHeight={100}
            isSearchable
            required
          />

          <UiDropdown
            formProps={formProps}
            name="author"
            options={assigneeOptions}
            label={tHelper('authorLabel')}
            placeholder={tHelper('authorPlaceholder')}
            maxHeight={100}
            isSearchable
            required
          />

          <UiDateRangePicker formProps={formProps} name="startDueDate" label={tHelper('startDueDateLabel')} required />

          <UiMaskedInput
            formProps={formProps}
            name="time"
            mask="99:99"
            label={tHelper('timeLabel')}
            placeholder={tHelper('timePlaceholder')}
            onBlur={onTimeBlur}
          />
        </div>
        <div>
          <UiWYSIWYG
            formProps={formProps}
            name="description"
            label={tHelper('descriptionLabel')}
            placeholder={tHelper('descriptionPlaceholder')}
            height={356}
            className={styles.wysiwyg}
            modal
            toolbarButtons={toolbarButtons}
          />

          <div className={styles.bottomBlock}>
            <UiButton color="green" loading={saving} onClick={formProps.onSubmit(onSubmit, showWarningToast)}>
              {tHelper('submit')}
            </UiButton>
          </div>
        </div>
      </div>
      <div>
        <UiFileUploader formProps={formProps} name="files" label={tHelper('attachments')} canRemove />
      </div>
    </UiModal>
  );
};
