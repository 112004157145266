import { TaskContract } from '@moonpanda/moonpanda.contracts';
import cloneDeep from 'lodash/cloneDeep';
import React, { FC } from 'react';

type Props = {
  model: TaskContract;
  updateTaskAPI: (subject: TaskContract, newSubject: TaskContract) => void;
};

export const TasksBillableChangeComponent: FC<Props> = ({ model, updateTaskAPI }) => (
  <span
    role="presentation"
    onClick={() => {
      const newSubject = cloneDeep(model);

      newSubject.isBillableHours = !model.isBillableHours;

      updateTaskAPI(model, newSubject);
    }}
  >
    {model.isBillableHours ? 'Y' : 'N'}
  </span>
);
