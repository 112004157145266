import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { InvoiceContract, InvoiceItemContract, InvoiceParameterTypeEnumContract } from '@moonpanda/moonpanda.contracts';
import { shallow } from 'zustand/shallow';
import isFunction from 'lodash/isFunction';

import { useStateFormValueWatch } from 'src/utils/stateForm/useFormWatch/useStateFormValueWatch';
import { CDate } from 'src/utils/CDate';
import { UiDatePicker, parseDateFromUiDatePicker } from 'src/components/UI/inputs/DatePicker';
import { UiDropdown, UiDropdownOnChangeValueType, UiDropdownOptionsType } from 'src/components/UI/inputs/DropDown';
import { UiInput } from 'src/components/UI/inputs/Text';
import { UiModal } from 'src/components/UI/Modals';
import { UiButton } from 'src/components/UI/UiButton';
import { SHORT_DATE_FORMAT } from 'src/config';
import { useRequest } from 'src/hooks/useRequest';
import useModalsStore from 'src/store/modals';
import { showErrorToast, showWarningToast } from 'src/utils/errors';
import { useTHelper } from 'src/utils/i18n';
import { useStateForm } from 'src/utils/stateForm';
import { UiToast } from 'src/components/UI/toasts';
import { apiDeleteInvoiceItem, apiUpdateInvoice } from 'src/api/invoicing';
import useProjectsListStore from 'src/components/UI/ProjectsDropdown/store';
import { BilledItemsGrid } from './BilledItemsGrid/BilledItemsGrid';

import styles from './styles.module.scss';

export type CreateInvoiceModalAdditionalInfoType = {
  invoiceData?: InvoiceContract;
  onCreateInvoice?: () => void;
  projectId?: number;
};

export type FormValues = {
  projectName: string;
  projectId: number | null;
  customerNameAndAddress: string;
  invoiceName: string;
  dateInvoiced: string;
  dueOn: string;
  note: string;
  invoiceParameters: {
    taxType: InvoiceParameterTypeEnumContract;
    tax: number;
    discountType: InvoiceParameterTypeEnumContract | null;
    discount?: number | null;
  };
};

export type InvoiceItemContractWithLocalId = InvoiceItemContract & { localId: number };

export const CreateInvoiceModal: FC = () => {
  const { closeModal, additionalInfo, toggleModals } = useModalsStore(
    (state) => ({
      closeModal: () => state.toggleModals('createInvoice', false),
      additionalInfo: state.createInvoice.additionalInfo as CreateInvoiceModalAdditionalInfoType,
      toggleModals: state.toggleModals,
    }),
    shallow,
  );

  const formProps = useStateForm<FormValues>({
    defaultValues: {
      projectName: '',
      projectId: null,
      customerNameAndAddress: '',
      invoiceName: '',
      dateInvoiced: '',
      dueOn: '',
      note: '',
      invoiceParameters: {
        taxType: 'Percent',
        tax: 0,
        discountType: 'Percent',
        discount: 0,
      },
    },
  });

  const [tax, discount, discountType, taxType] = useStateFormValueWatch(formProps.getSubscribeProps, [
    'invoiceParameters.tax',
    'invoiceParameters.discount',
    'invoiceParameters.discountType',
    'invoiceParameters.taxType',
  ]);

  const tHelper = useTHelper('modals.createInvoice');

  const [projectOptions, setProjectOptions] = useState<UiDropdownOptionsType>([]);

  const [saving, setSaving] = useState(false);

  const [invoicingTableData, setInvoicingTableData] = useState<InvoiceItemContractWithLocalId[]>([
    {
      description: '',
      quantity: 0,
      cost: 0,
      amount: 0,
      id: 0,
      localId: 0,
    },
  ]);

  const [total, setTotal] = useState(0);

  const handleAddLineItem = () => {
    setInvoicingTableData((prev) => [
      ...prev,
      {
        description: '',
        quantity: 0,
        cost: 0,
        amount: 0,
        localId: prev.length ? (prev[prev.length - 1].localId || 0) + 1 : 0,
        id: 0,
      },
    ]);
  };

  const isAllFieldsFilled = useMemo(
    () => invoicingTableData.every((item) => !!item.description && !!item.quantity && !!item?.cost),
    [invoicingTableData],
  );

  const subTotal = useMemo(
    () => invoicingTableData.reduce((acc, el) => acc + Number(el.amount), 0),
    [invoicingTableData],
  );

  const handleChangeItem = ({ id, value, key, localId }: { [key: string]: string | number }) => {
    setInvoicingTableData((prev) => {
      const index = prev.findIndex((el) => (el.id ? el.id === id : el.localId === localId));

      if (index !== -1) {
        const updatedRow = {
          ...prev[index],
          [key]: value,
        };

        if (key === 'quantity' || key === 'cost') {
          updatedRow.amount = Number(updatedRow.quantity) * Number(updatedRow.cost);
        }
        const updatedData = [...prev];

        updatedData[index] = updatedRow;

        return updatedData;
      }

      return prev;
    });
  };

  const { apiCaller } = useRequest();

  const handleDeleteRow = useCallback(
    ({ invoiceItemId, localId }: { invoiceItemId: number; localId: number }) => {
      if (additionalInfo.invoiceData && invoiceItemId) {
        apiCaller(
          apiDeleteInvoiceItem,
          {
            invoiceId: additionalInfo.invoiceData.id,
            invoiceItemId,
          },
          { key: additionalInfo.invoiceData.id },
        )
          .then(() => {
            UiToast.commonSuccessDeleted();
            setInvoicingTableData((data) => data.filter((el) => el.id !== invoiceItemId));
          })
          .catch(showErrorToast);
      } else {
        setInvoicingTableData((data) => data.filter((el) => el.localId !== localId));
      }
    },
    [additionalInfo, apiCaller],
  );

  const projects = useProjectsListStore((state) => state.projects);

  useEffect(() => {
    setProjectOptions(
      (projects.response || []).map(({ id, name }) => ({
        value: id,
        label: name,
      })),
    );
  }, [projects.response]);

  useEffect(() => {
    if (additionalInfo?.projectId) {
      const projectName = projectOptions.find((project) => project.value === additionalInfo.projectId)?.label;

      formProps.setValue({ projectId: additionalInfo.projectId, projectName });
    }
  }, [additionalInfo.projectId, formProps, projectOptions]);

  const onProjectChange = useCallback(
    (value: UiDropdownOnChangeValueType) => {
      formProps.setValue({ projectName: value?.label, projectId: value?.value });
    },
    [formProps],
  );

  useEffect(() => {
    if (tax) {
      if (taxType === 'Percent') {
        const taxAmount = (subTotal * Number(tax)) / 100;

        setTotal(taxAmount + subTotal);
      } else {
        setTotal(subTotal + Number(tax));
      }
    } else {
      setTotal(subTotal);
    }
    if (discount) {
      if (discountType === 'Percent') {
        const subTotalWithTax = taxType === 'Number' ? subTotal + tax : subTotal + (subTotal * tax) / 100;

        const discountAmount = (subTotalWithTax * Number(discount)) / 100;

        setTotal(subTotalWithTax - discountAmount);
      } else {
        setTotal((prev: number) => prev - Number(discount));
      }
    }
  }, [taxType, setTotal, subTotal, tax, discount, discountType, total]);

  const onSubmit = useCallback(
    (formData: FormValues) => {
      const dateInvoiced = parseDateFromUiDatePicker(formData.dateInvoiced as string);

      const dueDate = parseDateFromUiDatePicker(formData.dueOn as string);

      const model: InvoiceContract & { projectId: number } = {
        id: additionalInfo?.invoiceData?.id || 0,
        projectName: formData.projectName,
        projectId: formData.projectId as number,
        customerData: formData.customerNameAndAddress,
        invoiceNameId: formData.invoiceName,
        invoiceDate: dateInvoiced,
        dueDate,
        note: formData.note,
        total: total || 0,
        invoiceParameters: {
          taxType: formData.invoiceParameters.taxType,
          tax: formData.invoiceParameters.tax,
          discountType: formData.invoiceParameters.discountType,
          discount: formData.invoiceParameters.discount,
        },
        items: invoicingTableData.map((el) => ({
          ...el,
          id: additionalInfo.invoiceData ? el.id : 0,
        })),
      };

      if (additionalInfo.invoiceData) {
        if (additionalInfo.invoiceData.id === 0) {
          closeModal();
          toggleModals('viewInvoice', true, { invoiceModel: model, onCreateInvoice: additionalInfo.onCreateInvoice });
        } else {
          apiCaller(apiUpdateInvoice, model)
            .then(() => {
              UiToast.commonSuccessSaved();

              closeModal();

              if (isFunction(additionalInfo?.onCreateInvoice)) {
                additionalInfo?.onCreateInvoice();
              }
            })
            .catch(showErrorToast)
            .finally(() => {
              setSaving(false);
            });
        }
      } else {
        closeModal();
        toggleModals('viewInvoice', true, { invoiceModel: model, onCreateInvoice: additionalInfo.onCreateInvoice });
      }
    },
    [additionalInfo, apiCaller, closeModal, invoicingTableData, toggleModals, total],
  );

  useEffect(() => {
    if (additionalInfo.invoiceData) {
      formProps.reset({
        projectName: additionalInfo.invoiceData.projectName,
        projectId: additionalInfo.invoiceData.projectId,
        customerNameAndAddress: additionalInfo.invoiceData.customerData,
        invoiceName: additionalInfo.invoiceData.invoiceNameId,
        dateInvoiced: CDate.format(new Date(additionalInfo.invoiceData.invoiceDate), SHORT_DATE_FORMAT),
        dueOn: CDate.format(new Date(additionalInfo.invoiceData.dueDate), SHORT_DATE_FORMAT),
        note: additionalInfo.invoiceData.note,
        invoiceParameters: {
          taxType: additionalInfo.invoiceData.invoiceParameters.taxType,
          tax: additionalInfo.invoiceData.invoiceParameters.tax,
          discountType: additionalInfo.invoiceData.invoiceParameters.discountType,
          discount: additionalInfo.invoiceData.invoiceParameters.discount,
        },
      });
      setInvoicingTableData(additionalInfo.invoiceData.items as InvoiceItemContractWithLocalId[]);
    }
  }, [additionalInfo.invoiceData, formProps]);

  return (
    <UiModal
      onClose={closeModal}
      className={styles.modal}
      bodyClassName={styles.body}
      header={tHelper('header')}
      headerClassName={styles.header}
      contentClassName={styles.content}
      bodyContentClassName={styles.bodyContent}
    >
      <div className={styles.wrapper}>
        <div className={styles.form}>
          <UiDropdown
            formProps={formProps}
            name="projectId"
            options={projectOptions}
            label={tHelper('projectNameLabel')}
            placeholder={tHelper('projectNamePlaceholder')}
            onChange={onProjectChange}
            maxHeight={100}
            isSearchable
            required
          />

          <UiInput
            formProps={formProps}
            name="customerNameAndAddress"
            label={tHelper('customerNameAndAddressLabel')}
            className={styles.customerNameInput}
            required
            type="textarea"
          />

          <UiInput
            formProps={formProps}
            name="invoiceName"
            label={tHelper('invoiceNameLabel')}
            placeholder={tHelper('invoiceNamePlaceholder')}
            required
          />

          <UiDatePicker formProps={formProps} name="dateInvoiced" required label={tHelper('dateInvoicedLabel')} />
          <UiDatePicker
            formProps={formProps}
            name="dueOn"
            required
            label={tHelper('dueOnLabel')}
            minDate={new Date(Date.now()).toISOString()}
          />
          <UiInput
            formProps={formProps}
            name="note"
            label={tHelper('noteLabel')}
            placeholder={tHelper('notePlaceholder')}
          />
        </div>
        <div className={styles.divider} />
        <div className={styles.itemsToBeBilled}>
          <BilledItemsGrid
            data={invoicingTableData}
            handleAddLineItem={handleAddLineItem}
            handleChangeItem={handleChangeItem}
            subTotal={subTotal}
            handleDeleteRow={handleDeleteRow}
            formProps={formProps}
            total={total}
            tax={tax}
            discount={discount}
            discountType={discountType}
            taxType={taxType}
          />
          <UiButton
            color="dark"
            disabled={!isAllFieldsFilled}
            loading={saving}
            className={styles.submitBtn}
            onClick={formProps.onSubmit(onSubmit, showWarningToast)}
          >
            {additionalInfo.invoiceData ? tHelper('submitForEdit') : tHelper('submit')}
          </UiButton>
        </div>
      </div>
    </UiModal>
  );
};
