import React, { FC } from 'react';
import { Trans } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { UiAvatar } from 'src/components/common/Avatar';
import { UiPopover } from 'src/components/UI/Popover';
import { UiButton } from 'src/components/UI/UiButton';
import useGlobalStore from 'src/store';
import { useTHelper } from 'src/utils/i18n';

export const SidebarAvatar: FC = () => {
  const user = useGlobalStore((state) => state.user.response);

  const logOut = useGlobalStore((state) => state.logOut);

  const navigate = useNavigate();

  const tHelper = useTHelper('sidebar.logout');

  return (
    <>
      <UiPopover
        className="logOutPopoverWrapper"
        /* eslint-disable-next-line react/no-unstable-nested-components */
        content={(close) => (
          <div className="logOutPopover">
            <div>{tHelper('text')}</div>
            <UiButton
              onClick={() => {
                close();

                logOut(navigate);
              }}
              color="red"
            >
              {tHelper('button')}
            </UiButton>
          </div>
        )}
      >
        <UiAvatar data={user} width={46} />
      </UiPopover>
      <div>
        <Trans
          i18nKey="sidebar.header"
          values={{
            firstName: user?.firstName || 'MoonPanda',
            lastName: user?.lastName || 'Architecture',
          }}
        >
          <p>1</p>
          <p>1</p>
        </Trans>
      </div>
    </>
  );
};
