import { MutableRefObject, useCallback, useEffect, useRef } from 'react';
import { SafeAnyType } from 'src/utils/safeAny';

export const useRefClickOutside = <T extends HTMLElement>(
  condition: boolean,
  close: (event: Event) => void,
  minDelay = 0,
): MutableRefObject<T> => {
  const ref = useRef<SafeAnyType>(null);

  const allowClosing = useRef(false);

  useEffect(() => {
    if (minDelay) {
      setTimeout(() => {
        allowClosing.current = true;
      }, minDelay);
    }

    return () => {
      allowClosing.current = false;
    };
  }, [minDelay]);

  const localClose = useCallback(
    (event: Event) => {
      if (minDelay) {
        if (allowClosing.current) {
          close(event);
        }
      } else {
        close(event);
      }
    },
    [close, minDelay],
  );

  const onOuterClick = useCallback((event: Event) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      localClose(event);
    }
    // do not depend on close function. it may be a new function on every render
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (condition) {
      window.addEventListener('mousedown', onOuterClick);
    } else {
      window.removeEventListener('mousedown', onOuterClick);
    }

    return () => {
      window.removeEventListener('mousedown', onOuterClick);
    };
  }, [condition, onOuterClick]);

  return ref;
};
