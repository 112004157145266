import { AddToProjectTeamContract, TeamMemberContract, UserContract } from '@moonpanda/moonpanda.contracts';
import classNames from 'classnames';
import { compare } from 'fast-json-patch';
import React, { FC, useCallback, useEffect, useState } from 'react';

import { apiAccountGetTeams } from 'src/api/account';
import { apiGetProjectData } from 'src/api/projects';
import { apiCreateProjectTeam, apiDeleteProjectUser, apiUpdateProjectTeam } from 'src/api/team';
import { mergerUsers } from 'src/components/common/modals/AddTeamMembersToProject/mergerUsers';
import { UiCheckbox } from 'src/components/UI/inputs/Checkbox';
import { UiFormatNumberInput } from 'src/components/UI/inputs/FormatNumber';
import { UiInput } from 'src/components/UI/inputs/Text';
import { UiScrollbar } from 'src/components/UI/Scrollbar';
import { UiToast } from 'src/components/UI/toasts';
import { UiButton } from 'src/components/UI/UiButton';
import { EMAIL_PATTERN } from 'src/config';
import { FNPropsType, useRequest } from 'src/hooks/useRequest';
import { ApiPatchOperationType } from 'src/models/API';
import { prepareDataToPatch } from 'src/utils/http';
import i18n, { useTHelper } from 'src/utils/i18n';
import { StateFormReturnType } from 'src/utils/stateForm';
import { useStateFormValueWatch } from 'src/utils/stateForm/useFormWatch/useStateFormValueWatch';

import styles from './styles.module.scss';

export const projectsAddTeamMembersSaveTeamMembers = async ({
  initialUsers,
  currentUsers,
  projectId,
  apiCaller,
  isModal,
}: {
  initialUsers: ProjectsAddTeamMembersLocalUserType[];
  currentUsers: ProjectsAddTeamMembersLocalUserType[];
  projectId: number;
  apiCaller: FNPropsType;
  isModal?: boolean;
}): Promise<{ deleted: number[]; added: TeamMemberContract[] } | false> => {
  if (currentUsers.filter(({ selected }) => selected).length === 0) {
    UiToast.warning(i18n.t('modals.addTeamMembersToProject.teamMembersEmptyWarning'));

    return false;
  }

  const toDelete: number[] = [];

  const toAdd: number[] = [];

  const toUpdate: number[] = [];

  let createdMembers: TeamMemberContract[] = [];

  initialUsers.forEach((initialUser) => {
    let currentUser: ProjectsAddTeamMembersLocalUserType | undefined;

    if (initialUser.id) {
      const foundById = currentUsers.find(({ id }) => id === initialUser.id);

      if (foundById) {
        currentUser = foundById;
      }
    } else {
      // newly added
      const foundByName = currentUsers.find(({ name }) => name === initialUser.name);

      if (foundByName) {
        currentUser = foundByName;
      }
    }

    if (
      (initialUser.selected && (!currentUser || !currentUser.selected)) || // unselected
      (initialUser && currentUser === undefined && initialUser.id >= 0) // deleted by cross
    ) {
      // delete
      toDelete.push(initialUser.id);
    } else if (currentUser?.selected && !initialUser.selected) {
      // create
      toAdd.push(currentUser.userId);
    } else if (currentUser?.id && initialUser.baseRate !== currentUser.baseRate) {
      // update info
      toUpdate.push(currentUser.id);
    }
  });

  const toCreate: AddToProjectTeamContract & { projectId: number } = {
    projectId,
    usersToAddToProject: currentUsers.filter(({ userId }) => toAdd.includes(userId)),
    usersToInvite: currentUsers
      .filter(({ userId }) => userId === 0)
      .filter(({ ignoreInvite }) => ignoreInvite !== true)
      .map(({ email, baseRate }) => ({
        email,
        hourlyRate: baseRate,
      })),
  };

  const toUp: (ApiPatchOperationType & { projectId: number; userId: number })[] = [];

  toUpdate.forEach((updateUserId) => {
    const initialUser = initialUsers.find(({ id }) => id === updateUserId);

    const currentUser = currentUsers.find(({ id }) => id === updateUserId);

    if (initialUser && currentUser) {
      const diff = compare(
        {
          name: initialUser.name,
          hourlyRate: initialUser.baseRate,
        },
        {
          name: currentUser.name,
          hourlyRate: currentUser.baseRate,
        },
      );

      toUp.push(
        prepareDataToPatch(diff, {
          projectId,
          userId: updateUserId,
        }),
      );
    }
  });

  await Promise.allSettled(
    toDelete.map((userId) => apiCaller(apiDeleteProjectUser, { projectId, userId }, { key: userId })),
  );

  if (toCreate.usersToAddToProject.length || toCreate.usersToInvite.length) {
    await apiCaller(apiCreateProjectTeam, toCreate).then((response) => {
      createdMembers = response.data;
    });
  }

  if (toUp.length) {
    await Promise.allSettled(toUp.map((data) => apiCaller(apiUpdateProjectTeam, data, { key: data.userId })));
  }

  if (isModal) {
    UiToast.commonSuccessSaved();
  }

  return { deleted: toDelete, added: createdMembers };
};

export type ProjectsAddTeamMembersLocalUserType = UserContract & {
  id: number;
  selected: boolean;
  name: string;
  index: number;
  ignoreInvite?: boolean;
};

export type ProjectsAddTeamMembersComponentFormValues = {
  teamMembers: {
    needToCreate: boolean;
    search: string;
    users: ProjectsAddTeamMembersLocalUserType[];
    initialData: TeamMemberContract[];
    newEmail: string | null;
  };
};

type Props = {
  projectId: number;
  userId: number;
  formProps: StateFormReturnType<ProjectsAddTeamMembersComponentFormValues>;

  closeModal?: () => void;
};

export const ProjectsAddTeamMembersComponent: FC<Props> = ({ formProps, projectId, userId, closeModal }) => {
  const tHelper = useTHelper('modals.addTeamMembersToProject');

  const { apiCaller } = useRequest();

  const [loading, setLoading] = useState(true);

  const [showInviteSent, setShowInviteSent] = useState(false);

  const [projectOwnerId, setProjectOwnerId] = useState(-1);

  useEffect(() => {
    Promise.all([apiCaller(apiAccountGetTeams), apiCaller(apiGetProjectData, { projectId })])
      .then(([accountTeam, projectData]) => {
        setProjectOwnerId(projectData.data.projectOwnerId || -1);

        formProps.reset(
          {
            teamMembers: {
              initialData: projectData.data.team,
              search: '',
              needToCreate: projectData.data.team.length === 0,
              users: mergerUsers(accountTeam.data, projectData.data.team),
            },
          },
          {
            resetInitialForm: true,
            mergeWithPrevious: true,
          },
        );
      })
      .finally(() => {
        setLoading(false);
      });
  }, [apiCaller, formProps, projectId, userId]);

  const getName = (index: number, name?: string) => `teamMembers.users[${index}]${name ? `.${name}` : ''}`;

  const [users] = useStateFormValueWatch<ProjectsAddTeamMembersComponentFormValues, ['teamMembers.users']>(
    formProps.getSubscribeProps,
    ['teamMembers.users'],
  );

  const addNewUser = useCallback(
    ({ name, email, baseRate = 0 }: { name: string; email: string; baseRate?: number }) => {
      const currentUsers = formProps.getValue('teamMembers.users');

      const currentUsersNumber = currentUsers.length;

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      formProps.setValue(getName(currentUsersNumber), {
        userId: 0,
        firstName: '',
        lastName: '',
        title: '',
        baseRate,
        email,
        selected: true,
        name,
        index: currentUsersNumber,
      });
    },
    [formProps],
  );

  // const onDeleteUser = useCallback(
  //   (user: ProjectsAddTeamMembersLocalUserType) => {
  //     formProps.setValue(
  //       'teamMembers.users',
  //       formProps.getValue('teamMembers.users').filter(({ id }) => id !== user.id),
  //     );
  //   },
  //   [formProps],
  // );

  return (
    <div className={styles.addTeamMembersWrapper}>
      <div className={styles.addTeamMembers}>
        <UiScrollbar height={381}>
          <div
            className={classNames(styles.addTeamMembersInner, {
              loading,
            })}
          >
            <div className={styles.head}>
              <div>{tHelper('columns.name')}</div> <div>{tHelper('columns.rate')}</div>
            </div>
            {/* <div className={styles.search}> */}
            {/* <UiSearchInput */}
            {/*   placeholder={tHelper('searchPlaceholder')} */}
            {/*   className="app-search" */}
            {/*   onSearch={(text: string) => { */}
            {/*     search({ query: text, userSearch: true }).then(() => { */}
            {/*       setOpen(true); */}
            {/*     }); */}
            {/*   }} */}
            {/*   ref={searchInputRef} */}
            {/*   disabled={loading || searchLoading} */}
            {/*   active={open} */}
            {/*   onClickOutSide={() => { */}
            {/*     searchInputRef.current?.clear(); */}
            {/*     clear(); */}
            {/*     setOpen(false); */}
            {/*   }} */}
            {/*   childrenAfter={ */}
            {/*     open ? ( */}
            {/*       <div className={classNames(styles.results, 'searchResults')}> */}
            {/*         <UiScrollbar autoHeight> */}
            {/*           <div className={styles.block}> */}
            {/*             {isEmptySearch ? tHelper('noResults') : <>{renderRow('users')}</>} */}
            {/*           </div> */}
            {/*         </UiScrollbar> */}
            {/*       </div> */}
            {/*     ) : null */}
            {/*   } */}
            {/* /> */}
            {/* </div> */}
            <div className={styles.usersWrapper}>
              {users.map((user, index) => {
                const disabled = user.userId === projectOwnerId;

                return (
                  <div key={user.id} className={styles.row}>
                    <UiCheckbox formProps={formProps} name={getName(index, 'selected')} disabled={disabled} />
                    <UiInput formProps={formProps} name={getName(index, 'name')} disabled />
                    <UiFormatNumberInput
                      formProps={formProps}
                      name={getName(index, 'baseRate')}
                      min={1}
                      errorLabel={tHelper('columns.rate')}
                      required
                    />
                    {/* <IconClose */}
                    {/*   className={disabled ? undefined : styles.deleteEmail} */}
                    {/*   onClick={() => (disabled ? undefined : onDeleteUser(user))} */}
                    {/* /> */}
                  </div>
                );
              })}
            </div>
          </div>
        </UiScrollbar>
      </div>
      <div className={styles.addNewUser}>
        <h4>{tHelper('addNewUser.title')}</h4>
        <p>{tHelper('addNewUser.text')}</p>

        <div className={styles.addNewUserEmail}>
          <UiInput
            formProps={formProps}
            name="teamMembers.newEmail"
            placeholder={tHelper('addNewUser.emailPlaceholder')}
          />
          <UiButton
            onClick={() => {
              const value = formProps.getValue('teamMembers.newEmail')?.trim();

              /** check validity of the email */
              if ((value && !EMAIL_PATTERN.test(value)) || !value) {
                UiToast.warning(tHelper('addNewUser.emailInvalidWarning'));

                return;
              }

              /** check if there is the same email */
              const currentUsers = formProps.getValue('teamMembers.users');

              if (~currentUsers.findIndex(({ email }) => email === value)) {
                UiToast.warning(tHelper('addNewUser.emailExistsWarning'));

                return;
              }

              addNewUser({ name: value, email: value });

              formProps.setValue('teamMembers.newEmail', '');

              setShowInviteSent(true);

              setTimeout(() => {
                setShowInviteSent(false);
              }, 5000);
            }}
          >
            {tHelper('addNewUser.buttonText')}
          </UiButton>
        </div>
      </div>

      {showInviteSent && !closeModal && (
        <div className={styles.inviteSent}>
          <svg xmlns="http://www.w3.org/2000/svg" width="13" height="10" viewBox="0 0 13 10" fill="none">
            <path
              d="M3.43531 10L0 6.71476L0.926046 5.81732L3.43531 8.20513L12.0571 0L13 0.881392L3.43531 10Z"
              fill="#00C712"
            />
          </svg>
          {tHelper('addNewUser.inviteSent')}
        </div>
      )}
    </div>
  );
};
