/* eslint-disable max-len */
import { FC } from 'react';
import { CommonIconProps } from 'src/utils/iconProps';

type Props = CommonIconProps & {
  onClick?: () => void;
};

export const IconCopyLink: FC<Props> = ({ className, onClick }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="35"
    height="20"
    viewBox="0 0 35 20"
    fill="none"
    className={className}
    onClick={onClick}
  >
    <path
      d="M28.3 0.360352H6C2.68629 0.360352 0 3.04664 0 6.36035V13.9604C0 17.2741 2.68629 19.9604 6 19.9604H28.3C31.6138 19.9604 34.3 17.2741 34.3 13.9604V6.36035C34.3 3.04664 31.6138 0.360352 28.3 0.360352Z"
      fill="#3F5B64"
    />
    <path
      transform="translate(7,1)"
      d="M10.6862 12.1068L9.16752 13.5355C8.66162 14.0114 8.06268 14.2389 7.3707 14.2178C6.67872 14.1967 6.09479 13.9333 5.6189 13.4274C5.14302 12.9216 4.91559 12.3226 4.93662 11.6305C4.95764 10.9383 5.2211 10.3543 5.72701 9.87836L7.24564 8.44968L7.81418 9.05401L6.29555 10.4827C5.95721 10.801 5.78097 11.192 5.76682 11.6556C5.75267 12.1193 5.90474 12.5203 6.22304 12.8586C6.54135 13.197 6.93232 13.3732 7.39598 13.3874C7.85964 13.4015 8.26064 13.2494 8.59898 12.9311L10.1176 11.5025L10.6862 12.1068ZM8.21968 11.5499L7.65113 10.9455L10.6729 8.10272L11.2415 8.70706L8.21968 11.5499ZM11.6469 11.2029L11.0784 10.5986L12.597 9.16988C12.9354 8.85158 13.1116 8.4606 13.1258 7.99694C13.1399 7.53328 12.9878 7.13229 12.6695 6.79395C12.3512 6.45561 11.9603 6.27936 11.4966 6.26521C11.0329 6.25106 10.6319 6.40314 10.2936 6.72144L8.77497 8.15013L8.20643 7.54579L9.72506 6.1171C10.231 5.64116 10.8299 5.41373 11.5219 5.4348C12.2139 5.45587 12.7978 5.71933 13.2737 6.22517C13.7496 6.73101 13.977 7.32999 13.956 8.02212C13.9349 8.71425 13.6715 9.29828 13.1656 9.77422L11.6469 11.2029Z"
      fill="white"
    />
  </svg>
);
