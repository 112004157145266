import { FileContract } from '@moonpanda/moonpanda.contracts';
import React, { FC, useState } from 'react';

import { IconDownload } from 'src/components/icons/Download';
import { downloadBlobAsFile, useDownloadFile } from 'src/utils/file';

type Props = {
  file: FileContract;
};

export const DocumentsPageDownloadFileButton: FC<Props> = ({ file }) => {
  const { downLoad } = useDownloadFile();

  const [loading, setLoading] = useState(false);

  return (
    <IconDownload
      disabled={loading}
      onClick={() => {
        setLoading(true);

        downLoad(file)
          .then((response) => {
            if (response) {
              downloadBlobAsFile(response, file.fileName, {
                nameAsIs: true,
              });
            }
          })
          .finally(() => {
            setLoading(false);
          });
      }}
    />
  );
};
