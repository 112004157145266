import { EventContract } from '@moonpanda/moonpanda.contracts';
import classNames from 'classnames';
import React, { FC } from 'react';

import useProjectsListStore from 'src/components/UI/ProjectsDropdown/store';
import { UiWidget } from 'src/components/UI/widgets';
import useDashboardStore from 'src/pages/Dashboard/store';
import { IconHeader } from 'src/pages/Dashboard/widgets/upcomingEvents/icons/Header';
import { IconArrow } from 'src/pages/Dashboard/widgets/upcomingEvents/icons/Icon';
import useModalsStore from 'src/store/modals';
import { CDate } from 'src/utils/CDate';
import { useTHelper } from 'src/utils/i18n';

export const WidgetUpcomingEventsPeriod = ({ data, text }: { data: EventContract[]; text: string }) => {
  const toggleModals = useModalsStore((state) => state.toggleModals);

  const { getColorById } = useProjectsListStore((state) => state);

  return (
    <>
      {(data.length || 0) > 0 && (
        <div className={classNames('period', text.toLocaleLowerCase())}>
          <div className="periodHeader">{text}</div>

          <div className="periodBlocks">
            {data.map((event) => (
              <div
                className="periodBlock"
                key={event.id}
                title={event.name}
                role="presentation"
                onClick={() => {
                  toggleModals('eventModal', true, {
                    eventId: event.id,
                  });
                }}
              >
                <div className="color" style={{ backgroundColor: getColorById(event.projectId) }} />
                <div className="name">{event.name}</div>
                <div className="time">{CDate.format(new Date(event.startDate), 'HH:mmaaa')}</div>
                <IconArrow />
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export const WidgetUpcomingEvents: FC = () => {
  const tHelper = useTHelper('widgets.upcomingEvents');

  const { widgetUpcomingEventsData, loading } = useDashboardStore((state) => ({
    widgetUpcomingEventsData: state.upcomingEvents.response,
    loading: state.upcomingEvents.loading,
  }));

  return (
    <UiWidget loading={loading} className="upcomingWidget widget">
      <div>
        <IconHeader />
      </div>

      <div className="header">{tHelper('title')}</div>

      <WidgetUpcomingEventsPeriod data={widgetUpcomingEventsData?.today || []} text={tHelper('today')} />
      <WidgetUpcomingEventsPeriod data={widgetUpcomingEventsData?.later || []} text={tHelper('later')} />
    </UiWidget>
  );
};
