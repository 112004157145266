import { toast } from 'react-toastify';
import i18n from 'src/utils/i18n';

export type UiToastType = (
  content: Parameters<typeof toast>[0],
  options?: Parameters<typeof toast>[1],
) => ReturnType<typeof toast>;

const BaseToast =
  (type: 'info' | 'success' | 'warning' | 'error'): UiToastType =>
  (content, options) =>
    toast[type](content, {
      ...options,
    });

export const UiToast = {
  success: BaseToast('success'),
  error: BaseToast('error'),
  warning: BaseToast('warning'),
  info: BaseToast('info'),
  commonSuccessSaved: (): string | number => BaseToast('success')(i18n.t('common.saved') as string),
  commonSuccessDeleted: (): string | number => BaseToast('success')(i18n.t('common.deleted') as string),
};
