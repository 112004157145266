import { FileContract, ProjectContract } from '@moonpanda/moonpanda.contracts';
import classNames from 'classnames';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { apiGetPhaseStatistics } from 'src/api/phases';
import { UiProgress } from 'src/components/common/Progress';
import { UiDropdownRefProps } from 'src/components/UI/inputs/DropDown';
import { UiDropdownWithInput } from 'src/components/UI/inputs/DropDrownWithInput';
import { UiFileUploader } from 'src/components/UI/inputs/FileUploader';
import { defaultProjectColor } from 'src/config';
import { IconEmptyImage } from 'src/pages/Projects/view/widgets/View/icons/EmptyImage';
import { useTHelper } from 'src/utils/i18n';
import { getQueryParamQuiet } from 'src/utils/queryParams';
import { useStateForm } from 'src/utils/stateForm';

import styles from 'src/pages/Projects/view/widgets/View/styles.module.scss';
import { useStateFormValueWatch } from 'src/utils/stateForm/useFormWatch/useStateFormValueWatch';

type Props = {
  model: ProjectContract;
  onImageChange: (file: FileContract) => void;
};

type FormValues = {
  cover: FileContract[] | null;
  phase: number | null;
};

export const ProjectViewWidgetComponent: FC<Props> = ({ model, onImageChange }) => {
  const tHelper = useTHelper('pages.projects.projectPage.widgets.view');

  const [, setSearchParams] = useSearchParams();

  const [statistics, setStatistics] = useState({
    totalTasks: 0,
    completedTasks: 0,
  });

  const taskProgress =
    100 * ((model.projectStatistics?.completedTasks || 0) / (model.projectStatistics?.totalTasks || 1));

  const getStatistics = useCallback(
    (phaseId?: number | null) => {
      if (phaseId) {
        apiGetPhaseStatistics({ projectId: model.id, phaseId }).then(({ data }) => {
          setStatistics({
            completedTasks: data.completedTasks,
            totalTasks: data.totalTasks,
          });
        });
      } else {
        setStatistics({
          totalTasks: model.projectStatistics?.totalTasks || 0,
          completedTasks: model.projectStatistics?.completedTasks || 0,
        });
      }
    },
    [model.id, model.projectStatistics?.completedTasks, model.projectStatistics?.totalTasks],
  );

  const formProps = useStateForm<FormValues>({
    defaultValues: {
      cover: model.cover ? [model.cover] : null,
      phase: (() => {
        const phaseId = getQueryParamQuiet('phaseId');

        return phaseId ? +phaseId : null;
      })(),
    },
  });

  const dropDownRef = useRef<UiDropdownRefProps>(null);

  const [showDropdown, setShowDropdown] = useState(false);

  const phasesToSelect = useMemo(
    () => [
      { value: null as unknown as number, label: tHelper('allOptions') },
      ...model.phase.map(({ name, id }) => ({
        value: id,
        label: name,
      })),
    ],
    [model.phase, tHelper],
  );

  const phaseId = useStateFormValueWatch<FormValues['phase']>(formProps.getSubscribeProps, 'phase');

  useEffect(() => {
    getStatistics(phaseId);

    setSearchParams((state) => {
      if (phaseId) {
        state.set('phaseId', phaseId.toString());
      } else {
        state.delete('phaseId');
      }

      return state;
    });
    // don't depend on setSearchParams
    // eslint-disable-next-line
  }, [getStatistics, phaseId]);

  const currentPhaseName = useMemo(() => {
    const found = phasesToSelect.find(({ value }) => value === phaseId);

    return found?.label || '';
  }, [phaseId, phasesToSelect]);

  const isActivePhase = phasesToSelect.length > 0;

  const projectColor = model.color || defaultProjectColor;

  return (
    <>
      <div className={styles.imageWrapper}>
        <UiFileUploader
          formProps={formProps}
          name="cover"
          className={styles.files}
          accept="image/jpeg,image/png"
          maxNumber={1}
          privateStorage={false}
          renderProps={({ onOpen, files }) => (
            <div role="presentation" className={styles.emptyImage} onClick={onOpen}>
              {files.length ? <img src={files[0].url} alt="" loading="lazy" /> : <IconEmptyImage />}
            </div>
          )}
          onUpload={(_, loadedFiles) => {
            onImageChange(loadedFiles[0]);
          }}
        />
      </div>

      <div className={styles.content}>
        {model.number && (
          <div className={styles.projectNumber} style={{ backgroundColor: projectColor }}>
            {model.number}
          </div>
        )}
        <div className={styles.header} title={model.name}>
          {model.name}
        </div>
        <div
          role="presentation"
          className={styles.phase}
          title={currentPhaseName}
          onClick={() => {
            if (isActivePhase) {
              setShowDropdown(true);

              dropDownRef.current?.open();
            }
          }}
        >
          {tHelper('phase', {
            name: currentPhaseName,
          })}
          {isActivePhase && (
            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="6" viewBox="0 0 11 6" fill="none">
              <path
                d="M9.81018 1.1001L5.50018 4.5401L1.2002 1.1001"
                stroke="#455A63"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          )}
        </div>

        {isActivePhase && (
          <UiDropdownWithInput
            wrapperClassName={classNames(styles.phaseDropDown, showDropdown ? styles.phaseDropDownShow : undefined)}
            ref={dropDownRef}
            formProps={formProps}
            name="phase"
            options={phasesToSelect}
            isSearchable
            menuPortal
            onClose={() => {
              setShowDropdown(false);
            }}
          />
        )}

        {/* <UiProgress percent={budgetProgress} color={model.color} />
        <div className={styles.text}>
          {tHelper('invoiced', {
            from: formatCurrency(model.activePhaseStatistics?.phaseBudget.invoiced ?? 0, 0),
            to: formatCurrency(model.activePhaseStatistics?.phaseBudget.total ?? 0, 0),
          })}
        </div> */}

        <UiProgress percent={taskProgress} color={projectColor} />
        <div className={styles.text}>
          {tHelper('tasks', {
            from: statistics.completedTasks,
            to: statistics.totalTasks,
          })}
        </div>
      </div>
    </>
  );
};
