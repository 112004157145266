import { FC, useCallback, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

import { CommonApiResponseType } from 'src/models/API';
import { SafeAnyType } from 'src/utils/safeAny';
import { ChildrenPropType } from 'src/utils/types';

type Props = {
  data: CommonApiResponseType<SafeAnyType[]>;
  getData: () => void;
  loading: boolean;

  children?: ChildrenPropType;
  className?: string;
  fromGrid?: boolean;
  isReverse?: boolean;
};

export const UiScrollable: FC<Props> = ({
  data,
  getData,
  loading,
  children,
  className,
  fromGrid,
  isReverse = false,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  const loadingRef = useRef<boolean>(loading);

  const observerRef = useRef<IntersectionObserver | null>(null);

  const initialCheckRef = useRef<boolean>(true);

  useEffect(() => {
    loadingRef.current = loading;
  }, [loading]);

  const [finished, setFinished] = useState(false);

  const stopObserving = useCallback(() => {
    observerRef.current?.disconnect();
  }, []);

  useEffect(() => {
    if (!loading) {
      if (data.totalCount <= data.data.length || data.totalCount === 0) {
        const loadBtn = ref.current as HTMLDivElement;

        const observer = observerRef.current;

        observer?.unobserve(loadBtn);

        setFinished(true);
      } else if (initialCheckRef.current && observerRef.current) {
        if (data.totalCount > data.data.length) {
          setFinished(false);

          setTimeout(() => {
            const loadBtn = ref.current as HTMLDivElement;

            const observer = observerRef.current;

            if (loadBtn) {
              observer?.unobserve(loadBtn);
              observer?.observe(loadBtn);
            }
          }, 0);
        }
      }
    }
  }, [data, data.data.length, data.isSuccess, data.totalCount, loading, stopObserving]);

  useEffect(() => {
    // useGrid has initial loading=true
    if (fromGrid) {
      getData();
    }
  }, [getData, fromGrid]);

  useEffect(() => {
    const loadBtn = ref.current as HTMLDivElement;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            initialCheckRef.current = true;

            if (!loadingRef.current) {
              getData();
            }
          } else {
            // stop initial check
            initialCheckRef.current = false;
          }
        });
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.5,
      },
    );

    observer.observe(loadBtn);

    observerRef.current = observer;

    return () => observer.unobserve(loadBtn);
  }, [getData]);

  const target = (
    <div ref={ref} style={{ display: finished ? 'none' : 'block' }}>
      Loading...
    </div>
  );

  return (
    <div className={classNames('UiScrollable', className)}>
      {isReverse ? target : null}
      {children}
      {isReverse ? null : target}
    </div>
  );
};
