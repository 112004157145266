import { QueryRequestContract } from '@moonpanda/moonpanda.contracts';
import { useCallback, useEffect, useRef, useState } from 'react';
import isNumber from 'lodash/isNumber';

import { useOneRequest } from 'src/hooks/useOneRequest';
import { CommonInnerApiResponseType } from 'src/models/API';
import { showErrorToast } from 'src/utils/errors';
import { ApiFunctionType } from 'src/utils/http';
import { SafeAnyType } from 'src/utils/safeAny';
import { PartialBy } from 'src/utils/types';

type useGridType = {
  apiFn: ApiFunctionType;
  pageSize: QueryRequestContract['pageSize'];

  defaultOrderBy?: QueryRequestContract['orderBy'];
  initialLoad?: boolean;
  outerLoading?: boolean;
  defaultLoading?: boolean;
};

type PartialApiPaginateRequestType = PartialBy<QueryRequestContract, 'filters' | 'orderBy' | 'pageSize' | 'page'>;

type useGridReturnType<R = SafeAnyType> = {
  loading: boolean;
  data: CommonInnerApiResponseType<R[]>;
  loadNext: () => void;
  load: (data?: PartialApiPaginateRequestType & { otherParams?: Record<SafeAnyType, SafeAnyType> }) => void;
  updateAllData: (fn: (data: R[]) => R[]) => void;
  reload: () => void;
};

export const useGrid = <R = SafeAnyType[]>({
  apiFn,
  pageSize,
  defaultOrderBy,
  initialLoad = true,
  outerLoading,
  defaultLoading = true,
}: useGridType): useGridReturnType<R> => {
  const [loading, setLoading] = useState(defaultLoading);

  const dataLengthRef = useRef<number>(0);

  const orderByRef = useRef<QueryRequestContract['orderBy'] | undefined>(defaultOrderBy);

  const filtersByRef = useRef<QueryRequestContract['filters'] | undefined>([]);

  const pageRef = useRef(0);

  const [, setRerender] = useState(0);

  // todo create setData + setRerender in it
  const data = useRef<CommonInnerApiResponseType<R[]>>({
    data: [],
    twoFactorConfirmation: false,
    isSuccess: true,
    errors: [],
    totalCount: 0,
    loading: defaultLoading,
  });

  useEffect(() => {
    if (typeof outerLoading === 'boolean') {
      data.current = { ...data.current, loading: outerLoading };

      setRerender(Math.random());
    }
  }, [outerLoading]);

  const { apiCaller } = useOneRequest(apiFn);

  useEffect(() => {
    if (initialLoad) {
      apiCaller({
        pageSize,
        page: 0,
        orderBy: orderByRef.current,
        filters: filtersByRef.current,
      })
        .then((response) => {
          data.current = { ...response, loading: false };

          setRerender(Math.random());

          dataLengthRef.current = response.data.length;
        })
        .catch(showErrorToast)
        .finally(() => {
          setLoading(false);

          data.current = {
            ...data.current,
            loading: false,
          };
        });
    }
  }, [apiCaller, initialLoad, pageSize]);

  const loadNext = useCallback(() => {
    setLoading(true);

    apiCaller({
      pageSize,
      page: pageRef.current,
      orderBy: orderByRef.current,
      filters: filtersByRef.current,
    })
      .then((response) => {
        data.current = {
          ...response,
          data: [...data.current.data, ...response.data],
          loading: false,
        };

        dataLengthRef.current += response.data.length;
        pageRef.current += 1;

        /**
         * stop calling API
         * for cases when totalCount is more than actual data length but no further data comes
         * (backend inappropriate behaviour)
         * */
        if (response.data.length === 0 && pageRef.current > 0) {
          data.current.totalCount = data.current.data.length;
        }

        setRerender(Math.random());
      })
      .catch(showErrorToast)
      .finally(() => {
        setLoading(false);

        data.current = {
          ...data.current,
          loading: false,
        };
      });
  }, [apiCaller, pageSize]);

  const load = useCallback(
    (params?: PartialApiPaginateRequestType & { otherParams?: Record<SafeAnyType, SafeAnyType> }) => {
      if (params?.orderBy) {
        orderByRef.current = params.orderBy;
      }

      if (params?.filters) {
        filtersByRef.current = params.filters;
      }

      dataLengthRef.current = 0;

      setLoading(true);

      data.current = {
        data: [],
        twoFactorConfirmation: false,
        isSuccess: true,
        errors: [],
        totalCount: 0,
        loading: true,
      };

      if (params && isNumber(params.page)) {
        pageRef.current = params.page;
      }

      apiCaller({
        pageSize: params?.pageSize,
        page: pageRef.current,
        orderBy: orderByRef.current,
        filters: filtersByRef.current,
        ...(params?.otherParams ? params.otherParams : {}),
      })
        .then((response) => {
          data.current = {
            ...response,
            data: response.data,
            loading: false,
          };

          dataLengthRef.current += response.data.length;
          pageRef.current += 1;

          setRerender(Math.random());
        })
        .catch(showErrorToast)
        .finally(() => {
          setLoading(false);

          data.current = {
            ...data.current,
            loading: false,
          };
        });
    },
    [apiCaller],
  );

  const updateAllData = useCallback<(fn: (data: R[]) => R[]) => void>((fn) => {
    const newData = fn(data.current.data);

    data.current = {
      ...data.current,
      data: newData,
      totalCount: newData.length,
    };

    setRerender(Math.random());
  }, []);

  return {
    loading,
    data: data.current,
    loadNext,
    load,
    updateAllData,
    reload: load,
  };
};
