import isFunction from 'lodash/isFunction';
import React, { FC, useState } from 'react';
import { shallow } from 'zustand/shallow';

import { UiButton } from 'src/components/UI/UiButton';
import { useRequest } from 'src/hooks/useRequest';
import { useTHelper } from 'src/utils/i18n';
import {
  ProjectsAddTeamMembersComponent,
  ProjectsAddTeamMembersComponentFormValues,
  projectsAddTeamMembersSaveTeamMembers,
} from 'src/components/common/modals/AddTeamMembersToProject/component';
import { useStateForm } from 'src/utils/stateForm';
import styles from 'src/components/common/modals/CreateProject/styles.module.scss';
import { UiModal } from 'src/components/UI/Modals';
import useModalsStore from 'src/store/modals';

export type AddTeamMembersToProjectModalAdditionalInfoType = { projectId: number; userId: number; onSave?: () => void };

export const AddTeamMembersToProjectModal: FC = () => {
  const tHelper = useTHelper('modals.addTeamMembersToProject');

  const { closeModal, additionalInfo } = useModalsStore(
    (state) => ({
      closeModal: () => state.toggleModals('addTeamMembersToProject', false),
      additionalInfo: state.addTeamMembersToProject.additionalInfo as AddTeamMembersToProjectModalAdditionalInfoType,
    }),
    shallow,
  );

  const formProps = useStateForm<ProjectsAddTeamMembersComponentFormValues>({
    defaultValues: {
      teamMembers: {
        users: [],
      },
    },
  });

  const { apiCaller } = useRequest();

  const [saving, setSaving] = useState(false);

  return (
    <UiModal
      onClose={closeModal}
      className={styles.modal}
      bodyClassName={styles.body}
      header={tHelper('header')}
      footer={
        <UiButton
          className={styles.submit}
          loading={saving}
          onClick={async () => {
            setSaving(true);

            if (
              await projectsAddTeamMembersSaveTeamMembers({
                initialUsers: formProps.getInitialValue('teamMembers.users'),
                currentUsers: formProps.getValue('teamMembers.users'),
                projectId: additionalInfo.projectId,
                apiCaller,
                isModal: true,
              })
            ) {
              closeModal();

              if (isFunction(additionalInfo.onSave)) {
                additionalInfo.onSave();
              }
            }

            setSaving(false);
          }}
        >
          {tHelper('saveButtonModal')}
        </UiButton>
      }
    >
      <ProjectsAddTeamMembersComponent
        formProps={formProps}
        projectId={additionalInfo.projectId}
        userId={additionalInfo.userId}
        closeModal={closeModal}
      />
    </UiModal>
  );
};
