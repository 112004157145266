import { TaskContract, TimeLogContract } from '@moonpanda/moonpanda.contracts';
import { FC, useRef, useState } from 'react';

import { BaseSeparateModal } from 'src/models/modals';
import { WidgetTotalTaskOverviewTimeLogsModal } from 'src/pages/Dashboard/widgets/totalTaskOverview/modalTimeLogs';

type Props = BaseSeparateModal & {
  taskData: TaskContract;
  onTotalLoggedChanged: (task: TaskContract) => void;
};

export const ViewTaskTimeLoggedModal: FC<Props> = ({ onClose, taskData, onTotalLoggedChanged }) => {
  const [timeLogs, setTimeLogs] = useState<TimeLogContract[]>(() => [...taskData.timeLogs]);

  const changed = useRef(false);

  return (
    <WidgetTotalTaskOverviewTimeLogsModal
      taskId={taskData.id}
      timeLogs={timeLogs}
      onClose={() => {
        onClose(changed.current);
      }}
      onChangeTimeLogs={setTimeLogs}
      onTotalLoggedChanged={onTotalLoggedChanged}
    />
  );
};
