import { isValidElement } from 'react';
import ReactDOMServer from 'react-dom/server';
import { ChildrenPropType } from 'src/utils/types';

export const removeTags = (str: string | null): string => (str ? str.replace(/(<([^>]+)>)/gi, '') : '');

export const getStringFromReactElement = (el: ChildrenPropType): string | null =>
  isValidElement(el) ? removeTags(ReactDOMServer.renderToString(el)) : null;

export const getStringFromUiLabel = (el: ChildrenPropType): string | null => {
  if (typeof el === 'string') {
    return el;
  }

  return isValidElement(el) ? removeTags(ReactDOMServer.renderToString(el)) : null;
};
