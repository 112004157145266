import React, { FC, memo, useMemo } from 'react';

import { UiDropdownOptionsType, UiDropdownProps } from 'src/components/UI/inputs/DropDown';
import { UiDropdownWithInput } from 'src/components/UI/inputs/DropDrownWithInput';
import useProjectsListStore from 'src/components/UI/ProjectsDropdown/store';
import { StateFormReturnType, useStateForm } from 'src/utils/stateForm';

type Props = Omit<UiDropdownProps, 'formProps' | 'name' | 'options'> & {
  defaultValue?: number;
  ownFormProps?: StateFormReturnType;
  ownName?: string;
  customAllValue?: string;
};

type FormValues = {
  projectId: number;
};

export const UiProjectsDropdown: FC<Props> = memo((props) => {
  const formProps = useStateForm<FormValues>({
    defaultValues: {
      projectId: props?.defaultValue || -1,
    },
  });

  const projects = useProjectsListStore((state) => state.projects);

  const projectsOptions: UiDropdownOptionsType = useMemo(() => {
    const arr = (projects.response || []).map((project) => ({
      value: project.id,
      label: project.name,
    }));

    return props.customAllValue ? [{ value: -1, label: props.customAllValue }, ...arr] : arr;
  }, [projects.response, props.customAllValue]);

  return (
    <UiDropdownWithInput
      isLoading={projects.loading}
      isSearchable
      isClearable={!props.customAllValue}
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...props}
      formProps={props?.ownFormProps || formProps}
      name={props?.ownName || 'projectId'}
      options={projectsOptions}
    />
  );
});
