/* eslint-disable max-len */

export const IconInvoicingMoney = () => (
  <svg width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M25.0679 7.89879C22.5277 5.5454 19.9874 3.19201 17.4472 0.838623C13.7287 6.59461 7.40234 11.7132 1.39471 15.41C1.23051 16.1847 1.05668 16.9495 0.873169 17.6948C3.5003 20.1364 6.1274 22.5682 8.75453 25.0001C14.965 21.5484 21.5135 16.6357 25.4928 11.066C25.3673 10.0168 25.2224 8.96762 25.0679 7.9086V7.89879Z"
      fill="#699635"
    />
    <path
      d="M9.23702 21.9014C6.64853 19.499 4.06005 17.0966 1.47156 14.6942C7.90416 11.0072 13.3322 5.96704 17.3212 0.00512695C19.9097 2.40754 22.4983 4.80996 25.0964 7.21237C21.1171 13.1743 15.689 18.2145 9.24669 21.9014H9.23702Z"
      fill="#83BF4F"
    />
    <path
      d="M4.52418 15.5866C5.92467 16.8908 7.33486 18.1851 8.73536 19.4893C9.26658 19.303 9.90399 19.352 10.3773 19.6168C15.1583 16.5378 19.3018 12.6939 22.6244 8.26169C22.3346 7.82043 22.2863 7.23208 22.4891 6.7418C21.0886 5.43763 19.6785 4.14326 18.278 2.83909C17.8917 3.16268 17.3701 3.21171 16.9258 2.97637C13.6033 7.40858 9.45008 11.2525 4.67875 14.3315C4.92987 14.7433 4.87188 15.2238 4.52418 15.5866ZM8.58079 20.3228C6.8519 18.7245 5.12303 17.1261 3.40381 15.518C3.51005 15.4591 3.61631 15.3905 3.72256 15.3317C4.07027 15.1257 4.11854 14.7335 3.82879 14.4687C3.74186 14.3903 3.65491 14.3119 3.56799 14.2236C8.78361 10.9681 13.2845 6.79083 16.8002 1.95658C16.8872 2.03502 16.9741 2.11347 17.061 2.19192C17.3508 2.45667 17.7661 2.40764 17.9979 2.09386C18.0655 1.9958 18.1331 1.89774 18.2007 1.79968C19.9296 3.39802 21.6488 5.00617 23.3777 6.60452C23.3101 6.70257 23.2425 6.80063 23.1749 6.89868C22.9431 7.22227 22.9914 7.69296 23.2715 7.96752C23.3584 8.04596 23.4454 8.12441 23.5323 8.20285C20.0166 13.0469 15.5157 17.2144 10.3 20.4699C10.2131 20.3914 10.1262 20.313 10.0392 20.2247C9.74948 19.96 9.23757 19.9208 8.88987 20.1267C8.78362 20.1953 8.67736 20.2542 8.57112 20.313L8.58079 20.3228Z"
      fill="#699635"
    />
    <path
      d="M8.27126 14.6942C8.71555 14.5275 9.22746 14.5765 9.5462 14.8707C9.32405 15.0374 9.09223 15.1943 8.87008 15.361C8.7735 15.2727 8.59968 15.2727 8.48378 15.361C8.36787 15.4492 8.3389 15.5865 8.42583 15.6747C8.52241 15.763 8.69624 15.763 8.8218 15.6747C9.32405 15.3217 10.0195 15.3217 10.3962 15.6747C10.7052 15.9591 10.7149 16.3808 10.4444 16.724C10.5217 16.7926 10.599 16.8612 10.6666 16.9299C10.4638 17.077 10.261 17.2142 10.0581 17.3515C9.98086 17.2829 9.9036 17.2044 9.82633 17.1358C9.3917 17.2829 8.88943 17.2044 8.59967 16.9397C8.83148 16.7828 9.06331 16.6259 9.28545 16.469C9.38204 16.5573 9.55587 16.5573 9.68143 16.469C9.80699 16.3808 9.8263 16.2435 9.72971 16.1552C9.63312 16.067 9.46896 16.067 9.3434 16.1552C9.0633 16.3513 8.69627 16.4396 8.36788 16.4004C8.11676 16.3709 7.90424 16.2827 7.74005 16.1356C7.44063 15.8611 7.44064 15.4688 7.6821 15.1452C7.61449 15.0864 7.54686 15.0177 7.47925 14.9589C7.68208 14.8216 7.88491 14.6746 8.08774 14.5275C8.15535 14.5863 8.21332 14.6451 8.28093 14.704L8.27126 14.6942Z"
      fill="#699635"
    />
    <path
      d="M17.7562 5.78069C18.1136 5.52574 18.5579 5.52574 18.8767 5.81991C18.7125 6.03564 18.5386 6.25137 18.3744 6.45729C18.2778 6.36904 18.133 6.38865 18.0364 6.50632C17.9494 6.61418 17.9398 6.78088 18.0364 6.86913C18.133 6.95738 18.2875 6.93778 18.3744 6.82011C18.7511 6.35923 19.3499 6.26118 19.7266 6.61419C20.026 6.89855 20.1033 7.37904 19.9294 7.80068C20.0067 7.86932 20.084 7.93796 20.1516 8.0066C19.9971 8.19291 19.8425 8.37923 19.688 8.57534C19.6107 8.5067 19.5334 8.42825 19.4465 8.35961C19.0891 8.57534 18.6545 8.56553 18.3551 8.29097C18.5289 8.08505 18.7028 7.87913 18.8767 7.6634C18.9733 7.75166 19.1278 7.73204 19.2147 7.61437C19.3016 7.50651 19.3016 7.33981 19.2147 7.25156C19.1181 7.1633 18.9732 7.18292 18.8863 7.30059C18.6738 7.56534 18.3648 7.70262 18.0847 7.70262C17.8722 7.70262 17.6693 7.63398 17.5051 7.47709C17.2057 7.20253 17.1478 6.75146 17.312 6.34943C17.2444 6.29059 17.1767 6.22195 17.1091 6.16311C17.2637 5.9768 17.4182 5.7905 17.5727 5.59438C17.6403 5.65322 17.6983 5.71205 17.7659 5.77089L17.7562 5.78069Z"
      fill="#699635"
    />
    <path
      d="M16.529 13.9684C15.2058 15.1941 13.0133 15.1451 11.719 13.9488C10.4344 12.7525 10.5407 10.8698 11.8639 9.64407C13.1871 8.41835 15.2154 8.3203 16.5097 9.50679C17.7943 10.6933 17.8523 12.7329 16.529 13.9586V13.9684Z"
      fill="#699635"
    />
    <path
      d="M9.00549 9.35994L16.7806 16.5672C17.6596 15.8121 18.5192 15.0179 19.3402 14.1942L11.5747 6.98694C10.7537 7.81062 9.90375 8.59508 9.01516 9.35994H9.00549Z"
      fill="#FFDD7D"
    />
    <path
      d="M16.3852 12.9585C16.3852 12.9585 18.4039 13.5273 23.0883 12.2819C27.763 11.0366 25.5512 24.696 17.0131 24.696C16.0858 24.696 16.0471 23.4801 18.6453 21.3228C18.6453 21.3228 14.9557 22.2054 16.5591 19.4303C16.5591 19.4303 13.9416 19.901 15.1393 17.7633C15.1393 17.7633 15.6898 16.1944 15.1972 14.3804C15.1972 14.3804 15.487 13.439 16.3852 12.9585Z"
      fill="#E8E8E8"
    />
    <path
      d="M12.4348 9.25212C12.4348 9.25212 11.8746 7.20271 13.1013 2.44691C14.3279 -2.29908 0.873535 -0.0535676 0.873535 8.61474C0.873535 9.5561 2.0712 9.59532 4.19608 6.95757C4.19608 6.95757 3.32681 10.7034 6.06018 9.07561C6.06018 9.07561 5.59658 11.733 7.70215 10.5171C7.70215 10.5171 9.24753 9.95814 11.0344 10.4582C11.0344 10.4582 11.9615 10.1641 12.4348 9.25212Z"
      fill="#E8E8E8"
    />
  </svg>
);
