import { DocumentContract, FileAccessEnumContract, FileContract } from '@moonpanda/moonpanda.contracts';
import { ApiPaginateRequestType } from 'src/models/API';

import { ApiFunctionType, httpDelete, httpGet, httpPost } from 'src/utils/http';

const getPath = (url: string) => `Documents/${url}`;

export const apiUploadDocuments: ApiFunctionType<{ files: File[]; access?: FileAccessEnumContract }, FileContract[]> = (
  data,
) => httpPost(getPath(`upload?access=${data.access}`), data, { asFormData: true });

export const apiGetDocumentsList: ApiFunctionType<ApiPaginateRequestType, DocumentContract[]> = (data) =>
  httpGet(getPath('list'), data);

export const apiDeleteDocument: ApiFunctionType<{ documentId: number }, void> = (data) =>
  httpDelete(getPath(`${data.documentId}`), data);

export const apiGetDocument: ApiFunctionType<{ url: string; ignoreAuthHeader: boolean }, void> = (data) =>
  httpGet(data.url, data, {
    ignoreBaseUrl: true,
    expectBlob: true,
    ignoreAuthHeader: data.ignoreAuthHeader, // private urls
  });
