import {
  FeeTypeEnumContract,
  PhaseContract,
  ProfileDetailsContract,
  ProjectContract,
} from '@moonpanda/moonpanda.contracts';
import classNames from 'classnames';
import { diff } from 'deep-object-diff';
import { omit } from 'lodash';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useBlocker, useLocation, useNavigate, useParams } from 'react-router-dom';
import { compare } from 'fast-json-patch';

import { apiAddPhasesToProject, apiDeletePhaseProject, apiReplacePhaseProject } from 'src/api/phases';
import { apiUpdateProjectData } from 'src/api/projects';
import {
  ProjectsAddTeamMembersComponent,
  ProjectsAddTeamMembersComponentFormValues,
  projectsAddTeamMembersSaveTeamMembers,
} from 'src/components/common/modals/AddTeamMembersToProject/component';
import { mergerUsers } from 'src/components/common/modals/AddTeamMembersToProject/mergerUsers';
import { parseDateFromUiDatePicker, prepareSetDateToUiDatePicker } from 'src/components/UI/inputs/DatePicker';
import { UiLoader } from 'src/components/UI/loaders';
import { UiModal } from 'src/components/UI/Modals';
import { UiToast } from 'src/components/UI/toasts';
import { UiButton } from 'src/components/UI/UiButton';
import { useRequest } from 'src/hooks/useRequest';
import { ProjectsCreateProjectContentCard } from 'src/pages/Projects/create/ContentCard';
import { IconCreate } from 'src/pages/Projects/create/icons/Create';
import useProjectsStore, { ProjectStoreCRUDProjectDataType } from 'src/pages/Projects/store';
import { getRouteProjectViewPage, ROUTE_PROJECTS_PAGE } from 'src/routes/paths';
import useGlobalStore from 'src/store';
import useModalsStore from 'src/store/modals';
import { showErrorToast, showWarningToast } from 'src/utils/errors';
import { prepareDataToPatch } from 'src/utils/http';
import { useTHelper } from 'src/utils/i18n';
import { SafeAnyType } from 'src/utils/safeAny';
import { StateFormReturnType, useStateForm } from 'src/utils/stateForm';
import { useStateFormValueWatch } from 'src/utils/stateForm/useFormWatch/useStateFormValueWatch';
import { RecursiveNonNullable, RecursiveNullable } from 'src/utils/types';
import { UiFormatNumberInput } from 'src/components/UI/inputs/FormatNumber';
import { useStateFormFieldArray } from 'src/utils/stateForm/useStateFormFieldArray';

import styles from './styles.module.scss';

export type ProjectsCreateProjectPhasesFormValues = RecursiveNullable<{
  totalBudget: number;
  totalAllocated: number;
  totalRemaining: number;
}> & {
  phases: RecursiveNullable<{
    id: number;
    name: string;
    startDate: string;
    endDate: string;
    feeType: FeeTypeEnumContract;
    budget: number;
    percent: number;
    hasTasks: boolean;

    isEditing: boolean; // is editing at the moment
    isSaved: boolean; // the phase was saved and used in calculations
  }>[];
} & ProjectsAddTeamMembersComponentFormValues;

const emptyPhase: ProjectsCreateProjectPhasesFormValues['phases'][0] = {
  id: 0,
  name: null,
  startDate: null,
  endDate: null,
  feeType: null,
  budget: null,
  percent: null,
  isEditing: true,
  isSaved: false,
  hasTasks: false,
};

export const ProjectsCreateProjectStep2: FC = () => {
  const tHelper = useTHelper('pages.projects.phasesStep');

  const navigate = useNavigate();

  const { id } = useParams<{ id?: string }>();

  const projectId = +(id as string) || 0;

  const user = useGlobalStore((state) => state.user.response as ProfileDetailsContract);

  const toggleModals = useModalsStore((state) => state.toggleModals);

  const [showLeaveModal, setShowLeaveModal] = useState<false | 'back' | 'route'>(false);

  const formProps = useStateForm<ProjectsCreateProjectPhasesFormValues>({
    mode: 'onSubmit',
    defaultValues: {
      phases: [],
      teamMembers: {
        users: [],
      },
    },
  });

  const projectInfoRef = useRef<ProjectStoreCRUDProjectDataType>();

  const { getProjectCRUDStep, start, stop } = useProjectsStore((state) => state);

  const [ready, setReady] = useState(false);

  const { fields, append, refresh, remove } = useStateFormFieldArray<ProjectsCreateProjectPhasesFormValues['phases']>({
    formProps,
    name: 'phases',
  });

  const getFieldName = (index: number, name?: keyof typeof emptyPhase): SafeAnyType =>
    name ? `phases.${index}.${name}` : `phases.${index}`;

  const onChangeBudget = useCallback(() => {
    const totalBudget = formProps.getValue('totalBudget') || 0;

    const totalAllocated = formProps.getValue('phases').reduce((acc, phase) => acc + (phase?.budget || 0), 0);

    formProps.setValue('totalAllocated', totalAllocated);
    formProps.setValue('totalRemaining', totalBudget - totalAllocated);
  }, [formProps]);

  useEffect(() => {
    start({ projectId }).then(() => {
      getProjectCRUDStep()
        .then((data) => {
          projectInfoRef.current = data;

          formProps.reset(
            {
              totalBudget: data.budget.totalBudget,
              totalAllocated: data.budget.totalAllocated,
              totalRemaining: data.budget.totalRemaining,
              phases: data.phase.map((phase) => {
                const startDate = prepareSetDateToUiDatePicker(phase.startDate);

                const endDate = prepareSetDateToUiDatePicker(phase.endDate);

                return {
                  id: phase.id,
                  name: phase.name,
                  startDate,
                  endDate,
                  feeType: phase.feeType,
                  budget: phase.budget,
                  percent: 0,
                  isEditing: false,
                  isSaved: true,
                  hasTasks: phase.hasTasks,
                };
              }),
            },
            {
              resetInitialForm: true,
            },
          );

          onChangeBudget();

          setReady(true);
        })
        .catch(showErrorToast);
    });

    return () => {
      stop();
    };
  }, [onChangeBudget, formProps, getProjectCRUDStep, projectId, start, stop]);

  const { apiCaller } = useRequest();

  const getPhaseInfo = useCallback(
    (index: number): ProjectsCreateProjectPhasesFormValues['phases'][0] => formProps.getValue(getFieldName(index)),
    [formProps],
  );

  const calcBudget = useCallback(
    (index: number, mode: 'save' | 'delete') => {
      const { budget, isSaved } = getPhaseInfo(index);

      let budgetAllocatedTotal = formProps.getValue('totalAllocated') || 0;

      const budgetAllocatedThisPhase = budget || 0;

      let budgetRemainingTotal = formProps.getValue('totalRemaining') || 0;

      if (mode === 'save') {
        if (isSaved) {
          budgetAllocatedTotal = formProps
            .getValue('phases')
            .reduce((acc, item, itemIndex) => (itemIndex === index ? acc : acc + (item.budget || 0)), 0);

          budgetRemainingTotal = (formProps.getValue('totalBudget') || 0) - budgetAllocatedTotal;
        }

        // if (budgetAllocatedThisPhase > budgetRemainingTotal) {
        //   UiToast.warning(tHelper('totalBudgetExceeded'), {
        //     toastId: '1', // to prevent multiple triggering
        //   });
        //
        //   return false;
        // }

        formProps.setValue('totalAllocated', budgetAllocatedTotal + budgetAllocatedThisPhase);
        formProps.setValue('totalRemaining', budgetRemainingTotal - budgetAllocatedThisPhase);

        return true;
      }

      if (mode === 'delete') {
        if (isSaved) {
          formProps.setValue('totalAllocated', budgetAllocatedTotal - budgetAllocatedThisPhase);
          formProps.setValue('totalRemaining', budgetRemainingTotal + budgetAllocatedThisPhase);

          return true;
        }
      }

      return false;
    },
    [formProps, getPhaseInfo],
  );

  const [totalBudget] = useStateFormValueWatch<ProjectsCreateProjectPhasesFormValues, ['totalBudget']>(
    formProps.getSubscribeProps,
    ['totalBudget'],
  );

  const onCardSave = useCallback(
    (index: number, showToast = true) =>
      () => {
        if (calcBudget(index, 'save')) {
          formProps.setValue(getFieldName(index, 'isEditing'), false);
          formProps.setValue(getFieldName(index, 'isSaved'), true);

          refresh();

          const phase = getPhaseInfo(index) as RecursiveNonNullable<ProjectsCreateProjectPhasesFormValues['phases'][0]>;

          const model: PhaseContract & { projectId: number } = {
            projectId,
            id: phase.id,
            name: phase.name,
            startDate: parseDateFromUiDatePicker(phase.startDate),
            endDate: parseDateFromUiDatePicker(phase.endDate),
            budget: phase.budget,
            percent: 0,
            feeType: phase.feeType,
          };

          const apiFn = phase.id > 0 ? apiReplacePhaseProject : apiAddPhasesToProject;

          apiCaller(apiFn, model, {
            key: model.id,
          })
            .then((response) => {
              formProps.setValue(getFieldName(index, 'id'), response.data.id);

              formProps.reset(formProps.getValue(), {
                resetInitialForm: true,
              });

              if (showToast) {
                UiToast.success(tHelper('card.edit.phaseSaved'));
              }
            })
            .catch(showErrorToast);
        }
      },
    [refresh, calcBudget, formProps, getPhaseInfo, projectId, apiCaller, tHelper],
  );

  const onCardEdit = useCallback(
    (index: number) => () => {
      formProps.setValue(getFieldName(index, 'isEditing'), true);

      refresh();
    },
    [formProps, refresh],
  );

  const onCardDelete = useCallback(
    (index: number) => () => {
      if (calcBudget(index, 'delete')) {
        const { id } = getPhaseInfo(index);

        apiCaller(apiDeletePhaseProject, {
          projectId,
          id: id as number,
        })
          .then(() => {
            UiToast.success(tHelper('card.edit.phaseDeleted'));

            remove(index);

            refresh();
          })
          .catch(showErrorToast);
      } else {
        remove(index);

        refresh();
      }
    },
    [calcBudget, getPhaseInfo, apiCaller, projectId, tHelper, remove, refresh],
  );

  const onCardCancel = useCallback(
    (index: number) => () => {
      if (formProps.getValue(getFieldName(index, 'id')) > 0) {
        formProps.setValue(getFieldName(index, 'isEditing'), false);

        refresh();
      } else {
        onCardDelete(index)();
      }
    },
    [onCardDelete, formProps, refresh],
  );

  const { state } = useLocation();

  const isProjectSavedRef = useRef(false);

  const navigateBack = useCallback(() => {
    const isNewProject = !!state?.isNew;

    let navigateTo = '';

    if (isNewProject) {
      if (isProjectSavedRef.current) {
        navigateTo = getRouteProjectViewPage(projectId);
      } else {
        navigateTo = ROUTE_PROJECTS_PAGE;
      }
    } else if (state?.returnTo) {
      navigateTo = state.returnTo;
    } else {
      navigateTo = getRouteProjectViewPage(projectId);
    }

    navigate(navigateTo);
  }, [navigate, projectId, state?.returnTo, state?.isNew]);

  const onSaveProject = useCallback(
    () => async () => {
      const formData = formProps.getValue();

      const savedMembersInfo = await projectsAddTeamMembersSaveTeamMembers({
        initialUsers: formProps.getInitialValue('teamMembers.users'),
        currentUsers: formData.teamMembers.users,
        projectId,
        apiCaller,
      });

      if (savedMembersInfo) {
        /** reset teamMembers */
        const newInitialData = formProps
          .getValue('teamMembers.initialData')
          .filter(({ id }) => !savedMembersInfo.deleted.includes(id));

        const preparedUsers = mergerUsers([], [...newInitialData, ...savedMembersInfo.added]);

        savedMembersInfo.added.forEach((member) => {
          newInitialData.push(member);
        });

        formProps.setValue('teamMembers.initialData', newInitialData);
        formProps.setValue('teamMembers.users', preparedUsers);
        /** end reset teamMembers */

        const newProjectData: ProjectContract = {
          ...cloneDeep(projectInfoRef.current),
          budget: {
            id: projectInfoRef.current?.budget.id,
            totalBudget: formData.totalBudget,
            totalAllocated: formData.totalAllocated,
            totalRemaining: formData.totalRemaining,
          },
        } as ProjectContract;

        const model = compare(projectInfoRef.current as ProjectStoreCRUDProjectDataType, newProjectData);

        const next = () => {
          UiToast.commonSuccessSaved();

          formProps.reset(formProps.getValue(), {
            resetInitialForm: true,
          });

          isProjectSavedRef.current = true;

          navigateBack();
        };

        if (model.length) {
          apiCaller(apiUpdateProjectData, prepareDataToPatch(model, { projectId })).then(next).catch(showErrorToast);
        } else {
          next();
        }
      }
    },
    [navigateBack, apiCaller, formProps, projectId],
  );

  const areUnsavedChanges = useCallback(() => {
    const formDifference: SafeAnyType = diff(
      omit(formProps.getInitialValue(), 'teamMembers'),
      omit(formProps.getValue(), 'teamMembers'),
    );

    // phases bug
    if (formDifference.phases) {
      Object.entries(formDifference.phases).forEach(([key, value]) => {
        if (value === undefined) {
          delete formDifference.phases[key];
        }
      });
    }
    if (isEmpty(formDifference.phases)) {
      delete formDifference.phases;
    }

    const haveUnsavedMembers = formProps.getValue('teamMembers.users').findIndex(({ id }) => !id) >= 0;

    const haveDeletedUnsavedMembers =
      formProps.getValue('teamMembers.initialData').length > formProps.getValue('teamMembers.users').length;

    return haveUnsavedMembers || haveDeletedUnsavedMembers || !isEmpty(formDifference);
  }, [formProps]);

  const blocker = useBlocker(() => {
    if (showLeaveModal) {
      return false;
    }
    if (areUnsavedChanges()) {
      setShowLeaveModal('route');

      return true;
    }

    return false;
  });

  return (
    <div
      className={classNames('bodyPadding', 'fullPageLoader', styles.wrapper, {
        loading: !ready,
      })}
    >
      {!ready && <UiLoader />}

      <div className={styles.topWrapper}>
        <UiButton
          color="light"
          className={styles.backBtn}
          onClick={() => {
            if (areUnsavedChanges()) {
              setShowLeaveModal('back');
            } else {
              navigateBack();
            }
          }}
        >
          {tHelper('topButtons.back')}
        </UiButton>
        <UiButton
          color="dark"
          className={styles.backBtn}
          onClick={() => {
            toggleModals('createProject', true, {
              // projectId,
              projectData: projectInfoRef.current,
              onChangeProject: (response: ProjectContract) => {
                formProps.setValue('totalBudget', response.budget.totalBudget);
                formProps.setValue('totalRemaining', response.budget.totalRemaining);

                if (projectInfoRef.current) {
                  projectInfoRef.current = {
                    ...projectInfoRef.current,
                    budget: response.budget,
                    name: response.name,
                    number: response.number,
                    color: response.color,
                  };
                }

                onChangeBudget();
              },
            });
          }}
        >
          {tHelper('topButtons.editDetails')}
        </UiButton>
        <UiButton
          color="green"
          className={styles.saveBtn}
          onClick={formProps.onSubmit(() => {
            if (projectInfoRef.current) {
              onSaveProject()();
            }
          }, showWarningToast)}
        >
          {tHelper('topButtons.save')}
        </UiButton>
      </div>

      <div className={styles.inputs}>
        <UiFormatNumberInput
          formProps={formProps}
          name="totalBudget"
          prefix="$"
          label={tHelper('totalBudget')}
          required
          max={999_999_999_999}
          onChange={onChangeBudget}
        />
        <UiFormatNumberInput
          formProps={formProps}
          name="totalAllocated"
          prefix="$"
          label={tHelper('totalAllocated')}
          disabled
          required
        />
        <UiFormatNumberInput
          formProps={formProps}
          name="totalRemaining"
          prefix="$"
          label={tHelper('totalRemaining')}
          disabled
          required
          allowNegative
        />
      </div>

      <div className={styles.centerWrapper}>
        <div>
          <h5>&nbsp;</h5>
          {fields.map((field, index) => (
            <ProjectsCreateProjectContentCard
              key={field.id}
              field={field}
              formProps={formProps}
              blockName={getFieldName(index)}
              onSave={onCardSave(index)}
              onEdit={onCardEdit(index)}
              onDelete={onCardDelete(index)}
              onCancel={onCardCancel(index)}
              isEditing={field.isEditing ?? true}
              maxBudget={totalBudget || 0}
            />
          ))}

          <div
            role="presentation"
            className={styles.addNew}
            onClick={() => {
              append(emptyPhase);
            }}
          >
            <IconCreate className={styles.phaseAddNew} />
            {tHelper('addNewPhase')}
          </div>
        </div>
        <div>
          <h5>{tHelper('addTeamMembers')}</h5>

          {ready && (
            <ProjectsAddTeamMembersComponent
              formProps={formProps as unknown as StateFormReturnType<ProjectsAddTeamMembersComponentFormValues>}
              projectId={projectId}
              userId={user.userId}
            />
          )}
        </div>
      </div>

      {showLeaveModal && (
        <UiModal
          contentClassName={styles.leaveModal}
          onClose={() => null}
          noClose
          header={tHelper('leaveModal.header')}
          noCustomScrollBar
        >
          <div className={styles.leaveModalButtons}>
            <UiButton
              color="red"
              onClick={() => {
                setShowLeaveModal(false);

                if (blocker.state === 'blocked') {
                  blocker.proceed();
                }

                if (showLeaveModal === 'back') {
                  navigateBack();
                }
              }}
            >
              {tHelper('leaveModal.continue')}
            </UiButton>
            <UiButton
              color="light"
              onClick={() => {
                setShowLeaveModal(false);

                if (blocker.state === 'blocked') {
                  blocker.reset();
                }
              }}
            >
              {tHelper('leaveModal.cancel')}
            </UiButton>
          </div>
        </UiModal>
      )}
    </div>
  );
};
