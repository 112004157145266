/* eslint-disable max-len */
import { FC } from 'react';

export const IconSend: FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="10" viewBox="0 0 14 10" fill="none">
    <path
      d="M3.50879 9.81952L0.46875 0.0195312L13.6288 1.30951L3.50879 9.82953V9.81952ZM3.98877 8.1095L11.2388 2.0495L1.80884 1.1095L2.60876 3.6795L6.60876 3.47949L3.18872 5.5495L3.97876 8.1095H3.98877Z"
      fill="white"
    />
  </svg>
);
