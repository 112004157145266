import { TeamMemberContract } from '@moonpanda/moonpanda.contracts';
import React, { FC } from 'react';

import { UiAvatar } from 'src/components/common/Avatar';
import { IconAdd } from 'src/pages/Projects/view/widgets/Team/icons/Add';
import { useTHelper } from 'src/utils/i18n';

import styles from './styles.module.scss';

type Props = {
  data: TeamMemberContract[];
  onShowAddTeamMember: () => void;
};

export const ProjectTeamWidgetComponent: FC<Props> = ({ data, onShowAddTeamMember }) => {
  const tHelper = useTHelper('pages.projects.projectPage.widgets.team');

  return (
    <>
      <div className={styles.header}>{tHelper('header')}</div>
      <div className={styles.teamIcons}>
        {data.map((member) => (
          <UiAvatar key={member.id} data={member} width={38} />
        ))}
        <IconAdd className={styles.add} onClick={onShowAddTeamMember} />
      </div>
    </>
  );
};
