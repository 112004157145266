import { SortingOrderEnumContract } from '@moonpanda/moonpanda.contracts';
import classNames from 'classnames';
import get from 'lodash/get';
import isFunction from 'lodash/isFunction';
import React, { forwardRef, memo, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';

import { UiTablePartialNoData } from 'src/components/UI/table/partials/bodyNotFound';
import {
  UiTableColumnItemType,
  UiTableColumnType,
  uiTableGetColumnDataWithItem,
  UiTableInnerFiltersType,
  UiTableRefProps,
} from 'src/components/UI/table/helpers';
import { IconArrow } from 'src/components/UI/table/icons/Arrow';
import { UiTablePartialTD } from 'src/components/UI/table/partials/itemTD';
import { UiTableLoadingPartial } from 'src/components/UI/table/partials/loading';
import { CommonInnerApiResponseType } from 'src/models/API';
import { SafeAnyType } from 'src/utils/safeAny';
import { ChildrenPropType } from 'src/utils/types';

type Props = {
  // eslint-disable-next-line react/no-unused-prop-types
  data: CommonInnerApiResponseType;
  // eslint-disable-next-line react/no-unused-prop-types
  columns: UiTableColumnType[];
  // eslint-disable-next-line react/no-unused-prop-types
  getData: (filter: UiTableInnerFiltersType) => void;

  // eslint-disable-next-line react/no-unused-prop-types
  initialLoad?: boolean;
  // eslint-disable-next-line react/no-unused-prop-types
  itemKeyProp?: string; // must be always unique
  // eslint-disable-next-line react/no-unused-prop-types
  headDark?: boolean;
  // eslint-disable-next-line react/no-unused-prop-types
  additionalXRightColumn?: Omit<UiTableColumnItemType, 'renderValue' | 'title'> & {
    renderValue: (item: SafeAnyType) => React.ReactNode;
  };
  // eslint-disable-next-line react/no-unused-prop-types
  additionalXLeftColumn?: Omit<UiTableColumnItemType, 'renderValue' | 'title'> & {
    renderValue: (item: SafeAnyType) => React.ReactNode;
  };
  // eslint-disable-next-line react/no-unused-prop-types
  onFilterChanged?: () => void;
  // eslint-disable-next-line react/no-unused-prop-types
  lastColumn?: ChildrenPropType;
  // eslint-disable-next-line react/no-unused-prop-types
  appearance?: string; // 'table-bordered' | 'table-striped' | 'table-hover'
  // eslint-disable-next-line react/no-unused-prop-types
  className?: string;
};

export const UiTable = memo(
  forwardRef<UiTableRefProps, Props>(
    (
      {
        data,
        columns,
        headDark,
        getData,
        itemKeyProp = 'id',
        initialLoad = true,
        additionalXRightColumn,
        additionalXLeftColumn,
        onFilterChanged,
        lastColumn,
        appearance = 'table-bordered',
        className,
      },
      ref,
    ) => {
      const localColumns = useMemo(
        () =>
          columns.filter((item) => {
            if (isFunction(item)) {
              return true;
            }

            return !item.useSortAndNotShow;
          }),
        [columns],
      );

      const colSpan = localColumns.length;

      const defaultSortProperty = useMemo(() => {
        let columnInfo: UiTableColumnItemType<SafeAnyType> | undefined;

        // eslint-disable-next-line no-restricted-syntax
        for (const column of columns) {
          if (isFunction(column)) {
            const info = column(null);

            if (info.defaultSortDirection) {
              columnInfo = info;
              break;
            }
          } else if (column.defaultSortDirection) {
            columnInfo = column;
            break;
          }
        }

        return columnInfo;
      }, [columns]);

      const filtersRef = useRef<{
        propertyName: string;
        sortingOrder: SortingOrderEnumContract;
      }>();

      const getFilters = useCallback(() => filtersRef.current, []);

      useImperativeHandle(ref, () => ({ getFilters }));

      const [sortInfo, setSortInfo] = useState<{ name: string; direction: SortingOrderEnumContract }>({
        name: defaultSortProperty?.sortPropertyName || '',
        direction: defaultSortProperty?.defaultSortDirection || 'Asc',
      });

      useEffect(() => {
        const firstRender = filtersRef.current === undefined;

        filtersRef.current = {
          propertyName: sortInfo.name,
          sortingOrder: sortInfo.direction,
        };

        if ((firstRender && initialLoad) || !firstRender) {
          getData(filtersRef.current);
        }

        if (!firstRender && isFunction(onFilterChanged)) {
          onFilterChanged();
        }
      }, [getData, initialLoad, onFilterChanged, sortInfo]);

      const onSortClick = (columnData: UiTableColumnItemType) => () => {
        columnData.sortPropertyName = columnData.sortPropertyName as string;

        setSortInfo({
          name: columnData.sortPropertyName,
          direction: sortInfo.name === columnData.sortPropertyName && sortInfo.direction === 'Asc' ? 'Desc' : 'Asc',
        });
      };

      return (
        <table className={classNames('table', appearance, className)}>
          <thead className={headDark ? 'thead-dark' : undefined}>
            <tr>
              {additionalXLeftColumn && <th className="additional-left">&nbsp;</th>}
              {localColumns.map((columnRaw) => {
                const columnData = uiTableGetColumnDataWithItem(columnRaw, null);

                const isSortThisColumn = sortInfo.name === columnData.sortPropertyName;

                return (
                  <th key={columnData.title} className={columnData.thClassName}>
                    <div className="col">
                      <div className={columnData.className}>{columnData.title}</div>

                      {columnData.sortPropertyName && columnData.sortable !== false && (
                        <div className="sorting cursor-pointer" role="presentation" onClick={onSortClick(columnData)}>
                          <IconArrow
                            className={classNames('icon top', {
                              active: isSortThisColumn && sortInfo.direction === 'Asc',
                            })}
                          />
                          <IconArrow
                            className={classNames('icon bottom', {
                              active: isSortThisColumn && sortInfo.direction === 'Desc',
                            })}
                          />
                        </div>
                      )}
                    </div>
                  </th>
                );
              })}
              {additionalXRightColumn && <th className="additional-right">&nbsp;</th>}
            </tr>
          </thead>
          <tbody>
            {data.loading ? (
              <UiTableLoadingPartial colSpan={colSpan} />
            ) : (
              <>
                {data.isSuccess ? (
                  data.data.map((item: SafeAnyType) => (
                    <tr key={get(item, itemKeyProp)}>
                      {additionalXLeftColumn && (
                        <td className={classNames('additional-left', additionalXLeftColumn.thClassName)}>
                          <div className={additionalXLeftColumn.className}>
                            {additionalXLeftColumn.renderValue(item)}
                          </div>
                        </td>
                      )}
                      {localColumns.map((columnRaw) => UiTablePartialTD(item)(columnRaw))}
                      {additionalXRightColumn && (
                        <td className={classNames('additional-right', additionalXRightColumn.thClassName)}>
                          <div className={additionalXRightColumn.className}>
                            {additionalXRightColumn.renderValue(item)}
                          </div>
                        </td>
                      )}
                    </tr>
                  ))
                ) : (
                  <UiTablePartialNoData colSpan={colSpan} />
                )}
              </>
            )}

            {lastColumn}
          </tbody>
        </table>
      );
    },
  ),
);
