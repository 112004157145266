import isFunction from 'lodash/isFunction';
import { useCallback } from 'react';

type Props = {
  name: string;

  clearErrors?: (name: string, type?: 'hover') => void;
  errors?: { type: string; message: string }[];
  showAnyError?: boolean;
  validateName?: string;
};

type ReturnType = { errorString: string | undefined; onChangeInputHover: (bruteClear?: boolean) => void };

export const useHoverErrors = ({
  errors,
  name,
  clearErrors,
  showAnyError = false,
  validateName,
}: Props): ReturnType => {
  const hoverErrorsExists = !!errors?.some(({ type }) => type === 'hover');

  // show all errors or hover errors only
  const localErrors = (showAnyError ? errors : errors?.filter(({ type }) => type === 'hover'))?.map(
    ({ message }) => message,
  );

  const onChangeInputHover = useCallback(
    (bruteClear?: boolean) => {
      if (isFunction(clearErrors) && (hoverErrorsExists || bruteClear)) {
        clearErrors(name, 'hover');
        if (validateName) {
          clearErrors(validateName, 'hover');
        }
      }
    },
    [clearErrors, hoverErrorsExists, name, validateName],
  );

  return { errorString: localErrors?.join(',\n'), onChangeInputHover };
};
