import React, { FC } from 'react';
import { ChildrenPropType } from 'src/utils/types';

type Props = {
  children: ChildrenPropType;

  htmlFor?: string;
  className?: string;
};

export const UiMereLabel: FC<Props> = ({ htmlFor, className, children }) => (
  /* eslint-disable-next-line jsx-a11y/label-has-associated-control */
  <label htmlFor={htmlFor} className={className}>
    {children}
  </label>
);
