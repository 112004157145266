import React, { FC } from 'react';

import { UiPopover, UiPopoverProps } from 'src/components/UI/Popover';
import { UiButton } from 'src/components/UI/UiButton';
import { useTHelper } from 'src/utils/i18n';

type Props = Omit<UiPopoverProps, 'content'> & {
  onConfirm: () => void;
  text?: string;
  textConfirm?: string;
  show?: boolean;
};

export const UiDeleteConfirmPopover: FC<Props> = ({ children, onConfirm, text, textConfirm, show = true, ...rest }) => {
  const tHelper = useTHelper('components.deleteConfirmPopover');

  if (!show) {
    return children;
  }

  return (
    <UiPopover
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...rest}
      /* eslint-disable-next-line react/no-unstable-nested-components */
      content={(close) => (
        <div className="deleteConfirmPopover">
          <span>{text || tHelper('sure')}</span>
          <UiButton
            onClick={() => {
              onConfirm();

              close();
            }}
            color="red"
          >
            {textConfirm || tHelper('submit')}
          </UiButton>
        </div>
      )}
    >
      {children}
    </UiPopover>
  );
};
