import React, { FC } from 'react';

import { UiButton } from 'src/components/UI/UiButton';
import { useRequest } from 'src/hooks/useRequest';
import useModalsStore from 'src/store/modals';
import { ApiFunctionType, httpDelete, httpGet, httpPatch, httpPost, httpPut } from 'src/utils/http';
import { SafeAnyType } from 'src/utils/safeAny';
import { shallow } from 'zustand/shallow';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const TestApi = () => {
  const testApiGetFn: ApiFunctionType<{ ms: number; data: SafeAnyType }, unknown> = (data) => httpGet('test', data);

  const testApiPostFn: ApiFunctionType<{ ms: number; data: SafeAnyType }, unknown> = (data) =>
    httpPost(`test?ms=${data.ms}`, data);

  const testApiPutFn: ApiFunctionType<{ ms: number; data: SafeAnyType }, unknown> = (data) =>
    httpPut(`test?ms=${data.ms}`, data);

  const testApiPatchFn: ApiFunctionType<{ ms: number; data: SafeAnyType }, unknown> = (data) =>
    httpPatch(`test?ms=${data.ms}`, data);

  const testApiDeleteFn: ApiFunctionType<{ ms: number; data: SafeAnyType }, unknown> = (data) =>
    httpDelete(`test?ms=${data.ms}`, data);

  const { apiCaller, abort } = useRequest();

  return (
    <>
      {[testApiGetFn, testApiPostFn, testApiPutFn, testApiPatchFn, testApiDeleteFn].map((fn) => (
        <div style={{ display: 'flex', gap: 20, marginBottom: 20 }} key={fn.name}>
          <UiButton
            onClick={() => {
              apiCaller(fn, { ms: 5000, data: 1 }).catch((e) => {
                // eslint-disable-next-line no-console
                console.log('e', e);
              });
            }}
          >
            call {fn.name}
          </UiButton>
          <UiButton
            onClick={() => {
              abort();
            }}
          >
            cancel {fn.name}
          </UiButton>
        </div>
      ))}
    </>
  );
};

export const Test: FC = () => {
  const { showModal } = useModalsStore(
    (state) => ({
      showModal: () => state.toggleModals('eventModal', true),
    }),
    shallow,
  );

  return (
    <div className="bodyPadding">
      {/* <ChatExample /> */}
      <TestApi />
      {/* <ScrollExample /> */}

      <UiButton onClick={showModal}>show event create</UiButton>
    </div>
  );
};
