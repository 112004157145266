import classNames from 'classnames';
import get from 'lodash/get';
import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';

import { addDOMClass, removeDOMClass } from 'src/utils/DOM';
import { ChildrenPropType } from 'src/utils/types';

export type UiPopoverProps = {
  children: ChildrenPropType;
  content: (close: () => void) => ChildrenPropType;

  header?: ChildrenPropType;
  placement?: 'bottom' | 'top' | 'left' | 'right';
  customClose?: boolean; // custom handle to not close when you click on an inner datepicker
  trigger?: 'click' | 'hover';
  className?: string;
  wrapperClassName?: string;
};

/**
 * popover component for showing any content
 */
export const UiPopover: React.FC<UiPopoverProps> = memo(
  ({
    children,
    customClose,
    content,
    header,
    placement = 'bottom',
    trigger = 'click',
    className,
    wrapperClassName,
  }) => {
    const [show, setShow] = useState(false);

    const close = useCallback(() => setShow(false), []);

    const ref = useRef<HTMLDivElement>(null);

    const onOuterClick = useCallback(
      ({ target }: Event) => {
        const closeCondition = ref.current && !ref.current.contains(target as Node);

        if (customClose) {
          const hasElement: string[] = get(target, 'parentNode.parentNode.className', []);

          const clickOnDatePicker: boolean = hasElement ? hasElement.includes('flatpickr') : false;

          if (!clickOnDatePicker && closeCondition) {
            close();
          }
        } else if (closeCondition) {
          close();
        }
      },
      [close, customClose],
    );

    useEffect(() => {
      if (customClose) {
        if (show) {
          setTimeout(() => {
            window.addEventListener('click', onOuterClick);
          }, 0);
        } else {
          window.removeEventListener('click', onOuterClick);
        }
      }

      return () => {
        window.removeEventListener('click', onOuterClick);
        removeDOMClass('body', 'bodyLocked');
      };
    }, [show, onOuterClick, customClose]);

    return (
      <OverlayTrigger
        placement={placement}
        onToggle={() => {
          setShow((v) => !v);
        }}
        show={show}
        trigger={trigger}
        rootClose={!customClose}
        onEntered={() => {
          removeDOMClass('body', 'bodyLocked');
        }}
        onEntering={() => {
          addDOMClass('body', 'bodyLocked');
        }}
        overlay={
          <Popover className={className}>
            <div ref={ref}>
              {header && <Popover.Header as="h3">{header}</Popover.Header>}
              <Popover.Body>{content(close)}</Popover.Body>
            </div>
          </Popover>
        }
      >
        <div className={classNames('popoverWrapper', wrapperClassName)}>{children}</div>
      </OverlayTrigger>
    );

    // return (
    //   <OverlayTrigger
    //     trigger={trigger}
    //     rootClose={!customClose}
    //     placement={placement}
    //     show={show}
    //     onToggle={setShow}
    //     container={() => wrapperRef.current}
    //     overlay={
    //       <Popover>
    //         <div ref={ref}>
    //           {header && <Popover.Header as="h3">{header}</Popover.Header>}
    //           <Popover.Body>{content(close)}</Popover.Body>
    //         </div>
    //       </Popover>
    //     }
    //   >
    //     <div className="popoverWrapper" ref={wrapperRef}>
    //       {children}
    //     </div>
    //   </OverlayTrigger>
    // );
  },
);
