import { TaskContract, TaskDashboardContract, TaskStatusEnumContract } from '@moonpanda/moonpanda.contracts';
import classNames from 'classnames';
import React, { FC, useLayoutEffect, useRef } from 'react';

import {
  ViewTaskModalTimerPartial,
  ViewTaskModalTimerRefProps,
} from 'src/components/common/modals/ViewTask/components/timer';
import { IconEnd } from 'src/components/common/modals/ViewTask/icons/End';
import { IconStart } from 'src/components/common/modals/ViewTask/icons/Start';
import { SHORT_DATE_FORMAT } from 'src/config';
import styles from 'src/pages/Dashboard/Tasks/styles.module.scss';
import { TasksDoneChangeComponent } from 'src/pages/Tasks/components/DoneChange';
import { TasksStatusChangeComponent } from 'src/pages/Tasks/components/StatusChange';
import { CDate } from 'src/utils/CDate';

type Props = {
  task: TaskDashboardContract;
  updateTask: (task: TaskContract, prevStatus?: TaskStatusEnumContract) => void;
  onShowViewTask: (taskId: number) => () => void;

  timersRef?: Record<number, ViewTaskModalTimerRefProps> | null;
};

export const DashboardPageTasksRowComponent: FC<Props> = ({ task, updateTask, onShowViewTask, timersRef }) => {
  const ref = useRef<ViewTaskModalTimerRefProps | null>(null);

  useLayoutEffect(() => {
    if (timersRef) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      timersRef[task.taskId] = ref.current;
    }
  }, [task.taskId, timersRef]);

  return (
    <div className={styles.item}>
      <div className={styles.done}>
        <TasksDoneChangeComponent
          taskId={task.taskId}
          status={task.status}
          onSuccess={updateTask}
          projectColor={task.projectColor}
        />
      </div>
      <div className={styles.name}>
        <span role="presentation" title={task.taskName} onClick={onShowViewTask(task.taskId)}>
          {task.taskName}
        </span>
      </div>
      <div className={styles.date}>Due: {CDate.format(new Date(task.dueDate), SHORT_DATE_FORMAT)}</div>
      <div className={styles.status}>
        <TasksStatusChangeComponent taskId={task.taskId} status={task.status} onSuccess={updateTask} dropdownPortal />
      </div>
      <ViewTaskModalTimerPartial
        taskId={task.taskId}
        taskStatus={task.status}
        totalLogged={0}
        ref={ref}
        onTaskChange={(task) => {
          updateTask(task);
        }}
      >
        {({ text, isStarted, onTimerClick }) => (
          <div
            className={classNames(styles.timer, isStarted ? styles.timerStop : styles.timerStart)}
            role="presentation"
            onClick={onTimerClick}
          >
            {text}
            {isStarted ? <IconEnd /> : <IconStart />}
          </div>
        )}
      </ViewTaskModalTimerPartial>
    </div>
  );
};
