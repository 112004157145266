import classNames from 'classnames';
import { endOfWeek, startOfWeek } from 'date-fns';
import React, { FC, useEffect, useState } from 'react';

import { UiLoader } from 'src/components/UI/loaders';
import { IconArrow } from 'src/pages/Dashboard/widgets/timeTracking/icons/Arrow';
import { IconTimeTrackingWidgetHeader } from 'src/pages/Dashboard/widgets/timeTracking/icons/Header';
import useProjectsStore from 'src/pages/Projects/store';
import { ProjectSummaryWidgetTimeTrackingModalPartial } from 'src/pages/Projects/view/widgets/Summary/components/timeTracking/modal';
import { CDate } from 'src/utils/CDate';
import { numberToTimeObject } from 'src/utils/dateTime';
import { useTHelper } from 'src/utils/i18n';

import styles from 'src/pages/Projects/view/widgets/Summary/styles.module.scss';

type Props = {
  projectId: number;
};

export const ProjectSummaryWidgetTimeTrackingPartial: FC<Props> = ({ projectId }) => {
  const tHelper = useTHelper('pages.projects.projectPage.widgets.summary.timeTracking');

  const { getWidgetTimeTrackingData, widgetTimeTrackingData, loading, currentWeek, setWidgetTimeTrackingDataWeek } =
    useProjectsStore((state) => ({
      getWidgetTimeTrackingData: state.getWidgetTimeTrackingData,
      widgetTimeTrackingData: state.widgetTimeTrackingData.response,
      loading: state.widgetTimeTrackingData.loading,
      currentWeek: state.widgetTimeTrackingWeek,
      setWidgetTimeTrackingDataWeek: state.setWidgetTimeTrackingDataWeek,
    }));

  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    getWidgetTimeTrackingData();
  }, [getWidgetTimeTrackingData]);

  const getTimeFormatted = (time: number | undefined) => {
    const times = numberToTimeObject(time || 0);

    return `${times.hours}.${times.minutes}`;
  };

  return (
    <div className={styles.widgetTop}>
      {loading ? (
        <UiLoader />
      ) : (
        <>
          <div>
            <IconTimeTrackingWidgetHeader />
          </div>

          <div className={styles.week}>
            <IconArrow
              className={classNames(styles.arrow, styles.arrowLeft)}
              onClick={() => {
                setWidgetTimeTrackingDataWeek(
                  CDate.addTime(currentWeek, {
                    days: -7,
                  }),
                );
              }}
            />
            {tHelper('week', {
              start: CDate.format(startOfWeek(currentWeek), 'MMM d'),
              end: CDate.format(endOfWeek(currentWeek), 'MMM d'),
            })}
            <IconArrow
              className={styles.arrow}
              onClick={() => {
                setWidgetTimeTrackingDataWeek(
                  CDate.addTime(currentWeek, {
                    days: 7,
                  }),
                );
              }}
            />
          </div>

          <div className={styles.info}>
            <div className={styles.infoBlock}>
              <div className={styles.value}>
                {tHelper('time', { time: getTimeFormatted(widgetTimeTrackingData?.billable) })}
              </div>
              <div className={styles.text}>{tHelper('billable')}</div>
            </div>
            <div className={styles.infoBlock}>
              <div className={styles.value}>
                {tHelper('time', { time: getTimeFormatted(widgetTimeTrackingData?.nonBillable) })}
              </div>
              <div className={styles.text}>{tHelper('nonBillable')}</div>
            </div>
          </div>

          <span
            role="presentation"
            className={styles.viewModal}
            onClick={() => {
              setShowModal(true);
            }}
          >
            {tHelper('viewModal')}
          </span>
        </>
      )}

      {showModal && (
        <ProjectSummaryWidgetTimeTrackingModalPartial
          currentWeek={currentWeek}
          projectId={projectId}
          onClose={() => {
            setShowModal(false);
          }}
        />
      )}
    </div>
  );
};
