import React, { FC, useCallback, useMemo, useRef } from 'react';
import classNames from 'classnames';
import {
  FilterContract,
  InvoiceContract,
  ProfileDetailsContract,
  ProjectContract,
} from '@moonpanda/moonpanda.contracts';

import { UiWidget } from 'src/components/UI/widgets';
import { APP_HIDDEN_COMPONENTS, DEFAULT_TABLE_PAGE_SIZE, SHORT_DATE_FORMAT } from 'src/config';
import { WidgetUpcomingEventsPeriod } from 'src/pages/Dashboard/widgets/upcomingEvents';
import { IconHeader } from 'src/pages/Dashboard/widgets/upcomingEvents/icons/Header';
import useProjectsStore from 'src/pages/Projects/store';
import { ProjectTeamWidgetComponent } from 'src/pages/Projects/view/widgets/Team/component';
import useGlobalStore from 'src/store';
import useModalsStore from 'src/store/modals';
import { useTHelper } from 'src/utils/i18n';
import { IconPlus } from 'src/components/icons/Plus';
import parentStyles from 'src/pages/Projects/view/styles.module.scss';
import { UiScrollable } from 'src/components/UI/InfiniteScroll';
import { UiTable } from 'src/components/UI/table';
import { useGrid } from 'src/hooks/useGrid';
import { apiDeleteInvoice, apiGetInvoiceExportLink, apiGetInvoicingList } from 'src/api/invoicing';
import { UiTableInnerFiltersType, UiTableRefProps } from 'src/components/UI/table/helpers';
import { CDate } from 'src/utils/CDate';
import { IconTrash } from 'src/components/icons/Trash';
import { IconDownload } from 'src/components/icons/Download';
import { UiToast } from 'src/components/UI/toasts';
import { showErrorToast } from 'src/utils/errors';
import { useRequest } from 'src/hooks/useRequest';
import { UiDeleteConfirmPopover } from 'src/components/UI/DeleteConfirmPopover';
import { IconInvoicingMoney } from 'src/components/icons/InvoicingMoney';
import { downloadBlobAsFile } from 'src/utils/file';

import styles from './styles.module.scss';

type Props = {
  projectId: number;
};

export const ProjectTeamWidget: FC<Props> = ({ projectId }) => {
  const tHelper = useTHelper('widgets.upcomingEvents');

  const tableRef = useRef<UiTableRefProps>(null);

  const { apiCaller } = useRequest();

  const { data, refreshProject, upcomingEvents, loading } = useProjectsStore((state) => ({
    data: (state.project.response as ProjectContract).team,
    upcomingEvents: state.upcomingEvents.response,
    refreshProject: state.refreshProject,
    loading: state.upcomingEvents.loading,
  }));

  const {
    loading: invoicingListLoading,
    data: invoicingList,
    load,
    loadNext,
    reload,
    updateAllData,
  } = useGrid<InvoiceContract>({
    apiFn: apiGetInvoicingList,
    initialLoad: false,
    pageSize: DEFAULT_TABLE_PAGE_SIZE,
  });

  const userId = useGlobalStore((state) => (state.user.response as ProfileDetailsContract).userId);

  const { showModal, toggleModals } = useModalsStore((state) => ({
    showModal: () =>
      state.toggleModals('addTeamMembersToProject', true, {
        projectId,
        userId,
        onSave: refreshProject,
      }),
    toggleModals: state.toggleModals,
  }));

  const handleDeleteInvoice = useCallback(
    (id: number) => {
      apiCaller(
        apiDeleteInvoice,
        {
          id,
        },
        { key: id },
      )
        .then(() => {
          updateAllData((storeData) => storeData.filter((invoice) => invoice.id !== id));
          UiToast.commonSuccessDeleted();
        })
        .catch(showErrorToast);
    },
    [apiCaller, updateAllData],
  );

  const handleDownloadInvoice = useCallback(
    (id: number, invoiceNameId: string) => {
      apiCaller(
        apiGetInvoiceExportLink,
        {
          id,
        },
        { expectBlob: true },
      )
        .then((response) => {
          downloadBlobAsFile(response as unknown as Blob, `${invoiceNameId}`);
        })
        .catch(showErrorToast);
    },
    [apiCaller],
  );

  const handleViewInvoice = useCallback(
    (id: number) => {
      toggleModals('viewInvoice', true, {
        invoiceId: id,
        onCreateInvoice: reload,
      });
    },
    [reload, toggleModals],
  );

  const handleCreateInvoice = useCallback(
    () =>
      toggleModals('createInvoice', true, {
        onCreateInvoice: reload,
        projectId,
      }),
    [projectId, reload, toggleModals],
  );

  const onTableGetData = useCallback(
    (tableData: UiTableInnerFiltersType) => {
      const filters: FilterContract[] = [];

      if (projectId) {
        filters.push({ propertyName: 'projectId', operation: 'Equals', value: projectId.toString() });
      }

      load({
        page: 0,
        pageSize: DEFAULT_TABLE_PAGE_SIZE,
        orderBy: tableData.propertyName
          ? [{ propertyName: tableData.propertyName, sortingOrder: tableData.sortingOrder }]
          : undefined,
        filters,
      });
    },
    [load, projectId],
  );

  const getData = useCallback(() => {
    loadNext();
  }, [loadNext]);

  const columns = useMemo(
    () => [
      {
        title: tHelper('columns.invoiceName'),
        renderValue: ({ invoiceNameId, id }: InvoiceContract) => (
          <button
            className={styles.invoicingNameBtn}
            type="button"
            title={invoiceNameId}
            onClick={() => handleViewInvoice(id)}
          >
            {invoiceNameId}
          </button>
        ),
        className: classNames(styles.columnName),
      },
      {
        title: tHelper('columns.date'),
        renderValue: ({ invoiceDate }: InvoiceContract) => (
          <span title={CDate.format(new Date(invoiceDate), SHORT_DATE_FORMAT)}>
            {CDate.format(new Date(invoiceDate), SHORT_DATE_FORMAT)}
          </span>
        ),
        className: classNames(styles.columnName),
      },
      {
        title: tHelper('columns.amount'),
        renderValue: ({ total }: InvoiceContract) => <span title={String(total)}>{total}</span>,
        className: classNames(styles.columnName),
      },
    ],
    [handleViewInvoice, tHelper],
  );

  const additionalRightColumn = useMemo(
    () => ({
      renderValue: ({ id, invoiceNameId }: InvoiceContract) => (
        <>
          <UiDeleteConfirmPopover onConfirm={() => handleDeleteInvoice(id)}>
            <IconTrash />
          </UiDeleteConfirmPopover>
          <IconDownload className={styles.downloadIcon} onClick={() => handleDownloadInvoice(id, invoiceNameId)} />
        </>
      ),
    }),
    [handleDeleteInvoice, handleDownloadInvoice],
  );

  return (
    <UiWidget className={classNames(parentStyles.baseWidget, styles.widget)} loading={loading}>
      <ProjectTeamWidgetComponent data={data} onShowAddTeamMember={showModal} />
      <hr className={styles.hr} />
      <div className={styles.invoicing}>
        <IconInvoicingMoney />
        <p>
          {tHelper('invoicing')} <IconPlus onClick={handleCreateInvoice} className={styles.createInvoiceBtn} />
        </p>

        <UiScrollable data={invoicingList} getData={getData} loading={invoicingListLoading}>
          <UiTable
            data={invoicingList}
            getData={onTableGetData}
            columns={columns}
            additionalXRightColumn={additionalRightColumn}
            appearance="table-striped table-hover"
            className={styles.table}
            itemKeyProp="id"
            ref={tableRef}
          />
        </UiScrollable>
      </div>

      {!APP_HIDDEN_COMPONENTS.PROJECT_VIEW_CALENDAR_HIDDEN && (
        <>
          <hr className={styles.hr} />

          <div className="upcomingWidget">
            <div>
              <IconHeader />
            </div>

            <div className={styles.header}>{tHelper('title')}</div>

            <WidgetUpcomingEventsPeriod data={upcomingEvents?.today || []} text={tHelper('today')} />
            <WidgetUpcomingEventsPeriod data={upcomingEvents?.later || []} text={tHelper('later')} />
          </div>
        </>
      )}
    </UiWidget>
  );
};
