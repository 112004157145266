/* eslint-disable max-len */
import { FC } from 'react';
import { CommonIconProps } from 'src/utils/iconProps';

type Props = CommonIconProps & {
  onClick?: () => void;
};

export const IconPreview: FC<Props> = ({ className, onClick }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="37"
    height="22"
    viewBox="0 0 37 22"
    fill="none"
    className={className}
    onClick={onClick}
  >
    <path
      d="M29.55 1.36035H7.25C3.93629 1.36035 1.25 4.04664 1.25 7.36035V14.9604C1.25 18.2741 3.93629 20.9604 7.25 20.9604H29.55C32.8638 20.9604 35.55 18.2741 35.55 14.9604V7.36035C35.55 4.04664 32.8638 1.36035 29.55 1.36035Z"
      stroke="#3F5B64"
      strokeMiterlimit="10"
    />
    <path
      d="M18.1508 13.77C18.8908 13.77 19.5108 13.51 20.0208 13C20.5308 12.49 20.7908 11.8599 20.7908 11.1199C20.7908 10.3799 20.5308 9.75995 20.0208 9.24995C19.5108 8.73995 18.8807 8.47995 18.1407 8.47995C17.4007 8.47995 16.7808 8.73995 16.2708 9.24995C15.7608 9.75995 15.5007 10.39 15.5007 11.13C15.5007 11.87 15.7608 12.49 16.2708 13C16.7808 13.51 17.4108 13.77 18.1508 13.77ZM18.1508 12.8699C17.6608 12.8699 17.2508 12.7 16.9208 12.36C16.5808 12.02 16.4108 11.6099 16.4108 11.1199C16.4108 10.6299 16.5808 10.22 16.9208 9.88995C17.2608 9.54995 17.6708 9.37996 18.1608 9.37996C18.6508 9.37996 19.0607 9.54995 19.3907 9.88995C19.7307 10.23 19.9008 10.64 19.9008 11.13C19.9008 11.62 19.7307 12.03 19.3907 12.36C19.0507 12.7 18.6408 12.8699 18.1508 12.8699ZM18.1508 15.8C16.6308 15.8 15.2708 15.3699 14.0408 14.5099C12.8208 13.6499 11.9008 12.52 11.3008 11.13C11.9008 9.73996 12.8208 8.60995 14.0408 7.74995C15.2608 6.88995 16.6308 6.45996 18.1508 6.45996C19.6708 6.45996 21.0307 6.88995 22.2607 7.74995C23.4907 8.60995 24.4007 9.73996 25.0007 11.13C24.4007 12.52 23.4807 13.6499 22.2607 14.5099C21.0407 15.3699 19.6708 15.8 18.1508 15.8ZM18.1508 14.8699C19.4108 14.8699 20.5607 14.53 21.6207 13.85C22.6807 13.17 23.4708 12.26 24.0208 11.13C23.4708 9.99996 22.6707 9.08996 21.6207 8.40996C20.5707 7.72996 19.4108 7.38995 18.1608 7.38995C16.9108 7.38995 15.7507 7.72996 14.6907 8.40996C13.6407 9.08996 12.8308 9.99996 12.2708 11.13C12.8308 12.26 13.6407 13.17 14.6907 13.85C15.7407 14.53 16.9008 14.8699 18.1508 14.8699Z"
      fill="#3F5B64"
    />
  </svg>
);
