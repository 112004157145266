import { JwtTokenContract, OAuthSignInResponseContract, ProfileDetailsContract } from '@moonpanda/moonpanda.contracts';
import { useMsal } from '@azure/msal-react';
import isFunction from 'lodash/isFunction';
import { FC, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { apiAuthenticationSignInMicrosoft } from 'src/api/authentication';
import { IconMicrosoft } from 'src/components/icons/Microsoft';
import { UiToast } from 'src/components/UI/toasts';
import { UiButton } from 'src/components/UI/UiButton';
import { useRequest } from 'src/hooks/useRequest';
import { useSignInUser } from 'src/pages/SignUp/common';
import { useTHelper } from 'src/utils/i18n';
import { loginRequestMicrosoft } from 'src/config';

import styles from './styles.module.scss';

type Props = {
  onFillProfileData: (data: ProfileDetailsContract, jwt: JwtTokenContract) => void;
};

export const MicrosoftAuthButton: FC<Props> = ({ onFillProfileData }) => {
  const tHelper = useTHelper('pages.signIn');

  const { apiCaller } = useRequest();

  const [loading, setLoading] = useState(false);

  const [searchParams] = useSearchParams();

  const { instance } = useMsal();

  const signInUser = useSignInUser();

  const microsoftLogin = () => {
    setLoading(true);

    instance
      .loginPopup(loginRequestMicrosoft)
      .then((response) => {
        const dataToSend = {
          accessToken: response.accessToken,
          referrerId: +(searchParams.get('referrerId') || 0),
        };

        apiCaller(apiAuthenticationSignInMicrosoft, dataToSend)
          .then(({ data }) => {
            const goNext = (signInData: OAuthSignInResponseContract) => {
              if (signInData.needFillProfileData && isFunction(onFillProfileData)) {
                onFillProfileData(
                  signInData.signUpProfile as ProfileDetailsContract,
                  signInData.jwt as JwtTokenContract,
                );
              } else {
                signInUser(signInData.jwt as JwtTokenContract);
              }
            };

            goNext(data);
          })
          .finally(() => {
            setLoading(false);
          });
      })
      .catch((errorResponse) => {
        setLoading(false);

        UiToast.error(`Login failed: "${errorResponse}"`);
      });
  };

  return (
    <UiButton type="button" disabled={loading} onClick={() => microsoftLogin()} className={styles.microsoftBtn}>
      <div>
        <IconMicrosoft />
      </div>
      {tHelper(loading ? 'microsoftLoading' : 'microsoft')}
    </UiButton>
  );
};
