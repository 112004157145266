import {
  ProjectContract,
  TaskContract,
  TaskWeekStatusContract,
  TimeTrackingByTeamMemberContract,
  TimeTrackingContract,
} from '@moonpanda/moonpanda.contracts';

import useProjectsListStore from 'src/components/UI/ProjectsDropdown/store';
import { ApiPaginateRequestType, ApiPatchOperationType, CommonInnerApiResponseType } from 'src/models/API';
import { ApiFunctionType, httpDelete, httpGet, httpGetCancellable, httpPatch, httpPost, httpPut } from 'src/utils/http';

const path = 'Projects';

const getPath = (projectId: number) => `${path}/${projectId}`;

export const apiGetProjectList: ApiFunctionType<ApiPaginateRequestType, ProjectContract[]> = httpGetCancellable(path);

export const apiCreateProjectPhase1: ApiFunctionType<ProjectContract, ProjectContract> = (data) =>
  httpPost(path, data).then((response: CommonInnerApiResponseType<ProjectContract>) => {
    useProjectsListStore.getState().add(response.data);

    return response;
  });

export const apiGetProjectData: ApiFunctionType<{ projectId: number }, ProjectContract> = (data) =>
  httpGet(getPath(data.projectId), data);

export const apiReplaceProjectData: ApiFunctionType<ProjectContract, ProjectContract> = (data) =>
  httpPut(getPath(data.id), data);

export const apiUpdateProjectData: ApiFunctionType<ApiPatchOperationType<{ projectId: number }>, ProjectContract> = (
  data,
) =>
  httpPatch(getPath(data.projectId), data).then((response) => {
    useProjectsListStore.getState().update(response.data);

    return response;
  });

export const apiDeleteProject: ApiFunctionType<{ projectId: number }, void> = (data) =>
  httpDelete(getPath(data.projectId), data).then((response) => {
    useProjectsListStore.getState().delete(data.projectId);

    return response;
  });

export const apiGetProjectTimeTrackingData: ApiFunctionType<
  { weekNumber: number; projectId: number },
  TimeTrackingContract
> = (data) => httpGet(`/Dashboard/project/${data.projectId}/timeTracking`, data);

export const apiGetProjectTimeTrackingModalData: ApiFunctionType<
  { weekNumber: number; projectId: number },
  TimeTrackingByTeamMemberContract[]
> = (data) => httpGet(`/Dashboard/project/${data.projectId}/timeTracking/byTeamMember`, data);

export const apiGetProjectTimeTrackingModalExportLink: ApiFunctionType<
  { weekNumber: number; projectId: number },
  Blob
> = (data) =>
  httpGet(`/Dashboard/project/${data.projectId}/timeTracking/export`, data, {
    expectBlob: true,
  });

export const apiGetProjectTotalTaskOverviewData: ApiFunctionType<{ projectId: number }, TaskWeekStatusContract> = (
  data,
) => httpGet(`/Dashboard/project/${data.projectId}/taskStatus`, data);

export const apiGetProjectDashboardWeeklyTaskOverviewData: ApiFunctionType<
  ApiPaginateRequestType & { projectId: number },
  TaskContract[]
> = (data) => httpGet(`/Dashboard/project/${data.projectId}/tasksOverview`, data);
