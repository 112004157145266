import classNames from 'classnames';
import { memo, useCallback, useMemo, useState } from 'react';
import { TwitterPicker } from 'react-color';

import { UiInput } from 'src/components/UI/inputs/Text';
import { useInputBaseHook } from 'src/hooks/inputs';
import { useRefClickOutside } from 'src/hooks/useRefClickOutside';
import { getStringFromUiLabel } from 'src/utils/reactDom';
import { StateFormInputOptionsType, StateFormReturnType } from 'src/utils/stateForm';

export type UiInputColorPickerProps = {
  name: string;
  formProps: StateFormReturnType;
  label: string;

  required?: boolean;
  className?: boolean;
  placeholder?: string;
  errorLabel?: string;
};

export const UiInputColorPicker = memo<UiInputColorPickerProps>(
  ({ name, label, formProps, errorLabel, required, className, placeholder }) => {
    const [show, setShow] = useState(false);

    const inputOptions = useMemo(
      (): StateFormInputOptionsType => ({
        required,
        errorLabel: errorLabel || getStringFromUiLabel(label) || placeholder || undefined,
      }),
      [errorLabel, label, placeholder, required],
    );

    const [value, errors] = useInputBaseHook<string | number | null | undefined>({
      getSubscribeProps: formProps.getSubscribeProps,
      name,
      inputOptions,
      unregister: formProps.unregister,
      register: formProps.register,
      type: 'color',
    });

    const hasErrors = !!errors?.length;

    const closePopup = useCallback(() => {
      setShow(false);
    }, []);

    const showPopup = useCallback(() => {
      setShow(true);
    }, []);

    const ref = useRefClickOutside<HTMLDivElement>(true, closePopup, 10);

    return (
      <>
        <UiInput
          formProps={formProps}
          name={name}
          label={label}
          placeholder={placeholder}
          onClick={showPopup}
          onChange={closePopup}
          className={classNames('UiInput color', className, {
            required: hasErrors && required,
            error: hasErrors,
            'mp-1': value,
          })}
          required={required}
        >
          {value && (
            <div className="colorPickerPreview prefix" style={{ backgroundColor: (value as string) || '#fff' }} />
          )}
        </UiInput>

        {show && (
          <div className="colorPickerPopup" ref={ref}>
            <TwitterPicker
              color={formProps.getValue(name) || undefined}
              onChangeComplete={(color) => {
                closePopup();

                formProps.setValue(name, color.hex);
              }}
            />
          </div>
        )}
      </>
    );
  },
);
