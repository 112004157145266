/* eslint-disable max-len */
import classNames from 'classnames';
import { FC } from 'react';
import { CommonIconProps } from 'src/utils/iconProps';

type Props = CommonIconProps & {
  onClick?: () => void;
  disabled?: boolean;
};

export const IconDownload: FC<Props> = ({ className, onClick, disabled }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="36"
    height="20"
    viewBox="0 0 36 20"
    fill="none"
    className={classNames(className, {
      disabled,
    })}
    onClick={disabled ? undefined : onClick}
  >
    <path
      d="M29.089 0.360352H6.78906C3.47535 0.360352 0.789062 3.04664 0.789062 6.36035V13.9604C0.789062 17.2741 3.47535 19.9604 6.78906 19.9604H29.089C32.4027 19.9604 35.089 17.2741 35.089 13.9604V6.36035C35.089 3.04664 32.4027 0.360352 29.089 0.360352Z"
      fill="#3F5B64"
    />
    <path
      transform="translate(-1,0)"
      d="M15.4105 15.0505C15.1605 15.0505 14.9405 14.9605 14.7605 14.7705C14.5805 14.5805 14.4805 14.3705 14.4805 14.1205V12.1105H15.2104V14.1205C15.2104 14.1205 15.2305 14.2205 15.2705 14.2605C15.3105 14.3005 15.3605 14.3205 15.4105 14.3205H23.2205C23.2205 14.3205 23.3205 14.3005 23.3605 14.2605C23.4005 14.2205 23.4205 14.1705 23.4205 14.1205V12.1105H24.1505V14.1205C24.1505 14.3705 24.0605 14.5905 23.8705 14.7705C23.6805 14.9505 23.4705 15.0505 23.2205 15.0505H15.4105ZM19.3204 12.6305L16.6505 9.96051L17.1805 9.44051L18.9604 11.2105V5.27051H19.6904V11.2105L21.4705 9.44051L22.0005 9.96051L19.3304 12.6305H19.3204Z"
      fill="white"
    />
  </svg>
);
