/* eslint-disable max-len */
import React, { FC } from 'react';

export const IconShowMore: FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="7" height="10" viewBox="0 0 7 10" fill="none">
    <path
      d="M1.41992 1L5.49994 4.52002L1.41992 8.03003"
      stroke="#3F5B64"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
