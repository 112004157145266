import React, { FC, memo } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';

import { SafeAnyType } from 'src/utils/safeAny';
import { ChildrenPropType } from 'src/utils/types';

type Props = {
  children: ChildrenPropType;

  innerRef?: SafeAnyType;

  height?: number;
  autoHeight?: boolean;
  autoHeightMin?: number | string;
  autoHeightMax?: number | string;
  onScrollStart?: () => void;
};

export const UiScrollbar: FC<Props> = memo(
  ({ height, autoHeight, autoHeightMax, autoHeightMin, children, onScrollStart, innerRef }) => (
    <Scrollbars
      ref={innerRef}
      height={height}
      autoHeight={autoHeight}
      autoHeightMin={autoHeightMin}
      autoHeightMax={autoHeightMax}
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      renderTrackVertical={(props) => <div {...props} className="track-vertical" />}
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      renderThumbVertical={(props) => <div {...props} className="thumb-vertical" />}
      onScrollStart={onScrollStart}
    >
      {children}
    </Scrollbars>
  ),
);
