import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { apiGetDashboardWeeklyTaskOverviewData } from 'src/api/dashboard';
import { UiButton } from 'src/components/UI/UiButton';
import { UiWidget } from 'src/components/UI/widgets';
import useDashboardStore from 'src/pages/Dashboard/store';
import { WidgetTotalTaskOverviewModal } from 'src/pages/Dashboard/widgets/totalTaskOverview/modal';

import styles from './styles.module.scss';

const Block = ({ value, text }: { value: number | undefined; text: string }) => (
  <div className={styles.infoBlock}>
    <div className={styles.value}>{value || 0}</div>
    <div className={styles.text}>{text}</div>
  </div>
);

export const WidgetTotalTaskOverview: FC = () => {
  const { t } = useTranslation();

  const { widgetTotalTaskOverviewData, loading, refreshWidgetTotalTaskOverviewData, refreshWidgetTimeTrackingData } =
    useDashboardStore((state) => ({
      widgetTotalTaskOverviewData: state.widgetTotalTaskOverviewData.response,
      loading: state.widgetTotalTaskOverviewData.loading,
      refreshWidgetTotalTaskOverviewData: state.refreshWidgetTotalTaskOverviewData,
      refreshWidgetTimeTrackingData: state.refreshWidgetTimeTrackingData,
    }));

  const [showModal, setShowModal] = useState(false);

  return (
    <div className={styles.widgetWrapper}>
      <div className={styles.top}>
        <div className={styles.header}>{t('widgets.totalTaskOverview.header')}</div>
      </div>
      <UiWidget loading={loading} className={styles.wrapper}>
        <Block value={widgetTotalTaskOverviewData?.inProgress} text={t('common.taskStatuses.InProgress')} />
        <Block value={widgetTotalTaskOverviewData?.inReview} text={t('common.taskStatuses.InReview')} />
        <Block value={widgetTotalTaskOverviewData?.completed} text={t('common.taskStatuses.Completed')} />
        <Block value={widgetTotalTaskOverviewData?.notStarted} text={t('common.taskStatuses.NotStarted')} />

        <div />

        <UiButton
          className={styles.button}
          onClick={() => {
            setShowModal(true);
          }}
        >
          {t('widgets.totalTaskOverview.viewAll')}
        </UiButton>

        {showModal && (
          <WidgetTotalTaskOverviewModal
            apiFn={apiGetDashboardWeeklyTaskOverviewData}
            onClose={(somethingChanged) => {
              setShowModal(false);

              if (somethingChanged) {
                refreshWidgetTimeTrackingData();
                refreshWidgetTotalTaskOverviewData();
              }
            }}
          />
        )}
      </UiWidget>
    </div>
  );
};
