/* eslint-disable max-len */
import { FC } from 'react';

export const IconTasks: FC = () => (
  <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.9805 25.3899C11.2105 25.3899 9.56047 25.0699 8.04047 24.4399C6.52047 23.7999 5.19047 22.9199 4.07047 21.7999C2.95047 20.6799 2.06047 19.3499 1.43047 17.8299C0.79047 16.3099 0.480469 14.6599 0.480469 12.8899C0.480469 11.1199 0.80047 9.49989 1.43047 7.97989C2.07047 6.45989 2.95047 5.12989 4.07047 4.00989C5.19047 2.88989 6.52047 1.99988 8.04047 1.34988C9.56047 0.699884 11.2105 0.379883 12.9805 0.379883C14.5405 0.379883 16.0005 0.629883 17.3605 1.12988C18.7205 1.62988 19.9405 2.31989 21.0205 3.19989L19.6705 4.54987C18.7505 3.81987 17.7305 3.25986 16.6005 2.85986C15.4705 2.45986 14.2605 2.26987 12.9705 2.26987C9.95047 2.26987 7.42047 3.28989 5.38047 5.31989C3.35047 7.34988 2.33047 9.87988 2.33047 12.9099C2.33047 15.9399 3.35047 18.4599 5.38047 20.4999C7.41047 22.5299 9.94047 23.5499 12.9705 23.5499C16.0005 23.5499 18.5205 22.5299 20.5605 20.4999C22.5905 18.4699 23.6105 15.9399 23.6105 12.9099C23.6105 12.2799 23.5605 11.6699 23.4705 11.0799C23.3805 10.4899 23.2405 9.90987 23.0505 9.33987L24.4905 7.89987C24.8205 8.66987 25.0705 9.46988 25.2405 10.3099C25.4105 11.1399 25.4905 12.0099 25.4905 12.9099C25.4905 14.6799 25.1705 16.3299 24.5205 17.8499C23.8705 19.3699 22.9905 20.6999 21.8605 21.8199C20.7305 22.9499 19.4105 23.8299 17.8905 24.4599C16.3705 25.0999 14.7305 25.4099 12.9805 25.4099V25.3899ZM11.1305 18.5699L5.97047 13.3799L7.38047 11.9699L11.1305 15.7199L24.0805 2.76987L25.5205 4.17987L11.1305 18.5699Z"
      fill="#3F5B64"
    />
  </svg>
);
