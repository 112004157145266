/* eslint-disable max-len */
import { FC } from 'react';
import { CommonIconProps } from 'src/utils/iconProps';

type Props = CommonIconProps;

export const IconAvatar: FC<Props> = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="69" height="69" viewBox="0 0 69 69" fill="none" className={className}>
    <path
      d="M21.4897 47.6989C20.9597 47.6989 20.4898 47.4989 20.0898 47.0989C19.6898 46.6989 19.4897 46.2389 19.4897 45.6989V28.6489C19.4897 28.1389 19.6898 27.6789 20.0898 27.2689C20.4898 26.8589 20.9497 26.6589 21.4897 26.6589H26.3798L28.8098 23.7689H37.9197V25.7589H29.7397L27.3098 28.6489H21.4897V45.6989H44.0898V31.9389H46.0798V45.6989C46.0798 46.2289 45.8797 46.6989 45.4697 47.0989C45.0597 47.4989 44.5998 47.6989 44.0898 47.6989H21.4897ZM44.0898 28.6189V25.7589H41.2297V23.7689H44.0898V20.8789H46.0798V23.7689H48.9697V25.7589H46.0798V28.6189H44.0898ZM32.7698 42.8089C34.3798 42.8089 35.7198 42.2689 36.8098 41.1789C37.8998 40.0889 38.4398 38.7489 38.4398 37.1389C38.4398 35.5289 37.8998 34.1889 36.8098 33.1189C35.7198 32.0489 34.3798 31.5089 32.7698 31.5089C31.1598 31.5089 29.8198 32.0489 28.7498 33.1189C27.6798 34.1889 27.1398 35.5389 27.1398 37.1389C27.1398 38.7389 27.6798 40.0889 28.7498 41.1789C29.8198 42.2689 31.1698 42.8089 32.7698 42.8089ZM32.7698 40.8189C31.7198 40.8189 30.8497 40.4689 30.1597 39.7689C29.4697 39.0689 29.1298 38.1989 29.1298 37.1389C29.1298 36.0789 29.4697 35.2189 30.1597 34.5289C30.8497 33.8389 31.7198 33.4989 32.7698 33.4989C33.8198 33.4989 34.6998 33.8389 35.3998 34.5289C36.0998 35.2189 36.4498 36.0889 36.4498 37.1389C36.4498 38.1889 36.0998 39.0689 35.3998 39.7689C34.6998 40.4689 33.8298 40.8189 32.7698 40.8189Z"
      fill="white"
    />
  </svg>
);
