import { TaskContract } from '@moonpanda/moonpanda.contracts';
import classNames from 'classnames';
import { compare } from 'fast-json-patch';
import cloneDeep from 'lodash/cloneDeep';
import React, { FC, useCallback, useMemo, useRef, useState } from 'react';

import { apiUpdateTask } from 'src/api/tasks';
import { UiModal } from 'src/components/UI/Modals';
import { UiTable } from 'src/components/UI/table';
import { MAX_PAGE_SIZE_FOR_ONE_REQUEST } from 'src/config';
import { useGrid } from 'src/hooks/useGrid';
import { useRequest } from 'src/hooks/useRequest';
import { ApiPaginateRequestType } from 'src/models/API';
import { BaseSeparateModal } from 'src/models/modals';
import { WidgetTotalTaskOverviewTimeLogsModal } from 'src/pages/Dashboard/widgets/totalTaskOverview/modalTimeLogs';
import { TasksBillableChangeComponent } from 'src/pages/Tasks/components/BillableChange';
import { TasksDoneChangeComponent } from 'src/pages/Tasks/components/DoneChange';
import { TasksDueDateChangeComponent } from 'src/pages/Tasks/components/DueDateChange';
import { TasksStatusChangeComponent } from 'src/pages/Tasks/components/StatusChange';
import taskPageStyles from 'src/pages/Tasks/styles.module.scss';
import useModalsStore from 'src/store/modals';
import { numberToTimeObject } from 'src/utils/dateTime';
import { getDOMElement } from 'src/utils/DOM';
import { showErrorToast } from 'src/utils/errors';
import { ApiFunctionType, prepareDataToPatch } from 'src/utils/http';
import { useTHelper } from 'src/utils/i18n';

import styles from './styles.module.scss';

type Props = BaseSeparateModal & {
  apiFn:
    | ApiFunctionType<ApiPaginateRequestType, TaskContract[]>
    | ApiFunctionType<ApiPaginateRequestType & { projectId: number }, TaskContract[]>;

  projectId?: number;
};

export const WidgetTotalTaskOverviewModal: FC<Props> = ({ onClose, apiFn, projectId }) => {
  const tHelper = useTHelper('widgets.weeklyTaskOverview');

  const { apiCaller } = useRequest();

  const hasAnythingChangedRef = useRef(false);

  const toggleModals = useModalsStore((state) => state.toggleModals);

  const [showTimeLogsModal, setShowTimeLogsModal] = useState<{ show: boolean; taskId: number }>({
    show: false,
    taskId: 0,
  });

  const { data, load, updateAllData } = useGrid<TaskContract>({
    apiFn,
    initialLoad: false,
    pageSize: MAX_PAGE_SIZE_FOR_ONE_REQUEST,
  });

  const updateTaskInList = useCallback(
    (changedTaskData: TaskContract) => {
      hasAnythingChangedRef.current = true;

      updateAllData((storeData) => {
        const indexToChange = storeData.findIndex(({ id }) => id === changedTaskData.id);

        if (indexToChange >= 0) {
          storeData[indexToChange] = changedTaskData;
        }

        return storeData;
      });
    },
    [updateAllData],
  );

  const updateTaskAPI = useCallback(
    (subject: TaskContract, newSubject: TaskContract) => {
      apiCaller(
        apiUpdateTask,
        prepareDataToPatch(compare(subject, newSubject), {
          taskId: subject.id,
        }),
      )
        .then(({ data }) => {
          updateTaskInList(data);
        })
        .catch(showErrorToast);
    },
    [apiCaller, updateTaskInList],
  );

  const onTableGetData = useCallback(() => {
    load({
      otherParams: {
        projectId,
      },
    });
  }, [load, projectId]);

  const onShowViewTask = useCallback(
    (id: number) => () => {
      toggleModals('viewTask', true, {
        taskId: id,
        onChange: updateTaskInList,
      });
    },
    [toggleModals, updateTaskInList],
  );

  const columns = useMemo(
    () => [
      {
        title: tHelper('columns.done'),
        renderValue: ({ id, status, project }: TaskContract) => (
          <TasksDoneChangeComponent
            taskId={id}
            status={status}
            onSuccess={updateTaskInList}
            projectColor={project?.projectColor as string}
          />
        ),
        className: classNames('text-center', taskPageStyles.doneColumn, styles.columnDone),
      },
      {
        title: tHelper('columns.taskName'),
        renderValue: ({ id, name }: TaskContract) => (
          <span role="presentation" onClick={onShowViewTask(id)} className={taskPageStyles.taskName} title={name}>
            {name}
          </span>
        ),
        className: classNames(taskPageStyles.ellipsable, styles.columnTaskName),
      },
      {
        title: tHelper('columns.assignee'),
        renderValue: ({ assignee }: TaskContract) => <span title={assignee?.name}>{assignee?.name}</span>,
        className: classNames(taskPageStyles.ellipsable, styles.columnAssignee),
      },
      {
        title: tHelper('columns.status'),
        className: styles.columnStatus,
        renderValue: ({ id, status }: TaskContract) => (
          <TasksStatusChangeComponent
            taskId={id}
            status={status}
            onSuccess={updateTaskInList}
            dropdownPortal={getDOMElement('#weeklyTaskOverviewModal')}
          />
        ),
      },
      {
        title: tHelper('columns.dueDate'),
        renderValue: (task: TaskContract) => <TasksDueDateChangeComponent model={task} updateTaskAPI={updateTaskAPI} />,
        className: styles.columnDueDate,
      },
      {
        title: tHelper('columns.timeLogged'),
        renderValue: ({ id, totalLogged }: TaskContract) => (
          <div
            role="presentation"
            onClick={() => {
              setShowTimeLogsModal({
                show: true,
                taskId: id,
              });
            }}
          >
            {(() => {
              const times = numberToTimeObject(totalLogged);

              return tHelper('timeFormatted', {
                hours: times.hours.toString().length < 2 ? `0${times.hours}` : times.hours,
                minutes: times.minutes.toString().length < 2 ? `0${times.minutes}` : times.minutes,
              });
            })()}
          </div>
        ),
        className: styles.columnTimeLogged,
      },
      {
        title: tHelper('columns.billable'),
        renderValue: (task: TaskContract) => (
          <TasksBillableChangeComponent model={task} updateTaskAPI={updateTaskAPI} />
        ),
        className: styles.columnBillable,
      },
    ],
    [onShowViewTask, tHelper, updateTaskAPI, updateTaskInList],
  );

  const getTaskIndexById = (taskId: number) => data.data.findIndex(({ id }) => id === taskId);

  return (
    <UiModal
      onClose={() => {
        onClose(hasAnythingChangedRef.current);
      }}
      header={projectId ? tHelper('project_header') : tHelper('header')}
      contentClassName={styles.modalWrapper}
      id="weeklyTaskOverviewModal"
    >
      <UiTable data={data} initialLoad getData={onTableGetData} columns={columns} />

      {showTimeLogsModal.show && (
        <WidgetTotalTaskOverviewTimeLogsModal
          onClose={() => {
            setShowTimeLogsModal({ show: false, taskId: 0 });
          }}
          timeLogs={(() => {
            const taskIndex = getTaskIndexById(showTimeLogsModal.taskId);

            return taskIndex >= 0 ? data.data[taskIndex].timeLogs : [];
          })()}
          taskId={showTimeLogsModal.taskId}
          onChangeTimeLogs={(clonedTimeLogs) => {
            updateAllData((state) => {
              const newState = cloneDeep(state);

              const taskIndex = getTaskIndexById(showTimeLogsModal.taskId);

              if (~taskIndex) {
                newState[taskIndex].timeLogs = clonedTimeLogs;
              }

              return newState;
            });
          }}
          onTotalLoggedChanged={(task) => {
            updateAllData((state) => {
              const newState = cloneDeep(state);

              const taskIndex = getTaskIndexById(showTimeLogsModal.taskId);

              if (~taskIndex) {
                newState[taskIndex].totalLogged = task.totalLogged;
              }

              return newState;
            });
          }}
        />
      )}
    </UiModal>
  );
};
