// eslint-disable-next-line max-classes-per-file
import { parse, format, setHours, setMinutes, compareAsc, add, differenceInSeconds } from 'date-fns';
import isNil from 'lodash/isNil';

import { DATE_FORMAT_TIMEZONE, SHORT_DATE_FORMAT } from 'src/config';

abstract class ICDate {
  /** format a given date */
  static format(date: Date | number, formatString: string): string {
    try {
      return format(date, formatString); // locale?
    } catch (e) {
      const errorMsg = 'An error formatting the date';

      // eslint-disable-next-line no-console
      console.error(errorMsg, e);

      return errorMsg;
    }
  }

  /** it happens that dates may come not in the format */
  static getSafeDateString(dateString: string | null, format = SHORT_DATE_FORMAT) {
    if (!dateString) {
      return null;
    }

    if (format === SHORT_DATE_FORMAT) {
      const parts = dateString.split('/');

      const getFullPart = (p: string) => `${p.length === 2 ? p : `0${p}`}`;

      return `${getFullPart(parts[0])}/${getFullPart(parts[1])}/${parts[2]}`;
    }

    // .. other formats if needed

    return dateString;
  }

  /** parse a date from a string */
  static parse(date: string, formatStr: string, useTimeZone = true) {
    return parse(date, formatStr, useTimeZone ? new Date() : this.getDateWithoutTime());
  }

  /** set time to existing date (minutes, hours, days, months.... */
  static setTime(date: Date, { hours, minutes }: { hours?: number; minutes?: number }) {
    let localDate = date;

    if (!isNil(hours)) {
      localDate = setHours(localDate, hours);
    }
    if (!isNil(minutes)) {
      localDate = setMinutes(localDate, minutes);
    }

    return localDate;
  }

  /** add time to existing date (minutes, hours, days, months.... */
  static addTime(date: Date, duration: Duration) {
    return add(date, duration);
  }

  /** true if firstDate is strictly after secondDate (not equal) */
  static firstDateAfterSecond(firstDate: Date | number, secondDate: Date | number): boolean {
    return compareAsc(firstDate, secondDate) !== 1;
  }

  static getDateWithoutTime(): Date {
    return this.addTime(new Date(), {
      minutes: (new Date().getTimezoneOffset() / 60) * 60,
    });
  }
}

/**
 * the specific class for any date helpers
 */
export class CDate extends ICDate {
  /** custom methods using base methods */

  static formatStringToDate(value: string | null, toFormat = 'M/dd/yyyy'): string | null {
    return value ? CDate.format(CDate.parse(value, DATE_FORMAT_TIMEZONE), toFormat) : null;
  }

  static parseStrDateToDate(strDate: string): string {
    return CDate.format(CDate.parse(strDate, DATE_FORMAT_TIMEZONE), SHORT_DATE_FORMAT);
  }

  static getDiff(
    date1: Date,
    date2: Date,
    options?: { roundingMethod?: 'minutes' },
  ): {
    hours: string;
    minutes: string;
    seconds: string;
  } {
    const diffInSecondsRaw = differenceInSeconds(date1, date2);

    const diffInHours = Math.floor(diffInSecondsRaw / (60 * 60));

    let diffInMinutes = Math.floor((diffInSecondsRaw - diffInHours * 60 * 60) / 60);

    let diffInSeconds = diffInSecondsRaw - (diffInMinutes * 60 + diffInHours * 60 * 60);

    /** ignore seconds and add a minute if seconds are not 0 */
    if (options?.roundingMethod === 'minutes' && diffInSeconds >= 1) {
      diffInSeconds = 0;
      diffInMinutes += 1;
    }

    return {
      hours: `0${diffInHours}`.slice(-2),
      minutes: `0${diffInMinutes}`.slice(-2),
      seconds: `0${diffInSeconds}`.slice(-2),
    };
  }
}
