import { TaskContract, TaskDashboardContract, TaskStatusEnumContract } from '@moonpanda/moonpanda.contracts';
import classNames from 'classnames';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { Collapse } from 'react-bootstrap';

import { ViewTaskModalTimerRefProps } from 'src/components/common/modals/ViewTask/components/timer';
import { UiLoader } from 'src/components/UI/loaders';
import { UiScrollbar } from 'src/components/UI/Scrollbar';
import useDashboardStore from 'src/pages/Dashboard/store';
import { IconArrow } from 'src/pages/Dashboard/Tasks/icons/Arrow';
import { IconPlus } from 'src/pages/Dashboard/Tasks/icons/Plus';
import { DashboardPageTasksRowComponent } from 'src/pages/Dashboard/Tasks/row';
import useGlobalStore from 'src/store';
import useModalsStore from 'src/store/modals';
import { useTHelper } from 'src/utils/i18n';
import { ChildrenPropType } from 'src/utils/types';
import styles from './styles.module.scss';

type ScrollableBlockType = {
  tasks: TaskDashboardContract[];
  updateTask: (task: TaskContract, prevStatus?: TaskStatusEnumContract) => void;
  onShowViewTask: (taskId: number) => () => void;

  timersRef?: Record<number, ViewTaskModalTimerRefProps> | null;
  childrenBefore?: ChildrenPropType;
};

const ScrollableBlock: FC<ScrollableBlockType> = ({ tasks, onShowViewTask, timersRef, updateTask, childrenBefore }) => {
  if (tasks.length === 0) {
    return null;
  }

  return (
    <>
      {childrenBefore}

      <UiScrollbar autoHeight autoHeightMin="100%" autoHeightMax={184}>
        <div className={styles.content}>
          {tasks.map((task) => (
            <DashboardPageTasksRowComponent
              key={task.taskId}
              task={task}
              onShowViewTask={onShowViewTask}
              updateTask={updateTask}
              timersRef={timersRef}
            />
          ))}
        </div>
      </UiScrollbar>
    </>
  );
};

export const DashboardPageTasks: FC = () => {
  const tHelper = useTHelper('pages.dashboard.tasks');

  const toggleModals = useModalsStore((state) => state.toggleModals);

  const userHasProjects = useGlobalStore((state) => state.userHasProjects);

  const { tasks, getTasks, updateTask, onCreateTask, onDeleteTask } = useDashboardStore((state) => ({
    tasks: state.tasks,
    getTasks: state.getTasks,
    updateTask: state.updateTask,
    onCreateTask: state.onCreateTask,
    onDeleteTask: state.onDeleteTask,
  }));

  useEffect(() => {
    getTasks();
  }, [getTasks]);

  const timersRef = useRef<Record<number, ViewTaskModalTimerRefProps> | null>({});

  const [open, setOpen] = useState(true);

  const onShowViewTask = useCallback(
    (taskId: number) => () => {
      toggleModals('viewTask', true, {
        taskId,
        onChange: (task, taskBefore) => {
          updateTask(task, taskBefore.status);
        },
        onDeleteTask,
        onTimerClick: () => {
          setTimeout(() => {
            timersRef.current?.[taskId].refresh();
            // a little delay
          }, 100);
        },
      });
    },
    [onDeleteTask, toggleModals, updateTask],
  );

  return (
    <div className={styles.todoBlock}>
      <div className={styles.top}>
        <IconArrow
          onClick={() => setOpen(!open)}
          className={classNames(styles.collapseIcon, open ? styles.collapseIconOpen : undefined)}
        />

        <div className={styles.header}>{tHelper('header')}</div>
      </div>

      <Collapse in={open}>
        <div className={styles.center}>
          <div />

          {tasks.loading ? (
            <UiLoader />
          ) : (
            <div className={styles.content}>
              {userHasProjects && (
                <div
                  role="presentation"
                  className={classNames(styles.item, styles.createNew)}
                  onClick={() => {
                    toggleModals('createTask', true, {
                      onCreate: (task: TaskContract) => {
                        onCreateTask(task);
                      },
                    });
                  }}
                >
                  <IconPlus />
                  {tHelper('createNewTask')}
                </div>
              )}

              <ScrollableBlock
                tasks={tasks.notInReview}
                onShowViewTask={onShowViewTask}
                updateTask={updateTask}
                timersRef={timersRef.current}
              />

              <ScrollableBlock
                tasks={tasks.inReview}
                onShowViewTask={onShowViewTask}
                updateTask={updateTask}
                timersRef={timersRef.current}
                childrenBefore={
                  <div className={styles.linedText}>
                    {tHelper('readyForReview')}

                    <IconArrow />
                  </div>
                }
              />
            </div>
          )}
        </div>
      </Collapse>
    </div>
  );
};
