/* eslint-disable max-len */
import { FC } from 'react';

export const IconInvoicing: FC = () => (
  <svg width="28" height="28" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 178.8 224">
    <g>
      <path
        style={{
          fill: '#455a63',
          strokeWidth: 0,
        }}
        d="M65.2,0h80c17.7,0,32,14.3,32,32v160c0,17.7-14.3,32-32,32H33.2c-17.7,0-32-14.3-32-32V56C1.2,37.9,47.1,0,65.2,0ZM145.2,16h-80v16c0,16-8,24-24,24h-24v136c0,8.8,7.2,16,16,16h112c8.8,0,16-7.2,16-16V32c0-8.8-7.2-16-16-16ZM37.3,96h104.4c4.8,0,4.8,12,0,12H37.3c-5.4,0-5.4-12,0-12ZM37.3,120h52c4.8,0,4.8,12,0,12h-52c-5.4,0-5.4-12,0-12ZM37.3,144h52c4.8,0,4.8,12,0,12h-52c-5.4,0-5.4-12,0-12ZM37.3,168h52c4.8,0,4.8,12,0,12h-52c-5.4,0-5.4-12,0-12ZM37.3,72h104.4c4.8,0,4.8,12,0,12H37.3c-5.4,0-5.4-12,0-12ZM143.6,162.1c0-6.8-5.8-10.7-14.4-14.4-2.2-.9-11.5-4.4-11.5-6.7s3.5-3.5,7-3.5,7.6,1,9.6,2.7c.9.8,2.4.8,3.2-.1l3.1-3.1c.9-.9.9-2.4,0-3.4-2.5-2.4-5.8-4-9.7-4.9v-5.7c0-1.6-1.2-2.8-2.8-2.8h-5.6c-1.6,0-2.8,1.2-2.8,2.8v5.6c-7.4,1.5-12.4,6.3-12.4,12.6s8.4,11.6,16.4,14.9c3.3,1.4,9.6,3.9,9.6,6.3,0,3.4-5.4,3.9-8.6,3.9s-8.3-1.1-10.6-3c-1-.8-2.4-.7-3.2.1l-3.3,3.2c-1,1-.9,2.6,0,3.5,3.2,2.8,7.2,4.6,12.1,5.3v5.9c0,1.6,1.2,2.8,2.8,2.8h5.6c1.6,0,2.8-1.2,2.8-2.8v-6.2c8-1.5,12.7-6.1,12.7-13h0Z"
      />
    </g>
  </svg>
);
