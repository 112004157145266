import classNames from 'classnames';
import isBoolean from 'lodash/isBoolean';
import React, { forwardRef, useEffect, useImperativeHandle, useMemo } from 'react';
import Select, { CSSObjectWithLabel, SelectInstance, components } from 'react-select';

import { UiCommonInputLabel, UiCommonInputWrapper } from 'src/components/UI/inputs/common/html';
import { useInputBaseHook } from 'src/hooks/inputs';
import { getDOMElement } from 'src/utils/DOM';
import { useIdOrRandomString } from 'src/utils/formHelper';
import { SafeAnyType } from 'src/utils/safeAny';
import { StateFormInputOptionsType, StateFormReturnType } from 'src/utils/stateForm';
import isFunction from 'lodash/isFunction';

export type UiDropdownProps = {
  formProps: StateFormReturnType;
  name: string;
  options: { value: string | number; label: string; ignore?: boolean; title?: string }[];

  label?: string | React.ReactNode;

  placeholder?: string;
  wrapperClassName?: string;
  className?: string;
  classNameLabel?: string;
  required?: boolean;
  onChange?: (value: UiDropdownProps['options'][0] | null, prevValue: UiDropdownProps['options'][0] | null) => void;
  mode?: 'normal' | 'small';
  color?: string;
  isSearchable?: boolean;
  maxHeight?: number;
  isClearable?: boolean;
  portalTarget?: boolean | HTMLElement | null;
};

export type UiDropdownOptionsType = UiDropdownProps['options'];

export type UiDropdownOnChangeValueType = Parameters<NonNullable<UiDropdownProps['onChange']>>[0];

const Option = (props: SafeAnyType) => (
  // eslint-disable-next-line react/destructuring-assignment
  <div title={props.data.title || props.label}>
    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
    <components.Option {...props} />
  </div>
);

export type UiDropdownRefProps = {
  open: () => void;
};

export const UiDropdown = forwardRef<UiDropdownRefProps, UiDropdownProps>(
  (
    {
      formProps: { getSubscribeProps, register, setValue, unregister },
      className,
      wrapperClassName,
      placeholder,
      required,
      name,
      label,
      classNameLabel,
      options,
      onChange,
      mode = 'normal',
      color,
      isSearchable = false,
      maxHeight = 115,
      isClearable = false,
      portalTarget = false,
    },
    ref,
  ) => {
    const id = useIdOrRandomString();

    const selectRef = React.useRef<SelectInstance<UiDropdownOptionsType[0]>>();

    const inputOptions = useMemo(
      (): StateFormInputOptionsType => ({
        required: required || false,
      }),
      [required],
    );

    const [value, errors] = useInputBaseHook<string | number | null | undefined>({
      getSubscribeProps,
      name,
      inputOptions,
      unregister,
      register,
      type: 'dropdown',
    });

    useEffect(() => {
      if (color) {
        // todo ref?
        const element = getDOMElement(`#${CSS.escape(id)}`);

        if (element) {
          element?.style.setProperty('--dropdownBackgroundColor', color);
          element?.style.setProperty('--dropdownBorderColor', color);
        }
      }
    }, [color, id]);

    const hasErrors = !!errors?.length;

    const selectedOption = useMemo(() => {
      if (value !== undefined) {
        return options.find((option) => option.value === value) || null;
      }

      return null;
    }, [options, value]);

    const customStyles = {
      menuList: (base: CSSObjectWithLabel) => ({
        ...base,
        maxHeight,
      }),
      placeholder: (base: CSSObjectWithLabel) => ({
        ...base,
        maxHeight,
        'text-align': 'left',
      }),
      singleValue: (base: CSSObjectWithLabel) => ({
        ...base,
        'text-align': 'left',
        'grid-area': '1 / 1',
      }),
    };

    useImperativeHandle(ref, () => ({ open: () => selectRef.current?.focus() }));

    return (
      <UiCommonInputWrapper className={wrapperClassName}>
        <UiCommonInputLabel id={id} show={!!label} hasErrors={hasErrors} className={classNameLabel}>
          {label}
        </UiCommonInputLabel>

        <Select
          id={id}
          openMenuOnFocus // important for ref
          ref={selectRef as SafeAnyType}
          value={selectedOption}
          isClearable={isClearable}
          onChange={(selectedValue) => {
            if (!selectedValue?.ignore) {
              setValue(name, selectedValue?.value);
            }

            if (isFunction(onChange)) {
              onChange(selectedValue, selectedOption);
            }
          }}
          styles={customStyles}
          options={options}
          className={classNames('UiInput dropdown', className, {
            required: hasErrors && required,
            error: hasErrors,
            small: mode === 'small',
          })}
          classNamePrefix="react-select"
          isSearchable={isSearchable}
          placeholder={placeholder}
          components={{ Option }}
          menuPortalTarget={(() => {
            if (isBoolean(portalTarget)) {
              return portalTarget ? document.body : null;
            }

            return portalTarget;
          })()}
          menuShouldBlockScroll
        />
      </UiCommonInputWrapper>
    );
  },
);
