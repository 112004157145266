import classNames from 'classnames';
import React, { FC, useEffect } from 'react';

import { DashboardPageComments } from 'src/pages/Dashboard/Comments';
import { DashboardSearch } from 'src/pages/Dashboard/Search';
import useDashboardStore from 'src/pages/Dashboard/store';
import { DashboardPageTasks } from 'src/pages/Dashboard/Tasks';
import { DashboardWidgetTimeTracking } from 'src/pages/Dashboard/widgets/timeTracking';
import { WidgetTotalTaskOverview } from 'src/pages/Dashboard/widgets/totalTaskOverview';

import styles from './styles.module.scss';

export const DashboardPage: FC = () => {
  const { start, stop, isReady } = useDashboardStore((state) => ({
    start: state.start,
    stop: state.stop,
    isReady: !!state.request,
  }));

  useEffect(() => {
    start();

    return () => {
      stop();
    };
  }, [start, stop]);

  return (
    <div className={classNames('bodyPadding', styles.wrapper)}>
      {isReady && (
        <>
          <DashboardSearch />
          <DashboardPageComments />
          <DashboardPageTasks />

          <div className={styles.widgets}>
            <DashboardWidgetTimeTracking />
            <WidgetTotalTaskOverview />
          </div>
          {/*   {!APP_HIDDEN_COMPONENTS.TODO_LIST_WIDGET3_HIDDEN && <WidgetUpcomingEvents />} */}
        </>
      )}
    </div>
  );
};
