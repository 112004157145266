import React, { FC } from 'react';
import { useTHelper } from 'src/utils/i18n';

import styles from './styles.module.scss';

type Props = {
  message?: string; // string | html
};

export const ErrorPage: FC<Props> = ({ message }) => {
  const tHelper = useTHelper('errorPage');

  return (
    <div className={styles.page}>
      <h1>{tHelper('header')}</h1>
      <h2>{message}</h2>
    </div>
  );
};
