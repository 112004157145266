/* eslint-disable max-len */
import { FC } from 'react';

type Props = {
  onClick?: () => void;
  className?: string;
};

export const IconPlus: FC<Props> = ({ onClick, className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 13 13"
    fill="none"
    onClick={onClick}
    className={className}
  >
    <path
      d="M6.45435 9.08024H7.46781V6.73943H9.82207V5.72598H7.46781V3.27306H6.45435V5.72598H4.01489V6.73943H6.45435V9.08024ZM6.9252 12.1654C6.09112 12.1654 5.31086 12.0085 4.58441 11.6991C3.85795 11.3897 3.22118 10.9592 2.67858 10.4166C2.13598 9.87396 1.70549 9.23719 1.39607 8.51073C1.08666 7.78428 0.929688 7.00401 0.929688 6.16993C0.929688 5.33584 1.08666 4.55109 1.39607 3.82463C1.70549 3.09817 2.13598 2.46589 2.67858 1.92329C3.22118 1.38517 3.85795 0.954677 4.58441 0.640775C5.31086 0.326874 6.09112 0.169922 6.92969 0.169922C7.76825 0.169922 8.54851 0.326874 9.27497 0.640775C10.0014 0.954677 10.6382 1.38068 11.1763 1.9188C11.7145 2.45692 12.1449 3.09369 12.4588 3.82015C12.7727 4.5466 12.9297 5.33136 12.9297 6.16993C12.9297 7.00849 12.7727 7.78876 12.4588 8.51522C12.1449 9.24167 11.7145 9.87396 11.1763 10.4166C10.6382 10.9592 10.0014 11.3852 9.27497 11.6991C8.54851 12.013 7.76825 12.1699 6.92969 12.1699L6.9252 12.1654ZM6.9252 10.8381C8.21668 10.8381 9.31533 10.3852 10.2256 9.47934C11.1315 8.57351 11.5844 7.47037 11.5844 6.16544C11.5844 4.8605 11.1315 3.7753 10.2256 2.86499C9.31981 1.95916 8.21669 1.50624 6.91176 1.50624C5.60683 1.50624 4.52161 1.95916 3.61129 2.86499C2.70546 3.77082 2.25256 4.87396 2.25256 6.17889C2.25256 7.48382 2.70546 8.56902 3.61129 9.47934C4.51712 10.3852 5.62027 10.8381 6.9252 10.8381Z"
      fill="#3F5B64"
    />
  </svg>
);
