export const getDOMElement = <T = HTMLElement>(selector: string): T | null =>
  document.querySelector(selector.trim()) as T | null;

export const getDOMElements = (selector: string): NodeListOf<HTMLElement> | null =>
  document.querySelectorAll(selector.trim());

export const addDOMClass = (selector: string, className: string): void => {
  getDOMElement(selector)?.classList.add(className);
};

export const removeDOMClass = (selector: string, className: string): void => {
  getDOMElement(selector)?.classList.remove(className);
};

export const setDOMElementCss = (element: HTMLElement | null, styles: Partial<CSSStyleDeclaration>): void => {
  if (element) {
    const cssText = Object.entries(styles).reduce((acc, [key, value]) => {
      if (value !== null && value !== undefined) {
        /** camelCase to css-case */
        acc += `${key.replace(/[A-Z]/g, (letter) => `-${letter.toLowerCase()}`)}: ${value};`;
      }

      return acc;
    }, '');

    element.style.cssText = `${element.style.cssText}${cssText}`;
  }
};
