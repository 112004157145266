import classNames from 'classnames';
import React, { memo, SyntheticEvent, useCallback } from 'react';
import isFunction from 'lodash/isFunction';

import { UiLoader } from 'src/components/UI/loaders';
import { ChildrenPropType } from 'src/utils/types';

export type UiButton = {
  children: ChildrenPropType;

  type?: 'submit' | 'button' | 'reset';
  onClick?: (e: SyntheticEvent<HTMLButtonElement | HTMLLinkElement>) => void;
  disabled?: boolean;
  loading?: boolean;
  id?: string;
  className?: string;

  color?: 'dark' | 'light' | 'green' | 'red' | 'link';
};

export const UiButton: React.FC<UiButton> = memo(
  ({ color = 'dark', children, onClick, className, type = 'button', disabled = false, loading = false, id }) => {
    const preparedOnClick = useCallback(
      (e: SyntheticEvent<HTMLButtonElement | HTMLLinkElement>) => {
        if (!disabled && isFunction(onClick)) {
          onClick(e);
        }
      },
      [disabled, onClick],
    );

    return (
      <button
        // eslint-disable-next-line react/button-has-type
        type={type}
        id={id}
        onClick={preparedOnClick}
        disabled={disabled || loading}
        className={classNames('button', className, {
          [color]: true,
        })}
      >
        {loading ? <UiLoader btn /> : children}
      </button>
    );
  },
);
