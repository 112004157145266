import classNames from 'classnames';
import isFunction from 'lodash/isFunction';
import isNaN from 'lodash/isNaN';
import isNumber from 'lodash/isNumber';
import React, { memo, useCallback, useMemo } from 'react';
import MaskedInput from 'react-text-mask';

import { UiCommonInputLabel, UiCommonInputWrapper } from 'src/components/UI/inputs/common/html';
import { useIdOrRandomString } from 'src/utils/formHelper';
import { getStringFromUiLabel } from 'src/utils/reactDom';
import { StateFormInputOptionsType, StateFormReturnType } from 'src/utils/stateForm';
import { useInputBaseHook } from 'src/hooks/inputs';

export const maskedInputConvertMask = (mask: string): (RegExp | string)[] =>
  mask.split('').map((symbol) => {
    if (symbol === '9') {
      return /\d/;
    }

    if (symbol !== ' ' && !isNaN(+symbol) && isNumber(+symbol)) {
      return new RegExp(`[0-${symbol}]`);
    }

    return symbol;
  });

export type UiMaskedInputProps = {
  formProps: StateFormReturnType;
  name: string;
  mask: string; // mask as a string

  classNameLabel?: string;
  label?: string | React.ReactNode;
  className?: string;
  placeholder?: string;
  required?: boolean;
  requiredMessage?: string;
  errorLabel?: string;
  wrapperClassName?: string;
  // the function to validate value (not blocking an input)
  validate?: StateFormInputOptionsType['validate'];
  onChange?: (value: UiMaskedInputOnChangeValueType) => void;
  onBlur?: (value: UiMaskedInputOnChangeValueType) => void;
  disabled?: boolean;
  clearOnBlurIfNotFullFilled?: boolean;
};

export type UiMaskedInputOnChangeValueType = string | null;

export const UiMaskedInput = memo<UiMaskedInputProps>(
  ({
    formProps: { getValue, onChange, onBlur, register, unregister, getSubscribeProps, setValue },
    name,
    className,
    required,
    requiredMessage,
    errorLabel,
    validate,
    placeholder,
    mask,
    disabled,
    clearOnBlurIfNotFullFilled,
    wrapperClassName,
    label,
    classNameLabel,
    onChange: propOnChange,
    onBlur: propOnBlur,
  }) => {
    const id = useIdOrRandomString();

    const inputOptions = useMemo(
      (): StateFormInputOptionsType => ({
        required: required || false,
        requiredMessage,
        errorLabel: errorLabel || getStringFromUiLabel(label) || undefined,
        validate,
      }),
      [errorLabel, label, required, requiredMessage, validate],
    );

    const [value, errors] = useInputBaseHook<string | number | undefined>({
      getSubscribeProps,
      name,
      inputOptions,
      unregister,
      register,
      type: 'masked',
    });

    const hasErrors = !!errors?.length;

    const arrMask = useMemo(() => maskedInputConvertMask(mask), [mask]);

    const onChangeLocal = useCallback(
      ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
        if (disabled) {
          return;
        }
        onChange(name, value);

        if (isFunction(propOnChange)) {
          propOnChange(value);
        }
      },
      [disabled, name, onChange, propOnChange],
    );

    const onBlurLocal = useCallback(() => {
      onBlur(name);

      if (clearOnBlurIfNotFullFilled) {
        if ((getValue(name) || '').includes('_')) {
          setValue(name, null);
        }
      }

      if (isFunction(propOnBlur)) {
        propOnBlur(getValue(name));
      }
    }, [clearOnBlurIfNotFullFilled, getValue, name, onBlur, propOnBlur, setValue]);

    return (
      <UiCommonInputWrapper className={wrapperClassName}>
        <UiCommonInputLabel id={id} show={!!label} hasErrors={hasErrors} className={classNameLabel}>
          {label}
        </UiCommonInputLabel>

        <MaskedInput
          id={id}
          mask={arrMask}
          type="text"
          disabled={disabled}
          className={classNames('UiInput masked', className, {
            required: hasErrors && required,
            error: hasErrors,
          })}
          onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
            if (e.key === 'Enter') {
              onBlurLocal();
            }
          }}
          placeholder={placeholder}
          onChange={onChangeLocal}
          value={value}
          onBlur={onBlurLocal}
          data-name={name}
        />
      </UiCommonInputWrapper>
    );
  },
);
