/* eslint-disable max-len */
import { FC } from 'react';

export const IconArrow: FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="9" height="8" viewBox="0 0 9 8" fill="none">
    <path
      d="M0.970276 7.78055L0.340332 7.15054L6.53033 0.960541H0.850281V0.0605469H8.07031V7.28055H7.17029V1.60056L0.980286 7.79056L0.970276 7.78055Z"
      fill="#3F5B64"
    />
  </svg>
);
