import {
  CommentContract,
  EventContract,
  NewCommentItemContract,
  TaskContract,
  TaskDashboardContract,
  TaskStatusEnumContract,
  TaskWeekStatusContract,
  TimeTrackingContract,
} from '@moonpanda/moonpanda.contracts';
import { getWeek, endOfDay, startOfDay } from 'date-fns';
import { apiGetEvents } from 'src/api/events';
import useGlobalStore from 'src/store';
import { getUniqueId } from 'src/utils/getUniqueId';
import { create } from 'zustand';

import {
  apiGetDashboardNewComments,
  apiGetDashboardTasks,
  apiGetTimeTrackingData,
  apiGetTotalTaskOverviewData,
} from 'src/api/dashboard';
import { BaseStoreEntityType, baseStoreEntityValue } from 'src/store/types';
import { showErrorToast } from 'src/utils/errors';
import { FNPropsType, useRequest } from 'src/hooks/useRequest';

type Actions = {
  start: () => void;
  stop: () => void;

  setWidgetTimeTrackingDataWeek: (date: Date) => void;
  getWidgetTimeTrackingData: (softReload?: boolean) => void;
  refreshWidgetTimeTrackingData: () => void;

  getWidgetTotalTaskOverviewData: (softReload?: boolean) => void;
  refreshWidgetTotalTaskOverviewData: () => void;

  getTasks: () => void;
  updateTask: (task: TaskContract, prevStatus?: TaskStatusEnumContract) => void;
  onCreateTask: (task: TaskContract) => void;
  onDeleteTask: (task: TaskContract) => void;

  getComments: () => void;
  addOrUpdateComment: (data: CommentContract) => void;
  removeTaskInComment: (taskId: number, commentId: string) => void;

  getUpcomingEvents: () => void;
};

type State = {
  request: {
    apiCaller: FNPropsType;
    abort: () => void;
  } | null;

  widgetTimeTrackingWeek: Date;
  widgetTimeTrackingData: BaseStoreEntityType<TimeTrackingContract | null>;

  widgetTotalTaskOverviewData: BaseStoreEntityType<TaskWeekStatusContract | null>;

  tasks: {
    loading: boolean;
    inReview: TaskDashboardContract[];
    notInReview: TaskDashboardContract[];
  };

  comments: BaseStoreEntityType<(NewCommentItemContract & { id: string })[] | null>;

  upcomingEvents: BaseStoreEntityType<{ today: EventContract[]; later: EventContract[] }>;
};

const initialState: State = {
  request: null,

  widgetTimeTrackingWeek: new Date(),
  widgetTimeTrackingData: baseStoreEntityValue(null, true),

  widgetTotalTaskOverviewData: baseStoreEntityValue(null, true),

  tasks: {
    loading: true,
    inReview: [],
    notInReview: [],
  },

  comments: baseStoreEntityValue(null, true),

  upcomingEvents: baseStoreEntityValue({ today: [], later: [] }, true),
};

const checkIfTaskBelongsToAssignee = (taskUserId: number | null | undefined): boolean =>
  taskUserId ? taskUserId === useGlobalStore.getState().user.response?.userId : false;

const useDashboardStore = create<State & Actions>()((set, get) => ({
  ...initialState,

  start: () => {
    const { apiCaller, abort } = useRequest.getStatic();

    set({
      request: {
        apiCaller,
        abort,
      },
    });

    get().getWidgetTimeTrackingData();
    get().getWidgetTotalTaskOverviewData();
    get().getComments();
    get().getUpcomingEvents();
  },
  stop: () => {
    get().request?.abort();

    set({
      ...initialState,
    });
  },

  setWidgetTimeTrackingDataWeek: (date) => {
    set({
      widgetTimeTrackingWeek: date,
    });

    get().getWidgetTimeTrackingData(true);
  },
  getWidgetTimeTrackingData: (softReload) => {
    const apiCaller = get().request?.apiCaller;

    if (apiCaller) {
      if (!softReload) {
        set({
          widgetTimeTrackingData: baseStoreEntityValue(null, false),
        });
      }

      apiCaller(apiGetTimeTrackingData, {
        weekNumber: getWeek(get().widgetTimeTrackingWeek),
      })
        .then(({ data }) => {
          set({
            widgetTimeTrackingData: baseStoreEntityValue(data, false),
          });
        })
        .catch((e) => {
          set({
            widgetTimeTrackingData: baseStoreEntityValue(null, false, e),
          });

          showErrorToast(e);
        });
    }
  },
  refreshWidgetTimeTrackingData: () => {
    get().getWidgetTimeTrackingData(true);
  },

  getWidgetTotalTaskOverviewData: (softReload = false) => {
    const apiCaller = get().request?.apiCaller;

    if (apiCaller) {
      if (!softReload) {
        set({
          widgetTotalTaskOverviewData: baseStoreEntityValue(null, true),
        });
      }

      apiCaller(apiGetTotalTaskOverviewData)
        .then(({ data }) => {
          set({
            widgetTotalTaskOverviewData: baseStoreEntityValue(data, false),
          });
        })
        .catch((e) => {
          set({
            widgetTotalTaskOverviewData: baseStoreEntityValue(null, false, e),
          });

          showErrorToast(e);
        });
    }
  },
  refreshWidgetTotalTaskOverviewData: () => {
    get().getWidgetTotalTaskOverviewData(true);
  },

  getTasks: () => {
    const apiCaller = get().request?.apiCaller;

    if (apiCaller) {
      apiCaller(apiGetDashboardTasks)
        .then(({ data }) => {
          const tasksInReview: TaskDashboardContract[] = [];

          const tasksNotInReview: TaskDashboardContract[] = [];

          data.forEach((task) => {
            if (task.status === 'InReview' && checkIfTaskBelongsToAssignee(task.authorUserId)) {
              tasksInReview.push(task);
            } else {
              tasksNotInReview.push(task);
            }
          });

          set({
            tasks: {
              loading: false,
              inReview: tasksInReview,
              notInReview: tasksNotInReview,
            },
          });
        })
        .catch((e) => {
          set({
            tasks: {
              loading: false,
              inReview: [],
              notInReview: [],
            },
          });

          showErrorToast(e);
        });
    }
  },
  updateTask: (task, prevStatus) => {
    const wasInReview = prevStatus === 'InReview';

    // remove the task
    if (task.status === 'Completed') {
      set((state) => ({
        ...state,
        tasks: {
          ...state.tasks,
          [wasInReview ? 'inReview' : 'notInReview']: state.tasks[wasInReview ? 'inReview' : 'notInReview'].filter(
            ({ taskId }) => taskId !== task.id,
          ),
        },
      }));
    } else if (task.status === 'InReview' && prevStatus !== 'InReview') {
      // is assignee moves their task to InReview, do not move it to taskNotInReview
      if (!checkIfTaskBelongsToAssignee(task.assignee?.userId)) {
        // move to InReview block

        let taskNotInReview = get().tasks.notInReview;

        const taskToMove = taskNotInReview.find(({ taskId }) => taskId === task.id);

        taskNotInReview = taskNotInReview.filter(({ taskId }) => taskId !== task.id);

        if (taskToMove) {
          taskToMove.status = task.status;
          taskToMove.taskName = task.name;

          set((state) => ({
            ...state,
            tasks: {
              ...state.tasks,
              inReview: [taskToMove as TaskDashboardContract, ...state.tasks.inReview],
              notInReview: taskNotInReview,
            },
          }));
        }
      }
    } else if (task.status !== 'InReview' && prevStatus === 'InReview') {
      // move to NotInReview block

      let taskInReview = get().tasks.inReview;

      const taskToMove = taskInReview.find(({ taskId }) => taskId === task.id);

      taskInReview = taskInReview.filter(({ taskId }) => taskId !== task.id);

      if (taskToMove) {
        taskToMove.status = task.status;
        taskToMove.taskName = task.name;

        set((state) => ({
          ...state,
          tasks: {
            ...state.tasks,
            notInReview: [taskToMove as TaskDashboardContract, ...state.tasks.notInReview],
            inReview: taskInReview,
          },
        }));
      }
    } else {
      // change status
      set((state) => ({
        ...state,
        tasks: {
          ...state.tasks,
          [wasInReview ? 'inReview' : 'notInReview']: (() => {
            const current = state.tasks[wasInReview ? 'inReview' : 'notInReview'];

            const foundIndex = current.findIndex(({ taskId }) => taskId === task.id);

            if (~foundIndex) {
              current[foundIndex].status = task.status;
              current[foundIndex].taskName = task.name;
            }

            return [...current];
          })(),
        },
      }));
    }

    get().refreshWidgetTotalTaskOverviewData();
    get().refreshWidgetTimeTrackingData();
  },
  onCreateTask: (task) => {
    if (checkIfTaskBelongsToAssignee(task.assignee?.userId)) {
      get().getTasks();
    }
  },
  onDeleteTask: (task) => {
    set((state) => ({
      ...state,
      tasks: {
        loading: state.tasks.loading,
        inReview: state.tasks.inReview.filter(({ taskId }) => taskId !== task.id),
        notInReview: state.tasks.notInReview.filter(({ taskId }) => taskId !== task.id),
      },
    }));
  },

  getComments: () => {
    const apiCaller = get().request?.apiCaller;

    if (apiCaller) {
      apiCaller(apiGetDashboardNewComments)
        .then(({ data }) => {
          set({
            comments: baseStoreEntityValue(
              data.map((comment) => ({
                ...comment,
                id: getUniqueId(),
              })),
              false,
            ),
          });
        })
        .catch((e) => {
          set({
            comments: baseStoreEntityValue(null, false),
          });

          showErrorToast(e);
        });
    }
  },
  addOrUpdateComment: () => {
    get().getComments();
  },
  removeTaskInComment: () => {
    get().getComments();
  },

  getUpcomingEvents: () => {
    const apiCaller = get().request?.apiCaller;

    if (apiCaller) {
      Promise.all([
        apiCaller(
          apiGetEvents,
          {
            page: 0,
            pageSize: 2,
            filters: [
              {
                propertyName: 'startDate',
                operation: 'GreaterThanOrEqual',
                value: startOfDay(new Date()) as unknown as string,
              },
              {
                propertyName: 'startDate',
                operation: 'LessThanOrEqual',
                value: endOfDay(new Date()) as unknown as string,
              },
            ],
            orderBy: [{ propertyName: 'startDate', sortingOrder: 'Asc' }],
          },
          { key: 'today' },
        ),
        apiCaller(
          apiGetEvents,
          {
            page: 0,
            pageSize: 2,
            filters: [
              {
                propertyName: 'startDate',
                operation: 'GreaterThanOrEqual',
                value: endOfDay(new Date()) as unknown as string,
              },
            ],
            orderBy: [{ propertyName: 'startDate', sortingOrder: 'Asc' }],
          },
          { key: 'later' },
        ),
      ])
        .then(([todayResponse, laterResponse]) => {
          set({
            upcomingEvents: baseStoreEntityValue({ today: todayResponse.data, later: laterResponse.data }, false),
          });
        })
        .catch((e) => {
          set({
            upcomingEvents: baseStoreEntityValue({ today: [], later: [] }, false),
          });

          showErrorToast(e);
        });
    }
  },
}));

export default useDashboardStore;
