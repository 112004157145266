import { AddToProjectTeamContract, TeamMemberContract } from '@moonpanda/moonpanda.contracts';

import { ApiPaginateRequestType, ApiPatchOperationType } from 'src/models/API';
import { ApiFunctionType, httpDelete, httpGet, httpPatch, httpPost } from 'src/utils/http';

const getPath = (projectId: number, userId?: number) => `Projects/${projectId}/Team${userId ? `/${userId}` : ''}`;

export const apiGetProjectTeams: ApiFunctionType<
  ApiPaginateRequestType & { projectId: number },
  TeamMemberContract[]
> = (data) => httpGet(getPath(data.projectId), data);

export const apiCreateProjectTeam: ApiFunctionType<
  AddToProjectTeamContract & { projectId: number },
  TeamMemberContract[]
> = (data) => httpPost(getPath(data.projectId), data);

export const apiUpdateProjectTeam: ApiFunctionType<ApiPatchOperationType & { projectId: number; userId: number }> = (
  data,
) => httpPatch(getPath(data.projectId, data.userId), data);

export const apiDeleteProjectUser: ApiFunctionType<{ projectId: number; userId: number }> = (data) =>
  httpDelete(getPath(data.projectId, data.userId), data);
