import { TaskContract } from '@moonpanda/moonpanda.contracts';
import classNames from 'classnames';
import { ReplaceOperation } from 'fast-json-patch/module/core';
import React, { FC, memo, useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { TaskChat } from 'src/components/common/modals/ViewTask/components/chat';
import { ViewTaskModalTimerPartial } from 'src/components/common/modals/ViewTask/components/timer';
import { IconEnd } from 'src/components/common/modals/ViewTask/icons/End';
import { IconStart } from 'src/components/common/modals/ViewTask/icons/Start';
import { ViewTaskModalAdditionalInfoType, ViewTaskModalFormValues } from 'src/components/common/modals/ViewTask/index';
import { ViewTaskTimeLoggedModal } from 'src/components/common/modals/ViewTask/modals/TimeLogged';
import { UiTaskStatusDropdown } from 'src/components/common/TaskStatusDropdown';
import { IconArrowRight } from 'src/components/icons/ArrowRight';
import { parseDateFromUiDatePicker } from 'src/components/UI/inputs/DatePicker';
import { UiDateRangePicker, UiDateRangePickerProps } from 'src/components/UI/inputs/DateRangePicker';
import { UiDropdown, UiDropdownOnChangeValueType, UiDropdownOptionsType } from 'src/components/UI/inputs/DropDown';
import { UiFileUploader } from 'src/components/UI/inputs/FileUploader';
import { IconDelete } from 'src/components/UI/inputs/FileUploader/icons/Delete';
import { UiInput } from 'src/components/UI/inputs/Text';
import { UiWYSIWYG } from 'src/components/UI/inputs/WYSIWYG';
import { UiScrollbar } from 'src/components/UI/Scrollbar';
import { UiButton } from 'src/components/UI/UiButton';
import { defaultProjectColor, SHORT_DATE_FORMAT } from 'src/config';
import { IconPlus } from 'src/pages/Dashboard/Tasks/icons/Plus';
import { getRouteProjectViewPage } from 'src/routes/paths';
import { CDate } from 'src/utils/CDate';
import { cleanWYSIWYGHtml } from 'src/utils/formatters';
import { useTHelper } from 'src/utils/i18n';
import { SafeAnyType } from 'src/utils/safeAny';
import { StateFormReturnType } from 'src/utils/stateForm';
import { useStateFormValueWatch } from 'src/utils/stateForm/useFormWatch/useStateFormValueWatch';
import { UiDeleteConfirmPopover } from 'src/components/UI/DeleteConfirmPopover';

import styles from 'src/components/common/modals/ViewTask/styles.module.scss';

type Props = {
  taskData: TaskContract;
  formProps: StateFormReturnType<ViewTaskModalFormValues>;
  onStatusChange: (_: UiDropdownOnChangeValueType, prevValue: UiDropdownOnChangeValueType) => void;
  onFilesUpload: () => void;
  additionalInfo: ViewTaskModalAdditionalInfoType;
  onUpdateTask: (op: ReplaceOperation<SafeAnyType>[]) => Promise<TaskContract>;
  onDeleteTask: () => void;
  onClose: () => Promise<void>;

  assigneeOptions: UiDropdownOptionsType;
};

export const ViewTaskModalComponent: FC<Props> = memo(
  ({
    taskData,
    formProps,
    onStatusChange,
    onFilesUpload,
    additionalInfo,
    onUpdateTask,
    assigneeOptions,
    onDeleteTask,
    onClose,
  }) => {
    const tHelper = useTHelper('modals.viewTask');

    const navigate = useNavigate();

    const [showDescriptionEdit, setShowDescriptionEdit] = useState(false);

    const [showTimeLoggedEdit, setShowTimeLoggedEdit] = useState(false);

    const [showTitleEdit, setShowTitleEdit] = useState(false);

    const handleBlur = useCallback(
      (property: string) => (value: string) => {
        const op: ReplaceOperation<string | number>[] = [
          {
            op: 'replace',
            path: `/${property}`,
            value: cleanWYSIWYGHtml(value),
          },
        ];

        onUpdateTask(op).finally(() => {
          setShowDescriptionEdit(false);
          setShowTitleEdit(false);
        });
      },
      [onUpdateTask],
    );

    const [projectData, phaseData] = useStateFormValueWatch<
      [ViewTaskModalFormValues['projectData'], ViewTaskModalFormValues['phaseData']]
    >(formProps.getSubscribeProps, ['projectData', 'phaseData']);

    const toolbarButtons = useMemo(() => ['bold', 'italic', 'underline'], []);

    const onDateRangeChange = useCallback(
      (value: Parameters<NonNullable<UiDateRangePickerProps['onChange']>>[0]) => {
        const [startDate, dueDate] = value as [string, string];

        const startDateDate = parseDateFromUiDatePicker(startDate);

        const dueDateDate = parseDateFromUiDatePicker(dueDate);

        const op: ReplaceOperation<string | number>[] = [
          {
            op: 'replace',
            path: '/startDate',
            value: startDateDate as unknown as string,
          },
          {
            op: 'replace',
            path: '/dueDate',
            value: dueDateDate as unknown as string,
          },
        ];

        onUpdateTask(op);
      },
      [onUpdateTask],
    );

    const phaseOptions = useMemo(
      () =>
        projectData.phase.map(({ id, name, startDate, endDate }) => {
          const date = `${CDate.format(new Date(startDate), SHORT_DATE_FORMAT)} - ${CDate.format(
            new Date(endDate),
            SHORT_DATE_FORMAT,
          )}`;

          return {
            value: id,
            label: name,
            title: date,
          };
        }),
      [projectData.phase],
    );

    return (
      <>
        <div className={styles.header}>
          <div className={classNames(styles.status, taskData.isBillableHours ? styles.billable : undefined)}>
            {tHelper('default')}&nbsp;{tHelper(taskData.isBillableHours ? 'billable' : 'nonBillable')}
          </div>

          <div className={styles.taskName}>
            {showTitleEdit ? (
              <UiInput
                formProps={formProps}
                name="taskData.name"
                placeholder={tHelper('namePlaceholder')}
                onBlur={(v) => {
                  handleBlur('name')(v || '');
                }}
                wrapperClassName="mb-0"
              />
            ) : (
              <div
                role="presentation"
                className={classNames(styles.nameWrapper, 'cursor-pointer')}
                onClick={() => {
                  setShowTitleEdit(true);
                }}
              >
                <div className={styles.name} title={taskData.name}>
                  {taskData.name}
                </div>
              </div>
            )}
          </div>
        </div>

        <div className={classNames(styles.blocks, styles.contentBody)}>
          <div className={classNames(styles.block, styles.blockLeft)}>
            <div className={classNames(styles.contentP, styles.inputStatus)}>
              <p>{tHelper('status')}</p>

              <UiTaskStatusDropdown formProps={formProps} name="taskData.status" onChange={onStatusChange} />
            </div>

            <div className={classNames(styles.contentP, styles.inputProjectName)}>
              <p>
                {tHelper('projectLabel')}
                <span
                  role="presentation"
                  onClick={() => {
                    onClose().then(() => {
                      navigate(getRouteProjectViewPage(projectData.id, taskData.phaseId));
                    });
                  }}
                  className={styles.projectLink}
                >
                  <IconArrowRight color={projectData.color || defaultProjectColor} />
                </span>
              </p>
              <div>{projectData.name}</div>
            </div>

            <div className={classNames(styles.contentP, 'cursor-pointer', styles.inputPhase)}>
              <UiDropdown
                formProps={formProps}
                name="taskData.phaseId"
                options={phaseOptions}
                label={tHelper('phaseLabel')}
                placeholder={tHelper('phasePlaceholder')}
                onChange={(value) => {
                  if (value) {
                    const op: ReplaceOperation<number>[] = [
                      {
                        op: 'replace',
                        path: '/phaseId',
                        value: value.value as number,
                      },
                    ];

                    onUpdateTask(op);
                  }
                }}
                maxHeight={100}
                isSearchable
                required
              />
            </div>

            <div className={classNames(styles.contentP, styles.inputPhaseDates)}>
              <p>{tHelper('phaseDates')}</p>
              <div>
                {CDate.format(new Date(phaseData.startDate), SHORT_DATE_FORMAT)}&nbsp;-&nbsp;
                {CDate.format(new Date(phaseData.endDate), SHORT_DATE_FORMAT)}
              </div>
            </div>

            <div className={styles.contentP}>
              <UiDateRangePicker
                key={formProps.getValue('startDueDate')?.toString()}
                formProps={formProps}
                name="startDueDate"
                label={tHelper('startDueDateLabel')}
                required
                onChange={onDateRangeChange}
              />
            </div>

            <ViewTaskModalTimerPartial
              key={taskData.totalLogged}
              taskStatus={formProps.getValue('taskData.status')}
              onTaskChange={(task: TaskContract) => {
                additionalInfo.onChange?.(task, task);

                formProps.setValue('taskData', task);
              }}
              taskId={formProps.getValue('taskData.id')}
              totalLogged={taskData.totalLogged || 0}
              onTimerClick={additionalInfo.onTimerClick}
              childrenAfter={
                <div className={styles.plusIcon}>
                  <IconPlus />
                </div>
              }
            >
              {({ input, text, isStarted, onTimerClick }) => (
                <div className={styles.twoRows}>
                  <div
                    role="presentation"
                    onClick={() => {
                      setShowTimeLoggedEdit(true);
                    }}
                  >
                    {input}
                  </div>
                  <UiButton color="green" className={isStarted ? styles.btnStarted : undefined} onClick={onTimerClick}>
                    {text}
                    {isStarted ? <IconEnd /> : <IconStart />}
                  </UiButton>
                </div>
              )}
            </ViewTaskModalTimerPartial>

            <div className={styles.contentP}>
              <UiDropdown
                formProps={formProps}
                name="taskData.assignee.id"
                options={assigneeOptions}
                label={tHelper('assignee')}
                placeholder={tHelper('assigneePlaceholder')}
                onChange={(value) => {
                  if (value) {
                    const op: ReplaceOperation<string | number>[] = [
                      {
                        op: 'replace',
                        path: '/assignee/id',
                        value: value.value as number,
                      },
                    ];

                    onUpdateTask(op);
                  }
                }}
                maxHeight={100}
                isSearchable
                required
              />
            </div>

            <div className={styles.contentP}>
              <UiDropdown
                formProps={formProps}
                name="taskData.author.id"
                options={assigneeOptions}
                label={tHelper('author')}
                placeholder={tHelper('authorPlaceholder')}
                maxHeight={100}
                isSearchable
                required
                onChange={(value) => {
                  if (value) {
                    const op: ReplaceOperation<string | number>[] = [
                      {
                        op: 'replace',
                        path: '/author/id',
                        value: value.value as number,
                      },
                    ];

                    onUpdateTask(op);
                  }
                }}
              />
            </div>

            <UiFileUploader
              canRemove
              formProps={formProps}
              name="taskData.attachments"
              className={styles.files}
              onUpload={onFilesUpload}
            />
          </div>

          <div className={styles.blockRight}>
            <div className={styles.description}>
              {showDescriptionEdit ? (
                <UiWYSIWYG
                  formProps={formProps}
                  name="taskData.description"
                  placeholder={tHelper('descriptionPlaceholder')}
                  height={55}
                  onBlur={handleBlur('description')}
                  toolbarButtons={toolbarButtons}
                />
              ) : (
                <UiScrollbar autoHeight autoHeightMin="100%" autoHeightMax={100}>
                  <div
                    className={classNames(styles.headRight, 'cursor-pointer')}
                    role="presentation"
                    /* eslint-disable-next-line react/no-danger */
                    dangerouslySetInnerHTML={{
                      __html:
                        taskData.description || `<p style="color: #3f5b6480;">${tHelper('descriptionPlaceholder')}</p>`,
                    }}
                    onClick={() => {
                      setShowDescriptionEdit(true);
                    }}
                  />
                </UiScrollbar>
              )}
            </div>

            <div className={styles.chatWrapper}>
              <TaskChat taskId={taskData.id} projectId={taskData.projectId} projectData={projectData} />
              <div className={styles.actions}>
                <UiDeleteConfirmPopover onConfirm={onDeleteTask} placement="left">
                  <IconDelete />
                </UiDeleteConfirmPopover>
              </div>
            </div>
          </div>
        </div>

        {showTimeLoggedEdit && (
          <ViewTaskTimeLoggedModal
            onClose={() => {
              setShowTimeLoggedEdit(false);
            }}
            onTotalLoggedChanged={(task) => {
              formProps.setValue('taskData.totalLogged', task.totalLogged);
              formProps.setValue('taskData.timeLogs', task.timeLogs);
            }}
            taskData={taskData}
          />
        )}
      </>
    );
  },
);
