import { TaskContract, TaskStatusEnumContract } from '@moonpanda/moonpanda.contracts';
import { ReplaceOperation } from 'fast-json-patch/module/core';
import React, { FC, useEffect } from 'react';

import { apiUpdateTask } from 'src/api/tasks';
import { UiTaskStatusDropdown } from 'src/components/common/TaskStatusDropdown';
import { UiDropdownProps } from 'src/components/UI/inputs/DropDown';
import { useRequest } from 'src/hooks/useRequest';
import { showErrorToast } from 'src/utils/errors';
import { prepareDataToPatch } from 'src/utils/http';
import { useStateForm } from 'src/utils/stateForm';

type Props = {
  status: TaskStatusEnumContract;
  taskId: number;
  onSuccess: (task: TaskContract, prevStatus: TaskStatusEnumContract) => void;

  dropdownPortal?: UiDropdownProps['portalTarget'];
};

type FormValues = {
  status: TaskStatusEnumContract;
};

export const TasksStatusChangeComponent: FC<Props> = ({ status, taskId, onSuccess, dropdownPortal = false }) => {
  const formProps = useStateForm<FormValues>({
    defaultValues: {
      status,
    },
  });

  const { apiCaller } = useRequest();

  useEffect(() => {
    formProps.setValue('status', status);
  }, [formProps, status]);

  return (
    <UiTaskStatusDropdown
      formProps={formProps}
      name="status"
      wrapperClassName="mb-0"
      maxHeight={300}
      portalTarget={dropdownPortal}
      onChange={(value, prevValue) => {
        if (value?.value) {
          const op: ReplaceOperation<TaskStatusEnumContract>[] = [
            {
              op: 'replace',
              path: '/status',
              value: value?.value as TaskStatusEnumContract,
            },
          ];

          apiCaller(
            apiUpdateTask,
            prepareDataToPatch(op, {
              taskId,
            }),
          )
            .then(({ data }) => {
              onSuccess(data, prevValue?.value as TaskStatusEnumContract);
            })
            .catch(showErrorToast);
        }
      }}
    />
  );
};
