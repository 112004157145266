/* eslint-disable max-len */
import { FC } from 'react';

export const IconEnd: FC = () => (
  <svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.95001 14.4597H14.75V6.6597H6.95001V14.4597ZM10.86 20.9497C9.41998 20.9497 8.07001 20.6797 6.82001 20.1297C5.56001 19.5797 4.46002 18.8397 3.52002 17.8997C2.58002 16.9597 1.82998 15.8597 1.28998 14.5997C0.739978 13.3397 0.469971 11.9897 0.469971 10.5497C0.469971 9.11968 0.739978 7.7697 1.28998 6.5097C1.83998 5.2497 2.58002 4.1497 3.52002 3.2197C4.46002 2.2797 5.56001 1.53969 6.82001 0.999695C8.08001 0.449695 9.42999 0.179688 10.87 0.179688C12.31 0.179688 13.65 0.449695 14.91 0.999695C16.17 1.54969 17.27 2.2897 18.2 3.2197C19.14 4.1497 19.88 5.24968 20.42 6.51968C20.97 7.77968 21.24 9.12969 21.24 10.5597C21.24 11.9897 20.97 13.3497 20.42 14.5997C19.87 15.8597 19.13 16.9497 18.2 17.8997C17.27 18.8397 16.17 19.5897 14.9 20.1297C13.64 20.6797 12.29 20.9497 10.86 20.9497ZM10.86 19.7097C13.4 19.7097 15.55 18.8197 17.33 17.0397C19.11 15.2597 19.99 13.0997 19.99 10.5497C19.99 7.99968 19.1 5.85968 17.33 4.07968C15.56 2.29968 13.39 1.41971 10.84 1.41971C8.29003 1.41971 6.14999 2.30968 4.37 4.07968C2.59 5.84968 1.70001 8.0197 1.70001 10.5697C1.70001 13.0997 2.59 15.2597 4.37 17.0397C6.14999 18.8197 8.30999 19.7097 10.86 19.7097Z"
      fill="#FF003D"
    />
  </svg>
);
