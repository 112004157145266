import { PhaseContract, ProjectContract, TaskContract } from '@moonpanda/moonpanda.contracts';
import { compare } from 'fast-json-patch';
import { Operation, ReplaceOperation } from 'fast-json-patch/module/core';
import isFunction from 'lodash/isFunction';
import isNumber from 'lodash/isNumber';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import useProjectsStore from 'src/pages/Projects/store';
import { shallow } from 'zustand/shallow';

import { useTHelper } from 'src/utils/i18n';
import { UiToast } from 'src/components/UI/toasts';
import { viewTaskModalQueryParam } from 'src/routes/paths';
import { apiGetProjectTeams } from 'src/api/team';
import { MAX_PAGE_SIZE_FOR_ONE_REQUEST } from 'src/config';
import { apiTaskCommentsRead } from 'src/api/websocket';
import { prepareSetDateToUiDatePicker } from 'src/components/UI/inputs/DatePicker';
import { apiGetPhaseData } from 'src/api/phases';
import { apiGetProjectData } from 'src/api/projects';
import { useStateFormValueWatch } from 'src/utils/stateForm/useFormWatch/useStateFormValueWatch';
import { prepareDataToPatch } from 'src/utils/http';
import { UiDropdownOnChangeValueType, UiDropdownOptionsType } from 'src/components/UI/inputs/DropDown';
import { useStateForm } from 'src/utils/stateForm';
import { RecursiveNullable } from 'src/utils/types';
import { showErrorToast } from 'src/utils/errors';
import { apiDeleteTask, apiGetTask, apiUpdateTask } from 'src/api/tasks';
import { UiModal } from 'src/components/UI/Modals';
import { useRequest } from 'src/hooks/useRequest';
import useModalsStore from 'src/store/modals';
import { ViewTaskModalComponent } from 'src/components/common/modals/ViewTask/component';

import styles from './styles.module.scss';

export type ViewTaskModalAdditionalInfoType = {
  taskId: number;

  onChange?: (task: TaskContract, taskBefore: TaskContract) => void;
  onClose?: (task: TaskContract) => void;
  onTimerClick?: (value: 'start' | 'stop') => void;
  ids?: number[];
  onDeleteTask?: (task: TaskContract) => void;
};

export type ViewTaskModalFormValues = RecursiveNullable<{
  time: unknown;
  startDueDate: [string, string];
}> & {
  taskData: TaskContract;
  projectData: ProjectContract;
  phaseData: PhaseContract;
};

export const ViewTaskModal: FC = () => {
  const { toggleModals, additionalInfo } = useModalsStore(
    (state) => ({
      toggleModals: state.toggleModals,
      additionalInfo: state.viewTask.additionalInfo as ViewTaskModalAdditionalInfoType,
    }),
    shallow,
  );

  const tHelper = useTHelper('modals.viewTask');

  const formProps = useStateForm<ViewTaskModalFormValues>();

  const { apiCaller } = useRequest();

  const [, setSearchParams] = useSearchParams();

  const [loading, setLoading] = useState(true);

  const taskDataRef = useRef<TaskContract | null>(null);

  const [taskData] = useStateFormValueWatch<ViewTaskModalFormValues, ['taskData']>(formProps.getSubscribeProps, [
    'taskData',
  ]);

  const closeModal = useCallback(
    (): Promise<void> =>
      new Promise((resolve) => {
        toggleModals('viewTask', false);

        setSearchParams((state) => {
          state.delete(viewTaskModalQueryParam);

          return state;
        });

        resolve(undefined);
      }),
    [setSearchParams, toggleModals],
  );

  const [taskId, setTaskId] = useState(() => additionalInfo.taskId);

  useEffect(() => {
    setSearchParams((state) => {
      state.set(viewTaskModalQueryParam, taskId.toString());

      return state;
    });
  }, [setSearchParams, taskId]);

  // assignees
  const [assigneeOptions, setAssigneeOptions] = useState<UiDropdownOptionsType>([]);

  const updateInfo = useCallback(
    ({ projectId, phaseId }: { projectId: number; phaseId: number }) => {
      Promise.all([
        apiCaller(apiGetProjectData, {
          projectId,
        }),
        apiCaller(apiGetPhaseData, {
          projectId,
          phaseId,
        }),
      ])
        .then(([projectResponse, phaseResponse]) => {
          formProps.setValue({
            projectData: projectResponse.data,
            phaseData: phaseResponse.data,
          });

          apiCaller(apiGetProjectTeams, {
            projectId,
            pageSize: MAX_PAGE_SIZE_FOR_ONE_REQUEST,
            page: 0,
          }).then((teamsResponse) => {
            setAssigneeOptions(
              teamsResponse.data.map(({ id, name }) => ({
                value: id,
                label: name,
              })),
            );
          });

          setLoading(false);
        })
        .catch(showErrorToast);
    },
    [apiCaller, formProps],
  );

  useEffect(() => {
    setLoading(true);

    apiCaller(apiGetTask, {
      taskId,
    }).then(
      ({ data }) => {
        taskDataRef.current = data;

        formProps.reset(
          {
            taskData: data,
            startDueDate: [prepareSetDateToUiDatePicker(data.startDate), prepareSetDateToUiDatePicker(data.dueDate)],
          },
          {
            resetInitialForm: true,
          },
        );

        updateInfo({ projectId: data.projectId, phaseId: data.phaseId });
      },
      (error) => {
        showErrorToast(error);

        closeModal();
      },
    );
  }, [apiCaller, closeModal, formProps, taskId, updateInfo]);

  const onUpdateTask = useCallback(
    (op: Operation[]): Promise<TaskContract> =>
      new Promise((resolve, reject) => {
        if (taskData?.id) {
          apiCaller(
            apiUpdateTask,
            prepareDataToPatch(op, {
              taskId: taskData.id,
            }),
          )
            .then(({ data }) => {
              updateInfo({ projectId: data.projectId, phaseId: data.phaseId });

              /** update current project info if status changes (on project view page) */
              if (op.find(({ path }) => path === '/status')) {
                useProjectsStore.getState().getProjectSilent(data.projectId);
              }

              if (isFunction(additionalInfo.onChange)) {
                additionalInfo.onChange(data, formProps.getInitialValue('taskData'));
              }

              formProps.reset(
                {
                  taskData: data,
                },
                {
                  resetInitialForm: true,
                },
              );

              resolve(data);
            })
            .catch((e) => {
              showErrorToast(e);

              reject(e);
            });
        } else {
          reject(new Error('No task id'));
        }
      }),
    [additionalInfo, apiCaller, formProps, taskData?.id, updateInfo],
  );

  const onFilesUpload = useCallback(() => {
    const current = formProps.getValue('taskData');

    const initial = formProps.getInitialValue('taskData');

    onUpdateTask(compare(initial, current));
  }, [formProps, onUpdateTask]);

  const onStatusChange = useCallback(
    (value: UiDropdownOnChangeValueType) => {
      if (value) {
        const op: ReplaceOperation<string>[] = [
          {
            op: 'replace',
            path: '/status',
            value: value.value as string,
          },
        ];

        onUpdateTask(op);
      }
    },
    [onUpdateTask],
  );

  const onBeforeClose = useCallback(() => {
    if (taskDataRef.current) {
      if (isFunction(additionalInfo.onClose)) {
        additionalInfo.onClose(taskDataRef.current);
      }

      apiTaskCommentsRead(taskDataRef.current.id);
    }
  }, [additionalInfo]);

  const onClose = useCallback((): Promise<void> => {
    onBeforeClose();

    return closeModal();
  }, [closeModal, onBeforeClose]);

  const { numberIds, currentIdsPosition, onPositionGo } = useMemo((): {
    numberIds: number;
    currentIdsPosition: number;
    onPositionGo: undefined | ((position: number | 'left' | 'right') => void);
  } => {
    const showArrows = additionalInfo.ids && additionalInfo.ids.length > 1;

    if (!showArrows) {
      return {
        numberIds: 0,
        currentIdsPosition: 0,
        onPositionGo: undefined,
      };
    }

    const ids = additionalInfo.ids as number[];

    const index = ids.findIndex((v) => v === taskId);

    const leftId: number | undefined = ids[index - 1];

    const rightId: number | undefined = ids[index + 1];

    return {
      currentIdsPosition: index,
      numberIds: ids.length,
      onPositionGo: (position) => {
        onBeforeClose();

        if (isNumber(position)) {
          setTaskId(ids[position]);
        } else if (position === 'left') {
          setTaskId(leftId);
        } else {
          setTaskId(rightId);
        }
      },
    };
  }, [additionalInfo.ids, onBeforeClose, taskId]);

  const onDeleteTask = useCallback(() => {
    apiCaller(apiDeleteTask, { taskId: taskData.id })
      .then(() => {
        closeModal();

        if (isFunction(additionalInfo.onDeleteTask)) {
          additionalInfo.onDeleteTask(taskData);
        }

        UiToast.success(tHelper('deleted'));
      })
      .catch(showErrorToast);
  }, [additionalInfo, apiCaller, closeModal, tHelper, taskData]);

  return (
    <UiModal
      onClose={onClose}
      className={styles.wrapper}
      loading={loading}
      header={false}
      bodyClassName={styles.body}
      bodyContentClassName={styles.bodyContentClassName}
      numberIds={numberIds}
      currentIdsPosition={currentIdsPosition}
      onPositionGo={onPositionGo}
    >
      {taskData && (
        <ViewTaskModalComponent
          additionalInfo={additionalInfo}
          formProps={formProps}
          onFilesUpload={onFilesUpload}
          onStatusChange={onStatusChange}
          taskData={taskData}
          onUpdateTask={onUpdateTask}
          assigneeOptions={assigneeOptions}
          onDeleteTask={onDeleteTask}
          onClose={onClose}
        />
      )}
    </UiModal>
  );
};
