/* eslint-disable react/jsx-props-no-spreading */
import classNames from 'classnames';
import equal from 'fast-deep-equal';
import React, { ChangeEvent, memo, useMemo } from 'react';
import isFunction from 'lodash/isFunction';

import { calcInputSelection, getInputMaxLength } from 'src/components/UI/inputs/common/common';
import { UiCommonInputLabel, UiCommonInputWrapper } from 'src/components/UI/inputs/common/html';
import { useInputBaseHook } from 'src/hooks/inputs';
import { useIdOrRandomString } from 'src/utils/formHelper';
import { useHoverErrors } from 'src/components/UI/inputs/common/useHoverErrors';
import { getStringFromUiLabel } from 'src/utils/reactDom';
import { StateFormInputOptionsType, StateFormReturnType } from 'src/utils/stateForm';
import { ChildrenPropType } from 'src/utils/types';

export type UiInputProps = {
  formProps: StateFormReturnType;
  name: string;

  label?: string | React.ReactNode;
  required?: boolean;
  pattern?: RegExp; // the regex pattern for the input (blocks an input, allows only input symbols from regexp)
  placeholder?: string;
  id?: string;
  disabled?: boolean;
  readonly?: boolean;
  replaceValue?: (v: string) => string; // the function to replace input value
  requiredMessage?: string;
  errorLabel?: string;
  type?: 'email' | 'text' | 'password' | 'textarea';
  maxLength?: number;
  minLength?: number;

  onFocus?: (value: string | null | undefined) => void;
  onBlur?: (value: string | null | undefined) => void;

  wrapperClassName?: string;
  className?: string;
  classNameLabel?: string;
  defaultValue?: string;
  rows?: number;
  showHoverError?: boolean;
  onChange?: (value: string | null) => void;
  onKeyDown?: (key: string) => void;
  onClick?: () => void;
  onPaste?: (text: string) => void;
  children?: ChildrenPropType;
  childrenAfter?: ChildrenPropType;
  prepareValue?: (value: string | number | null | undefined) => string | undefined;
  onEnter?: (value: string | null) => void;
};

export const UiInput: React.FC<UiInputProps> = memo(
  ({
    name,
    formProps: { clearErrors, onChange, onBlur: formOnBlur, register, unregister, getSubscribeProps, setRef },
    maxLength,
    required = false,
    requiredMessage,
    minLength,
    placeholder,
    id: propId,
    type = 'text',
    pattern,
    replaceValue,
    wrapperClassName,
    className,
    disabled,
    readonly,
    label,
    errorLabel,
    classNameLabel,
    rows,
    showHoverError = false,
    onFocus,
    onBlur,
    onChange: handlerOnChange,
    defaultValue,
    onClick,
    children,
    childrenAfter,
    prepareValue,
    onEnter,
    onPaste,
    onKeyDown,
  }) => {
    const id = useIdOrRandomString(propId);

    const localMaxLength = useMemo(() => getInputMaxLength(maxLength), [maxLength]);

    const inputOptions = useMemo(
      (): StateFormInputOptionsType => ({
        required,
        minLength,
        requiredMessage,
        errorLabel: errorLabel || getStringFromUiLabel(label) || placeholder || undefined,
        maxLength,
      }),
      [errorLabel, label, maxLength, minLength, placeholder, required, requiredMessage],
    );

    const [value, errors] = useInputBaseHook<string | number | null | undefined>({
      getSubscribeProps,
      name,
      inputOptions,
      unregister,
      register,
      type,
    });

    const hasErrors = !!errors?.length;
    /** end initialization effects */

    const { onChangeInputHover } = useHoverErrors({
      name,
      errors,
      clearErrors,
      showAnyError: showHoverError,
    });

    /** common props */
    const props = {
      id,
      className: classNames('UiInput text', className, {
        required: hasErrors && required,
        error: hasErrors,
        textarea: type === 'textarea',
      }),
      disabled,
      'data-name': name,
      maxLength: localMaxLength,
      placeholder,
      readOnly: disabled || readonly || false,
      onKeyDown: ({ preventDefault, key }: React.KeyboardEvent) => {
        if (disabled) {
          preventDefault();
        }

        if (key === 'Enter' && isFunction(onEnter)) {
          onEnter(value as string | null);
        }

        if (isFunction(onKeyDown)) {
          onKeyDown(key);
        }
      },
      onClick: () => {
        if (isFunction(onClick)) {
          onClick();
        }
      },
      onChange: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (disabled) {
          return;
        }

        onChangeInputHover();

        const {
          target: { value, selectionStart },
        } = e;

        /** handle it all manually */
        let tempValue: string | null = value;

        tempValue = tempValue.trimStart(); // string mustn't start with spaces

        /** the second check of maxLength in case of pasting a value (and also for tests) */
        if (localMaxLength) {
          if (tempValue.length >= localMaxLength) {
            tempValue = tempValue.substring(0, localMaxLength);
          }
        }

        if (tempValue.length && pattern) {
          if (!pattern.test(tempValue)) {
            return;
          }
        }

        /** empty string -> null */
        if (tempValue.length === 0) {
          tempValue = null;
        }
        // else {
        //   /** remove all spaces(! not \s because of \n) in a row but one */
        //   tempValue = tempValue.replace(/ +/g, ' ');
        // }

        if (isFunction(replaceValue) && tempValue) {
          tempValue = replaceValue(tempValue);
        }

        onChange(name, tempValue);
        if (isFunction(handlerOnChange)) {
          handlerOnChange(tempValue);
        }

        if (selectionStart) {
          // safari https://www.py4u.net/discuss/279152
          e.target.selectionEnd = calcInputSelection(selectionStart, value);
        }
      },
      onFocus: (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (isFunction(onFocus)) {
          onFocus(e.target.value);
        }
      },
      onBlur: (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        formOnBlur(name);

        if (isFunction(onBlur)) {
          onBlur(e.target.value);
        }
      },
      onPaste: (e: React.ClipboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (isFunction(onPaste)) {
          e.stopPropagation();
          e.preventDefault();
          onPaste(e.clipboardData.getData('text'));
        }
      },
      ref: setRef(name),
    };

    return (
      <UiCommonInputWrapper className={wrapperClassName}>
        <UiCommonInputLabel
          id={id}
          show={!!label}
          hasErrors={hasErrors}
          required={required}
          disabled={disabled}
          className={classNameLabel}
        >
          {label}
        </UiCommonInputLabel>

        {children}

        {type === 'textarea' ? (
          <textarea {...props} spellCheck="false" value={value || ''} rows={rows} defaultValue={defaultValue} />
        ) : (
          <input
            type={type}
            {...props}
            value={prepareValue ? prepareValue(value) : value || ''}
            defaultValue={defaultValue}
          />
        )}

        {childrenAfter}
      </UiCommonInputWrapper>
    );
  },
  equal,
);
