import React, { Component } from 'react';
import { ErrorPage } from 'src/pages/ErrorPage';

type Props = {
  children: React.ReactNode;
};

export class ErrorBoundary extends Component<Props, { hasError: boolean }> {
  state = {
    hasError: false,
  };

  static getDerivedStateFromError(): { hasError: boolean } {
    return { hasError: true };
  }

  componentDidCatch(error: Error): void {
    if (error.toString().indexOf('ChunkLoadError') > -1) {
      window.location.reload();
    } else {
      // eslint-disable-next-line no-console
      console.error('ErrorBoundary', error);
    }
  }

  render(): React.ReactNode {
    const { children } = this.props;

    const { hasError } = this.state;

    if (hasError) {
      return <ErrorPage />;
    }

    return children;
  }
}
