/* eslint-disable max-len */
import { FC } from 'react';

export const IconToDoList: FC = () => (
  <svg width="26" height="29" viewBox="0 0 26 29" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.84023 26.31H8.70024V16.54H17.2902V26.31H23.1502V11.08L12.9902 3.46003L2.83023 11.08V26.31H2.84023ZM0.500235 28.65V9.90002L13.0002 0.52002L25.5002 9.90002V28.65H14.9502V18.88H11.0402V28.65H0.490234H0.500235Z"
      fill="#3F5B64"
    />
  </svg>
);
