import classNames from 'classnames';
import { FC } from 'react';
import { UiLoader } from 'src/components/UI/loaders';

import { ChildrenPropType } from 'src/utils/types';

import styles from './styles.module.scss';

type Props = {
  children: ChildrenPropType;

  loading?: boolean;
  className?: string;
};

export const UiWidget: FC<Props> = ({ children, loading, className }) => (
  <div className={classNames(styles.wrapper, className)}>{loading ? <UiLoader /> : children}</div>
);
