import { FC, memo } from 'react';

import { UiGantt } from 'src/components/UI/gantt';

import styles from './styles.module.scss';

type Props = {
  projectId: number;
};

export const ProjectsProjectGantSection: FC<Props> = memo(({ projectId }) => (
  <div className={styles.container} id="ganntWrapper">
    {projectId && <UiGantt projectId={projectId} />}
  </div>
));
