import { FileContract } from '@moonpanda/moonpanda.contracts';
import React, { FC } from 'react';

import { UiPopover } from 'src/components/UI/Popover';
import { IconPreview } from 'src/components/icons/Preview';
import { getFileExtensionFromName, openFileInNewTab, previewableFileTypes, useDownloadFile } from 'src/utils/file';

type Props = {
  file: FileContract;
};

export const DocumentsPagePreviewFileButton: FC<Props> = ({ file }) => {
  const { downLoad } = useDownloadFile();

  return previewableFileTypes.includes(getFileExtensionFromName(file.fileName)) ? (
    <IconPreview
      onClick={() => {
        downLoad(file).then((response) => {
          if (response) {
            openFileInNewTab(response, file.fileName);
          }
        });
      }}
    />
  ) : (
    <UiPopover
      placement="top"
      trigger="hover"
      /* eslint-disable-next-line react/no-unstable-nested-components,react/no-unescaped-entities */
      content={() => <span>This file can't be previewed</span>}
    >
      <IconPreview className="disabled" />
    </UiPopover>
  );
};
