/* eslint-disable max-len */
import { FC } from 'react';

import { CommonIconProps } from 'src/utils/iconProps';

type Props = CommonIconProps;

export const IconCreate: FC<Props> = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="27" height="26" viewBox="0 0 27 26" fill="none" className={className}>
    <path
      d="M13.45 25.8896C20.4916 25.8896 26.2 20.1813 26.2 13.1396C26.2 6.09802 20.4916 0.389648 13.45 0.389648C6.40832 0.389648 0.699951 6.09802 0.699951 13.1396C0.699951 20.1813 6.40832 25.8896 13.45 25.8896Z"
      fill="#455A63"
    />
    <path
      d="M12.9501 16.2296H14.0301V13.7395H16.5301V12.6595H14.0301V10.0495H12.9501V12.6595H10.3601V13.7395H12.9501V16.2296ZM13.4501 19.5096C12.5601 19.5096 11.7301 19.3396 10.9601 19.0096C10.1901 18.6796 9.51007 18.2295 8.93007 17.6495C8.35007 17.0695 7.90007 16.3995 7.57007 15.6295C7.24007 14.8595 7.07007 14.0296 7.07007 13.1396C7.07007 12.2496 7.24007 11.4195 7.57007 10.6495C7.90007 9.87954 8.35007 9.19955 8.93007 8.62955C9.51007 8.05955 10.1801 7.59953 10.9501 7.26953C11.7201 6.93953 12.5501 6.76953 13.4401 6.76953C14.3301 6.76953 15.1601 6.93953 15.9301 7.26953C16.7001 7.59953 17.3801 8.05955 17.9501 8.62955C18.5201 9.19955 18.9801 9.87954 19.3101 10.6495C19.6401 11.4195 19.8101 12.2595 19.8101 13.1495C19.8101 14.0395 19.6401 14.8696 19.3101 15.6396C18.9801 16.4096 18.5201 17.0795 17.9501 17.6595C17.3801 18.2395 16.7001 18.6895 15.9301 19.0195C15.1601 19.3495 14.3301 19.5195 13.4401 19.5195L13.4501 19.5096ZM13.4501 18.0995C14.8201 18.0995 15.9901 17.6195 16.9601 16.6595C17.9201 15.6995 18.4101 14.5196 18.4101 13.1396C18.4101 11.7596 17.9301 10.5995 16.9701 9.62955C16.0101 8.66955 14.8301 8.17953 13.4501 8.17953C12.0701 8.17953 10.9101 8.65954 9.94007 9.61954C8.98007 10.5795 8.49007 11.7596 8.49007 13.1396C8.49007 14.5196 8.97007 15.6795 9.93007 16.6495C10.8901 17.6095 12.0701 18.0995 13.4501 18.0995Z"
      fill="white"
    />
  </svg>
);
