import classNames from 'classnames';
import isFunction from 'lodash/isFunction';
import React, { ReactNode } from 'react';
import { UiTableColumnType, uiTableGetColumnDataWithItem } from 'src/components/UI/table/helpers';
import { SafeAnyType } from 'src/utils/safeAny';

export const UiTablePartialTD =
  (item: SafeAnyType) =>
  // eslint-disable-next-line
  (columnRaw: UiTableColumnType): ReactNode => {
    const columnData = uiTableGetColumnDataWithItem(columnRaw, item);

    const valueToRender = isFunction(columnData.renderValue)
      ? (columnData.renderValue(item) as ReactNode)
      : item[columnData.renderValue];

    return (
      <td key={columnData.title}>
        {valueToRender ? <div className={classNames('item-content', columnData.className)}>{valueToRender}</div> : ''}
      </td>
    );
  };
