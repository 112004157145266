import { Reducer, useCallback, useEffect, useReducer, useState } from 'react';
import isFunction from 'lodash/isFunction';

import { ApiError, useRequest } from 'src/hooks/useRequest';
import { CommonInnerApiResponseType } from 'src/models/API';
import { SafeAnyType } from 'src/utils/safeAny';
import { BaseStoreEntityType, baseStoreEntityValue } from 'src/store/types';
import { PromiseReturnType } from 'src/utils/types';

type ActonType =
  | { type: 'start' }
  | { type: 'ok'; payload: SafeAnyType }
  | { type: 'change'; payload: SafeAnyType }
  | { type: 'error'; payload: ApiError }
  | { type: 'finish' };

type ReducerType<R = SafeAnyType> = Reducer<BaseStoreEntityType<CommonInnerApiResponseType<R>, ApiError>, ActonType>;

const reducer: ReducerType = (state, action) => {
  switch (action.type) {
    case 'start':
      return baseStoreEntityValue(null, true);
    case 'ok':
      return baseStoreEntityValue({ ...action.payload, loading: false });
    case 'change':
      return baseStoreEntityValue(
        state.response
          ? { ...state.response, ...action.payload, loading: false }
          : { ...action.payload, loading: false },
      );
    case 'error':
      return baseStoreEntityValue(null, false, action.payload);
    case 'finish':
      return { ...state, loading: false };
    default:
      return state;
  }
};

type ReturnType<R extends SafeAnyType, F extends (...args: SafeAnyType) => SafeAnyType> = {
  apiCaller: (data?: Parameters<F>[0]) => Promise<PromiseReturnType<F>>;
  abort: () => void;
  changeResponse: (data: Partial<R>) => void;
  errorTextList: string[];
  clearErrors: () => void;
  addErrors: (errors: string[]) => void;
} & BaseStoreEntityType<CommonInnerApiResponseType<R>, ApiError>;

type ConfigProps<R extends SafeAnyType, F extends (...args: SafeAnyType) => SafeAnyType> = {
  data?: Parameters<F>[0];
  showErrors?: boolean;
  saveResponse?: boolean;
  initialCall?: boolean;
  onSuccess?: (response: CommonInnerApiResponseType<R>) => void;
  onError?: (error: ApiError) => void;
  onFinally?: () => void;
};

export const useOneRequest = <
  R extends SafeAnyType = SafeAnyType,
  F extends (...args: SafeAnyType) => SafeAnyType = (...args: SafeAnyType) => SafeAnyType,
>(
  apiFunction: F,
  { data, showErrors, initialCall, saveResponse, onSuccess, onError, onFinally }: ConfigProps<R, F> = {},
): ReturnType<R, F> => {
  const { apiCaller, abort } = useRequest();

  const [errorTextList, setErrorTextList] = useState<string[]>([]);

  /** errors specific to the request */
  const [state, dispatch] = useReducer<ReducerType<R>>(
    reducer,
    baseStoreEntityValue<CommonInnerApiResponseType<R>, ApiError>(null, !!initialCall),
  );

  const addErrors = useCallback((errors: string[]) => {
    setErrorTextList((prevState) => [...new Set([...prevState, ...errors])]);
  }, []);

  const clearErrors = useCallback(() => {
    setErrorTextList([]);
  }, []);

  const abortLocal = useCallback(() => {
    dispatch({ type: 'finish' });

    abort();
  }, [abort]);

  const changeResponse = useCallback((payload: Partial<R>) => dispatch({ type: 'change', payload }), []);

  const apiCallerLocal = useCallback(
    (apiData: Parameters<F>[0] = data) => {
      abort();

      dispatch({ type: 'start' });

      clearErrors();

      return new Promise<SafeAnyType>((resolve, reject) => {
        apiCaller(apiFunction, apiData, { showErrors })
          .then((response: SafeAnyType) => {
            if (initialCall || saveResponse) {
              dispatch({ type: 'ok', payload: response });
            }

            if (isFunction(onSuccess)) {
              onSuccess(response);
            }

            resolve(response);

            return response;
          })
          .catch((error: ApiError) => {
            if (initialCall || saveResponse) {
              dispatch({ type: 'error', payload: error });
            }

            setErrorTextList(['error.errorMessages']);

            if (isFunction(onError)) {
              onError(error);
            }

            reject(error);

            // throw error;
          })
          .finally(() => {
            if (isFunction(onFinally)) {
              onFinally();
            }

            dispatch({ type: 'finish' });
          });
      });
    },
    // eslint-disable-next-line
    [apiCaller, abort, apiFunction, initialCall, saveResponse, showErrors, clearErrors],
  );

  useEffect(() => {
    if (initialCall) {
      apiCallerLocal();
    }
  }, [apiCallerLocal, initialCall]);

  return {
    apiCaller: apiCallerLocal,
    abort: abortLocal,
    loading: state.loading,
    response: state.response,
    error: state.error,
    changeResponse,
    errorTextList,
    clearErrors,
    addErrors,
  };
};
