import classNames from 'classnames';
import React, { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { NumericFormat } from 'react-number-format';
import isNumber from 'lodash/isNumber';
import isFunction from 'lodash/isFunction';

import { UiCommonInputLabel, UiCommonInputWrapper } from 'src/components/UI/inputs/common/html';
import { useInputBaseHook } from 'src/hooks/inputs';
import { useIdOrRandomString } from 'src/utils/formHelper';
import { createArrayXLength } from 'src/utils/objects';
import { getStringFromUiLabel } from 'src/utils/reactDom';
import { StateFormRegisterOptions, StateFormReturnType } from 'src/utils/stateForm';

export type UiFormatNumberProps = {
  formProps: StateFormReturnType;
  name: string;

  label?: string | React.ReactNode;
  prefix?: string;
  postfix?: string;
  classNameLabel?: string;
  max?: number; // value. not a length because a value is more flexible
  min?: number;
  allowNegative?: boolean;
  thousandSeparator?: string;
  placeholder?: string;
  disabled?: boolean;
  className?: string;
  wrapperClassName?: string;
  required?: boolean;
  autoFocus?: boolean;
  decimalScale?: number;
  onlyInteger?: boolean; // synonym for decimalScale={0}
  onBlur?: (name: string, value: UiFormatNumberOnChangeValueType) => void;
  id?: string;
  zeroInsteadEmptyString?: boolean;
  allowZeroValue?: boolean;
  errorLabel?: string;
  requiredMessage?: string;
  onEnter?: () => void;
  onChange?: (value: UiFormatNumberOnChangeValueType) => void;
};

export type UiFormatNumberOnChangeValueType = number | null;

export const UiFormatNumberInput = memo<UiFormatNumberProps>(
  ({
    formProps: { onChange, onBlur: formOnBlur, getValue, register, unregister, getSubscribeProps, setRef },
    name,
    max = 999999,
    min = 0,
    allowNegative = false,
    thousandSeparator = ',',
    prefix,
    postfix,
    placeholder,
    disabled,
    className,
    wrapperClassName,
    required = false,
    autoFocus = false,
    decimalScale = 2,
    onlyInteger,
    onBlur,
    id: propId,
    zeroInsteadEmptyString,
    allowZeroValue,
    errorLabel,
    requiredMessage,
    onEnter,
    onChange: onChangeProp,
    label,
    classNameLabel,
  }) => {
    const { t } = useTranslation();

    const id = useIdOrRandomString(propId);

    const inputOptions: StateFormRegisterOptions = useMemo(
      () => ({
        required,
        errorLabel: errorLabel || getStringFromUiLabel(label) || placeholder || undefined,
        requiredMessage,
        /** this way seems to be better than preventing input */
        validate: (floatValue) =>
          min && floatValue && +floatValue < min
            ? t(`common.validation.numberMin`, {
                name: errorLabel || getStringFromUiLabel(label) || placeholder || name,
                value: floatValue,
                min,
              }).toString()
            : true,
        type: 'number',
      }),
      [errorLabel, label, min, name, placeholder, required, requiredMessage, t],
    );

    const [value, errors] = useInputBaseHook({
      name,
      inputOptions,
      type: 'number',
      register,
      unregister,
      getSubscribeProps,
    });

    const hasErrors = !!errors?.length;

    const preparePlaceholder: string = useMemo(() => {
      if (placeholder) {
        return placeholder;
      }

      if (!onlyInteger && decimalScale > 0) {
        return `${prefix || ''}0.${createArrayXLength(decimalScale)
          .map(() => 0)
          .join('')}`;
      }

      return `${prefix || ''}0`;
    }, [decimalScale, onlyInteger, placeholder, prefix]);

    return (
      <UiCommonInputWrapper className={wrapperClassName}>
        <UiCommonInputLabel id={id} show={!!label} hasErrors={hasErrors} className={classNameLabel}>
          {label}
        </UiCommonInputLabel>

        <NumericFormat
          getInputRef={setRef(name)}
          prefix={prefix}
          data-name={name}
          thousandSeparator={thousandSeparator}
          allowNegative={isNumber(min) && min < 0 ? true : allowNegative}
          autoFocus={autoFocus}
          decimalScale={onlyInteger ? 0 : decimalScale}
          id={id}
          isAllowed={({ floatValue, value }) => {
            const allowedErrors = [];

            // for values similar to 000000
            if (floatValue === 0 && value.split('.')[0].length > 1) {
              allowedErrors.push('too many zeros');
            } else if (floatValue === 0 && !allowZeroValue && !zeroInsteadEmptyString) {
              allowedErrors.push('zero value');
            }
            if (max && floatValue && floatValue > max) {
              allowedErrors.push('max');
            }

            return !allowedErrors.length;
          }}
          value={value}
          disabled={disabled}
          placeholder={preparePlaceholder}
          className={classNames('UiInput formatNumber', className, {
            required: hasErrors && required,
            error: hasErrors,
            disabled,
          })}
          onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
            if (!(onlyInteger || decimalScale === 0)) {
              // https://github.com/s-yadav/react-number-format/issues/115#issuecomment-342141253
              const { key, target } = e;

              const {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                selectionStart,
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                value,
              } = target;

              /** replace ',' to '.' when enter decimals. allow both ',' and '.' */
              if (key === ',' && !value.endsWith('.')) {
                e.preventDefault();
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                target.value = `${value.substring(0, selectionStart)}.${value.substring(selectionStart, value.length)}`;
              }
            }
            if (e.key === 'Enter' && isFunction(onEnter)) {
              onEnter();
            }
          }}
          onChange={({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
            if (disabled) {
              return;
            }

            const numberValue = Number.parseFloat(value.replace(prefix || '', '').replaceAll(',', ''));

            if (!Number.isNaN(numberValue) || !value) {
              onChange(name, (numberValue === 0 && allowZeroValue) || numberValue ? numberValue : null);

              if (isFunction(onChangeProp)) {
                onChangeProp((numberValue === 0 && allowZeroValue) || numberValue ? numberValue : null);
              }
            }
          }}
          onBlur={() => {
            formOnBlur(name);

            if (zeroInsteadEmptyString && !value && value !== 0) {
              onChange(name, 0);
            }

            if (isFunction(onBlur)) {
              onBlur(name, getValue(name));
            }
          }}
        />

        {postfix && <span className="postfix">{postfix}</span>}
      </UiCommonInputWrapper>
    );
  },
);
