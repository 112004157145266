import { AddEventModalAdditionalInfoType } from 'src/components/common/modals/AddEvent';
import { AddTeamMembersToProjectModalAdditionalInfoType } from 'src/components/common/modals/AddTeamMembersToProject';
import { CreateProjectModalAdditionalInfoType } from 'src/components/common/modals/CreateProject';
import { CreateTaskModalAdditionalInfoType } from 'src/components/common/modals/CreateTask';
import { ViewTaskModalAdditionalInfoType } from 'src/components/common/modals/ViewTask';
import { createWithEqualityFn } from 'zustand/traditional';
import { ViewInvoiceModalAdditionalInfoType } from 'src/components/common/modals/ViewInvoice';
import { CreateInvoiceModalAdditionalInfoType } from 'src/components/common/modals/CreateInvoice';

type GlobalModals =
  | 'createProject'
  | 'createTask'
  | 'createInvoice'
  | 'viewInvoice'
  | 'viewTask'
  | 'addTeamMembersToProject'
  | 'trialEnded'
  | 'eventModal'
  | 'paymentLock';

type AdditionalInfoType =
  | CreateTaskModalAdditionalInfoType
  | CreateProjectModalAdditionalInfoType
  | ViewTaskModalAdditionalInfoType
  | AddTeamMembersToProjectModalAdditionalInfoType
  | AddEventModalAdditionalInfoType
  | ViewInvoiceModalAdditionalInfoType
  | CreateInvoiceModalAdditionalInfoType;

type Actions = {
  toggleModals: (name: GlobalModals, value: boolean, additionalInfo?: AdditionalInfoType) => void;
};

type State = Record<GlobalModals, { show: boolean; additionalInfo?: AdditionalInfoType }>;

const initialState: State = {
  createProject: { show: false },
  createTask: { show: false },
  createInvoice: { show: false },
  viewInvoice: { show: false },
  viewTask: { show: false },
  addTeamMembersToProject: { show: false },
  trialEnded: { show: false },
  eventModal: { show: false },
  paymentLock: { show: false },
};

const useModalsStore = createWithEqualityFn<State & Actions>()(
  (set) => ({
    ...initialState,

    toggleModals: (type, value, additionalInfo) =>
      set(() => ({
        [type]: {
          show: value,
          additionalInfo,
        },
      })),
  }),
  Object.is,
);

export default useModalsStore;
