import { FC } from 'react';

type Props = {
  colSpan: number;
};

export const UiTablePartialNoData: FC<Props> = ({ colSpan }) => (
  <tr>
    <td colSpan={colSpan}>
      <div className="table-no-data">No data is found</div>
    </td>
  </tr>
);
