import { FC, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { apiAccountDeleteAccount } from 'src/api/account';
import { UiDeleteConfirmPopover } from 'src/components/UI/DeleteConfirmPopover';
import { UiToast } from 'src/components/UI/toasts';
import { UiButton } from 'src/components/UI/UiButton';
import { useOneRequest } from 'src/hooks/useOneRequest';
import useGlobalStore from 'src/store';
import { showErrorToast } from 'src/utils/errors';
import { useTHelper } from 'src/utils/i18n';

import styles from 'src/pages/Settings/Personal/styles.module.scss';

export const SettingsPageDeleteComponent: FC = () => {
  const tHelper = useTHelper('pages.settings.personal.delete');

  const logOut = useGlobalStore((state) => state.logOut);

  const navigate = useNavigate();

  const { apiCaller, loading } = useOneRequest(apiAccountDeleteAccount);

  const onDelete = useCallback(() => {
    apiCaller()
      .then(() => {
        UiToast.success(tHelper('deleteSuccess'));

        logOut(navigate);
      })
      .catch(showErrorToast);
  }, [apiCaller, logOut, navigate, tHelper]);

  return (
    <div className={styles.deleteWrapper}>
      <p>{tHelper('deleteLabel')}</p>
      <UiDeleteConfirmPopover placement="right" onConfirm={onDelete}>
        <UiButton color="red" loading={loading}>
          {tHelper('deleteBtn')}
        </UiButton>
      </UiDeleteConfirmPopover>
    </div>
  );
};
