/* eslint-disable max-len */
import { FC } from 'react';
import { CommonIconProps } from 'src/utils/iconProps';

type Props = CommonIconProps & {
  onClick?: () => void;
};

export const IconForward: FC<Props> = ({ className, onClick }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="35"
    height="20"
    viewBox="0 0 35 20"
    fill="none"
    className={className}
    onClick={onClick}
  >
    <path
      d="M28.9993 0.360352H6.69922C3.38551 0.360352 0.699219 3.04664 0.699219 6.36035V13.9604C0.699219 17.2741 3.38551 19.9604 6.69922 19.9604H28.9993C32.313 19.9604 34.9993 17.2741 34.9993 13.9604V6.36035C34.9993 3.04664 32.313 0.360352 28.9993 0.360352Z"
      fill="#3F5B64"
    />
    <path
      d="M15.9509 13.19L13.9609 6.79004L22.561 7.63005L15.9509 13.19ZM16.261 12.0701L21.001 8.11005L14.8409 7.50005L15.361 9.18004L17.9709 9.05005L15.741 10.4L16.261 12.0701Z"
      fill="white"
    />
  </svg>
);
