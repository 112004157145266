/* eslint-disable max-len */
import { FC } from 'react';
import { CommonIconProps } from 'src/utils/iconProps';

type Props = CommonIconProps & {
  onClick: () => void;
};

export const IconBack: FC<Props> = ({ className, onClick }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19"
    height="19"
    viewBox="0 0 19 19"
    fill="none"
    className={className}
    onClick={onClick}
  >
    <path
      d="M10.2298 1.17946L10.2598 1.20946C10.7698 1.72946 10.7698 2.55946 10.2598 3.06946L5.09978 8.19946H17.2698C17.9998 8.19946 18.5898 8.78946 18.5898 9.51946C18.5898 10.2495 17.9998 10.8395 17.2698 10.8395H5.09978L10.2498 15.9895C10.7598 16.4995 10.7598 17.3395 10.2498 17.8495H10.2398C9.72979 18.3795 8.8898 18.3795 8.3798 17.8595L0.379805 9.85946C0.189805 9.66947 0.189805 9.34947 0.379805 9.15947L8.36979 1.16946C8.8898 0.649462 9.72979 0.659462 10.2398 1.16946L10.2298 1.17946Z"
      fill="#455A63"
    />
  </svg>
);
