import classNames from 'classnames';
import React, { forwardRef, ReactNode, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import Select, { components, CSSObjectWithLabel } from 'react-select';

import { UiCommonInputLabel, UiCommonInputWrapper } from 'src/components/UI/inputs/common/html';
import { UiDropdownRefProps } from 'src/components/UI/inputs/DropDown';
import { UiButton } from 'src/components/UI/UiButton';
import { useInputBaseHook } from 'src/hooks/inputs';
import { getDOMElement } from 'src/utils/DOM';
import { useIdOrRandomString } from 'src/utils/formHelper';
import { SafeAnyType } from 'src/utils/safeAny';
import { StateFormInputOptionsType, StateFormReturnType } from 'src/utils/stateForm';
import isFunction from 'lodash/isFunction';

export type UiDropdownProps = {
  formProps: StateFormReturnType;
  name: string;
  options: { value: string | number; label: string; ignore?: boolean }[];

  label?: string | React.ReactNode;

  placeholder?: string;
  wrapperClassName?: string;
  className?: string;
  classNameLabel?: string;
  required?: boolean;
  onChange?: (value: UiDropdownProps['options'][0] | null, prevValue: UiDropdownProps['options'][0] | null) => void;
  mode?: 'normal' | 'small';
  color?: string;
  isSearchable?: boolean;
  maxHeight?: number;
  isClearable?: boolean;
  onClose?: () => void;
  isLoading?: boolean;
  menuPortal?: boolean;
};

//* ***
const Menu = (props: JSX.IntrinsicElements['div']) => (
  <div
    style={{
      backgroundColor: 'white',
      borderRadius: 4,
      marginTop: 8,
      position: 'absolute',
      zIndex: 2,
      width: '100%',
      top: '-8px',
    }}
    /* eslint-disable-next-line react/jsx-props-no-spreading */
    {...props}
  />
);

const Blanket = (props: JSX.IntrinsicElements['div']) => (
  <div
    style={{
      bottom: 0,
      left: 0,
      top: 0,
      right: 0,
      position: 'fixed',
      zIndex: 1,
    }}
    /* eslint-disable-next-line react/jsx-props-no-spreading */
    {...props}
  />
);

const Dropdown = ({
  children,
  isOpen,
  target,
  onClose,
  className,
}: {
  children?: ReactNode;
  readonly isOpen: boolean;
  readonly target: ReactNode;
  readonly onClose: () => void;
  className?: string;
}) => (
  <div style={{ position: 'relative', width: '100%' }} className={classNames('dropdownToggler', className)}>
    {target}
    {isOpen ? <Menu>{children}</Menu> : null}
    {isOpen ? <Blanket onClick={onClose} /> : null}
  </div>
);

const Option = (props: SafeAnyType) => (
  // eslint-disable-next-line react/destructuring-assignment
  <div title={props.label}>
    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
    <components.Option {...props} />
  </div>
);

// todo
export const UiDropdownWithInput = forwardRef<UiDropdownRefProps, UiDropdownProps>(
  (
    {
      formProps: { getSubscribeProps, register, setValue, unregister },
      className,
      wrapperClassName,
      placeholder = 'Select...',
      required,
      name,
      label,
      classNameLabel,
      options,
      onChange,
      mode = 'normal',
      color,
      isSearchable = false,
      maxHeight = 115,
      isClearable = false,
      onClose,
      isLoading,
      menuPortal,
    },
    ref,
  ) => {
    const id = useIdOrRandomString();

    const [isOpen, setIsOpen] = useState(false);

    const inputOptions = useMemo(
      (): StateFormInputOptionsType => ({
        required: required || false,
      }),
      [required],
    );

    const [value, errors] = useInputBaseHook<string | number | null | undefined>({
      getSubscribeProps,
      name,
      inputOptions,
      unregister,
      register,
      type: 'dropdown',
    });

    useEffect(() => {
      if (color) {
        // todo ref?
        const element = getDOMElement(`#${CSS.escape(id)}`);

        if (element) {
          element?.style.setProperty('--dropdownBackgroundColor', color);
          element?.style.setProperty('--dropdownBorderColor', color);
        }
      }
    }, [color, id]);

    const hasErrors = !!errors?.length;

    const selectedOption = useMemo(() => {
      if (value !== undefined) {
        return options.find((option) => option.value === value) || null;
      }

      return null;
    }, [options, value]);

    const customStyles = {
      menuList: (base: CSSObjectWithLabel) => ({
        ...base,
        maxHeight,
        paddingBottom: 0,
        paddingTop: 0,
      }),
      menu: (base: CSSObjectWithLabel) => ({
        ...base,
        marginTop: 0,
        marginBottom: 0,
      }),
      placeholder: (base: CSSObjectWithLabel) => ({
        ...base,
        maxHeight,
        'text-align': 'left',
      }),
      singleValue: (base: CSSObjectWithLabel) => ({
        ...base,
        'text-align': 'left',
        'grid-area': '1 / 1',
      }),
      container: (base: CSSObjectWithLabel) => ({
        ...base,
        display: isOpen ? 'block' : 'none',
      }),
      // valueContainer: (base: CSSObjectWithLabel) => ({
      //   ...base,
      //   padding: '0 8px',
      //   height: '28px !important',
      //   top: '-6px',
      // }),
    };

    useImperativeHandle(ref, () => ({ open: () => setIsOpen(true) }));

    return (
      <UiCommonInputWrapper className={wrapperClassName}>
        <UiCommonInputLabel id={id} show={!!label} hasErrors={hasErrors} className={classNameLabel}>
          {label}
        </UiCommonInputLabel>

        <Dropdown
          isOpen={isOpen}
          onClose={() => {
            setIsOpen(false);

            if (isFunction(onClose)) {
              onClose();
            }
          }}
          className={classNames('UiInput dropdown', className, {
            required: hasErrors && required,
            error: hasErrors,
            small: mode === 'small',
          })}
          target={
            <UiButton onClick={() => setIsOpen((prev) => !prev)} color="light">
              <span>{selectedOption?.label || placeholder}</span>
              <svg className="chevron-down" height="20" width="20" viewBox="0 0 20 20" focusable="false">
                {/* eslint-disable-next-line max-len */}
                <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z" />
              </svg>
            </UiButton>
          }
        >
          <Select
            isLoading={isLoading}
            id={id}
            autoFocus
            value={selectedOption}
            isClearable={isClearable}
            backspaceRemovesValue={false}
            components={{ IndicatorSeparator: null, Option }}
            controlShouldRenderValue={false}
            hideSelectedOptions={false}
            // menuIsOpen
            openMenuOnFocus
            onChange={(selectedValue) => {
              if (!selectedValue?.ignore) {
                setValue(name, selectedValue?.value);
              }

              if (isFunction(onChange)) {
                onChange(selectedValue, selectedOption);
              }

              if (isFunction(onClose)) {
                onClose();
              }

              setIsOpen(false);
            }}
            className={classNames('UiInput dropdown', className, {
              required: hasErrors && required,
              error: hasErrors,
              small: mode === 'small',
            })}
            options={options}
            styles={customStyles}
            tabSelectsValue={false}
            classNamePrefix="react-select"
            isSearchable={isSearchable}
            placeholder={placeholder}
            menuPortalTarget={menuPortal ? document.body : undefined}
          />
        </Dropdown>
      </UiCommonInputWrapper>
    );
  },
);
