import React, { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Menu, MenuItem, Sidebar, sidebarClasses, menuClasses } from 'react-pro-sidebar';
import { Link, matchPath, useLocation } from 'react-router-dom';

import { SidebarAvatar } from 'src/components/Sidebar/Avatar';
import { APP_HIDDEN_COMPONENTS } from 'src/config';
import { publicRoutesMap } from 'src/routes';
import {
  ROUTE_CALENDAR_PAGE,
  ROUTE_DOCUMENTS_PAGE,
  ROUTE_INDEX_PAGE,
  ROUTE_MESSAGES_PAGE,
  ROUTE_PROJECTS_PAGE,
  ROUTE_SETTINGS_PAGE,
  ROUTE_TASKS_PAGE,
  ROUTE_INVOICING_PAGE,
  ROUTE_PROJECTS_CREATE_PROJECT_MODAL,
} from 'src/routes/paths';
import useGlobalStore from 'src/store';
import { useTHelper } from 'src/utils/i18n';
import { userIntercom } from 'src/utils/outerStatistics/userGuiding';
import { ChildrenPropType } from 'src/utils/types';

import { IconCalendar } from './icons/Calendar';
import { IconDocuments } from './icons/Documents';
import { IconMessages } from './icons/Messages';
import { IconProjects } from './icons/Projects';
import { IconSettings } from './icons/Settings';
import { IconTasks } from './icons/Tasks';
import { IconToDoList } from './icons/ToDoList';
import { IconInvoicing } from './icons/Invoicing';
import { IconPlus } from '../icons/Plus';

type MenuOptionProps = {
  title: string;
  to: string;
  icon: ChildrenPropType;
  pathname: string;
  className?: string;

  disabled?: boolean;
};
const MenuOption: FC<MenuOptionProps> = ({ title, to, icon, pathname, disabled, className }) => {
  const tHelper = useTHelper('sidebar.options');

  const active = to === '/' ? pathname === to : !!matchPath(pathname, to);

  return (
    <MenuItem className={className} component={<Link to={to} />} icon={icon} disabled={disabled} active={active}>
      {tHelper(title)}
    </MenuItem>
  );
};

type MenuWrapperProps = {
  children: ChildrenPropType;
};

const MenuWrapper: FC<MenuWrapperProps> = ({ children }) => (
  <Menu
    menuItemStyles={{
      button: {
        marginBottom: '18px',
        borderRadius: 16,
        padding: '0 10px',
      },
    }}
    rootStyles={{
      paddingLeft: '16px',
      paddingRight: '16px',
      [`.${menuClasses.active} a, .${menuClasses.button}:hover`]: {
        backgroundColor: 'rgba(63, 91, 100, 25%)',
      },
      [`.${menuClasses.icon}`]: {
        minWidth: '26px',
        width: '26px',
        marginRight: '18px',
      },
    }}
  >
    {children}
  </Menu>
);

export const AppSidebar: FC = memo(() => {
  const { pathname } = useLocation();

  /** chat js */
  // do not show on public pages. is it a condition of authenticated user?
  const condition = useMemo(() => !publicRoutesMap.map(({ path }) => path).includes(pathname), [pathname]);

  useEffect(() => {
    if (condition) {
      userIntercom();
    }
  }, [condition]);
  /** end chat js */

  const [collapsed, setCollapsed] = useState(true);

  const openSidebar = useGlobalStore((state) => state.openSidebar);

  const open = useCallback(() => {
    openSidebar();

    setCollapsed(false);
  }, [openSidebar]);

  const close = useCallback(() => {
    setCollapsed(true);
  }, []);

  const menuOptions: { to: string; icon: JSX.Element; title: string; visible?: boolean }[] = useMemo(
    () =>
      [
        {
          to: ROUTE_INDEX_PAGE,
          icon: <IconToDoList />,
          title: 'index',
        },
        {
          to: ROUTE_PROJECTS_PAGE,
          icon: <IconProjects />,
          title: 'projects',
        },
        {
          to: ROUTE_INVOICING_PAGE,
          icon: <IconInvoicing />,
          title: 'invoicing',
        },
        {
          to: ROUTE_MESSAGES_PAGE,
          icon: <IconMessages />,
          title: 'messages',
          visible: !APP_HIDDEN_COMPONENTS.ROUTE_MESSAGES_PAGE,
        },
        {
          to: ROUTE_TASKS_PAGE,
          icon: <IconTasks />,
          title: 'tasks',
        },
        {
          to: ROUTE_CALENDAR_PAGE,
          icon: <IconCalendar />,
          title: 'calendar',
        },
        {
          to: ROUTE_DOCUMENTS_PAGE,
          icon: <IconDocuments />,
          title: 'documents',
        },
      ].filter(({ visible }) => visible !== false),
    [],
  );

  return (
    <Sidebar
      collapsed={collapsed}
      rootStyles={{
        height: '100vh',
        backgroundColor: 'white',
        [`.${sidebarClasses.container}`]: {
          backgroundColor: '#fff',
        },
      }}
      width="213px"
      collapsedWidth="77px"
      className="sidebar"
      onMouseEnter={open}
      onMouseLeave={close}
    >
      <div className="sidebar-header">
        <SidebarAvatar key={collapsed ? 1 : 2} />
      </div>

      <MenuWrapper>
        {menuOptions.map(({ title, to, icon }) => (
          <MenuOption key={title} to={to} icon={icon} title={title} pathname={pathname} />
        ))}
      </MenuWrapper>

      <hr className="hr" />

      <MenuWrapper>
        <MenuOption
          to={ROUTE_PROJECTS_CREATE_PROJECT_MODAL}
          icon={<IconPlus />}
          title="createProject"
          pathname={pathname}
          className="createProjectIcon"
        />
        {/* <MenuOption */}
        {/*   to={ROUTE_SETTINGS_TEAM_PAGE} */}
        {/*   icon={<IconTeam />} */}
        {/*   title="team" */}
        {/*   pathname={pathname} */}
        {/*   className="teamIcon" */}
        {/* /> */}
        <MenuOption to={ROUTE_SETTINGS_PAGE} icon={<IconSettings />} title="settings" pathname={pathname} />
      </MenuWrapper>
    </Sidebar>
  );
});
