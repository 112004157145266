import classNames from 'classnames';
import React, { FC } from 'react';

import { UiWidget } from 'src/components/UI/widgets';
import { ProjectSummaryWidgetTimeTrackingPartial } from 'src/pages/Projects/view/widgets/Summary/components/timeTracking';

import parentStyles from 'src/pages/Projects/view/styles.module.scss';
import { ProjectSummaryWidgetTotalTaskOverviewPartial } from 'src/pages/Projects/view/widgets/Summary/components/totalTaskOverview';
import styles from './styles.module.scss';

type Props = {
  projectId: number;
};

export const ProjectSummaryWidget: FC<Props> = ({ projectId }) => (
  <UiWidget className={classNames(parentStyles.baseWidget, styles.widgets)}>
    <ProjectSummaryWidgetTimeTrackingPartial projectId={projectId} />

    <hr className={styles.hr} />

    <ProjectSummaryWidgetTotalTaskOverviewPartial projectId={projectId} />
  </UiWidget>
);
