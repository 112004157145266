/* eslint-disable max-len */
import { FC, MouseEvent } from 'react';
import { CommonIconProps } from 'src/utils/iconProps';

type Props = CommonIconProps & {
  onClick?: (event: MouseEvent) => void;
};

export const IconDelete: FC<Props> = ({ className, onClick }) => (
  <svg
    width="12"
    height="14"
    viewBox="0 0 12 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    onClick={onClick}
  >
    <path
      d="M2.16211 13.4201C1.87211 13.4201 1.6221 13.3101 1.4021 13.1001C1.1921 12.8901 1.08203 12.6401 1.08203 12.3401V2.2301H0.332031V1.39014H3.51208V0.870117H8.302V1.39014H11.4821V2.2301H10.7321V12.3401C10.7321 12.6301 10.6221 12.8801 10.4121 13.0901C10.2021 13.3001 9.95211 13.4101 9.66211 13.4101H2.16211V13.4201ZM9.89209 2.2301H1.94202V12.3401C1.94202 12.4101 1.96209 12.4601 2.01208 12.5001C2.06208 12.5401 2.112 12.5601 2.172 12.5601H9.672C9.732 12.5601 9.78203 12.5401 9.83203 12.4901C9.88203 12.4401 9.9021 12.3901 9.9021 12.3301V2.22009L9.89209 2.2301ZM4.08203 11.0001H4.922V3.78015H4.08203V11.0001ZM6.91211 11.0001H7.75208V3.78015H6.91211V11.0001Z"
      fill="#EA0647"
    />
  </svg>
);
