import { FC, useEffect, useMemo } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import classNames from 'classnames';

import { UiTopMenu, UiTopMenuProps } from 'src/components/common/TopMenu';
import { ProjectViewLayout } from 'src/pages/Projects/projectViewLayout';
import useProjectsStore from 'src/pages/Projects/store';
import {
  getRouteProjectViewPage,
  ROUTE_PROJECTS_PAGE,
  ROUTE_PROJECTS_PROJECT_PAGE,
  ROUTE_PROJECTS_TIMELINE_PAGE,
} from 'src/routes/paths';
import useGlobalStore from 'src/store';
import { useTHelper } from 'src/utils/i18n';
import { ChildrenPropType } from 'src/utils/types';

type Props = {
  children: ChildrenPropType;

  projectId?: number;
  className?: string;
};

export const ProjectPageLayout: FC<Props> = ({ children, className, projectId }) => {
  const tHelper = useTHelper('pages.projects.menuOptions');

  const { pathname } = useLocation();

  const firstProjectId = useGlobalStore((state) => state.firstProjectId);

  const currentProjectId = useMemo(() => {
    const matched = matchPath(ROUTE_PROJECTS_PROJECT_PAGE, pathname);

    if (matched) {
      return matched.params.id ? +matched.params.id : 0;
    }

    return firstProjectId || 0;
  }, [firstProjectId, pathname]);

  const menuOptions: UiTopMenuProps['options'] = useMemo(
    () => [
      { label: tHelper('overview'), url: ROUTE_PROJECTS_PAGE },
      { label: tHelper('timeline'), url: ROUTE_PROJECTS_TIMELINE_PAGE },
      (() => {
        const isProjectInfoPath = !!matchPath(ROUTE_PROJECTS_PROJECT_PAGE, pathname);

        return {
          label: tHelper('project'),
          url: getRouteProjectViewPage(currentProjectId),
          isActive: isProjectInfoPath,
          isVisible: currentProjectId !== null,
        };
      })(),
    ],
    [currentProjectId, pathname, tHelper],
  );

  const isViewPage = useMemo(() => !!matchPath(ROUTE_PROJECTS_PROJECT_PAGE, pathname), [pathname]);

  useEffect(() => {
    if (isViewPage && projectId) {
      useProjectsStore
        .getState()
        .start({ projectId })
        .then(() => {
          useProjectsStore.getState().getProject();
        });
    }
  }, [pathname, isViewPage, projectId]);

  useEffect(
    () => () => {
      useProjectsStore.getState().stop();
    },
    [],
  );

  return (
    <div className={classNames('bodyPadding', className)}>
      {isViewPage ? (
        <ProjectViewLayout projectId={projectId as number} menuOptions={menuOptions} />
      ) : (
        <UiTopMenu options={menuOptions} />
      )}

      {children}
    </div>
  );
};
