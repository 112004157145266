import {
  NewCommentItemContract,
  TaskContract,
  TaskDashboardContract,
  TaskWeekStatusContract,
  TimeTrackingByProjectsContract,
  TimeTrackingContract,
} from '@moonpanda/moonpanda.contracts';
import { ApiPaginateRequestType } from 'src/models/API';

import { ApiFunctionNoParamType, ApiFunctionType, httpGet } from 'src/utils/http';

const getPath = (path: string) => `Dashboard/${path}`;

export const apiGetTotalTaskOverviewData: ApiFunctionNoParamType<TaskWeekStatusContract> = (data) =>
  httpGet(getPath('taskWeekStatus'), data);

export const apiGetDashboardWeeklyTaskOverviewData: ApiFunctionType<ApiPaginateRequestType, TaskContract[]> = (data) =>
  httpGet(getPath('weeklyTasksOverview'), data);

export const apiGetTimeTrackingData: ApiFunctionType<{ weekNumber: number }, TimeTrackingContract> = (data) =>
  httpGet(getPath('timeTracking'), data);

export const apiGetTimeTrackingModalData: ApiFunctionType<{ weekNumber: number }, TimeTrackingByProjectsContract[]> = (
  data,
) => httpGet(getPath('timeTracking/byProjects'), data);

export const apiGetTimeTrackingModalExportLink: ApiFunctionType<{ weekNumber: number }, Blob> = (data) =>
  httpGet(
    getPath('timeTracking/export'),
    { ...data, timeZoneOffset: new Date().getTimezoneOffset() },
    {
      expectBlob: true,
    },
  );

export const apiGetDashboardTasks: ApiFunctionNoParamType<TaskDashboardContract[]> = (data) =>
  httpGet(getPath('todo'), data);

export const apiStopAllTaskTimersBeforeLeave: ApiFunctionNoParamType<void> = (data) =>
  httpGet(getPath('stop_all_timers'), data);

export const apiGetDashboardNewComments: ApiFunctionNoParamType<NewCommentItemContract[]> = (data) =>
  httpGet(getPath('newComments'), data);
