import React, { FC, useCallback, useMemo, useRef, useState } from 'react';
import { UiDeleteConfirmPopover } from 'src/components/UI/DeleteConfirmPopover';

import { UiButtonCheckbox } from 'src/components/UI/inputs/ButtonCheckbox';
import { UiDatePicker } from 'src/components/UI/inputs/DatePicker';
import { UiFormatNumberInput } from 'src/components/UI/inputs/FormatNumber';
import { UiInput } from 'src/components/UI/inputs/Text';
import { UiButton } from 'src/components/UI/UiButton';
import { IconDelete } from 'src/pages/Projects/create/icons/Delete';
import { IconDelimeter } from 'src/pages/Projects/create/icons/Delimeter';
import { IconEdit } from 'src/pages/Projects/create/icons/Edit';
import { IconOk } from 'src/pages/Projects/create/icons/Ok';
import { ProjectsCreateProjectPhasesFormValues } from 'src/pages/Projects/create/index';
import { useTHelper } from 'src/utils/i18n';
import { SafeAnyType } from 'src/utils/safeAny';
import { StateFormReturnType } from 'src/utils/stateForm';
import { formatCurrency } from 'src/utils/currency';

import styles from './styles.module.scss';

type FieldType = ProjectsCreateProjectPhasesFormValues['phases'][0];

type Props = {
  field: FieldType;
  formProps: StateFormReturnType<ProjectsCreateProjectPhasesFormValues>;
  onSave: (data: ProjectsCreateProjectPhasesFormValues) => void;
  onEdit: () => void;
  onDelete: () => void;
  onCancel: () => void;
  isEditing: boolean;
  blockName: string;
  maxBudget: number;
};

export const ProjectsCreateProjectContentCard: FC<Props> = ({
  field,
  formProps,
  isEditing,
  blockName,
  onSave,
  onCancel,
  onEdit,
  onDelete,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  maxBudget,
}) => {
  const tHelper = useTHelper('pages.projects.phasesStep.card');

  const [startDate, setStartDate] = useState<string | null>(null);

  const typesOptions = useMemo(
    () => [
      { value: 'Fixed', label: tHelper('edit.feeTypes.fixed') },
      { value: 'Hourly', label: tHelper('edit.feeTypes.hourly') },
    ],
    [tHelper],
  );

  const getItemName = useCallback((name: keyof FieldType) => `${blockName}.${name}` as keyof FieldType, [blockName]);

  const beforeSaveFieldRef = useRef<FieldType | null>(null);

  const edit = () => {
    setStartDate(field.startDate);
    onEdit();
    beforeSaveFieldRef.current = { ...field };
  };

  const cancel = useCallback(() => {
    if (beforeSaveFieldRef.current) {
      formProps.setValue({
        [getItemName('name')]: beforeSaveFieldRef.current.name,
        [getItemName('startDate')]: beforeSaveFieldRef.current.startDate,
        [getItemName('endDate')]: beforeSaveFieldRef.current.endDate,
        [getItemName('feeType')]: beforeSaveFieldRef.current.feeType,
        [getItemName('budget')]: beforeSaveFieldRef.current.budget,
      });

      beforeSaveFieldRef.current = null;
    }

    onCancel();
  }, [formProps, getItemName, onCancel]);

  if (isEditing) {
    return (
      <div className={styles.phaseItemEditable}>
        <UiInput formProps={formProps} name={getItemName('name')} label={tHelper('edit.phase')} required />
        <UiDatePicker
          formProps={formProps}
          name={getItemName('startDate')}
          label={tHelper('edit.startDate')}
          onChange={setStartDate}
          required
        />
        <UiDatePicker
          formProps={formProps}
          name={getItemName('endDate')}
          label={tHelper('edit.endDate')}
          minDate={startDate || undefined}
          required
        />
        <UiButtonCheckbox
          formProps={formProps}
          name={getItemName('feeType')}
          label={tHelper('edit.feeType')}
          options={typesOptions}
          required
        />
        <UiFormatNumberInput
          formProps={formProps}
          name={getItemName('budget')}
          label={tHelper('edit.budget')}
          // max={maxBudget}
          prefix="$"
          required
        />

        <div className={styles.phaseFooter}>
          <UiButton color="light" onClick={cancel}>
            {tHelper('edit.cancel')}
          </UiButton>
          <UiButton
            onClick={() => {
              const keys: (keyof FieldType)[] = (
                ['name', 'budget', 'startDate', 'endDate', 'feeType', 'percent'] as const
              ).map(getItemName);

              formProps.trigger(keys as SafeAnyType);

              if (formProps.getErrors(keys as SafeAnyType).flat().length === 0) {
                onSave(formProps.getValue(blockName as SafeAnyType));
              }
            }}
          >
            {tHelper('edit.save')}
          </UiButton>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.phaseItemCard}>
      <div className={styles.phaseCardInfo}>
        <div className={styles.phaseCardNames} title={field.name || undefined}>
          {field.name}
        </div>
        <div>
          {field.startDate} - {field.endDate}
        </div>
      </div>

      <div className={styles.phaseCardInfoIcons}>
        <IconOk />
        <div>
          <IconEdit className="action" onClick={edit} />
          <IconDelimeter />
          <UiDeleteConfirmPopover onConfirm={onDelete} placement="left" show={!!field.hasTasks}>
            <IconDelete className="action" onClick={field.hasTasks ? undefined : onDelete} />
          </UiDeleteConfirmPopover>
        </div>
      </div>

      <div className={styles.phaseCardInfo}>
        <div className={styles.phaseCardNames}>${formatCurrency(field.budget || 0)}</div>
        {/* <div>{field.percent}%</div> */}
      </div>
    </div>
  );
};
