/* eslint-disable max-len */
import { FC } from 'react';
import { CommonIconProps } from 'src/utils/iconProps';

type Props = CommonIconProps;

export const IconArrow: FC<Props> = ({ className }) => (
  <svg width="8" height="4" viewBox="0 0 8 4" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path opacity="0.5" d="M7.5 0.5L4 3.46L0.5 0.5" stroke="#3F5B64" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
