import React, { FC, useEffect } from 'react';
import { Trans } from 'react-i18next';
import { shallow } from 'zustand/shallow';

import { PaymentLockModal } from 'src/components/common/modals/PaymentLock';
import { UiToast } from 'src/components/UI/toasts';
import { AddTeamMembersToProjectModal } from 'src/components/common/modals/AddTeamMembersToProject';
import { ViewTaskModal } from 'src/components/common/modals/ViewTask';
import { CreateTaskModal } from 'src/components/common/modals/CreateTask';
import { CreateInvoiceModal } from 'src/components/common/modals/CreateInvoice';
import useModalsStore from 'src/store/modals';
import { CreateProjectModal } from 'src/components/common/modals/CreateProject';
import { TrialEndedModal } from 'src/components/common/modals/TrialEnded';
import { EventModal } from 'src/components/common/modals/AddEvent';
import { ViewInvoiceModal } from 'src/components/common/modals/ViewInvoice';

export const AllAppModals: FC = () => {
  useEffect(() => {
    if (window.innerWidth <= 1024) {
      UiToast.error(
        <Trans i18nKey="common.messageForNotDesktopUsers" />,

        {
          position: 'top-center',
          autoClose: false,
        },
      );
    }
  }, []);

  return null;
};

export const InAppModals: FC = () => {
  const {
    showCreateProject,
    showCreateTask,
    showCreateInvoice,
    showViewInvoice,
    showViewTask,
    showAddTeamMembersToProject,
    showEventModal,
    showTrialEnded,
    showPaymentLock,
  } = useModalsStore(
    (state) => ({
      showCreateProject: state.createProject.show,
      showCreateTask: state.createTask.show,
      showCreateInvoice: state.createInvoice.show,
      showViewInvoice: state.viewInvoice.show,
      showViewTask: state.viewTask.show,
      showAddTeamMembersToProject: state.addTeamMembersToProject.show,
      showTrialEnded: state.trialEnded.show,
      showEventModal: state.eventModal.show,
      showPaymentLock: state.paymentLock.show,
    }),
    shallow,
  );

  return (
    <>
      {showCreateProject && <CreateProjectModal />}
      {showCreateTask && <CreateTaskModal />}
      {showCreateInvoice && <CreateInvoiceModal />}
      {showViewInvoice && <ViewInvoiceModal />}
      {showViewTask && <ViewTaskModal />}
      {showAddTeamMembersToProject && <AddTeamMembersToProjectModal />}
      {showTrialEnded && <TrialEndedModal />}
      {showEventModal && <EventModal />}
      {showPaymentLock && <PaymentLockModal />}
    </>
  );
};
