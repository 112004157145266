import { searchPageQueryParam } from 'src/pages/Search';

/** route paths */
export const ROUTE_INDEX_PAGE = '/home';

export const ROUTE_PROJECTS_PAGE = '/projects-overview';

export const ROUTE_PROJECTS_TIMELINE_PAGE = `/projects/timeline`;

export const ROUTE_PROJECTS_PROJECT_PAGE = `/projects/project-details/:id`;

export const ROUTE_PROJECTS_CREATE_PROJECT_MODAL = `/projects/project/create`;

export const ROUTE_PROJECTS_CREATE_PROJECT_PHASES_PAGE = `/projects/projects/create/:id`;

export const ROUTE_MESSAGES_PAGE = '/messages';

export const ROUTE_TASKS_PAGE = '/tasks';

export const ROUTE_CALENDAR_PAGE = '/calendar';

export const ROUTE_DOCUMENTS_PAGE = '/documents';

export const ROUTE_INVOICING_PAGE = '/invoicing';

export const ROUTE_SETTINGS_PAGE = '/settings';

export const ROUTE_SETTINGS_TEAM_PAGE = `${ROUTE_SETTINGS_PAGE}/team`;

export const ROUTE_SIGN_UP_PAGE = '/signup';

export const ROUTE_RECOVER_PASSWORD_PAGE = '/recover_password';

export const ROUTE_CHANGE_PASSWORD_PAGE = '/reset_password';

export const ROUTE_SIGN_IN_PAGE = '/login';

export const ROUTE_SEARCH_PAGE = '/search';

export const ROUTE_PRIVACY_POLICY = '/privacy-policy';

export const ROUTE_TERMS_CONDITIONS = '/terms-and-conditions';
/** end route paths */

/** generating url for routes */
export const getRouteProjectViewPage = (id: string | number, phaseId?: number) =>
  `${ROUTE_PROJECTS_PROJECT_PAGE.replace(':id', id.toString())}${phaseId ? `?phaseId=${phaseId}` : ''}`;

export const getRouteProjectsCreatePhasesPage = (id: string | number) =>
  ROUTE_PROJECTS_CREATE_PROJECT_PHASES_PAGE.replace(':id', id.toString());

export const getRouteSearchPage = (query: string) => `${ROUTE_SEARCH_PAGE}?${searchPageQueryParam}=${query}`;
/** end generating url for routes */

export const viewTaskModalQueryParam = 'viewTaskModalId';
