import classNames from 'classnames';
import React, { FC, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import { UiSearchInput, UiSearchInputRefProps } from 'src/components/UI/Search';
import { IconContacts } from 'src/pages/Dashboard/Search/icons/Contacts';
import { IconComments } from 'src/pages/Dashboard/Search/icons/Comments';
import { IconProjects } from 'src/pages/Dashboard/Search/icons/Projects';
import { IconShowMore } from 'src/pages/Dashboard/Search/icons/ShowMore';
import { IconTasks } from 'src/pages/Dashboard/Search/icons/Tasks';
import useSearchStore, { SearchStoreEntities, SearchStoreResultItem } from 'src/pages/Search/store';
import { getRouteSearchPage } from 'src/routes/paths';
import { useTHelper } from 'src/utils/i18n';

import styles from './styles.module.scss';

const maxShow = 2; // 515px height = 3 items max

export const DashboardSearch: FC = memo(() => {
  const tHelper = useTHelper('pages.search');

  const [open, setOpen] = useState(false);

  const { loading, search, clear, searchItems, isEmptySearch, query, start, stop } = useSearchStore((state) => state);

  useEffect(() => {
    start();

    return () => {
      stop();
    };
  }, [start, stop]);

  const onSearch = useCallback(
    (text: string) => {
      search({ query: text }).then(() => {
        setOpen(true);
      });
    },
    [search],
  );

  const searchInputRef = useRef<UiSearchInputRefProps>(null);

  const onItemChoose = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (item: SearchStoreResultItem, type: SearchStoreEntities) => () => {
      setOpen(false);

      searchInputRef.current?.clear();

      if (item.link) {
        window.open(item.link, '_blank');
      }
    },
    [],
  );

  const renderRow = (type: SearchStoreEntities) => {
    if (searchItems[type].length === 0) {
      return null;
    }

    let icon = null;

    switch (type) {
      case 'comments':
        icon = <IconComments />;
        break;
      case 'tasks':
        icon = <IconTasks />;
        break;
      case 'projects':
        icon = <IconProjects />;
        break;
      case 'users':
        icon = <IconContacts />;
        break;
      default:
        break;
    }

    return (
      <div className={styles.entity}>
        <div className={styles.title}>
          {icon}
          {tHelper(type)}
        </div>
        <div className={styles.items}>
          {searchItems[type].slice(0, maxShow).map((item) => (
            <div key={item.id} className={styles.item} role="presentation" onClick={onItemChoose(item, type)}>
              {item.text}
            </div>
          ))}
        </div>
      </div>
    );
  };

  const showMore = useMemo(
    () =>
      searchItems.projects.length > maxShow ||
      searchItems.tasks.length > maxShow ||
      searchItems.users.length > maxShow ||
      searchItems.comments.length > maxShow,
    [searchItems.comments.length, searchItems.projects.length, searchItems.tasks.length, searchItems.users.length],
  );

  return (
    <UiSearchInput
      placeholder={tHelper('inputPlaceholder')}
      className="app-search"
      onSearch={onSearch}
      disabled={loading}
      ref={searchInputRef}
      active={open}
      onClickOutSide={() => {
        searchInputRef.current?.clear();
        clear();
        setOpen(false);
      }}
      childrenAfter={
        open ? (
          <div className={classNames(styles.results, 'searchResults')}>
            <div className={styles.block}>
              {isEmptySearch ? (
                tHelper('noResults')
              ) : (
                <>
                  {renderRow('comments')}
                  {renderRow('tasks')}
                  {renderRow('projects')}
                  {renderRow('users')}
                </>
              )}
            </div>

            {showMore && (
              <div className={styles.showMore}>
                <Link to={getRouteSearchPage(query)}>
                  {tHelper('showMore')}&nbsp;
                  <IconShowMore />
                </Link>
              </div>
            )}
          </div>
        ) : null
      }
    />
  );
});
