import isArray from 'lodash/isArray';
import { SafeAnyType } from 'src/utils/safeAny';

export const objectToFormData = (data: Record<string, SafeAnyType>): FormData => {
  const formData = new FormData();

  Object.entries(data).forEach(([key, value]) => {
    if (isArray(value)) {
      value.forEach((item: File) => {
        formData.append(key, item);
      });
    } else {
      formData.append(key, value);
    }
  });

  return formData;
};
