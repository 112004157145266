import { FC, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { apiChangePassword } from 'src/api/authentication';
import { IconAppLogo } from 'src/components/icons/AppLogo';
import { UiInput } from 'src/components/UI/inputs/Text';
import { UiToast } from 'src/components/UI/toasts';
import { UiButton } from 'src/components/UI/UiButton';
import { useRequest } from 'src/hooks/useRequest';
import { useSignInUser } from 'src/pages/SignUp/common';
import { ROUTE_SIGN_IN_PAGE } from 'src/routes/paths';
import { showErrorToast } from 'src/utils/errors';
import { useTHelper } from 'src/utils/i18n';
import { useStateForm } from 'src/utils/stateForm';

import styles from 'src/pages/RecoverPassword/styles.module.scss';

type FormValues = {
  newPassword: string | null;
  newPasswordAgain: string | null;
};

export const ChangePasswordPage: FC = () => {
  const tHelper = useTHelper('pages.changePassword');

  const formProps = useStateForm<FormValues>();

  const [searchParams] = useSearchParams();

  const navigate = useNavigate();

  const { apiCaller } = useRequest();

  const [loading, setLoading] = useState(false);

  const signInUser = useSignInUser();

  return (
    <form
      className={styles.wrapper}
      onSubmit={formProps.onSubmit((formData) => {
        const resetKey = searchParams.get('key');

        if (!resetKey) {
          UiToast.error(tHelper('noResetKey'));

          return;
        }

        if (formData.newPassword !== '' && formData.newPassword === formData.newPasswordAgain) {
          setLoading(true);
          apiCaller(apiChangePassword, {
            resetKey,
            newPassword: formData.newPassword as string,
          })
            .then(({ data }) => {
              signInUser(data, true);

              UiToast.commonSuccessSaved();

              navigate(ROUTE_SIGN_IN_PAGE);
            })
            .catch(showErrorToast)
            .finally(() => {
              setLoading(false);
            });
        } else {
          formProps.setError('newPassword', tHelper('passwordsDontMatch'));
          formProps.setError('newPasswordAgain', tHelper('passwordsDontMatch'));

          UiToast.warning(tHelper('passwordsDontMatch'));
        }
      })}
    >
      <h1>{tHelper('header')}</h1>

      <IconAppLogo className={styles.iconLogo} />

      <div>
        <UiInput
          formProps={formProps}
          name="newPassword"
          type="password"
          placeholder={tHelper('passwordPlaceholder')}
          required
        />

        <UiInput
          formProps={formProps}
          name="newPasswordAgain"
          type="password"
          placeholder={tHelper('passwordRepeatPlaceholder')}
          required
        />

        <UiButton type="submit" loading={loading}>
          {tHelper('submitBtn')}
        </UiButton>
      </div>
    </form>
  );
};
