import classNames from 'classnames';
import React, { FC, useCallback, useEffect, useRef } from 'react';

import { UiLoader } from 'src/components/UI/loaders';
import { UiSearchInput } from 'src/components/UI/Search';
import { IconComments } from 'src/pages/Dashboard/Search/icons/Comments';
import { IconContacts } from 'src/pages/Dashboard/Search/icons/Contacts';
import { IconProjects } from 'src/pages/Dashboard/Search/icons/Projects';
import { IconTasks } from 'src/pages/Dashboard/Search/icons/Tasks';
import useSearchStore, { SearchStoreEntities, SearchStoreResultItem } from 'src/pages/Search/store';
import { useTHelper } from 'src/utils/i18n';
import { getQueryParamQuiet } from 'src/utils/queryParams';

import styles from './styles.module.scss';

export const searchPageQueryParam = 'query';

export const SearchPage: FC = () => {
  const tHelper = useTHelper('pages.search');

  const { loading, search, clear, searchItems, isEmptySearch, start, stop } = useSearchStore((state) => state);

  const defaultQuery = useRef(getQueryParamQuiet(searchPageQueryParam) || undefined);

  useEffect(() => {
    start();

    return () => {
      stop();
    };
  }, [start, stop]);

  useEffect(() => {
    if (defaultQuery.current) {
      search({ query: defaultQuery.current });
    }
  }, [search]);

  const onItemChoose = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (item: SearchStoreResultItem, type: SearchStoreEntities) => () => {
      if (item.link) {
        window.open(item.link, '_blank');
      }
    },
    [],
  );

  const renderRow = (type: SearchStoreEntities) => {
    if (searchItems[type].length === 0) {
      return null;
    }

    let icon = null;

    switch (type) {
      case 'comments':
        icon = <IconComments />;
        break;
      case 'tasks':
        icon = <IconTasks />;
        break;
      case 'projects':
        icon = <IconProjects />;
        break;
      case 'users':
        icon = <IconContacts />;
        break;
      default:
        break;
    }

    return (
      <div className={styles.entity}>
        <div className={styles.title}>
          {icon}
          {tHelper(type)}
        </div>
        <div className={styles.items}>
          {searchItems[type].map((item) => (
            <div key={item.id} className={styles.item} role="presentation" onClick={onItemChoose(item, type)}>
              {item.text}
            </div>
          ))}
        </div>
      </div>
    );
  };

  const onSearch = useCallback(
    (value: string) => {
      search({ query: value });
    },
    [search],
  );

  return (
    <div className={classNames('bodyPadding', styles.wrapper)}>
      <UiSearchInput
        placeholder={tHelper('inputPlaceholder')}
        defaultValue={defaultQuery.current}
        onSearch={onSearch}
        disabled={loading}
        onClear={clear}
        wrapperClassName="mb-0"
      />

      <div className={styles.results}>
        {loading ? (
          <UiLoader centered />
        ) : (
          <>
            {isEmptySearch ? (
              tHelper('noResults')
            ) : (
              <>
                {renderRow('comments')}
                {renderRow('tasks')}
                {renderRow('projects')}
                {renderRow('users')}
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};
