import { ProfileDetailsContract, ProjectContract } from '@moonpanda/moonpanda.contracts';
import { compare } from 'fast-json-patch';
import { FC, useEffect, useState } from 'react';
import { shallow } from 'zustand/shallow';
import isFunction from 'lodash/isFunction';

import { isPatchDataEmpty, prepareDataToPatch } from 'src/utils/http';
import { useRequest } from 'src/hooks/useRequest';
import { UiInputColorPicker } from 'src/components/UI/inputs/Color';
import { UiFormatNumberInput } from 'src/components/UI/inputs/FormatNumber';
import { UiInput } from 'src/components/UI/inputs/Text';
import { UiModal } from 'src/components/UI/Modals';
import { UiButton } from 'src/components/UI/UiButton';
import { apiCreateProjectPhase1, apiGetProjectData, apiUpdateProjectData } from 'src/api/projects';
import useModalsStore from 'src/store/modals';
import { showErrorToast, showWarningToast } from 'src/utils/errors';
import { useStateForm } from 'src/utils/stateForm';
import { RecursiveNullable } from 'src/utils/types';
import { getRouteProjectsCreatePhasesPage } from 'src/routes/paths';
import { useNavigate } from 'react-router-dom';
import { useTHelper } from 'src/utils/i18n';
import useGlobalStore from 'src/store';

import styles from './styles.module.scss';

export type CreateProjectModalAdditionalInfoType =
  | {
      projectId?: number;
      projectData?: ProjectContract;

      onCreateProject?: (task: ProjectContract) => void;
      onChangeProject?: (task: ProjectContract) => void;
      onClose?: () => void;
    }
  | undefined;

type FormValues = RecursiveNullable<{
  name: string;
  number: string;
  color: string;
  budget: number;
}>;

export const CreateProjectModal: FC = () => {
  const { closeModal, additionalInfo } = useModalsStore(
    (state) => ({
      closeModal: () => state.toggleModals('createProject', false),
      additionalInfo: state.createProject.additionalInfo as CreateProjectModalAdditionalInfoType,
    }),
    shallow,
  );

  const [saving, setSaving] = useState(false);

  const [initialData, setInitialData] = useState<ProjectContract>({
    id: 0,
    name: '',
    number: '',
    color: '',
    cover: null,
    budget: {
      id: 0,
      totalBudget: 0,
      totalRemaining: 0,
      totalAllocated: 0,
    },
    attachments: [],
    phase: [],
    team: [],
    projectStatistics: null,
  });

  const tHelper = useTHelper('modals.createProject');

  const { apiCaller } = useRequest();

  const formProps = useStateForm<FormValues>({
    defaultValues: {
      name: null,
      number: null,
      color: null,
      budget: null,
    },
  });

  const projectId = additionalInfo?.projectId || 0;

  const editMode = projectId || additionalInfo?.projectData;

  useEffect(() => {
    const onThen = (data: ProjectContract) => {
      setInitialData(data);

      formProps.setValue({
        name: data.name,
        number: data.number,
        color: data.color,
        budget: data.budget.totalBudget,
      });
    };

    if (projectId) {
      apiCaller(apiGetProjectData, {
        projectId,
      }).then(({ data }) => {
        onThen(data);
      });
    } else if (additionalInfo?.projectData) {
      onThen(additionalInfo.projectData);
    }
  }, [additionalInfo?.projectData, apiCaller, formProps, projectId]);
  const user = useGlobalStore((state) => state.user.response as ProfileDetailsContract);

  const navigate = useNavigate();

  return (
    <UiModal
      onClose={() => {
        additionalInfo?.onClose?.();

        closeModal();
      }}
      className={styles.modal}
      bodyClassName={styles.body}
      header={tHelper(editMode ? 'headerUpdate' : 'header')}
      noCustomScrollBar
      footer={
        <UiButton
          className={styles.submit}
          onClick={formProps.onSubmit((formData) => {
            setSaving(true);

            const model: ProjectContract = {
              ...initialData,
              name: formData.name as string,
              number: formData.number as string,
              color: formData.color as string,
              budget: {
                ...initialData.budget,
                totalBudget: formData.budget as number,
              },
              projectOwnerId: user.userId,
              // TODO: add user id projectOwnerId: //UserId
            };

            const apiFn = editMode ? apiUpdateProjectData : apiCreateProjectPhase1;

            const apiData = editMode
              ? prepareDataToPatch(compare(initialData, model), {
                  projectId: projectId || additionalInfo?.projectData?.id || -1,
                })
              : model;

            if (editMode && isPatchDataEmpty(apiData)) {
              closeModal();

              return;
            }

            apiCaller(apiFn, apiData)
              .then(({ data: response }) => {
                if (editMode && isFunction(additionalInfo?.onChangeProject)) {
                  additionalInfo?.onChangeProject(response);

                  return;
                }

                if (!editMode && isFunction(additionalInfo?.onCreateProject)) {
                  additionalInfo?.onCreateProject(response);

                  return;
                }

                navigate(getRouteProjectsCreatePhasesPage(response.id));
              })
              .catch((e) => {
                showErrorToast(e);

                additionalInfo?.onClose?.();
              })
              .finally(() => {
                setSaving(false);
                closeModal();
              });
          }, showWarningToast)}
          loading={saving}
        >
          {tHelper(editMode ? 'updateBtn' : 'createBtn')}
        </UiButton>
      }
    >
      <UiInput
        formProps={formProps}
        label={tHelper('nameLabel')}
        name="name"
        placeholder={tHelper('namePlaceholder')}
        required
      />

      <div className="row">
        <div className="col">
          <UiInput
            formProps={formProps}
            label={tHelper('numberLabel')}
            name="number"
            placeholder={tHelper('numberPlaceholder')}
            maxLength={8}
          />
        </div>

        {/* Fix autofill in Chrome */}
        <form className="col" autoComplete="off">
          <UiInputColorPicker
            formProps={formProps}
            name="color"
            placeholder={tHelper('colorPlaceholder')}
            label={tHelper('colorLabel')}
          />
        </form>
      </div>

      <UiFormatNumberInput
        formProps={formProps}
        name="budget"
        prefix="$"
        label={tHelper('budgetLabel')}
        placeholder={tHelper('budgetPlaceholder')}
        decimalScale={3}
        max={999_999_999_999}
        required
      />
    </UiModal>
  );
};
