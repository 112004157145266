import { getDOMElement } from 'src/utils/DOM';
import config from './applicationconfig.json';

export const API_URL = `${config.API_BASE_URL}api/v1/`;

export const { FROALA_KEY } = config;

export const DOM_ELEMENT_FOR_PORTALS: Element = getDOMElement('#root') as Element;

export const EMAIL_PATTERN =
  // eslint-disable-next-line max-len
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}])|(([a-zA-Z\-\d]+\.)+[a-zA-Z]{2,}))$/;

export const SHORT_DATE_FORMAT = 'MM/dd/yyyy';

export const DEFAULT_TABLE_PAGE_SIZE = 25;

export const MAX_PAGE_SIZE_FOR_ONE_REQUEST = 500;

export const DATE_FORMAT_TIMEZONE = "yyyy-MM-dd'T'HH:mm:ss.SSS'+00:00'";

export const APP_HIDDEN_COMPONENTS = {
  ROUTE_MESSAGES_PAGE: config.PAGE_MESSAGES_HIDDEN,
  TODO_LIST_WIDGET3_HIDDEN: config.TODO_LIST_WIDGET3_HIDDEN,
  DOCUMENTS_SEND_BUTTON_HIDDEN: config.DOCUMENTS_SEND_BUTTON_HIDDEN,
  PROJECT_VIEW_CALENDAR_HIDDEN: config.PROJECT_VIEW_CALENDAR_HIDDEN,
};

export const { GOOGLE_CLIENT_ID, MICROSOFT_CLIENT_ID, MICROSOFT_REDIRECT_URI, STRIPE_PUBLISH_KEY } = config;

export const msalConfig = {
  auth: {
    clientId: MICROSOFT_CLIENT_ID,
    authority: 'https://login.microsoftonline.com/common',
    redirectUri: MICROSOFT_REDIRECT_URI,
  },
};

export const loginRequestMicrosoft = {
  scopes: ['User.Read'],
};

export const defaultProjectColor = '#0062A5';
