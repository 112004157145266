/* eslint-disable max-len */
import { FC } from 'react';
import { CommonIconProps } from 'src/utils/iconProps';

type Props = CommonIconProps;

export const IconExport: FC<Props> = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none" className={className}>
    <path
      d="M2.30992 20.1398C1.79992 20.1398 1.35993 19.9498 0.979927 19.5798C0.599927 19.2098 0.419922 18.7698 0.419922 18.2498V14.1898H1.89993V18.2498C1.89993 18.3498 1.93992 18.4398 2.02992 18.5298C2.10992 18.6098 2.20992 18.6599 2.30992 18.6599H18.1199C18.2199 18.6599 18.3099 18.6198 18.3999 18.5298C18.4799 18.4498 18.5299 18.3498 18.5299 18.2498V14.1898H20.0099V18.2498C20.0099 18.7598 19.8199 19.1998 19.4499 19.5798C19.0799 19.9598 18.6399 20.1398 18.1199 20.1398H2.30992ZM10.2199 15.2399L4.80992 9.82985L5.86993 8.77985L9.46992 12.3698V0.339844H10.9499V12.3698L14.5499 8.77985L15.6099 9.82985L10.1999 15.2399H10.2199Z"
      fill="white"
    />
  </svg>
);
