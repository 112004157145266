import { NewCommentItemContract, TaskContract } from '@moonpanda/moonpanda.contracts';
import classNames from 'classnames';
import React, { FC, useCallback, useState } from 'react';
import { Collapse } from 'react-bootstrap';

import { UiLoader } from 'src/components/UI/loaders';
import { UiScrollbar } from 'src/components/UI/Scrollbar';
import { IconArrow } from 'src/pages/Dashboard/Comments/icons/Arrow';
import { DashboardPageCommentsRowComponent } from 'src/pages/Dashboard/Comments/row';
import useDashboardStore from 'src/pages/Dashboard/store';
import useModalsStore from 'src/store/modals';
import { useTHelper } from 'src/utils/i18n';

import styles from './styles.module.scss';

export const DashboardPageComments: FC = () => {
  const tHelper = useTHelper('pages.dashboard.comments');

  const [open, setOpen] = useState(true);

  const { comments, removeTaskInComment } = useDashboardStore((state) => ({
    comments: state.comments,
    removeTaskInComment: state.removeTaskInComment,
  }));

  const toggleModals = useModalsStore((state) => state.toggleModals);

  const showViewTaskModal = useCallback(
    (comment: NewCommentItemContract & { id: string }) => (_: string, taskIds: number[]) => {
      toggleModals('viewTask', true, {
        taskId: taskIds[0],
        ids: taskIds,
        onClose: (task: TaskContract) => {
          removeTaskInComment(task.id, comment.id);
        },
      });
    },
    [removeTaskInComment, toggleModals],
  );

  return (
    <div className={styles.messagesBlock}>
      <div className={styles.top}>
        <IconArrow
          onClick={() => setOpen(!open)}
          className={classNames(styles.collapseIcon, open ? styles.collapseIconOpen : undefined)}
        />

        <div className={styles.header}>{tHelper('header')}</div>
      </div>

      <Collapse in={open}>
        <div className={styles.center}>
          <div />
          <UiScrollbar autoHeight autoHeightMin="100%" autoHeightMax="35vh">
            <div className={styles.content}>
              {comments.loading && <UiLoader />}
              {comments.response && comments.response?.length > 0 ? (
                comments.response.map((comment) => (
                  <DashboardPageCommentsRowComponent
                    key={comment.id}
                    model={comment}
                    showViewTaskModal={showViewTaskModal(comment)}
                  />
                ))
              ) : (
                <h3 className={styles.noComments}>{tHelper('noComments')}</h3>
              )}
            </div>
          </UiScrollbar>
        </div>
      </Collapse>
    </div>
  );
};
