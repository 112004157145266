import { UserContract } from '@moonpanda/moonpanda.contracts';
import { compare } from 'fast-json-patch';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { apiAccountDeleteTeamUser, apiAccountGetTeams, apiAccountUpdateTeamUser } from 'src/api/account';
import { IconOk } from 'src/components/icons/Ok';
import { UiDeleteConfirmPopover } from 'src/components/UI/DeleteConfirmPopover';
import { UiFormatNumberInput } from 'src/components/UI/inputs/FormatNumber';
import { IconClose } from 'src/components/UI/Modals/icons/Close';
import { UiTable } from 'src/components/UI/table';
import { UiToast } from 'src/components/UI/toasts';
import { useRequest } from 'src/hooks/useRequest';
import { CommonInnerApiResponseType } from 'src/models/API';
import { SettingsPageLayout } from 'src/pages/Settings/layout';
import { IconDelete } from 'src/pages/Settings/Team/icons/Delete';
import { IconDelimeter } from 'src/pages/Settings/Team/icons/Delimeter';
import { IconEdit } from 'src/pages/Settings/Team/icons/Edit';
import { formatCurrency } from 'src/utils/currency';
import { showErrorToast } from 'src/utils/errors';
import { prepareDataToPatch } from 'src/utils/http';
import { useTHelper } from 'src/utils/i18n';
import { useStateForm } from 'src/utils/stateForm';

import styles from './styles.module.scss';

type FormValues = UserContract;

export const SettingsTeamPage: FC = () => {
  const tHelper = useTHelper('pages.settings.team');

  const formProps = useStateForm<FormValues>();

  const [data, setData] = useState<CommonInnerApiResponseType<UserContract[]>>({
    data: [],
    twoFactorConfirmation: false,
    isSuccess: true,
    errors: [],
    totalCount: 0,
    loading: true,
  });

  const [editableUserId, setEditableUserId] = useState<number | null>(null);

  const { apiCaller } = useRequest();

  // load initial data
  useEffect(() => {
    apiCaller(apiAccountGetTeams)
      .then((response) => {
        setData({
          ...response,
          loading: false,
        });
      })
      .finally(() => {
        setData((currentData) => ({
          ...currentData,
          loading: false,
        }));
      });
  }, [apiCaller]);

  const updateUser = useCallback(
    (currentData: UserContract, initialData: UserContract) => {
      apiCaller(
        apiAccountUpdateTeamUser,
        prepareDataToPatch(compare(initialData, currentData), {
          userId: currentData.userId,
        }),
      )
        .then(({ data }) => {
          setData((currentData) => {
            const userIndex = currentData.data.findIndex(({ userId }) => userId === data.userId);

            if (~userIndex) {
              currentData.data[userIndex] = data;
            }

            return { ...currentData };
          });
        })
        .catch(showErrorToast);
    },
    [apiCaller],
  );

  const deleteUser = useCallback(
    (userId: number) => {
      apiCaller(apiAccountDeleteTeamUser, {
        userId,
      })
        .then(() => {
          UiToast.commonSuccessDeleted();

          setData((currentData) => ({
            ...currentData,
            data: currentData.data.filter((user) => userId !== user.userId),
          }));
        })
        .catch(showErrorToast);
    },
    [apiCaller],
  );

  const columns = useMemo(
    () => [
      {
        title: tHelper('columns.name'),
        renderValue: ({ firstName, lastName }: UserContract) => `${firstName} ${lastName}`,
        className: 'text-left',
        thClassName: styles.thName,
      },
      {
        title: tHelper('columns.title'),
        renderValue: 'title',
        className: 'text-center',
        thClassName: styles.thTitle,
      },
      {
        title: tHelper('columns.email'),
        renderValue: 'email',
        className: 'text-center',
        thClassName: styles.thEmail,
      },
      {
        title: tHelper('columns.baseRate'),
        renderValue: ({ baseRate, userId }: UserContract) => {
          if (userId === editableUserId) {
            return <UiFormatNumberInput formProps={formProps} name="baseRate" wrapperClassName="mb-0" required />;
          }

          return formatCurrency(baseRate);
        },
        className: 'text-center',
        thClassName: styles.thRate,
      },
    ],
    [editableUserId, formProps, tHelper],
  );

  const additionalColumn = useMemo(
    () => ({
      renderValue: (user: UserContract) => (
        <>
          {user.userId === editableUserId ? (
            <>
              <IconOk
                className="action save"
                onClick={formProps.onSubmit((formData) => {
                  updateUser(formData, formProps.getInitialValue());

                  setEditableUserId(null);
                })}
              />
              <IconClose
                className="action cancel"
                onClick={() => {
                  setEditableUserId(null);
                }}
              />
            </>
          ) : (
            <>
              <IconEdit
                onClick={() => {
                  formProps.reset(user, {
                    resetInitialForm: true,
                  });

                  setEditableUserId(user.userId);
                }}
                className="action"
              />
              <IconDelimeter />
              <UiDeleteConfirmPopover
                placement="left"
                onConfirm={() => {
                  deleteUser(user.userId);
                }}
              >
                <div>
                  <IconDelete className="action" />
                </div>
              </UiDeleteConfirmPopover>
            </>
          )}
        </>
      ),
      thClassName: styles.thActionRow,
      className: 'text-left',
    }),
    [deleteUser, editableUserId, formProps, updateUser],
  );

  return (
    <SettingsPageLayout>
      <div className={styles.wrapper}>
        <UiTable
          data={data}
          initialLoad
          getData={() => null}
          columns={columns}
          itemKeyProp="userId"
          additionalXRightColumn={additionalColumn}
          headDark
        />
      </div>
    </SettingsPageLayout>
  );
};
