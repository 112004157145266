/* eslint-disable max-len */
import { FC } from 'react';

type Props = {
  onClick?: () => void;
};

export const IconDelete: FC<Props> = ({ onClick }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="11" height="12" viewBox="0 0 11 12" fill="none" onClick={onClick}>
    <path
      d="M1.64996 11.4399C1.38996 11.4399 1.15997 11.3399 0.969971 11.1499C0.779971 10.9599 0.679993 10.7299 0.679993 10.4699V1.35986H0V0.599884H2.85999V0.129883H7.17999V0.599884H10.04V1.35986H9.35999V10.4699C9.35999 10.7299 9.26001 10.9599 9.07001 11.1499C8.88001 11.3399 8.65001 11.4399 8.39001 11.4399H1.64001H1.64996ZM8.60999 1.35986H1.44V10.4699C1.44 10.5299 1.46 10.5799 1.5 10.6199C1.54 10.6599 1.59001 10.6799 1.64001 10.6799H8.39001C8.44001 10.6799 8.48997 10.6599 8.52997 10.6199C8.56997 10.5799 8.58997 10.5299 8.58997 10.4799V1.36987L8.60999 1.35986ZM3.37 9.25989H4.13V2.74988H3.37V9.25989ZM5.91998 9.25989H6.67999V2.74988H5.91998V9.25989Z"
      fill="#3F5B64"
    />
  </svg>
);
