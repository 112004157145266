/* eslint-disable max-len */
import { FC } from 'react';

export const IconNew: FC = () => (
  <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.07 24.5898H17.79V18.3298H24.09V15.6098H17.79V9.04977H15.07V15.6098H8.55003V18.3298H15.07V24.5898ZM16.33 32.8498C14.1 32.8498 12.01 32.4298 10.06 31.5998C8.12003 30.7698 6.42004 29.6198 4.96004 28.1698C3.50004 26.7098 2.36003 25.0098 1.53003 23.0698C0.700029 21.1298 0.280029 19.0398 0.280029 16.7998C0.280029 14.5598 0.700029 12.4698 1.53003 10.5298C2.36003 8.58977 3.50004 6.88977 4.96004 5.44977C6.41004 3.99977 8.11003 2.85978 10.05 2.01978C11.99 1.17978 14.08 0.759766 16.32 0.759766C18.56 0.759766 20.65 1.17978 22.6 2.01978C24.55 2.85978 26.24 3.99977 27.69 5.43977C29.13 6.87977 30.28 8.57977 31.12 10.5298C31.96 12.4798 32.38 14.5698 32.38 16.8098C32.38 19.0498 31.96 21.1398 31.12 23.0798C30.28 25.0198 29.13 26.7198 27.69 28.1698C26.24 29.6198 24.55 30.7598 22.6 31.5998C20.66 32.4398 18.57 32.8498 16.33 32.8498ZM16.33 29.2998C19.79 29.2998 22.73 28.0898 25.16 25.6698C27.59 23.2498 28.8 20.2898 28.8 16.7998C28.8 13.3098 27.59 10.3998 25.17 7.96977C22.75 5.53977 19.79 4.32977 16.3 4.32977C12.81 4.32977 9.90003 5.53978 7.47003 7.95978C5.04003 10.3798 3.83003 13.3398 3.83003 16.8298C3.83003 20.3198 5.04004 23.2298 7.46004 25.6598C9.88004 28.0898 12.84 29.2998 16.33 29.2998Z"
      fill="#455A63"
    />
  </svg>
);
