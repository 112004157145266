import { FileContract } from '@moonpanda/moonpanda.contracts';
import classNames from 'classnames';
import React, { lazy, memo, Suspense, useCallback, useMemo, useRef, useState } from 'react';
import isFunction from 'lodash/isFunction';
import { renderToString } from 'react-dom/server';
import { UiAvatar } from 'src/components/common/Avatar';
import { getDOMElement } from 'src/utils/DOM';
import Tribute from 'tributejs';

import { UiMereLabel } from 'src/components/UI/UiMereLabel';
import { API_URL, FROALA_KEY } from 'src/config';
import { useInputBaseHook } from 'src/hooks/inputs';
import { CommonApiResponseType } from 'src/models/API';
import { getStringFromUiLabel } from 'src/utils/reactDom';
import { SafeAnyType } from 'src/utils/safeAny';
import { StateFormInputOptionsType, StateFormReturnType } from 'src/utils/stateForm';
import { ChildrenPropType, NonUndefined } from 'src/utils/types';

const FroalaLazy = lazy(() => import('src/components/UI/inputs/WYSIWYG/lazy'));

type Props = {
  formProps: StateFormReturnType;
  name: string;

  label?: string;
  className?: string;
  classNameLabel?: string;
  disabled?: boolean;
  placeholder?: string;
  required?: boolean;
  heightMin?: number;
  heightMax?: number;
  height?: number;
  validateName?: string;
  modal?: boolean; // to fix the insert link element
  config?: Record<string, SafeAnyType>;
  errorLabel?: string;
  children?: ChildrenPropType;
  onBlur?: (value: string) => void;
  toolbarButtons?: string[];

  mentionableUsers?: {
    value: number;
    label: string;
    avatar: string | null;
  }[];
};

export const UiWYSIWYG: React.FC<Props> = memo(
  ({
    formProps: { onChange: formOnChange, register, unregister, getSubscribeProps, getValue },
    name,
    disabled = false,
    required,
    placeholder,
    heightMin = 100,
    heightMax = 200,
    height,
    validateName,
    modal,
    config: configProp,
    className,
    classNameLabel,
    errorLabel,
    label,
    children,
    onBlur,
    toolbarButtons,
    mentionableUsers,
  }: Props) => {
    const inputOptions = useMemo(
      (): StateFormInputOptionsType => ({
        required: required || false,
        errorLabel: errorLabel || getStringFromUiLabel(label) || undefined,
      }),
      [required, errorLabel, label],
    );

    const [value, errors] = useInputBaseHook<string | null | undefined>({
      getSubscribeProps,
      name,
      inputOptions,
      unregister,
      register,
      type: 'wysiwyg',
      validateName,
    });

    const hasErrors = !!errors?.length;

    const isFocusedRef = useRef(false);

    const [focused, setFocused] = useState(false);

    const tributeRef = useRef<
      Tribute<{
        key: string;
        id: number;
        avatar: string | null;
      }>
    >();

    const tributeInit = useCallback(
      (users: NonUndefined<Props['mentionableUsers']>) =>
        new Tribute({
          values: users.map((data) => ({
            key: data.label,
            id: data.value,
            avatar: data.avatar,
          })),
          selectTemplate(item) {
            if (item) {
              // don't use @ - https://github.com/froala/wysiwyg-editor/issues/4379
              // eslint-disable-next-line max-len
              return `<span class="fr-deletable fr-tribute" data-userId="${item.original.id}">${item.original.key}</a></span>`;
            }

            return '';
          },
          menuItemTemplate(item) {
            const renderedAvatar = renderToString(
              <UiAvatar
                className="img"
                wrapperClassName="wrapImg"
                data={{
                  avatar: item.original.avatar
                    ? {
                        id: 1,
                        fileName: '',
                        url: item.original.avatar,
                      }
                    : undefined,
                  name: item.original.key,
                }}
                width={18}
              />,
            );

            return `${renderedAvatar}${item.string}`;
          },
        }),
      [],
    );

    const config = useMemo(
      () => ({
        key: FROALA_KEY,
        heightMin: height ? null : heightMin,
        heightMax: height ? null : heightMax,
        height,
        useClasses: true,
        attribution: false,
        editorClass: 'editor',
        quickInsertEnabled: false,
        spellcheck: false,
        charCounterCount: false,
        toolbarBottom: true,
        linkAlwaysBlank: true,

        imageUploadURL: `${API_URL}Documents/upload?access=Public`,
        imageUploadParam: 'files',
        imageAllowedTypes: ['jpeg', 'jpg', 'png'],

        videoUpload: false,
        placeholderText: placeholder,
        toolbarButtons: (() => {
          if (toolbarButtons) {
            return toolbarButtons;
          }

          let arr = [
            'bold',
            'italic',
            'underline',
            'formatUL',
            'formatOL',
            'insertImage',
            'insertVideo',
            // 'insertFile', // https://froala.com/wysiwyg-editor/docs/concepts/file/upload/
            'emoticons',
          ];

          if (modal) {
            // move these properties to the center of the modal
            const propertiesToMove = ['insertImage', 'insertVideo', 'emoticons', 'insertLink'];

            const properties = arr.filter((name) => propertiesToMove.includes(name));

            arr = arr.filter((name) => !properties.includes(name));

            arr.splice(0, 0, ...properties);
          }

          return arr;
        })(),
        events: {
          keyup() {
            const regexpZeroWidthSpace = /([\u200B]+|[\u200C]+|[\u200D]+|[\u200E]+|[\u200F]+|[\uFEFF]+)/g;

            // check and remove &zerowidthspace
            if (
              mentionableUsers?.length &&
              regexpZeroWidthSpace.test(
                getDOMElement('.fr-wrapper .fr-element')?.innerHTML || '', // ref?
              )
            ) {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              // eslint-disable-next-line @typescript-eslint/no-this-alias
              const editor: SafeAnyType = this;

              editor.html.set(editor.html.get().replace(regexpZeroWidthSpace, ''));
              editor.selection.setAtEnd(editor.$el.get(0));
              editor.selection.restore();
            }
          },
          destroy() {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            // eslint-disable-next-line @typescript-eslint/no-this-alias
            const editor: SafeAnyType = this;

            tributeRef.current?.detach(editor.el);
          },
          initialized() {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            // eslint-disable-next-line @typescript-eslint/no-this-alias
            const editor: SafeAnyType = this;

            if (mentionableUsers?.length) {
              tributeRef.current = tributeInit(mentionableUsers);

              tributeRef.current.attach(editor.el);

              editor.events.on(
                'keydown',
                (e: KeyboardEvent) => !(e.key === 'Enter' && tributeRef.current?.isActive),
                true,
              );
            }

            if (disabled) {
              // https://github.com/froala/react-froala-wysiwyg/issues/228
              editor.off();
            }
          },
          focus() {
            setFocused(true);
            isFocusedRef.current = true;
          },
          blur() {
            setFocused(false);
            isFocusedRef.current = false;

            if (isFunction(onBlur)) {
              onBlur(getValue(name));
            }
          },
          // eslint-disable-next-line func-names
          'image.uploaded': function (responseRaw: string) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            // eslint-disable-next-line @typescript-eslint/no-this-alias
            const editor: SafeAnyType = this;

            const fileUrl = (JSON.parse(responseRaw) as CommonApiResponseType<FileContract[]>).data[0];

            editor.image.insert(fileUrl.url, null, null);

            editor.image.remove();
          },
        },
        ...configProp,
      }),
      [
        configProp,
        disabled,
        getValue,
        height,
        heightMax,
        heightMin,
        mentionableUsers,
        modal,
        name,
        onBlur,
        placeholder,
        toolbarButtons,
        tributeInit,
      ],
    );

    const onChange = useCallback(
      (text: string) => {
        if (isFocusedRef.current) {
          formOnChange(name, text.length > 0 ? text : null);
        }
      },
      [formOnChange, name],
    );

    return (
      <div className="UiInput-wrapper wysiwyg">
        <div
          className={classNames('UiInput', className, {
            error: hasErrors,
            focused,
          })}
        >
          {label && <UiMereLabel className={classNameLabel}>{label}</UiMereLabel>}

          <Suspense fallback={<div>Froala is loading...</div>}>
            <FroalaLazy config={config} model={value} onModelChange={onChange} />
            {children}
          </Suspense>
        </div>
      </div>
    );
  },
);
