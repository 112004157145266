import React, { FC, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { apiDeleteProject } from 'src/api/projects';
import { UiTopMenu, UiTopMenuProps } from 'src/components/common/TopMenu';
import { UiDeleteConfirmPopover } from 'src/components/UI/DeleteConfirmPopover';
import { UiDropdownProps } from 'src/components/UI/inputs/DropDown';
import { UiProjectsDropdown } from 'src/components/UI/ProjectsDropdown';
import { UiToast } from 'src/components/UI/toasts';
import { useRequest } from 'src/hooks/useRequest';
import { IconDelete } from 'src/pages/Projects/icons/Delete';
import { getRouteProjectsCreatePhasesPage, getRouteProjectViewPage, ROUTE_PROJECTS_PAGE } from 'src/routes/paths';
import useGlobalStore from 'src/store';
import useProjectsStore from 'src/pages/Projects/store';
import { showErrorToast } from 'src/utils/errors';
import { IconEdit } from './view/widgets/View/icons/Edit';

type Props = {
  menuOptions: UiTopMenuProps['options'];
  projectId: number;
};

export const ProjectViewLayout: FC<Props> = ({ menuOptions, projectId }) => {
  const { apiCaller } = useRequest();

  const navigate = useNavigate();

  const { projectData } = useProjectsStore((state) => ({
    projectData: state.project.response,
    updateProject: state.updateProject,
  }));

  const recalculateFirstProjectId = useGlobalStore((state) => state.recalculateFirstProjectId);

  const onProjectChange = useCallback(
    (value: UiDropdownProps['options'][0] | null) => {
      if (value?.value) {
        navigate(getRouteProjectViewPage(value.value));
      }
    },
    [navigate],
  );

  return (
    <div className="projectLayoutView">
      <UiTopMenu options={menuOptions} />

      <div className="action-buttons">
        <UiProjectsDropdown
          defaultValue={projectId}
          onChange={onProjectChange}
          isSearchable={false}
          isClearable={false}
        />
        {projectData && (
          <Link to={getRouteProjectsCreatePhasesPage(projectData.id)} className="editButton">
            <IconEdit />
          </Link>
        )}
        <UiDeleteConfirmPopover
          placement="left"
          wrapperClassName="deleteProjectWrapper"
          onConfirm={() => {
            if (projectId) {
              apiCaller(apiDeleteProject, {
                projectId,
              })
                .then(() => {
                  UiToast.commonSuccessDeleted();

                  recalculateFirstProjectId(projectId);

                  navigate(ROUTE_PROJECTS_PAGE);
                })
                .catch(showErrorToast);
            } else {
              UiToast.warning('No projectId');
            }
          }}
        >
          <div className="deleteProject">
            <IconDelete />
          </div>
        </UiDeleteConfirmPopover>
      </div>
    </div>
  );
};
