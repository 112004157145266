import { TaskContract, TaskStatusEnumContract } from '@moonpanda/moonpanda.contracts';
import classNames from 'classnames';
import { ReplaceOperation } from 'fast-json-patch/module/core';
import React, { FC, useEffect } from 'react';

import { apiUpdateTask } from 'src/api/tasks';
import { UiCheckbox } from 'src/components/UI/inputs/Checkbox';
import { useRequest } from 'src/hooks/useRequest';
import taskPageStyles from 'src/pages/Tasks/styles.module.scss';
import { showErrorToast } from 'src/utils/errors';
import { prepareDataToPatch } from 'src/utils/http';
import { useStateForm } from 'src/utils/stateForm';

type Props = {
  status: TaskStatusEnumContract;
  taskId: number;
  onSuccess: (task: TaskContract) => void;
  projectColor: string;
};

type FormValues = {
  done: boolean;
};

export const TasksDoneChangeComponent: FC<Props> = ({ status, taskId, onSuccess, projectColor }) => {
  const formProps = useStateForm<FormValues>({
    defaultValues: {
      done: status === 'Completed',
    },
  });

  const { apiCaller } = useRequest();

  useEffect(() => {
    formProps.setValue('done', status === 'Completed');
  }, [formProps, status]);

  return (
    <UiCheckbox
      formProps={formProps}
      name="done"
      wrapperClassName={classNames('mb-0', taskPageStyles.checkbox)}
      onChange={(_, checked) => {
        const op: ReplaceOperation<TaskStatusEnumContract>[] = [
          {
            op: 'replace',
            path: '/status',
            value: checked ? 'Completed' : 'InProgress',
          },
        ];

        apiCaller(
          apiUpdateTask,
          prepareDataToPatch(op, {
            taskId,
          }),
        )
          .then(({ data }) => {
            onSuccess(data);
          })
          .catch(showErrorToast);
      }}
      colorOnChecked={projectColor}
    />
  );
};
