import { JwtTokenContract, OAuthSignInResponseContract, ProfileDetailsContract } from '@moonpanda/moonpanda.contracts';
import { useGoogleLogin } from '@react-oauth/google';
import isFunction from 'lodash/isFunction';
import { FC, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { apiAuthenticationSignInGoogle } from 'src/api/authentication';
import { IconGoogle } from 'src/components/icons/Google';
import { UiToast } from 'src/components/UI/toasts';
import { UiButton } from 'src/components/UI/UiButton';
import { useRequest } from 'src/hooks/useRequest';
import { useSignInUser } from 'src/pages/SignUp/common';
import { showErrorToast } from 'src/utils/errors';
import { useTHelper } from 'src/utils/i18n';

import styles from './styles.module.scss';

type Props = {
  onFillProfileData: (data: ProfileDetailsContract, jwt: JwtTokenContract) => void;
};

export const GoogleAuthButton: FC<Props> = ({ onFillProfileData }) => {
  const tHelper = useTHelper('pages.signIn');

  const { apiCaller } = useRequest();

  const [loading, setLoading] = useState(false);

  const [searchParams] = useSearchParams();

  const signInUser = useSignInUser();

  const googleLogin = useGoogleLogin({
    onSuccess: (response) => {
      setLoading(true);

      const dataToSend = {
        accessToken: response.access_token,
        referrerId: +(searchParams.get('referrerId') || 0),
      };

      apiCaller(apiAuthenticationSignInGoogle, dataToSend)
        .then(({ data }) => {
          const goNext = (signInData: OAuthSignInResponseContract) => {
            if (signInData.needFillProfileData && isFunction(onFillProfileData)) {
              onFillProfileData(signInData.signUpProfile as ProfileDetailsContract, signInData.jwt as JwtTokenContract);
            } else {
              signInUser(signInData.jwt as JwtTokenContract);
            }
          };

          goNext(data);
        })
        .catch((e) => {
          setLoading(false);

          showErrorToast(e);
        });
    },
    onError: (errorResponse) => {
      UiToast.error(`Login failed: "${errorResponse.error}: ${errorResponse.error_description}"`);
    },
  });

  return (
    <UiButton type="button" disabled={loading} onClick={() => googleLogin()} className={styles.googleBtn}>
      <div>
        <IconGoogle />
      </div>
      {tHelper(loading ? 'googleLoading' : 'google')}
    </UiButton>
  );
};
