/* eslint-disable max-len */
import { FC } from 'react';
import { CommonIconProps } from 'src/utils/iconProps';

type Props = CommonIconProps & {
  onClick?: () => void;
  fill?: 'currentColor';
};

export const IconDelete: FC<Props> = ({ className, onClick, fill }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="14"
    viewBox="0 0 10 12"
    fill="none"
    className={className}
    onClick={onClick}
  >
    <path
      d="M2.33984 11.05C2.09984 11.05 1.89979 10.96 1.71979 10.79C1.54979 10.62 1.45984 10.41 1.45984 10.17V1.91004H0.839844V1.22004H3.43982V0.790039H7.34979V1.22004H9.94983V1.91004H9.32983V10.17C9.32983 10.41 9.23982 10.61 9.06982 10.79C8.89982 10.96 8.68983 11.05 8.44983 11.05H2.32983H2.33984ZM8.64984 1.91004H2.14984V10.17C2.14984 10.22 2.16984 10.27 2.20984 10.3C2.24984 10.34 2.28984 10.35 2.33984 10.35H8.45984C8.45984 10.35 8.54984 10.33 8.58984 10.29C8.62984 10.25 8.64984 10.21 8.64984 10.16V1.90004V1.91004ZM3.89984 9.08004H4.58984V3.18004H3.89984V9.08004ZM6.20984 9.08004H6.89984V3.18004H6.20984V9.08004Z"
      fill={fill || '#C6C6C6'}
    />
  </svg>
);
