import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { CommentContract, ProjectContract } from '@moonpanda/moonpanda.contracts';

import {
  apiTaskCommentsCreate,
  apiTaskCommentsDelete,
  apiTaskCommentsGet,
  apiTaskCommentsUpdate,
} from 'src/api/websocket';
import { IconSend } from 'src/components/common/modals/ViewTask/components/chat/icons/Send';
import { TaskMessage } from 'src/components/common/modals/ViewTask/components/chat/Message';
import useTaskCommentsStore from 'src/components/common/modals/ViewTask/store';
import { UiScrollable } from 'src/components/UI/InfiniteScroll';
import { UiWYSIWYG } from 'src/components/UI/inputs/WYSIWYG';
import { UiScrollbar } from 'src/components/UI/Scrollbar';
import { UiButton } from 'src/components/UI/UiButton';
import { cleanWYSIWYGHtml } from 'src/utils/formatters';
import { SafeAnyType } from 'src/utils/safeAny';
import { useStateForm } from 'src/utils/stateForm';
import { RecursiveNullable } from 'src/utils/types';
import useGlobalStore from 'src/store';

import styles from 'src/components/common/modals/ViewTask/components/chat/styles.module.scss';

type FormValues = RecursiveNullable<{
  text: string;
}>;

type Props = {
  taskId: number;
  projectId: number;
  projectData: ProjectContract;
};

export const TaskChat: FC<Props> = ({ taskId, projectId, projectData }) => {
  const formProps = useStateForm<FormValues>();

  const ref = useRef<Scrollbars>();

  const [page, setPage] = useState(0);

  const [isCommentSaving, setIsCommentSaving] = useState(false);

  const [loading, setLoading] = useState(false);

  const scrollToBottom = () => {
    // lag
    setTimeout(() => {
      ref.current?.scrollToBottom();
    }, 0);
  };

  const editableComment = useRef<CommentContract | null>(null);

  const { init, loadComments, addComment, updateComment, removeComment, clear, comments } = useTaskCommentsStore(
    (state) => ({
      init: state.init,
      loadComments: state.load,
      addComment: state.add,
      updateComment: state.update,
      removeComment: state.remove,
      clear: state.clear,
      comments: state.messages,
    }),
  );

  useEffect(() => {
    if (comments.totalCount) {
      scrollToBottom();
    }
  }, [comments.totalCount]);

  useEffect(() => {
    init(taskId);

    return () => {
      clear();
      editableComment.current = null;
    };
  }, [clear, init, taskId]);

  const getMentionedUserIds = useCallback((str: string): number[] => {
    const res = str
      .match(/data-userid="(\d+)"/gm)
      ?.map((value) => value.match(/\d+/gm))
      .map(Number)
      .flat();

    return res ? [...new Set(res)] : [];
  }, []);

  const createComment = useCallback(
    (formData: FormValues) =>
      apiTaskCommentsCreate({
        id: 0,
        isNewForCurrentUser: false,
        createdAt: new Date(),
        content: cleanWYSIWYGHtml(formData.text as string),
        author: null,
        authorAvatar: null,
        taskId,
        projectId,
        mentionedUserIds: getMentionedUserIds(formData.text || ''),
      }).then((response) => {
        addComment(response.data);

        // scrollToBottom();
      }),
    [addComment, getMentionedUserIds, projectId, taskId],
  );

  const editComment = useCallback(
    (formData: FormValues) => {
      const comment: CommentContract = {
        ...(editableComment.current as CommentContract),
        content: cleanWYSIWYGHtml(formData.text as string),
        mentionedUserIds: getMentionedUserIds(formData.text || ''),
      };

      return apiTaskCommentsUpdate(comment).then((response) => updateComment(response.data));
    },
    [getMentionedUserIds, updateComment],
  );

  const onCreateComment = useCallback(
    (formData: FormValues) => {
      if (formData.text) {
        setIsCommentSaving(true);

        formProps.setValue('text', '');

        (editableComment.current ? editComment(formData) : createComment(formData))
          .catch(() => null)
          .finally(() => {
            setIsCommentSaving(false);
            editableComment.current = null;
          });
      }
    },
    [createComment, editComment, formProps],
  );

  const getData = useCallback(() => {
    ref.current?.scrollTop(30); // depends on UiScrollable target component

    setLoading(true);

    apiTaskCommentsGet(
      {
        page,
        pageSize: 10,
      },
      taskId,
    )
      .then((response) => {
        setPage((p) => p + 1);

        loadComments(response);
      })
      .catch(() => null)
      .finally(() => {
        setLoading(false);
      });
  }, [loadComments, page, taskId]);

  const userId = useGlobalStore((state) => state.user.response?.userId);

  const handleDeleteComment = useCallback(
    (data: CommentContract) => {
      setIsCommentSaving(true);

      apiTaskCommentsDelete(data)
        .then(() => {
          removeComment(data.id);
        })
        .catch(() => null)
        .finally(() => setIsCommentSaving(false));
    },
    [removeComment],
  );

  const handleEditComment = useCallback(
    (data: CommentContract) => {
      editableComment.current = data;
      formProps.setValue('text', data.content);
    },
    [formProps],
  );

  return (
    <div className={styles.wrapper}>
      <div className={styles.messages}>
        <UiScrollbar height={100} innerRef={ref as SafeAnyType}>
          <UiScrollable data={comments} getData={getData} loading={loading} isReverse>
            {comments.data.map((message) => (
              <TaskMessage
                key={message.id}
                comment={message}
                canEditAndRemove={message.author?.userId === userId}
                onDelete={handleDeleteComment}
                onEdit={handleEditComment}
              />
            ))}
          </UiScrollable>
        </UiScrollbar>
        <div className={styles.scrollfix} />
      </div>
      <UiWYSIWYG
        formProps={formProps}
        name="text"
        className={styles.chat}
        heightMax={80}
        heightMin={80}
        modal
        toolbarButtons={useMemo(
          () => [
            'insertImage',
            'insertVideo',
            'insertLink',
            'emoticons',
            'bold',
            'italic',
            'underline',
            'formatUL',
            'formatOL',
          ],
          [],
        )}
        config={useMemo(() => ({ toolbarSticky: false }), [])}
        mentionableUsers={useMemo(
          () =>
            projectData.team
              // ignore users who haven't accepted the invitation
              .filter(({ userId }) => userId !== 0)
              .map(({ userId, name, avatar }) => ({
                value: userId,
                label: name,
                avatar: avatar?.url || null,
              })),
          [projectData.team],
        )}
      >
        <UiButton className={styles.submit} onClick={formProps.onSubmit(onCreateComment)} disabled={isCommentSaving}>
          <IconSend />
        </UiButton>
      </UiWYSIWYG>
    </div>
  );
};
