import { JwtTokenContract } from '@moonpanda/moonpanda.contracts';

import { useNavigate } from 'react-router-dom';
import { useRequest } from 'src/hooks/useRequest';
import { ROUTE_INDEX_PAGE } from 'src/routes/paths';
import useGlobalStore from 'src/store';
import { showErrorToast } from 'src/utils/errors';
import { setHttpJwtToken } from 'src/utils/http';
import { setJwtToken } from 'src/utils/localStorage';

export const useSignInUser = () => {
  const { apiCaller } = useRequest();

  const navigate = useNavigate();

  const authUser = useGlobalStore((state) => state.authUser);

  return (tokenData: JwtTokenContract, onlySetJwt = false) =>
    new Promise((resolve, reject) => {
      setJwtToken(tokenData);

      setHttpJwtToken(tokenData.jwt);

      if (!onlySetJwt) {
        authUser(apiCaller)
          .then(() => {
            navigate(ROUTE_INDEX_PAGE, { replace: true });

            resolve('ok');
          })
          .catch((e) => {
            showErrorToast(e);
            reject(e);
          });
      } else {
        resolve('ok');
      }
    });
};
