/* eslint-disable max-len */
import { FC } from 'react';

export const IconDelete: FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="18" viewBox="0 0 16 18" fill="none">
    <path
      d="M3.12085 17.8204C2.72085 17.8204 2.38082 17.6804 2.09082 17.3904C1.80082 17.1004 1.66089 16.7604 1.66089 16.3604V2.55043H0.630859V1.40043H4.97083V0.69043H11.5109V1.40043H15.8508V2.55043H14.8208V16.3604C14.8208 16.7604 14.6809 17.1004 14.3909 17.3904C14.1009 17.6804 13.7608 17.8204 13.3608 17.8204H3.12085ZM13.6709 2.55043H2.81091V16.3604C2.81091 16.4504 2.84088 16.5304 2.90088 16.5804C2.96088 16.6404 3.04085 16.6704 3.12085 16.6704H13.3608C13.4408 16.6704 13.5108 16.6404 13.5708 16.5704C13.6408 16.5004 13.6709 16.4304 13.6709 16.3604V2.55043ZM5.73083 14.5304H6.88086V4.67043H5.73083V14.5304ZM9.59082 14.5304H10.7408V4.67043H9.59082V14.5304Z"
      fill="#455A63"
    />
  </svg>
);
