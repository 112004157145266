/* eslint-disable max-len */
import { FC } from 'react';

export const IconPlus: FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
    <path
      d="M12.7907 20.7401H15.0507V15.5201H20.3007V13.2601H15.0507V7.79013H12.7907V13.2601H7.35071V15.5201H12.7907V20.7401ZM13.8407 27.6201C11.9807 27.6201 10.2407 27.2701 8.6207 26.5801C7.0007 25.8901 5.5807 24.9301 4.3707 23.7201C3.1607 22.5101 2.20071 21.0901 1.51071 19.4701C0.820712 17.8501 0.470703 16.1101 0.470703 14.2501C0.470703 12.3901 0.820712 10.6401 1.51071 9.02011C2.20071 7.40011 3.1607 5.99012 4.3707 4.78012C5.5807 3.58012 7.0007 2.62014 8.6207 1.92014C10.2407 1.22014 11.9807 0.870117 13.8507 0.870117C15.7207 0.870117 17.4607 1.22014 19.0807 1.92014C20.7007 2.62014 22.1207 3.57011 23.3207 4.77011C24.5207 5.97011 25.4807 7.39013 26.1807 9.01013C26.8807 10.6301 27.2307 12.3801 27.2307 14.2501C27.2307 16.1201 26.8807 17.8601 26.1807 19.4801C25.4807 21.1001 24.5207 22.5101 23.3207 23.7201C22.1207 24.9301 20.7007 25.8801 19.0807 26.5801C17.4607 27.2801 15.7207 27.6301 13.8507 27.6301L13.8407 27.6201ZM13.8407 24.6601C16.7207 24.6601 19.1707 23.6501 21.2007 21.6301C23.2207 19.6101 24.2307 17.1501 24.2307 14.2401C24.2307 11.3301 23.2207 8.91013 21.2007 6.88013C19.1807 4.86013 16.7207 3.85013 13.8107 3.85013C10.9007 3.85013 8.48071 4.86013 6.45071 6.88013C4.43071 8.90013 3.42072 11.3601 3.42072 14.2701C3.42072 17.1801 4.43071 19.6001 6.45071 21.6301C8.47071 23.6501 10.9307 24.6601 13.8407 24.6601Z"
      fill="#3F5B64"
    />
  </svg>
);
