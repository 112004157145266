import { ProfileDetailsContract } from '@moonpanda/moonpanda.contracts';
import i18next from 'i18next';
import { NavigateFunction } from 'react-router/dist/lib/hooks';
import useGanntStore from 'src/components/UI/gantt/store';
import { create } from 'zustand';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { SafeAnyType } from 'src/utils/safeAny';
import { deleteQueryParamQuiet, getQueryParamQuiet } from 'src/utils/queryParams';
import useProjectsListStore from 'src/components/UI/ProjectsDropdown/store';
import useModalsStore from 'src/store/modals';
import {
  apiAccountChangeSubscription,
  apiAccountGetUserInfo,
  apiPaymentsCompleteCheckoutSession,
} from 'src/api/account';
import { apiSignalRStart, apiSignalRStop } from 'src/api/websocket';
import { UiToast } from 'src/components/UI/toasts';
import { FNPropsType } from 'src/hooks/useRequest';
import { BaseStoreEntityType, baseStoreEntityValue } from 'src/store/types';
import { showErrorToast } from 'src/utils/errors';
import { ROUTE_SIGN_IN_PAGE } from 'src/routes/paths';
import { removeJwtToken } from 'src/utils/localStorage';
import { apiGetProjectList } from 'src/api/projects';
import { setHttpJwtToken } from 'src/utils/http';
import { userGuidingIdentify } from 'src/utils/outerStatistics/userGuiding';
import { apiGetTimeLogsByTask } from 'src/api/timeLogs';

type Actions = {
  setUser: (data: BaseStoreEntityType<ProfileDetailsContract | null>) => void;
  changeUser: (data: Partial<ProfileDetailsContract>) => void;
  authUser: (apiCaller: FNPropsType) => Promise<void>;
  logOut: (navigate: NavigateFunction) => void;

  recalculateFirstProjectId: (projectId: number) => void;

  openSidebar: () => void;
  subscribeOnSidebarOpen: (fn: () => void) => void;
  unsubscribeOnSidebarOpen: (fn: () => void) => void;

  addTaskWithTimer: (taskId: number) => void;
  removeTaskWithTimer: (taskId: number) => void;

  changeSubscription: () => Promise<void>;
};

type State = {
  user: BaseStoreEntityType<ProfileDetailsContract | null>;
  userHasProjects: boolean;
  firstProjectId: number | null;

  onSidebarOpenActions: Set<() => void>;

  tasksWithTimers: number[];
};

const initialState: State = {
  user: baseStoreEntityValue(null, true),
  userHasProjects: false,
  firstProjectId: null,

  onSidebarOpenActions: new Set(),

  tasksWithTimers: [],
};

const useGlobalStore = create<State & Actions>()((set, get) => ({
  ...initialState,

  recalculateFirstProjectId: (projectId) => {
    if (get().firstProjectId === projectId) {
      apiGetProjectList({
        page: 0,
        pageSize: 1,
        orderBy: [{ propertyName: 'id', sortingOrder: 'Desc' }],
      }).then((projects) => {
        if (projects.data) {
          set({
            firstProjectId: projects.data[0].id,
          });
        }
      });
    }
  },

  openSidebar: () => {
    get().onSidebarOpenActions.forEach((value, key) => {
      key();
    });
  },
  subscribeOnSidebarOpen: (fn) => {
    set((state) => {
      state.onSidebarOpenActions.add(fn);

      return state;
    });
  },
  unsubscribeOnSidebarOpen: (fn) => {
    set((state) => {
      state.onSidebarOpenActions.delete(fn);

      return state;
    });
  },

  setUser: (data) => {
    set({
      user: data,
    });
  },
  changeUser: (data) => {
    set((state) => ({
      user: {
        ...state.user,
        response: {
          ...state.user.response,
          ...(data as ProfileDetailsContract),
        },
      },
    }));
  },
  authUser: async (apiCaller) =>
    new Promise((resolve, reject) => {
      (async () => {
        try {
          /** stripe query response */
          const stripeSessionId = getQueryParamQuiet('session_id');

          const stripeSuccess = getQueryParamQuiet('success') === 'true';

          if (stripeSessionId) {
            if (stripeSuccess) {
              UiToast.success(i18next.t('common.stripeSuccess'));
            } else {
              useModalsStore.getState().toggleModals('trialEnded', true);
            }

            await apiPaymentsCompleteCheckoutSession({
              sessionId: stripeSessionId,
              success: stripeSuccess,
            });

            deleteQueryParamQuiet(['session_id', 'success']);
          }
          /** end stripe query response */

          /** load account data */
          const { data } = await apiCaller(apiAccountGetUserInfo);

          userGuidingIdentify(data);

          /** get 1 project to see if the user has some projects */
          const { data: projects } = await apiGetProjectList({
            page: 0,
            pageSize: 1,
            orderBy: [{ propertyName: 'id', sortingOrder: 'Desc' }],
          });

          get().userHasProjects = projects.length === 1;
          get().firstProjectId = projects.length === 1 ? projects[0].id : null;

          /** load tasks having live(active) timers */
          apiGetTimeLogsByTask({
            pageSize: 1000,
            page: 0,
            taskId: 0,
            filters: [
              {
                propertyName: 'endTime',
                operation: 'Equals',
                value: null as unknown as string,
              },
            ],
          })
            .then((timeLogs) => {
              set({
                tasksWithTimers: timeLogs.data.map(({ taskId }) => taskId),
              });
            })
            .catch(showErrorToast);

          try {
            await apiSignalRStop();

            await apiSignalRStart();
          } catch (e) {
            UiToast.warning("Couldn't connect to SignalR");

            reject(new Error("Couldn't connect to SignalR"));
          }

          get().setUser({
            loading: false,
            response: data,
            error: null,
          });

          if (!data.isActive && !(stripeSessionId && stripeSuccess)) {
            useModalsStore.getState().toggleModals('trialEnded', true);
          }

          if (data.subscriptionStatus === 'Frozen') {
            useModalsStore.getState().toggleModals('paymentLock', true);
          }

          useProjectsListStore.getState().load();

          resolve();
        } catch (e: SafeAnyType) {
          get().setUser({
            loading: false,
            response: null,
            error: null,
          });

          showErrorToast("Couldn't start the app");

          reject(new Error("Couldn't start the app"));
        }
      })();
    }),
  logOut: (navigate) => {
    set({
      ...initialState,
      user: {
        ...initialState.user,
        loading: false,
      },
    });

    setHttpJwtToken(null);

    removeJwtToken();

    navigate(ROUTE_SIGN_IN_PAGE);

    // clear all stores
    useProjectsListStore.getState().clear();
    useGanntStore.getState().clear();
  },

  addTaskWithTimer: (taskId) => {
    set((state) => ({
      ...state,
      tasksWithTimers: [...state.tasksWithTimers, taskId],
    }));
  },
  removeTaskWithTimer: (taskId) => {
    set((state) => ({
      ...state,
      tasksWithTimers: state.tasksWithTimers.filter((id) => id !== taskId),
    }));
  },

  changeSubscription: () =>
    apiAccountChangeSubscription({
      returnUrl: window.location.href,
    }).then((response) => {
      window.location.href = response.data.url;
    }),
}));

export default useGlobalStore;
