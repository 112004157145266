import {
  JwtTokenContract,
  SignInContract,
  SignUpContract,
  SignUpResponseContract,
  OAuthSingInContract,
  OAuthSignInResponseContract,
  EmailValidationContract,
  ChangePasswordContract,
  ResetPasswordContract,
} from '@moonpanda/moonpanda.contracts';

import { ApiFunctionType, httpPost } from 'src/utils/http';

const getPath = (path: string) => `Authentication/${path}`;

export const apiAuthenticationRefreshTokenUrl = getPath('refresh_token');

export const apiAuthenticationSignUp: ApiFunctionType<
  SignUpContract & { referrerId?: number },
  SignUpResponseContract
> = (data) => httpPost(getPath(`signup${data.referrerId ? `?referrerId=${data.referrerId}` : ''}`), data);

export const apiAuthenticationSignIn: ApiFunctionType<SignInContract, JwtTokenContract> = (data) =>
  httpPost(getPath('signin'), data);

export const apiAuthenticationRefreshToken: ApiFunctionType<JwtTokenContract, JwtTokenContract> = (data) =>
  httpPost(apiAuthenticationRefreshTokenUrl, data);

export const apiAuthenticationSignInGoogle: ApiFunctionType<OAuthSingInContract, OAuthSignInResponseContract> = (
  data,
) => httpPost(getPath('signin/google'), data);

export const apiAuthenticationSignInMicrosoft: ApiFunctionType<OAuthSingInContract, OAuthSignInResponseContract> = (
  data,
) => httpPost(getPath('signin/microsoft'), data);

export const apiCheckEmailExists: ApiFunctionType<EmailValidationContract, unknown> = (data) =>
  httpPost(getPath('signup/email_validation'), data);

export const apiResetPassword: ApiFunctionType<ResetPasswordContract, void> = (data) =>
  httpPost(getPath('reset_password'), data);

export const apiChangePassword: ApiFunctionType<ChangePasswordContract, JwtTokenContract> = (data) =>
  httpPost(getPath('change_password'), data);
