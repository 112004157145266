import { FC, useCallback, useState } from 'react';

import { UiModal } from 'src/components/UI/Modals';
import { UiButton } from 'src/components/UI/UiButton';
import useGlobalStore from 'src/store';
import { showErrorToast } from 'src/utils/errors';
import { useTHelper } from 'src/utils/i18n';

import styles from './styles.module.scss';

export const PaymentLockModal: FC = () => {
  const tHelper = useTHelper('modals.paymentLock');

  const changeSubscription = useGlobalStore((state) => state.changeSubscription);

  const [loading, setLoading] = useState(false);

  const onSubscriptionChange = useCallback(() => {
    setLoading(true);

    changeSubscription()
      .catch(showErrorToast)
      .finally(() => {
        setLoading(false);
      });
  }, [changeSubscription]);

  return (
    <UiModal onClose={() => null} contentClassName={styles.content} noClose noCustomScrollBar>
      <div className={styles.bodyContent}>
        <h2>{tHelper('header')}</h2>
        <UiButton loading={loading} onClick={onSubscriptionChange}>
          {tHelper('button')}
        </UiButton>
      </div>
    </UiModal>
  );
};
