import { EventContract } from '@moonpanda/moonpanda.contracts';

import { ApiPaginateRequestType } from 'src/models/API';
import { ApiFunctionType, httpDelete, httpGet, httpPost, httpPut } from 'src/utils/http';

const getPath = (eventId?: number) => `Events${eventId ? `/${eventId}` : ''}`;

export const apiGetEvent: ApiFunctionType<{ id: number }, EventContract> = (data) => httpGet(getPath(data.id), data);

export const apiGetEvents: ApiFunctionType<ApiPaginateRequestType, EventContract[]> = (data) =>
  httpGet(getPath(), data);

export const apiCreateEvent: ApiFunctionType<EventContract, EventContract> = (data) => httpPost(getPath(), data);

export const apiDeleteEvent: ApiFunctionType<{ id: number }, EventContract> = (data) =>
  httpDelete(getPath(data.id), data);

export const apiUpdateEvent: ApiFunctionType<EventContract, EventContract> = (data) => httpPut(getPath(data.id), data);
