import { TimeLogContract } from '@moonpanda/moonpanda.contracts';
import { ReplaceOperation } from 'fast-json-patch/module/core';

import { ApiPaginateRequestType, ApiPatchOperationType, CommonApiResponseType } from 'src/models/API';
import { ApiFunctionType, httpDelete, httpGet, httpPatch, httpPost, prepareDataToPatch } from 'src/utils/http';
import { addTimerInfo, removeTimerInfo } from 'src/utils/sessionStorage';

const getPath = (taskId: number, timeLogId?: number) => `tasks/${taskId}/TimeLogs${timeLogId ? `/${timeLogId}` : ''}`;

export const apiGetTimeLogsByTask: ApiFunctionType<ApiPaginateRequestType & { taskId: number }, TimeLogContract[]> = (
  data,
) => httpGet(getPath(data.taskId), data);

export const apiCreateTaskTimeLog: ApiFunctionType<TimeLogContract, TimeLogContract> = (data) =>
  httpPost(getPath(data.taskId), data).then((response: CommonApiResponseType<TimeLogContract>) => {
    if (response.data.endTime === null) {
      addTimerInfo({ taskId: data.taskId, timeLogId: response.data.id });
    }

    return response;
  });

export const apiUpdateTaskTimeLog: ApiFunctionType<
  ApiPatchOperationType & { taskId: number; timeLogId: number },
  TimeLogContract
> = (data) => httpPatch(getPath(data.taskId, data.timeLogId), data);

export const apiStopTaskTimerById: ApiFunctionType<{ taskId: number; timeLogId: number }, TimeLogContract> = (data) => {
  const op: ReplaceOperation<string> = {
    op: 'replace',
    path: '/endTime',
    value: new Date().toISOString(),
  };

  return httpPatch(getPath(data.taskId, data.timeLogId), prepareDataToPatch([op], data)).then(
    (response: CommonApiResponseType<TimeLogContract>) => {
      removeTimerInfo({ taskId: response.data.taskId, timeLogId: response.data.id });

      return response;
    },
  );
};

/**
 * stop all timers when the page either being reloaded or closed or the browser being closed
 */
export const apiStopTaskTimersByIds: (
  data: { taskId: number; timeLogId: number }[],
) => Promise<Awaited<CommonApiResponseType<TimeLogContract>>[]> = (data) => Promise.all(data.map(apiStopTaskTimerById));

export const apiDeleteTaskTimeLog: ApiFunctionType<{ taskId: number; timeLogId: number }, TimeLogContract> = (data) =>
  httpDelete(getPath(data.taskId, data.timeLogId), data);
