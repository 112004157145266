import { GanttStatic } from '@moonpanda/dhtmlx-gantt';

import styles from 'src/components/UI/gantt/styles.module.scss';
import { GANTT_CREATE_TASK_ID } from 'src/components/UI/gantt/config/index';

export const getGanttLayout = (gantt: GanttStatic, singleProject: boolean) => {
  if (singleProject) {
    gantt.config.process_resource_assignments = false;

    const createTaskHTML = `
      <div class="${styles.gridCreateButtonWrapper}">
        <button id="${GANTT_CREATE_TASK_ID}" class="${styles.createTaskButton}">
          <span></span>
          Create task
        </button>
      </div>`
      .replace(/\s+/g, ' ')
      .trim();

    return {
      css: 'gantt_container',
      cols: [
        {
          rows: [
            {
              height: gantt.config.scale_height,
              css: styles.topScale,
              cols: [
                {
                  width: 445,
                  view: 'grid',
                  group: 'grids',
                },
                {
                  rows: [
                    {
                      view: 'timeline',
                      scrollX: 'scrollHor',
                    },
                  ],
                },
              ],
            },
            {
              css: `${styles.noScale} ${styles.projectLine}`,
              height: gantt.config.row_height + 9,
              cols: [
                {
                  html: createTaskHTML,
                  group: 'grids',
                  config: { scale_height: 0 },
                },
                { view: 'timeline', scrollX: 'scrollHor', config: { scale_height: 0 } },
              ],
            },
            {
              css: styles.noScale,
              cols: [
                {
                  width: 445,
                  view: 'grid',
                  group: 'grids',
                  config: { scale_height: 0 },
                  bind: 'task',
                  scrollY: 'scrollVer',
                },
                {
                  rows: [
                    {
                      view: 'timeline',
                      config: { scale_height: 0 },
                      scrollX: 'scrollHor',
                      scrollY: 'scrollVer',
                      bind: 'task',
                    },
                    { view: 'scrollbar', id: 'scrollHor' },
                  ],
                },
              ],
            },
          ],
        },
        { view: 'scrollbar', id: 'scrollVer' },
      ],
    };
  }

  return {
    css: 'gantt_container',
    cols: [
      {
        width: 445,
        rows: [{ view: 'grid', scrollY: 'scrollVer' }],
      },
      {
        rows: [
          { view: 'timeline', scrollX: 'scrollHor', scrollY: 'scrollVer' },
          // horizontal scrollbar for the timeline
          { view: 'scrollbar', id: 'scrollHor', group: 'horizontal' },
        ],
      },
      { view: 'scrollbar', id: 'scrollVer' },
    ],
  };
};
