import axios from 'axios';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { viewTaskModalQueryParam } from 'src/routes/paths';
import useModalsStore from 'src/store/modals';
import { getQueryParamQuiet } from 'src/utils/queryParams';
import { clearTimersInfo, getTimersInfo } from 'src/utils/sessionStorage';
import { apiStopTaskTimersByIds } from 'src/api/timeLogs';

export const AppSubscribes: FC = () => {
  const { t } = useTranslation();

  const toggleModals = useModalsStore(({ toggleModals }) => toggleModals);

  useEffect(() => {
    /** show view task modal */
    const rawTaskId = getQueryParamQuiet(viewTaskModalQueryParam);

    if (rawTaskId) {
      toggleModals('viewTask', true, {
        taskId: +rawTaskId,
      });
    }
    /** end show view task modal */

    /** stop timers */
    window.addEventListener('beforeunload', (e) => {
      const timersInfo = getTimersInfo();

      if (axios.defaults.headers.common.Authorization && timersInfo.length) {
        clearTimersInfo();

        apiStopTaskTimersByIds(timersInfo);

        e.preventDefault();
        e.returnValue = '';
      }
    });
    // it must be called once at start. no need to subscribe on changes
    // eslint-disable-next-line
  }, [t]);

  return null;
};
