import { CommentContract } from '@moonpanda/moonpanda.contracts';
import { CommonApiResponseType } from 'src/models/API';
import { createWithEqualityFn } from 'zustand/traditional';

type Actions = {
  init: (taskId: number) => void;
  add: (data: CommentContract) => void;
  addOrUpdate: (data: CommentContract) => void;
  load: (data: CommonApiResponseType<CommentContract[]>) => void;
  remove: (id: CommentContract['id']) => void;
  update: (data: CommentContract) => void;
  clear: () => void;
};

type State = { taskId: number; messages: CommonApiResponseType<CommentContract[]> };

const initialState: State = {
  taskId: 0,
  messages: {
    data: [],
    twoFactorConfirmation: false,
    isSuccess: true,
    errors: [],
    totalCount: 1, // more than 0 to start loading
  },
};

const useTaskCommentsStore = createWithEqualityFn<State & Actions>()(
  (set, get) => ({
    ...initialState,

    init: (taskId) => {
      set({
        taskId,
      });
    },
    clear: () => {
      set(() => initialState);
    },

    addOrUpdate: (comment) => {
      if (comment.taskId === get().taskId) {
        get().add(comment);
      }
      // update?
    },

    add: (comment) => {
      set((current) => ({
        messages: {
          ...current.messages,
          totalCount: current.messages.totalCount + 1,
          data: [...current.messages.data, comment],
        },
      }));
    },
    load: (response) => {
      set((current) => ({
        messages: {
          ...response,
          data: [...response.data.reverse(), ...current.messages.data],
        },
      }));
    },
    remove: (id) => {
      set((current) => ({
        messages: {
          ...current.messages,
          totalCount: current.messages.totalCount - 1,
          data: current.messages.data.filter((item) => item.id !== id),
        },
      }));
    },
    update: (comment) => {
      set((current) => {
        const index = current.messages.data.findIndex((item) => item.id === comment.id);

        if (!~index) {
          return current;
        }

        const updatedData = [...current.messages.data];

        updatedData.splice(index, 1, comment);

        return {
          messages: {
            ...current.messages,
            data: updatedData,
          },
        };
      });
    },
  }),
  Object.is,
);

export default useTaskCommentsStore;
