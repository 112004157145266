/* eslint-disable max-len */
import { FC } from 'react';

export const IconProjects: FC = () => (
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.5 1.18018H14V6.65018H1.5V1.18018ZM1.5 10.9502H26.5V16.4202H1.5V10.9502ZM1.5 20.7202H18.69V26.1902H1.5V20.7202Z"
      stroke="#3F5B64"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
  </svg>
);
