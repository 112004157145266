/* eslint-disable max-len */
import React, { FC } from 'react';

export const IconTasks: FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18">
    <path
      d="m9.5,0C4.25,0,0,4.25,0,9.5s4.25,9.5,9.5,9.5,9.5-4.25,9.5-9.5C18.99,4.26,14.74,0,9.5,0Zm3.71,8.21l-4,4c-.39.39-1.02.39-1.41,0l-2-2c-.38-.4-.37-1.03.02-1.41.39-.37,1-.37,1.39,0l1.29,1.29,3.29-3.29c.4-.38,1.03-.37,1.41.02.37.39.37,1,0,1.39Z"
      fill="#3F5B64"
    />
  </svg>
);
